// CustomerSelector.js
// copyright @artiss.co

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';
import { Typography } from '@material-ui/core';
import * as colors from '../Colours';
//import user from '../../context/user';

/**
 * @component CustomerSelector
 * @description Widget to select a customer. Works with CustomerContext, CustomerContextProvider
 * @property {object} user user data, set from userContext
 * @property {function} onSelected parent function, called when user selects the customer
 * @return {undefined} 
 * @example <CustomerSelector user={user} onSelected={setCustomer} />
 */

class CustomerSelector extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            selected: "",//null
            isOpen: false,//select
        };
    }

    componentDidMount() {
        this.getList();
    }    

    getList = () => {
        let user = this.props.user;
        //console.log("CustomerSelector getList user: ", user);
        if (!user) return;
        APIHandler.callPostAPI({callname: "web_get_user_profile", requestData: {}, authtoken: user.idtoken, callback: this.onListUpdated});
    }      

    onListUpdated = (response) => {
        console.log("CustomerSelector onListUpdated: response: ", response);
        let processedList = []

        //process response into list of customers + groups
        let assignedList = (response.data && response.data.assigned ? response.data.assigned : []);
        for (let i=0; i < assignedList.length; i++) {
            let assignedItem = assignedList[i];
            if (assignedItem.scope==="customer") {
                let customer = {
                    name: assignedItem.name, 
                    scope: assignedItem.scope, 
                    scope_guid: assignedItem.scope_guid, 
                    customer_filter_criteria: {
                        customer_guid: assignedItem.scope_guid
                    }
                };
                processedList.push(customer);
            }
            else if (assignedItem.scope==="customer_group") {
                let group = {
                    name: assignedItem.name, 
                    scope: assignedItem.scope, 
                    scope_guid: assignedItem.scope_guid,
                    customer_filter_criteria: {
                        customer_group_guid: assignedItem.scope_guid
                    }
                };
                processedList.push(group);
                let members = assignedItem.members;
                for (let j=0; j < members.length; j++) {
                    //assumed to be customer
                    let member = {name: members[j].customer_name, scope: "customer", scope_guid: members[j].customer_guid, parent_guid: group.scope_guid};
                    processedList.push(member);
                }
            }
            else {
                console.log("onListUpdated ERROR! Incorrect assignedItem.scope: ", assignedItem.scope);
            }
        }

        //if the list has more than 1 entry, add ALL
        if (processedList.length > 1) {
            processedList.unshift({
                name: "ALL", 
                scope: "ALL", 
                scope_guid: "ALL",
                customer_filter_criteria: {}                
            });
            processedList.unshift({
                name: "Select Provider", 
                scope: "ALL", //"NA", 
                scope_guid: "ALL", //"NA",
                customer_filter_criteria: {}                
            });
        }

        console.log("onListUpdated: processedList: ", processedList);
        if (processedList.length > 0) this.setState({list: processedList, selected: processedList[0].scope_guid, selectedTuple: processedList[0]});
        else this.setState({list: processedList, selected: "", selectedTuple: null});
    }

    onSelected = (event) => {  //aka handleInputChange
        //console.log("onSelected this.props: ", this.props);
        let selectedTuple = Utils.getJSONObjectFromArrayForKeyValue(this.state.list, "scope_guid", event.target.value);
        //console.log("onSelected: selectedTuple: ", event.target.value, ":", selectedTuple)
        if (selectedTuple.scope==="NA") return;//skip
        this.setState({selected: event.target.value, selectedTuple: selectedTuple});
        if (this.props.onSelected) {
            console.log("calling this.props.onSelected: ", selectedTuple);
            this.props.onSelected(selectedTuple);
        }
    }

    onOpen = (event) => {
        this.setState({isOpen: true})
    }

    onClose = (event) => {
        this.setState({isOpen: false})
    }

    render() {
        const {classes} = this.props
        return(         
            <FormControl className={classes.formControl} m={0} p={0}>
                <InputLabel shrink htmlFor="selector" style={{color: "white"}}>
                Provider
                </InputLabel>{/* retaining this for sticky header fix! */}
                <Select
                    labelId="selector" 
                    id="selector"
                    value={this.state.selected}
                    onChange={this.onSelected} 
                    onOpen={this.onOpen}
                    onClose={this.onClose}                           
                    //MenuProps={{ disablePadding: true, dense: true }}    
                    //SelectDisplayProps={{ disablePadding: true, dense: true, fontSize: 'small' }}
                    className={classes.select}
                    m={0} p={0}
                >                    
                    {this.state.list.map((listItem, i) => (
                        (this.state.isOpen===true) 
                        ? (listItem.scope==="customer_group" || (listItem.scope==="ALL" && listItem.name==="ALL")) 
                            ? <MenuItem classes={{root: classes.menuItemRoot,selected: classes.menuItemSelected}} 
                                className={classes.menuItemGroup} dense={true} value={listItem.scope_guid} key={i}><ArrowDropDownIcon />{listItem.name}</MenuItem> 
                            : (listItem.scope==="ALL" && listItem.name!=="ALL")
                                ? <MenuItem classes={{root: classes.menuItemRoot,selected: classes.menuItemSelected}} 
                                    className={classes.menuItem} dense={true} value={listItem.scope_guid} key={i}>{listItem.name}</MenuItem> 
                                : <MenuItem classes={{root: classes.menuItemRoot,selected: classes.menuItemSelected}} 
                                    className={classes.menuItem} dense={true} value={listItem.scope_guid} key={i}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{listItem.name}</MenuItem>
                        : (listItem.scope==="customer_group" || (listItem.scope==="ALL" && listItem.name==="ALL"))
                            ? <MenuItem classes={{root: classes.menuItemRoot,selected: classes.menuItemSelected}} 
                                className={classes.menuItemGroup} dense={true} value={listItem.scope_guid} key={i}>{listItem.name}</MenuItem> 
                            : (listItem.scope==="ALL" && listItem.name!=="ALL")
                                ? <MenuItem classes={{root: classes.menuItemRoot,selected: classes.menuItemSelected}} 
                                    className={classes.menuItem} dense={true} value={listItem.scope_guid} key={i}>{listItem.name}</MenuItem> 
                                : <MenuItem classes={{root: classes.menuItemRoot,selected: classes.menuItemSelected}} 
                                    className={classes.menuItem} dense={true} value={listItem.scope_guid} key={i}>{listItem.name}</MenuItem>
                    ))}
                </Select>                
            </FormControl>
        )
    }

}

const astyle = (theme) => ({
    formControl: {
    },
    menuItemGroup: {
        fontSize: 'small',
        fontWeight: "fontWeightBold",
        backgroundColor: colors.artissdark_lighter,
        color: "white"        
    },
    menuItem: {
        fontSize: 'small',
        backgroundColor: colors.artissdark_lighter,
        color: "white"        
    },
    select: {
        fontSize: 'small',
        color: "white"
    },
    menuItemRoot: {
        "&$menuItem, &$menuItem:focus, &$menuItemGroup, &$menuItemGroup:focus": {
            backgroundColor: colors.artissdark,
        },
        "&$menuItemSelected, &$menuItemSelected:focus, &$menuItemGroupSelected, &$menuItemGroupSelected:focus": {
            backgroundColor: colors.artissdark_lighter,
        },
        "&$menuItem:hover, &$menuItemGroup:hover": {
            backgroundColor: "grey",//colors.grey500,
        },
        "&$menuItemSelected:hover, &$menuItemGroupSelected:hover": {
            backgroundColor: "grey",//colors.grey500,
        },
    },
    menuItemSelected: {
    },
})

export default withStyles(astyle, {withTheme: true})(CustomerSelector)
