import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
//import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import "../../App.css";
import Card from "../widgets/Card";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import BarChartPlus from "../widgets/charts/nivo/BarChartPlus";
import BarChartHorizontalPlus from "../widgets/charts/nivo/BarChartHorizontalPlus";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from "../widgets/charts/nivo/PieChart";
//import ObservationsView from '../widgets/ObservationsView';
import StickyTitle from '../widgets/Stickytitle';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";


class GroupDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();
    let defaultDateRangeFilter = {
      filterName: "last_3_months",//year_to_date, last_four_quarters, all_data
      label: "Last 3 months",
      durationBy: "week"
    };
    this.state = {
      ...newState,
      dateRangeFilter: defaultDateRangeFilter,
      prevDateRangeFilter: defaultDateRangeFilter,
    };
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    console.log("getData this.state: ", this.state);
    let durationBy = this.state.dateRangeFilter.durationBy;
    console.log("getData durationBy: ", durationBy);

    //https://github.com/ArtissLST/customer-ui/issues/10#issuecomment-720486696
    let requestDataForGroup = {
      "table_slugs": [
        //row 1: 
        "paid_to_charge_bucket_by_payor_by_customer",
        
        //row 2:
        "paid_by_cpt_mod_by_payor_plan_by_customer",        
        
        //row 3:
        "allowed_to_charge_by_payor_plan_by_customer",//(top/bottom 5)
        "paid_to_allowed_by_payor_plan_by_customer",//(top/bottom 5)
        "adjustment_breakup_by_payor_plan_by_customer",//(top/bottom 5)
        //TODO: wrong data??
        "paid_age_bucket_by_payor_plan_by_customer",//(top/bottom 5)
        
        //row 4:
        "li_payment_amount_and_count_by_" + durationBy + "_by_customer",//month/week//quarter
        "payment_summary_by_customer",
        "denial_summary_by_customer",
        "paid_allowed_charged_summary_by_customer",

        //row 5:
        //TODO: group by lab, then by payor/state/cpt
        "paid_by_payor_by_customer",
        "paid_by_patient_profile_by_customer",
        //"paid_by_cpt_mod_by_payor_plan_by_customer",//already mentioned above; TODO: process this
        "li_payment_by_cpt_by_customer",

        //row 6 => 1
        //TODO: group by payor, then by lab
        //"allowed_to_charge_by_payor_plan_by_customer",//top/bottom//already mentioned above; TODO: process this

      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };

    //bottom charts:
    let requestDataForGroupBottom = {
      "table_slugs": [
        //row 2:
        "paid_by_cpt_mod_by_payor_plan_by_customer",

        //row 3:
        "allowed_to_charge_by_payor_plan_by_customer",//(top/bottom 5)
        "paid_to_allowed_by_payor_plan_by_customer",//(top/bottom 5)
        "adjustment_breakup_by_payor_plan_by_customer",//(top/bottom 5)
        "paid_age_bucket_by_payor_plan_by_customer",//(top/bottom 5)
        
        //row 6 => 1
        //"allowed_to_charge_by_payor_plan_by_customer",//top/bottom//already mentioned above; TODO: process this
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"bottom","limit":10}
      }      
    }

    this.setState({isLoadingCount: 2});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForGroup, authtoken: authtoken, callback: this.updateResponseForGroup});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForGroupBottom, authtoken: authtoken, callback: this.updateResponseForGroupBottom});
  }  

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
    }));
  };

  updateResponseForGroup = (response) => {
    console.log("updateResponseForGroup: ", response);
    this.setState({group: response});
    this.updateLoadingDone();
  }

  updateResponseForGroupBottom = (groupBottom) => {
    console.log("updateResponseForGroupBottom: ", groupBottom);
    this.setState({groupBottom: groupBottom});
    this.updateLoadingDone();
  }

  onChangeDateRangeFilter = (selectedFilter) => {
    console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);

    //use approp slugs based on date range criteria
    let durationBy = "";
    //switch (this.state.dateRangeFilter.filterName) {
    switch (selectedFilter.daterange.literal) {
      case "last_3_months": durationBy = "week"; break;
      case "year_to_date": durationBy = "month"; break;
      case "last_4_quarters": durationBy = "quarter"; break;
      case "all_data": durationBy = "quarter"; break;
      default: durationBy = "month"; break;//set default to month as "safe" default
    }  

    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext,
        durationBy: durationBy
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };

  render() {
    const {classes} = this.props

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };    

    let num1 = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let group = this.state.group;
    let groupBottom = this.state.groupBottom;

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;
    let dateRangeFilter = this.state.dateRangeFilter;

    if (!group || (group && group.status!=="success")) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='detailed_dashboard' 
          title={"Group Dashboard"} 
          subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}          
          callbackForDateRange={this.onChangeDateRangeFilter}           
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(group && group.status!=="success") && <div>Error loading group data</div>}
      </Container>      
    );
    else {
      console.log("group: ", group);
      console.log("groupBottom: ", groupBottom);
      console.log("this.state: ", this.state);
      console.log("this.props: ", this.props);

      let dateRangeFilter;//the one that we GOT from the latest response - or the current one while we're GETTING the next response
      if (group.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
        dateRangeFilter = this.state.dateRangeFilter;
      else if (group.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
        dateRangeFilter = this.state.prevDateRangeFilter;
      console.log("dateRangeFilter: ", dateRangeFilter);

      //data processing:
      //row 1:
      //paid_to_charge_bucket_by_payor_by_customer: 
      //-- bar chart
      //-- transpose from ins to bucket-grouped data 
      //-- 1 row per bucket - 7 rows; add payor + paid_amount? as attributes
      //let paid_to_charge_bucket_by_payor_by_customer = Utils.groupJSONObjectArrayByKeyAndRow(group.data.paid_to_charge_bucket_by_payor_by_customer, ...)
      //ALT: 1 row per payor! => no change!
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "denial_count", "Denied");
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "zero_paid", "Zero Paid");
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "from_0_to_15", "0-15 %");
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "from_15_to_50", "15-50 %");
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "from_50_to_75", "50-75 %");
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "from_75_to_100", "75-100 %");
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "gt_100", "> 100 %");
      group.data.paid_to_charge_bucket_by_payor_by_customer = Utils.copyJSONObjectArrayKey(group.data.paid_to_charge_bucket_by_payor_by_customer, "reversal_count", "Reversal");
      //group.data.paid_to_charge_bucket_by_payor_by_customer = toTitleCase
      //["denied", "zero_paid", "from_0_to_15", "from_15_to_50", "from_15_to_50", "from_50_to_75", "from_75_to_100", "gt_100", "reversal_count"]
      let keys_paid_to_charge_bucket_by_payor_by_customer = ["Denied", "Zero Paid", "0-15 %", "15-50 %", "50-75 %", "75-100 %", "> 100 %", "Reversal"];

      
      //row 2A: top charts
      //no change
      //paid_by_cpt_mod_by_payor_plan_by_customer: % allowed_to_charge - TOP / BOTTOM - 
      //-- table + hor bar chart - (move to revenue dashboard?) 
      //row 2B: bottom charts
      group.data.paid_by_cpt_mod_by_payor_plan_by_customer = Utils.addGeneratedAttribute(group.data.paid_by_cpt_mod_by_payor_plan_by_customer, 
        ["payor", "plan_type", "cpt_code", "mod1"], "payor_and_plan_type_and_cpt_code_and_mod");      
      if (groupBottom) {
        groupBottom.data.paid_by_cpt_mod_by_payor_plan_by_customer = Utils.addGeneratedAttribute(groupBottom.data.paid_by_cpt_mod_by_payor_plan_by_customer, 
          ["payor", "plan_type", "cpt_code", "mod1"], "payor_and_plan_type_and_cpt_code_and_mod");        
      }

      //row 3A: top charts
      group.data.adjustment_breakup_by_payor_plan_by_customer = Utils.addGeneratedAttribute(group.data.adjustment_breakup_by_payor_plan_by_customer, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      group.data.adjustment_breakup_by_payor_plan_by_customer = Utils.addGeneratedAttribute(group.data.adjustment_breakup_by_payor_plan_by_customer, 
        ["customer", "category"], "customer_and_category");//not used; makes chart too complex to read!
      group.data.paid_to_allowed_by_payor_plan_by_customer = Utils.addGeneratedAttribute(group.data.paid_to_allowed_by_payor_plan_by_customer, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      group.data.allowed_to_charge_by_payor_plan_by_customer = Utils.addGeneratedAttribute(group.data.allowed_to_charge_by_payor_plan_by_customer, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      //row 3B: bottom charts
      //same as above
      if (groupBottom) {
        groupBottom.data.adjustment_breakup_by_payor_plan_by_customer = Utils.addGeneratedAttribute(groupBottom.data.adjustment_breakup_by_payor_plan_by_customer, 
          ["payor", "plan_type"], "payor_and_plan_type");      
        groupBottom.data.adjustment_breakup_by_payor_plan_by_customer = Utils.addGeneratedAttribute(group.data.adjustment_breakup_by_payor_plan_by_customer, 
          ["customer", "category"], "customer_and_category");      
        groupBottom.data.paid_to_allowed_by_payor_plan_by_customer = Utils.addGeneratedAttribute(groupBottom.data.paid_to_allowed_by_payor_plan_by_customer, 
          ["payor", "plan_type"], "payor_and_plan_type");      
        groupBottom.data.allowed_to_charge_by_payor_plan_by_customer = Utils.addGeneratedAttribute(groupBottom.data.allowed_to_charge_by_payor_plan_by_customer, 
          ["payor", "plan_type"], "payor_and_plan_type");      
      }

      //TODO: paid_age_bucket_by_payor_plan 
      //-- hor bar chart
      //-- DATA ISSUE https://github.com/ArtissLST/customer-ui/issues/121
      //-- needs transposing / grouping by age bucket - single row per age bucket
      //-- use groupJSONObjectArrayByKeyAndRow
      //TODO: add lab buttons
      //top
      let paid_age_bucket_by_payor_plan_top = Utils.addGeneratedAttribute(group.data.paid_age_bucket_by_payor_plan_by_customer, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      paid_age_bucket_by_payor_plan_top = Utils.addGeneratedAttribute(paid_age_bucket_by_payor_plan_top, 
        ["customer", "age_bucket"], "customer_and_age_bucket", "|");        
      let paid_age_bucket_by_payor_plan_top_reduced = Utils.groupJSONObjectArrayByKeyAndRow(paid_age_bucket_by_payor_plan_top, "customer_and_age_bucket", "payor_and_plan_type", "paid_amount");
      paid_age_bucket_by_payor_plan_top_reduced = Utils.addSplitAttributes(paid_age_bucket_by_payor_plan_top_reduced, 
        ["customer", "age_bucket"], "customer_and_age_bucket", "|");        
      let paid_age_bucket_by_payor_plan_top_keys = Utils.getUniqueKeyValueListFromJSONObjectArray(paid_age_bucket_by_payor_plan_top, "payor_and_plan_type");
      //bottom
      let paid_age_bucket_by_payor_plan_bottom, paid_age_bucket_by_payor_plan_bottom_reduced, paid_age_bucket_by_payor_plan_bottom_keys;
      if (groupBottom) {
        paid_age_bucket_by_payor_plan_bottom = Utils.addGeneratedAttribute(groupBottom.data.paid_age_bucket_by_payor_plan_by_customer, 
          ["payor", "plan_type"], "payor_and_plan_type");      
        paid_age_bucket_by_payor_plan_bottom = Utils.addGeneratedAttribute(paid_age_bucket_by_payor_plan_bottom, 
          ["customer", "age_bucket"], "customer_and_age_bucket", "|");        
        paid_age_bucket_by_payor_plan_bottom_reduced = Utils.groupJSONObjectArrayByKeyAndRow(paid_age_bucket_by_payor_plan_bottom, "customer_and_age_bucket", "payor_and_plan_type", "paid_amount");
        paid_age_bucket_by_payor_plan_bottom_reduced = Utils.addSplitAttributes(paid_age_bucket_by_payor_plan_bottom_reduced, 
          ["customer", "age_bucket"], "customer_and_age_bucket", "|");        
        paid_age_bucket_by_payor_plan_bottom_keys = Utils.getUniqueKeyValueListFromJSONObjectArray(paid_age_bucket_by_payor_plan_bottom, "payor_and_plan_type");
      }
  
      //row 4:
      //-- trend / linechart - does grouping by week
      console.log("render: dateRangeFilter.durationBy: ", dateRangeFilter.durationBy);
      let trend_li_payment_amount_and_count = group.data["li_payment_amount_and_count_by_" + dateRangeFilter.durationBy + "_by_customer"];
      console.log("trend_li_payment_amount_and_count: ", trend_li_payment_amount_and_count);
      trend_li_payment_amount_and_count = Utils.addGeneratedAttribute(trend_li_payment_amount_and_count, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      console.log("trend_li_payment_amount_and_count: ", trend_li_payment_amount_and_count);
      trend_li_payment_amount_and_count._meta = group._meta["li_payment_amount_and_count_by_" + dateRangeFilter.durationBy + "_by_customer"];

      //row 5:
      //TODO: group by lab, then by payor/state/cpt
      
      //row 6:
      //TODO: group by payor, then by lab

      let periodDisplay = group.dates.period_display;

      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='detailed_dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 
              serverdaterange={periodDisplay}
              title={"Group Dashboard"} 
              subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}    
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
              <Tab label="Summary" {...TabHandler.a11yProps(1)} />
              <Tab label="Top Payors" {...TabHandler.a11yProps(1)} />
              <Tab label="Bottom Payors" {...TabHandler.a11yProps(2)} />
            </Tabs>
          </AppBar>

          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper} p={0} m={0}>           
              <Box className={classes.chartRow}>
                <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                    dataInput={trend_li_payment_amount_and_count} meta={trend_li_payment_amount_and_count._meta} 
                    title="Revenue Trends" subtitle="$ - Payment" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                    groupBy="customer" keyYAxis="payment_amount" labelForY="Payment Amount" />            
                <PieChart type="full_pie" width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                    dataInput={group.data.payment_summary_by_customer}  meta={group._meta.payment_summary_by_customer} 
                    title="Revenue" subtitle="$ - Payment" indexBy="customer" keyName="payment_amount" />
              </Box>  
              <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 1.25} height={chartHeight} scheme="nivo"                
                      dataInput={group.data.denial_summary_by_customer} meta={null} 
                      title="Denial" subtitle="%" indexBy="customer" keys={["denial_percent"]} averageCharacterWidth={8} />
                <BarChartHorizontal width={chartWidth * 1.25} height={chartHeight} scheme="nivo"                
                      dataInput={group.data.paid_allowed_charged_summary_by_customer} meta={null} 
                      title="Allowed" subtitle="%" indexBy="customer" keys={["allowed_amount_percent"]} averageCharacterWidth={8} />
              </Box>  

              <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 3} height={chartHeight} scheme="nivo" groupMode="stacked"
                  dataInput={Utils.groupJSONObjectArrayByKeyAndRow(group.data.paid_by_payor_by_customer, "customer", "payor", "paid_amount")} reverseData={false} 
                  slugData={group.data.paid_by_payor_by_customer}
                  meta={group._meta.paid_by_payor_by_customer}
                  title="Top Payors" subtitle="$ - Paid" legends={true} legendDirection="column" averageCharacterWidth={8}
                  indexBy="customer" 
                  keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.paid_by_payor_by_customer, "payor")}
                  //keys={["paid_amount"]}
                />   

              </Box>
              <Box className={classes.chartRow}>

                <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked"
                  dataInput={Utils.groupJSONObjectArrayByKeyAndRow(
                    Utils.addGeneratedAttribute(group.data.paid_by_patient_profile_by_customer, [
                      "patient_state","age_bucket","patient_gender"], "patient_demographics", ","),
                    "customer", "patient_demographics", "paid_amount")} 
                  reverseData={false} 
                  slugData={group.data.paid_by_patient_profile_by_customer}
                  meta={group._meta.paid_by_patient_profile_by_customer}
                  title="Top Patient Demographics" subtitle="$ - Paid" legends={true} legendDirection="column" averageCharacterWidth={8}
                  indexBy="customer" 
                  keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.paid_by_patient_profile_by_customer, "patient_demographics")}
                  //keys={["paid_amount"]}
                />   

                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="nivo" groupMode="stacked"
                    dataInput={Utils.groupJSONObjectArrayByKeyAndRow(group.data.li_payment_by_cpt_by_customer,
                      "customer", "cpt_code", "payment_amount")} 
                    reverseData={false} 
                    slugData={group.data.li_payment_by_cpt_by_customer}
                    meta={group._meta.li_payment_by_cpt_by_customer}
                    title="Top CPT Codes" subtitle="$ - Payment" legends={true} legendDirection="column" averageCharacterWidth={8}
                    indexBy="customer" 
                    keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.li_payment_by_cpt_by_customer, "cpt_code")}
                  />   
                </Box>


            </Paper>
          </TabHandler.TabPanel>            

          <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper} p={0} m={0}>                
            {/* row 1 */}
            <Box className={classes.chartRow}>
              <BarChartHorizontalPlus width={chartWidth * 2.5} height={chartHeight} scheme="nivo" groupMode="stacked"
                dataInput={group.data.paid_to_charge_bucket_by_payor_by_customer} reverseData={false} 
                slugData={group.data.paid_to_charge_bucket_by_payor_by_customer}
                meta={group._meta.paid_to_charge_bucket_by_payor_by_customer}
                title="Top Payors - Paid vs. Charge" subtitle="%"  legends={true} legendDirection="column"
                indexBy="payor" keys={keys_paid_to_charge_bucket_by_payor_by_customer} 
                visibleKey="customer" 
              />   
            </Box>

            {/* row 2 */}
            <Box className={classes.chartRow}>
              {/*<BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo"              
                dataInput={group.data.paid_by_cpt_mod_by_payor_plan_by_customer} 
                meta={group._meta.paid_by_cpt_mod_by_payor_plan_by_customer} 
                title="Top (payor, plan, CPT mod) combos - Allowed vs Charge" subtitle="%" 
                indexBy="payor_and_plan_type_and_cpt_code_and_mod" keys={["allowed_to_charge"]}  />*/}
              {/*<BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="nivo" groupMode="stacked"               
                dataInput={Utils.groupJSONObjectArrayByKeyAndRow(group.data.paid_by_cpt_mod_by_payor_plan_by_customer, "customer", "payor_and_plan_type_and_cpt_code_and_mod", "allowed_to_charge")} 
                meta={group._meta.paid_by_cpt_mod_by_payor_plan_by_customer} 
                title="Top (payor, plan, CPT mod) combos - Allowed vs Charge" subtitle="%" legends={false}
                indexBy="customer" 
                keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.paid_by_cpt_mod_by_payor_plan_by_customer, "payor_and_plan_type_and_cpt_code_and_mod")} 
              />*/}

            </Box>
            <Box className={classes.chartRow}>

              <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked" itemCharacterWidth={7}
                dataInput={Utils.groupJSONObjectArrayByKeyAndRow(group.data.paid_by_cpt_mod_by_payor_plan_by_customer, "payor_and_plan_type_and_cpt_code_and_mod", "customer", "allowed_to_charge")} 
                slugData={group.data.paid_by_cpt_mod_by_payor_plan_by_customer}
                meta={group._meta.paid_by_cpt_mod_by_payor_plan_by_customer} 
                title="Top (payor, plan, CPT mod) combos - Allowed vs Charge" subtitle="%" legends={true} //legendKey={"customer"} 
                indexBy="payor_and_plan_type_and_cpt_code_and_mod" 
                keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.paid_by_cpt_mod_by_payor_plan_by_customer, "customer")} 
                //visibleKey="customer"//doesnt work since customer values have been brought in as keys!
              />              

            </Box>
            <Box className={classes.chartRow}>

              {/*<BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo"                
                  dataInput={Utils.groupJSONObjectArrayByKeyAndSum(group.data.adjustment_breakup_by_payor_plan_by_customer, "payor_and_plan_type", "adj_amount")}
                  keys={["adj_amount"]} 
                  indexBy="payor_and_plan_type" 
                  meta={group._meta.adjustment_breakup_by_payor_plan_by_customer} 
                  title="Top Payors/Plans by Adjustment" subtitle="$ - Adjustment" 
              />*/}
              <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked" itemCharacterWidth={7}                             
                //dataInput={Utils.addSplitAttributes(
                  //Utils.groupJSONObjectArrayByKeyAndRow(group.data.adjustment_breakup_by_payor_plan_by_customer, "payor_and_plan_type", "customer_and_category", "adj_amount"),
                  //["customer", "category"], "customer_and_category", "+")
                //} 
                //visibleKey="customer" 
                dataInput={Utils.groupJSONObjectArrayByKeyAndRow(group.data.adjustment_breakup_by_payor_plan_by_customer, "payor_and_plan_type", "customer_and_category", "adj_amount")}
                slugData={group.data.adjustment_breakup_by_payor_plan_by_customer}
                meta={group._meta.adjustment_breakup_by_payor_plan_by_customer} 
                title="Top Payors/Plans - Adjustment" subtitle="$" legends={false} //legendKey={"customer"}
                indexBy="payor_and_plan_type"
                keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.adjustment_breakup_by_payor_plan_by_customer, "customer_and_category")} 
              />

            </Box>
            <Box className={classes.chartRow}>

              {/*<BarChartHorizontal width={chartWidth * 2.0} height={chartHeight} scheme="dark2"                
                  dataInput={group.data.paid_to_allowed_by_payor_plan_by_customer} 
                  meta={group._meta.paid_to_allowed_by_payor_plan_by_customer} 
                  title="Top Payors/Plans - Paid vs. Allowed" subtitle="%" 
                  indexBy="payor_and_plan_type" keys={["paid_to_allowed"]} />*/}
              <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked" itemCharacterWidth={7}               
                dataInput={Utils.groupJSONObjectArrayByKeyAndRow(group.data.paid_to_allowed_by_payor_plan_by_customer, "payor_and_plan_type", "customer", "paid_to_allowed")} 
                slugData={group.data.paid_to_allowed_by_payor_plan_by_customer}
                meta={group._meta.paid_to_allowed_by_payor_plan_by_customer} 
                title="Top Payors/Plans - Paid vs. Allowed" subtitle="%" legends={true} //legendKey={"customer"}
                indexBy="payor_and_plan_type"
                keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.paid_to_allowed_by_payor_plan_by_customer, "customer")} 
              />              
              {/*<BarChartHorizontal width={chartWidth} height={chartHeight} scheme="paired"                
                  dataInput={group.data.allowed_to_charge_by_payor_plan_by_customer} 
                  slugData={group.data.allowed_to_charge_by_payor_plan_by_customer}
                  meta={group._meta.allowed_to_charge_by_payor_plan_by_customer} 
                  title="Top Payors/Plans - Allowed vs. Charge" subtitle="%"  
                  indexBy="payor_and_plan_type" keys={["allowed_to_charge"]} />*/}
            
            </Box>
            <Box className={classes.chartRow}>

              <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="paired" groupMode="stacked" itemCharacterWidth={7}               
                dataInput={Utils.groupJSONObjectArrayByKeyAndRow(group.data.allowed_to_charge_by_payor_plan_by_customer, "payor_and_plan_type", "customer", "allowed_to_charge")} 
                slugData={group.data.allowed_to_charge_by_payor_plan_by_customer}
                meta={group._meta.allowed_to_charge_by_payor_plan_by_customer} 
                title="Top Payors/Plans - Allowed vs. Charge" subtitle="%" legends={true} //legendKey={"customer"}
                indexBy="payor_and_plan_type"
                keys={Utils.getUniqueKeyValueListFromJSONObjectArray(group.data.allowed_to_charge_by_payor_plan_by_customer, "customer")} 
              />              
            </Box>

            <Box className={classes.chartRow}>
              <BarChartPlus width={chartWidth * 2.5} height={chartHeight} scheme="nivo" groupMode="stacked"                
                dataInput={paid_age_bucket_by_payor_plan_top_reduced} 
                slugData={group.data.paid_age_bucket_by_payor_plan_by_customer}
                meta={group._meta.paid_age_bucket_by_payor_plan_by_customer} 
                title="Top Payors/Plans by Payment Age" subtitle="$ - Paid" 
                indexBy="age_bucket" keys={paid_age_bucket_by_payor_plan_top_keys} legends={false}
                visibleKey="customer" 
              />
            </Box>

            </Paper>
            </TabHandler.TabPanel>

            <TabHandler.TabPanel key={2} value={this.state.currentTab} index={2} className={classes.tabPanel}>
            {/* row 3B */}
            {groupBottom && (
              <Paper className={classes.sectionpaper} p={0} m={0}>                
              <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked" itemCharacterWidth={7}
                  dataInput={Utils.groupJSONObjectArrayByKeyAndRow(groupBottom.data.paid_by_cpt_mod_by_payor_plan_by_customer, "payor_and_plan_type_and_cpt_code_and_mod", "customer", "allowed_to_charge")} 
                  slugData={groupBottom.data.paid_by_cpt_mod_by_payor_plan_by_customer}
                  meta={groupBottom._meta.paid_by_cpt_mod_by_payor_plan_by_customer} 
                  title="Bottom (payor, plan, CPT mod) combos - Allowed vs Charge" subtitle="%" legends={true} //legendKey={"customer"} 
                  indexBy="payor_and_plan_type_and_cpt_code_and_mod" 
                  keys={Utils.getUniqueKeyValueListFromJSONObjectArray(groupBottom.data.paid_by_cpt_mod_by_payor_plan_by_customer, "customer")} 
                />              
              </Box>
              <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked" itemCharacterWidth={7}                             
                  dataInput={Utils.groupJSONObjectArrayByKeyAndRow(groupBottom.data.adjustment_breakup_by_payor_plan_by_customer, "payor_and_plan_type", "customer_and_category", "adj_amount")}
                  slugData={groupBottom.data.adjustment_breakup_by_payor_plan_by_customer} reverseData={true}
                  meta={groupBottom._meta.adjustment_breakup_by_payor_plan_by_customer} 
                  title="Bottom Payors/Plans - Adjustment" subtitle="$" legends={false} //legendKey={"customer"}
                  indexBy="payor_and_plan_type"
                  keys={Utils.getUniqueKeyValueListFromJSONObjectArray(groupBottom.data.adjustment_breakup_by_payor_plan_by_customer, "customer_and_category")} 
                />
              </Box>
              <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked" itemCharacterWidth={7}               
                  dataInput={Utils.groupJSONObjectArrayByKeyAndRow(groupBottom.data.paid_to_allowed_by_payor_plan_by_customer, "payor_and_plan_type", "customer", "paid_to_allowed")} 
                  slugData={groupBottom.data.paid_to_allowed_by_payor_plan_by_customer}
                  meta={groupBottom._meta.paid_to_allowed_by_payor_plan_by_customer} 
                  title="Bottom Payors/Plans - Paid vs. Allowed" subtitle="%" legends={true} //legendKey={"customer"}
                  indexBy="payor_and_plan_type"
                  keys={Utils.getUniqueKeyValueListFromJSONObjectArray(groupBottom.data.paid_to_allowed_by_payor_plan_by_customer, "customer")} 
                />              
              </Box>
              <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="paired" groupMode="stacked" itemCharacterWidth={7}               
                  dataInput={Utils.groupJSONObjectArrayByKeyAndRow(groupBottom.data.allowed_to_charge_by_payor_plan_by_customer, "payor_and_plan_type", "customer", "allowed_to_charge")} 
                  slugData={groupBottom.data.allowed_to_charge_by_payor_plan_by_customer}
                  meta={groupBottom._meta.allowed_to_charge_by_payor_plan_by_customer} 
                  title="Bottom Payors/Plans - Allowed vs. Charge" subtitle="%" legends={true} //legendKey={"customer"}
                  indexBy="payor_and_plan_type"
                  keys={Utils.getUniqueKeyValueListFromJSONObjectArray(groupBottom.data.allowed_to_charge_by_payor_plan_by_customer, "customer")} 
                />              
              </Box>
              <Box className={classes.chartRow}>
                <BarChartPlus width={chartWidth * 2.5} height={chartHeight} scheme="nivo" groupMode="stacked"                
                  dataInput={paid_age_bucket_by_payor_plan_bottom_reduced} 
                  slugData={groupBottom.data.paid_age_bucket_by_payor_plan_by_customer}
                  meta={groupBottom._meta.paid_age_bucket_by_payor_plan_by_customer} 
                  title="Bottom Payors/Plans by Payment Age" subtitle="$ - Paid" 
                  indexBy="age_bucket" keys={paid_age_bucket_by_payor_plan_bottom_keys} legends={false}
                  visibleKey="customer"                   
                />
              </Box>

              </Paper>              

            )}
            </TabHandler.TabPanel>
        </Container>
      );
    }
  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(GroupDashboard)
