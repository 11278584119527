import React from 'react'
import { Line } from '@nivo/line'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CallMadeIcon from '@material-ui/icons/CallMade';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import * as Utils from '../../../services/Utils';
import ChartInspector from '../../../devtools/ChartInspector';
import ExpandedChartPopover from '../expanded/ExpandedChartPopover';
import * as colors from '../../../Colours';

//import { SvgIcon } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';

//export default 
class LineChart extends React.Component  {
    constructor(props) {    
        super(props);
        this.state = {
            displayExpandedChart: false,
        }
    }  

    openExpandedChart = () => {
        if (this.props.inceptionLevel!=1)
            this.setState({displayExpandedChart: true})
    };

    closeExpandedChart = () => {
        this.setState({displayExpandedChart: false})
    };
        
    render() {
        const {classes} = this.props;
        try {

            //let title = this.props.title ? this.props.title : this.props.meta.display_title;
            let title = this.props.title ? (this.props.title + (this.props.subtitle ? " (" + this.props.subtitle + ")" : "")) : this.props.meta.display_title;

            const {classes} = this.props;

            let cs1 = {
                style: "currency",
                currency: "USD",
                minimumSignificantDigits: 2,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            };    
        
            let num1 = {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            };    
        
            //console.log("LineChart props: ", this.props);

            //const data = generateDrinkStats(18)
            let xScale, xFormat, yScale, axisLeft, axisBottom;
            /*
            if (this.props.filterName==="last_7_days") {
                //xScale={type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'day',};
                //xFormat="time:%Y-%m-%d";
                //yScale={type: 'linear', stacked: false};
                axisLeft={legend: 'linear scale', legendOffset: 12, format: "~s"};
                //axisBottom={ format: '%b %d', tickValues: 'every 1 day', legend: 'time scale', legendOffset: -12,};
            }
            else if (this.props.filterName==="last_4_weeks" || this.props.filterName==="last_8_weeks") {
                //xScale={type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'day',};
                //xFormat="time:%Y-%m-%d";
                //yScale={type: 'linear', stacked: false};
                axisLeft={legend: 'linear scale', legendOffset: 12, format: "~s"};
                //axisBottom={ format: '%b %d', tickValues: 'every 1 day', legend: 'time scale', legendOffset: -12,};
            }
            */
            axisLeft={format: "~s", tickValues: (this.props.tickValues ? this.props.tickValues : 4)};//, legendOffset: 12
            //axisLeft={format: e => Math.floor(e) === e && e, tickValues: (this.props.tickValues ? this.props.tickValues : 4)}
            //https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
            //https://github.com/plouc/nivo/issues/144
            //axisBottom={format: "~s", tickValues: 5};
            //axisBottom={tickValues: 4};//doesnt work for array of dates etc!
            //https://github.com/plouc/nivo/issues/138
            //let data = this.props.dataInput;
            //reverse data 
            //1. clone. direct reverse didn't work, so trying on a clone
            //https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
            let dataInput = this.props.dataInput.map(a => ({...a}));
            //2. reverse clone
            dataInput = dataInput.reverse();
            //3. remove data from props??

            let keyXAxis = this.props.keyXAxis;
            let chartData = [];
            if (this.props.groupBy) {
                /*
                <LineChart width={chartWidth * 1.25} height={chartHeight * 0.50} scheme="nivo" 
                    dataInput={trend_paid_by_payor} meta={null} 
                    title="Top Payor Trends" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                    groupBy="payor" keyYAxis="paid_amount" labelForY="Paid Amount" /> 

                    //keyY1Axis="payment_count" labelForY1="Payment Count" 
                    //keyY2Axis="payment_amount" labelForY2="Payment Amount"
                */
                //"paid_by_payor_by_" / "paid_by_physician_by_" / "li_payment_by_cpt_by_"
                //props: groupBy, sequence
                //TODO: replicate below with groupBy, sequence
                //*** get array == dataInput = 

                //[{"payor": "AARP","interval": "01/08","paid_amount": 408.2,"interval_order": "2018-01-08T00:00:00"},

                /*
                //from groupby, get unique payor list; find how many line series need to be shown (one for each groupby value e.g. payor)
                //https://stackoverflow.com/a/45886147
                //let uniquePayors = Array.from(new Set(dataInput.map((item) => item.payor)));
                let uniquePayors = Utils.getUniqueKeyValueListFromJSONObjectArray(dataInput, "payor");
                //get unique list of "sequence" from array
                let sequenceList = Utils.getUniqueKeyValueListFromJSONObjectArray(dataInput, "interval_order");
                //order this list https://stackoverflow.com/a/45544166
                sequenceList.sort((a, b) => a.localeCompare(b));
                //labelForY1 for each series = payor name
                //keyY1Axis for each series = paid_amount. ALT: payor name?
                //within for loop, use min-max "sequence" for ordering each data point; 
                let chartData = [];
                for (let i=0; i < uniquePayors.length; i++) {
                    let processedDataForYThisPayor = [];
                    for (let j=0; j < sequenceList.length; j++) {
                        //get matching item, if available
                        let itemFound = dataInput.find(item => {
                            return item.payor == uniquePayors[i] && item.interval_order == sequenceList[j];
                        });
                        if (itemFound) {
                            processedDataForYThisPayor.push({x: item.interval, y: item.paid_amount});
                        }
                        else {
                            //add null as value
                            processedDataForYThisPayor.push({x: item.interval, y: null});
                        }
                    }
                    //{id: labelForY1, data: processedDataForY1},
                    //chartData = [{id: labelForY1, data: processedDataForY1},{id: labelForY2, data: processedDataForY2},]; 
                    chartData.push({id: uniquePayors[i], data: processedDataForYThisPayor})
                }
                */

                //from groupby, get unique payor list; find how many line series need to be shown (one for each groupby value e.g. payor)
                //https://stackoverflow.com/a/45886147
                //let uniquePayors = Array.from(new Set(dataInput.map((item) => item.payor)));
                let uniqueSeriesList = Utils.getUniqueKeyValueListFromJSONObjectArray(dataInput, this.props.groupBy);
                //console.log("uniqueSeriesList: ", uniqueSeriesList);

                //get unique list of "sequence" from array
                let sequenceList = Utils.getUniqueKeyValueListFromJSONObjectArray(dataInput, this.props.sequence);
                //console.log("sequenceList: ", sequenceList);
                //order this list https://stackoverflow.com/a/45544166
                sequenceList.sort((a, b) => a.localeCompare(b));
                //console.log("sequenceList: ", sequenceList);

                //get matching (sequence="interval_order", keyXAxis="interval") tuples - used when populating chartData for missing points
                //let sequenceKeyMatchList = Array.from(new Set(dataInput.map((item) => {item[sequence], item[keyXAxis]})));
                let sequenceKeyMatchList = Array.from(new Set(dataInput.map((item) => {return ({sequence: item[this.props.sequence], keyXAxis: item[this.props.keyXAxis]});})));
                //console.log("sequenceKeyMatchList: ", sequenceKeyMatchList);

                //labelForY1 for each series = payor name
                //keyY1Axis for each series = paid_amount. ALT: payor name?
                //within for loop, use "sequence" range of values for ordering each data point;   
                
                console.log("uniqueSeriesList: ", uniqueSeriesList);
                for (let i=0; i < uniqueSeriesList.length; i++) {
                    let processedDataForThisSeries = [];
                    for (let j=0; j < sequenceList.length; j++) {
                        //get matching item, if available
                        let itemFound = dataInput.find(item => {
                            return item[this.props.groupBy] === uniqueSeriesList[i] && item[this.props.sequence] === sequenceList[j];
                        });
                        if (itemFound) {
                            processedDataForThisSeries.push({x: itemFound[this.props.keyXAxis], y: itemFound[this.props.keyYAxis]});
                        }
                        else {
                            //-- address missing ones for each payor, so the chart shows gaps for missing rows. null == MISSING per
                            //-- https://nivo.rocks/storybook/?path=/story/line--holes-in-data
                            //add null as value - but get the matching XAxis interval!!
                            let matchingSequenceKey = Utils.getJSONObjectFromArrayForKeyValue(sequenceKeyMatchList, this.props.sequence, sequenceList[j]);
                            if (!matchingSequenceKey) {
                                console.log("MISSING matchingSequenceKey: ", title, "; sequenceKeyMatchList: ", sequenceKeyMatchList, "; sequence: ", this.props.sequence, "; sequenceList[j]: ", sequenceList[j]);
                                let intervalObject = Utils.getJSONObjectFromArrayForKeyValue(sequenceKeyMatchList, "sequence", sequenceList[j]);
                                if (intervalObject && intervalObject.keyXAxis)
                                    processedDataForThisSeries.push({x: intervalObject.keyXAxis, y: null});                           
                            }
                            else {
                                //-- create processedDataForY1 for each series - as follows
                                //processedDataForThisSeries.push({x: itemFound[keyXAxis], y: null});
                                console.log("FOUND matchingSequenceKey: ", title, "; sequenceKeyMatchList: ", sequenceKeyMatchList, "; sequence: ", this.props.sequence, "; sequenceList[j]: ", sequenceList[j]);
                                console.log("FOUND matchingSequenceKey: matchingSequenceKey: ", matchingSequenceKey);
                                console.log("FOUND matchingSequenceKey: matchingSequenceKey[this.props.keyXAxis]: ", matchingSequenceKey[this.props.keyXAxis]);
                                processedDataForThisSeries.push({x: matchingSequenceKey[this.props.keyXAxis], y: null});
                            }
                        }
                    }
                    //create chartData - as follows
                    //chartData = [{id: labelForY1, data: processedDataForY1},{id: labelForY2, data: processedDataForY2},]; 
                    chartData.push({id: uniqueSeriesList[i], data: processedDataForThisSeries});
                }            
                console.log("chartData 1: title: ", title, chartData);  
                
                //now add missing values! 
                //ref: https://github.com/plouc/nivo/issues/91
            }
            else {
                let labelForY1 = this.props.labelForY1;
                let labelForY2 = this.props.labelForY2;
                let keyY1Axis = this.props.keyY1Axis;
                let keyY2Axis = this.props.keyY2Axis;            
                let processedDataForY1 = [];
                let processedDataForY2 = [];
                if (dataInput) {
                    for (var i=0; i < dataInput.length; i++) {
                        if (keyY1Axis) processedDataForY1.push({x: dataInput[i][keyXAxis], y: dataInput[i][keyY1Axis]})
                        if (keyY2Axis) processedDataForY2.push({x: dataInput[i][keyXAxis], y: dataInput[i][keyY2Axis]})
                    }
                }   
                chartData = [];
                if (keyY1Axis) chartData.push({id: labelForY1, data: processedDataForY1});
                if (keyY2Axis) chartData.push({id: labelForY2, data: processedDataForY2});
            }

            let labelItemWidth = Utils.getLabelItemWidth(chartData, "id");
            //console.log("LineChart labelItemWidth: ", labelItemWidth);
            //let updatedWidth = Math.max(labelItemWidth * 1.7, this.props.width);        
            //let updatedWidth = labelItemWidth + this.props.width;
            const commonProperties = {
                width: this.props.width,//updatedWidth,
                height: this.props.height,//250,//400
                margin: { top: 10, bottom: 25, left: 50, right: labelItemWidth + 40},
                //margin: { top: 20, left: 30, right: 125, bottom: 30},//needed for label spacing; do not change!
                //data,
                animate: false,
                enableSlices: 'x',
            };

            //https://nivo.rocks/line/
            let legends = [];
            if (this.props.showLegend == true || (this.props.showLegend !== false && (chartData.length > 1 && chartData.length < 15)) ) {
                legends=[
                    {
                        anchor: 'top-right',
                        direction: 'column',
                        justify: false,
                        translateX: labelItemWidth + 25,
                        translateY: 0,
                        itemWidth: labelItemWidth,
                        itemHeight: 11,
                        itemOpacity: 0.75,
                        itemsSpacing: 2,
                        symbolSize: 11,
                        itemDirection: 'left-to-right',
                        itemTextColor: colors.white,//"#333333",
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ];
            }

            //https://nivo.rocks/guides/legends/
            /*
            const CustomSymbolShape = ({
                x, y, size, fill, borderWidth, borderColor
            }) => (
                <rect
                    x={x}
                    y={y}
                    //transform={`rotate(45 ${size/2} ${size/2})`}
                    fill={fill}
                    strokeWidth={borderWidth}
                    stroke={borderColor}
                    width={size}
                    height={size}
                    style={{ pointerEvents: 'none' }}
                />
            )
            */
           const tooltipIcon = ({fill}) => (
                <StopIcon />
                /*<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                    <rect width="10" height="10" fill={colors.artissdark} />
                </svg>*/
           );
            
           let isTickWhichShouldBeHidden = function(tick) {                
                //find index, then if even or odd row
                //processedDataForThisSeries.push({x: matchingSequenceKey[this.props.keyXAxis], y: null});
                //chartData.push({id: uniqueSeriesList[i], data: processedDataForThisSeries});
                //console.log("isTickWhichShouldBeHidden tick: ", tick);
                try {
                    let index = Utils.getIndexFromJSONObjectArrayForKeyValue(chartData[0].data, "x", tick);
                    if (index % 2 === 1) return true; else return false;
                }
                catch (err) {
                    console.log("isTickWhichShouldBeHidden err: ", err);
                    return true;
                }                
            }   
            axisBottom={format: tick => (isTickWhichShouldBeHidden(tick) ? '' : tick)};

            let onClick = undefined;
            //if (this.props.inceptionLevel!=1) 
                //onClick = (event, ref) => this.openExpandedChart(); 
              
            let onClickChart = undefined;
            if (this.props.inceptionLevel==1) 
                onClickChart = (node, event) => {
                    console.log("onClickChart node: ", node);
                    console.log("onClickChart event: ", event);
                    if (this.props.onClickChart) this.props.onClickChart({node: node, event: event});
                }
      
            if (chartData && chartData.length > 0) {

                //console.log("chartData 2: title: ", title, chartData);

                return (
                    <Paper className={classes.chartPaper}>
                    {this.state.displayExpandedChart &&  Utils.showExpandedChart(this.props) &&
                        <ExpandedChartPopover {...this.props} chartType="LineChart" closeExpandedChart={this.closeExpandedChart} />
                    }
                    <div onClick={onClick}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}} >
                            <Typography variant="subtitle1" align='center'>{title}</Typography>
                            &nbsp;&nbsp;
                            {this.props.inceptionLevel!=1 && Utils.showExpandedChart(this.props) && <IconButton onClick={() => {this.openExpandedChart()}}>
                            <CallMadeIcon style={{ fontSize: 14 }} />
                            </IconButton>}
                            &nbsp;&nbsp;
                            <ChartInspector _props={this.props} title={title} renderedData={dataInput} meta={this.props.meta} />
                        </div>
                        {/*<span>{this.props.meta.description}</span>*/}
                        <Line
                            onClick={onClickChart}
                            {...commonProperties}
                            data={chartData}
                            xScale={xScale}
                            yScale={yScale}
                            yFormat={".2~s"}
                            /*
                            pointLabel={value => {
                                console.log("pointLabel value: ", value);
                                //return (`${Number(value).toLocaleString('en-US')}`);
                                return (value.x + " : " + value.y);
                            }}
                            */
                            //tooltip={value => {return (value.point.serieId + ": " + value.point.data.y);}}
                            tooltip={value => {     
                                //console.log("linechart value: ", value);
                                //backgroundColor: colors.grey400
                                return (<span className={classes.tooltip} style={{ backgroundColor: "#333", color: value.point.serieColor, whiteSpace: "nowrap", display: 'flex', alignItems: 'center', padding: 2 }}>
                                    {/*{tooltipIcon(value.point.serieColor)}*/}
                                    <StopIcon />
                                    <Typography style={{ fontSize: 14, color: colors.white }}>
                                    {
                                        this.props.commentKey ? this.props.commentKey(value) 
                                        : (value.point.serieId + " - " + value.point.data.xFormatted + ": " + Number(value.point.data.y).toLocaleString('en-US'))
                                    }
                                    </Typography>
                                </span>);                      
                            }}
                           /*
                            tooltipFormat={value => {
                                console.log("tooltip value: ", value);
                                //return (`${Number(value).toLocaleString('en-US')}`);
                                //if (this.props.keys && this.props.keys[0] && this.props.keys[0].endsWith("_amount")) valueString = `${Number(value).toLocaleString('en-US', cs1)}`;
                                //let valueString = `${Number(value.point.data.y).toLocaleString('en-US', num1)}`;
                                //return (value.point.serieId + ": " + valueString);
                                return  (value.point.serieId + " - " + value.point.data.xFormatted + ": " + Number(value.point.data.y).toLocaleString('en-US'));
                            }}  
                            */
                
                            //axisLeft={axisLeft}
                            axisLeft={axisLeft}
                            axisBottom={axisBottom}
                            //axisBottom={null}
                            //curve={select('curve', curveOptions, 'monotoneX')}
                            enablePointLabel={false}
                            enableGridX={this.props.enableGridX===true ? true : false}
                            //enableGridY={this.props.enableGridY===false ? false : true}
                            enableGridY={this.props.enableGridY===true ? true : false}
                            //pointSymbol={CustomSymbol}
                            grid={{line: {stroke: "DarkSlateGrey",strokeWidth: 1,}}}                   
                            pointSize={16}
                            pointBorderWidth={1}
                            pointBorderColor={{
                                from: 'color',
                                modifiers: [['darker', 0.3]],
                            }}
                            useMesh={true}
                            enableSlices={false}
                            legends={legends}
                            theme={{
                                fontSize: '12px', 
                                textColor: colors.white, 
                                tooltip: {container: {background: '#333'}},
                                grid: {
                                  line: {
                                    stroke: "DarkSlateGrey",
                                    strokeWidth: 1,
                                  }
                                },
                            }}                            
                            colors={{ scheme: this.props.scheme }} 
                        />                            
                    </div>
                    </Paper>
                );  
            }
            else {
                return (
                    <Paper className={classes.chartPaper}>
                        <div style={{height: this.props.height, width: this.props.width}} >
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}} >
                                <Typography variant="subtitle1" align='center'>{title}</Typography>
                                <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
                            </div>
                            <div style={{marginBottom: 10}}></div>
                            <div style={{fontSize: 12, fontStyle: "italic", align: 'center'}}>
                                <Typography align='center'>{"No data available"}</Typography>                
                            </div>
                        </div>
                    </Paper>
                );  
            }      
        }
        catch (err) {
            console.log("Error rendering this card: ", {props: this.props, err: err});
            return (
                <Paper className={classes.chartPaper}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", height: this.props.height, width: this.props.width}} >
                        <Typography variant="subtitle1" align='center'>{this.props.title}</Typography>
                        <Typography variant="body1" align='center'>{"Error rendering this chart"}</Typography>
                        <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
                    </div>
                </Paper>
            );  
        }
    }
}

//TODO: create chart specific theme?
const astyle = (theme) => {
    //return DashboardHandler.getTheme(theme);
    return {
        tooltip: {
            backgroundColor: "#333",//"#303030",//colors.primarydark, //theme.palette.primary.dark,//theme.palette.primary.main,
        },  
        chartPaper: {
          margin: 5,
          padding: 0,
          elevation: 2,
          //backgroundColor: theme.palette.primary.main
          backgroundColor: theme.palette.primary.dark,
          //backgroundColor: 'transparent',
        },        
    }
}
  
export default withStyles(astyle, {withTheme: true})(LineChart)
  