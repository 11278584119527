// Modbreakout.js
// copyright @ artiss.co 

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'

import * as colors from '../../Colours'


const ModBreakout = (props) => {
    const classes = astyle();
    let {moddata} = props

    console.log("Mod ", moddata)

    let countc = {}; let modkey = []; let paymentc = {};
    for(let i=0;i<moddata.length;i++ ) {
        let k1 = moddata[i].modifier
        if(k1 === "") { k1 = "global" }
        let v1 = moddata[i].payment_count
        let p1 = moddata[i].payment_amount
        countc[k1] = v1
        paymentc[k1] = p1
        modkey.push(k1)
    }
    countc['Modifier'] = 'Count by Modifier'
    let countchart = []; 
    countchart.push(countc)
    let paidchart = []
    paymentc['Modifier'] = 'Paid by Modifier'
    paidchart.push(paymentc)
    // console.log("ICD", countchart)
    // console.log("ICDkey", icdkey)

    if(countchart.length === 0) {
        return null
    } 
    
    return(
        <Box className={classes.top}>
        
        <Typography variant="caption"> Volume breakup by Modifier </Typography>
          <ResponsiveBar
            data={countchart}
            keys={modkey}
            layout="horizontal"
            indexBy="Modifier"
            height={25}
            //margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
            //innerPadding={4}
            padding={0}
            colors={{ scheme: 'category10' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    },
                }
              }}
        />

        <div style={{marginTop: 5}} />
        
        <Typography variant="caption"> Paid breakup by Modifier </Typography>
        <ResponsiveBar
            data={paidchart}
            keys={modkey}
            layout="horizontal"
            indexBy="Modifier"
            height={45}
            margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
            //innerPadding={4}
            padding={0}
            colors={{ scheme: 'category10' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            //labelFormat={["$", ".2s"]}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    },
                }
              }}
              legends={[
                {
                    dataFrom: 'keys',
                    itemTextColor: '#ffffff',
                    height: 80,
                    anchor: 'bottom',
                    //anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: -100,
                    translateY: 20,
                    itemsSpacing: 2,
                    itemWidth: 50,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />  
            
        </Box>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    top: {
        width: '90%',
        height: 150,
        display: 'flex',
        //flex: 1,
        flexDirection: 'column',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        //backgroundColor: theme.palette.primary.dark,
        //padding: 10,
        //height: 75,
        paddingTop: 40,
        paddingBottom: 40,
        marginTop: 30,
        marginLeft: 10
    },
    scp: {
        backgroundColor: colors.secondarydark,
    },
    sctxt: {
        fontSize: 'large'
    },
    
}));

export default ModBreakout