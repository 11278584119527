// DenialDetails.js
/// copyrights @ artiss.co

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { ResponsiveTreeMap } from '@nivo/treemap'
import * as colors from '../../Colours'
import * as utils from '../../services/Utils'
import deny from '../../images/deny.png'

const DenialDetails = (props) => {
    const classes = astyle();
    let {denialsummary, denialdetails} = props    
    let tmc = {}
    tmc = {reason: 'Denial details', children: [] }
    let countarr = []; let amtarr = [];
    for(let i=0; i<denialdetails.length; i++) {
        let res = denialdetails[i].reason
        let cat = denialdetails[i].category
        // to make reason unique, adding category to reason, when reason is the same across different entriess
        // headmap graph goes into a loop
        let cnt = {reason: cat + ':' + res, value: denialdetails[i].denial_count}
        let amt = {reason: cat + ':' + res, value: denialdetails[i].denial_amount}
        countarr.push(cnt)
        amtarr.push(amt)
    }
    let cntchild = {reason:"Denialscount", children: countarr}
    let amtchild = {reason: "Denialsamount", children: amtarr}
    tmc.children.push(cntchild)
    tmc.children.push(amtchild)
    let smsg = 'attributed to'
    
    console.log( "root  is ", tmc)

    const genId = () => {
        return Math.floor(Math.random() * 1000)
    }

    let denialcnt = denialsummary[0].denial_count
    let pheight = 0
    if(denialcnt === 0) 
        {pheight = 20}
    else {pheight = 150}
    
    
    return(
        <Paper className={classes.cardpaper} style={{height: denialcnt>0 ? 150 : 50}}>
            <img src={deny} alt="rank" className={classes.denyimg} />
            {denialcnt > 0 ? 
            <>
            <Box className={classes.topo1}>
                <Typography variant="h6" component={'span'} display="inline"> {denialsummary[0].denial_count} denials 
                <Typography variant="subtitle1" display="inline"> {smsg} </Typography> 
                <Typography variant="h6" display="inline"> {denialdetails.length} </Typography>
                <Typography variant="subtitle1" display="inline"> reasons, amounting to </Typography> 
                <Typography variant="h6" display="inline"> ${denialsummary[0].denial_amount} </Typography>
                </Typography>
            </Box>
            <Box className={classes.textchart} >
                <Typography variant="body2">denial reason</Typography>
                <ResponsiveTreeMap
                    root={cntchild}
                    //identity={genId}
                    identity="reason"
                    value="value"
                    height={120}
                    //tile="slice"
                    innerPadding={3}
                    outerPadding={3}
                    margin={{ top: 0, right: 10, bottom: 10, left: 10 }}
                    label="value"
                    //labelFormat=".0s"
                    labelSkipSize={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
                    colors={{ scheme: 'nivo' }}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={11}
                    theme={{ 
                        textColor: 'white',
                        tooltip: {
                            container: {
                                background: '#333',
                            },
                        }
                      }}
                />
                </Box>
                <Box className={classes.textchart} >
                <Typography variant="body2">charged amount denied</Typography>
                <ResponsiveTreeMap
                    root={amtchild}
                    identity="reason"
                    value="value"
                    //valueFormat={["$", ".2s"]
                    //valueFormat=".02s"
                    height={120}
                    //tile="slice"
                    innerPadding={3}
                    outerPadding={3}
                    margin={{ top: 0, right: 10, bottom: 10, left: 10 }}
                    label="value"
                    //labelFormat=".0s"
                    labelSkipSize={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
                    colors={{ scheme: 'category10' }}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={11}
                    labelFormat="$.3s"
                    //labelFormat={["$", ".2s"]} 
                    //tooltipFormat={value => utils.showUSD_f1(value)}
                    //tooltip="$.3s"
                    theme={{ 
                        textColor: 'white',
                        tooltip: {
                            container: {
                                background: '#333',
                            },
                        }
                      }}
                />
                </Box>
            </>
            : 
            <Typography variant="h6" display="inline"> There were zero denials in this time period </Typography> 
            }
        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        //width: '80%',
        display: 'flex',
        //flexDirection: 'row',
        justifyContent: 'flex-start',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 5,
        margin: 10,
        // height: 150
    },
    topo1: {
        flex: 1.1,
        paddingLeft: 8
    },
    textchart: {
        flexDirection: 'column',
        flex: 1
    },
    denyimg: {
        height: 25,
        width: 25
    }
    
}));

export default DenialDetails;
