import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
//import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import "../../App.css";
import Card from "../widgets/Card";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from "../widgets/charts/nivo/PieChart";
//import ObservationsView from '../widgets/ObservationsView';
import StickyTitle from '../widgets/Stickytitle';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";


class PayorDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();
    let defaultDateRangeFilter = {
      filterName: "last_3_months",//year_to_date, last_four_quarters, all_data
      label: "Last 3 months",
      durationBy: "week"
    };
    this.state = {
      ...newState,
      dateRangeFilter: defaultDateRangeFilter,
      prevDateRangeFilter: defaultDateRangeFilter,
    };
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();     
    //if (prevState.payor && this.state.payor && (prevState.payor._time != this.state.payor._time)) {
      ////this.getData(); 
      //this.forceUpdate();
    //}
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    console.log("getData this.state: ", this.state);
    let durationBy = this.state.dateRangeFilter.durationBy;
    console.log("getData durationBy: ", durationBy);

    //https://github.com/ArtissLST/customer-ui/issues/10#issuecomment-720486696
    let requestDataForPayor = {
      "table_slugs": [
        //row 1: 
        "paid_to_charge_bucket_by_payor",
        
        //row 2:
        "paid_by_cpt_mod_by_payor_plan",      
        "li_payment_amount_count_by_modifier",  
        
        //row 3:
        "allowed_to_charge_by_payor_plan",//(top/bottom 5)
        "paid_to_allowed_by_payor_plan",//(top/bottom 5)
        "adjustment_breakup_by_payor_plan",//(top/bottom 5)
        //TODO: wrong data??
        "paid_age_bucket_by_payor_plan",//(top/bottom 5)
        
        //row 4:
        "paid_by_payor_plan_by_" + durationBy,//month/week//quarter
        "paid_by_processed_as",
        "pr_adjustment_breakup_by_payor_plan",//(top/bottom 5)        
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };

    //bottom charts:
    let requestDataForPayorBottom = {
      "table_slugs": [
        //row 2:
        "paid_by_cpt_mod_by_payor_plan",

        //row 3:
        "allowed_to_charge_by_payor_plan",//(top/bottom 5)
        "paid_to_allowed_by_payor_plan",//(top/bottom 5)
        "adjustment_breakup_by_payor_plan",//(top/bottom 5)
        "paid_age_bucket_by_payor_plan",//(top/bottom 5)
        
        //row 4:
        "pr_adjustment_breakup_by_payor_plan",//(top/bottom 5)                
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"bottom","limit":10}
      }      
    }

    this.setState({isLoadingCount: 2});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForPayor, authtoken: authtoken, callback: this.updateResponseForPayor});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForPayorBottom, authtoken: authtoken, callback: this.updateResponseForPayorBottom});
  }  

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
      //randomKey: Math.random(),
    }));
    //this.forceUpdate();
  };

  updateResponseForPayor = (response) => {
    console.log("updateResponseForPayor: ", response);
    this.setState({payor: response});
    this.updateLoadingDone();
  }

  updateResponseForPayorBottom = (payorBottom) => {
    console.log("updateResponseForPayorBottom: ", payorBottom);
    this.setState({payorBottom: payorBottom});
    this.updateLoadingDone();
  }

  onChangeDateRangeFilter = (selectedFilter) => {
    console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);

    //use approp slugs based on date range criteria
    let durationBy = "";
    //switch (this.state.dateRangeFilter.filterName) {
    switch (selectedFilter.daterange.literal) {
      case "last_3_months": durationBy = "week"; break;
      case "year_to_date": durationBy = "month"; break;
      case "last_4_quarters": durationBy = "quarter"; break;
      case "all_data": durationBy = "quarter"; break;
      default: durationBy = "month"; break;//set default to month as "safe" default
    }  

    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext,
        durationBy: durationBy
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };

  render() {
    try{
    const {classes} = this.props

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };    

    let num1 = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let payor = this.state.payor;
    let payorBottom = this.state.payorBottom;

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;
    let dateRangeFilter = this.state.dateRangeFilter;

    if (!payor || (payor && payor.status!=="success")) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='detailed_dashboard' 
          title={"Payor Dashboard"} 
          subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}          
          callbackForDateRange={this.onChangeDateRangeFilter}           
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(payor && payor.status!=="success") && <div>Error loading payor data</div>}
      </Container>      
    );
    else {
      console.log("payor: ", payor);
      console.log("payorBottom: ", payorBottom);
      console.log("this.state: ", this.state);
      console.log("this.props: ", this.props);

      let dateRangeFilter;//the one that we GOT from the latest response - or the current one while we're GETTING the next response
      if (payor.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
        dateRangeFilter = this.state.dateRangeFilter;
      else if (payor.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
        dateRangeFilter = this.state.prevDateRangeFilter;
      console.log("dateRangeFilter: ", dateRangeFilter);

      //data processing:
      //row 1:
      //paid_to_charge_bucket_by_payor: 
      //-- bar chart
      //-- transpose from ins to bucket-grouped data 
      //-- 1 row per bucket - 7 rows; add payor + paid_amount? as attributes
      //let paid_to_charge_bucket_by_payor = Utils.groupJSONObjectArrayByKeyAndRow(payor.data.paid_to_charge_bucket_by_payor, ...)
      //ALT: 1 row per payor! => no change!
      
      //row 2A: top charts
      //no change
      //paid_by_cpt_mod_by_payor_plan: % allowed_to_charge - TOP / BOTTOM - 
      //-- table + hor bar chart - (move to revenue dashboard?) 
      //row 2B: bottom charts
      payor.data.paid_by_cpt_mod_by_payor_plan = Utils.addGeneratedAttribute(payor.data.paid_by_cpt_mod_by_payor_plan, 
        ["payor", "plan_type", "cpt_code", "mod1"], "payor_and_plan_type_and_cpt_code_and_mod");      
      if (payorBottom) {
        payorBottom.data.paid_by_cpt_mod_by_payor_plan = Utils.addGeneratedAttribute(payorBottom.data.paid_by_cpt_mod_by_payor_plan, 
          ["payor", "plan_type", "cpt_code", "mod1"], "payor_and_plan_type_and_cpt_code_and_mod");        
      }

      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "denial_count", "Denied");
      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "zero_paid", "Zero Paid");
      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "from_0_to_15", "0-15 %");
      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "from_15_to_50", "15-50 %");
      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "from_50_to_75", "50-75 %");
      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "from_75_to_100", "75-100 %");
      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "gt_100", "> 100 %");
      payor.data.paid_to_charge_bucket_by_payor = Utils.copyJSONObjectArrayKey(payor.data.paid_to_charge_bucket_by_payor, "reversal_count", "Reversal");
      //["denied", "zero_paid", "from_0_to_15", "from_15_to_50", "from_15_to_50", "from_50_to_75", "from_75_to_100", "gt_100", "reversal_count"]
      let keys_paid_to_charge_bucket_by_payor = ["Denied", "Zero Paid", "0-15 %", "15-50 %", "50-75 %", "75-100 %", "> 100 %", "Reversal"];


      //row 3A: top charts
      payor.data.adjustment_breakup_by_payor_plan = Utils.addGeneratedAttribute(payor.data.adjustment_breakup_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      payor.data.paid_to_allowed_by_payor_plan = Utils.addGeneratedAttribute(payor.data.paid_to_allowed_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      payor.data.allowed_to_charge_by_payor_plan = Utils.addGeneratedAttribute(payor.data.allowed_to_charge_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      //row 3B: bottom charts
      //same as above
      if (payorBottom) {
        payorBottom.data.adjustment_breakup_by_payor_plan = Utils.addGeneratedAttribute(payorBottom.data.adjustment_breakup_by_payor_plan, 
          ["payor", "plan_type"], "payor_and_plan_type");      
        payorBottom.data.paid_to_allowed_by_payor_plan = Utils.addGeneratedAttribute(payorBottom.data.paid_to_allowed_by_payor_plan, 
          ["payor", "plan_type"], "payor_and_plan_type");      
        payorBottom.data.allowed_to_charge_by_payor_plan = Utils.addGeneratedAttribute(payorBottom.data.allowed_to_charge_by_payor_plan, 
          ["payor", "plan_type"], "payor_and_plan_type");      
      }

      //TODO: paid_age_bucket_by_payor_plan 
      //-- hor bar chart
      //-- DATA ISSUE https://github.com/ArtissLST/customer-ui/issues/121
      //-- needs transposing / grouping by age bucket - single row per age bucket
      //-- use groupJSONObjectArrayByKeyAndRow
      //top
      let paid_age_bucket_by_payor_plan_top = Utils.addGeneratedAttribute(payor.data.paid_age_bucket_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      let paid_age_bucket_by_payor_plan_top_reduced = Utils.groupJSONObjectArrayByKeyAndRow(paid_age_bucket_by_payor_plan_top, "age_bucket", "payor_and_plan_type", "paid_amount");
      let paid_age_bucket_by_payor_plan_top_keys = Utils.getUniqueKeyValueListFromJSONObjectArray(paid_age_bucket_by_payor_plan_top, "payor_and_plan_type");
      //bottom
      let paid_age_bucket_by_payor_plan_bottom, paid_age_bucket_by_payor_plan_bottom_reduced, paid_age_bucket_by_payor_plan_bottom_keys;
      if (payorBottom) {
        paid_age_bucket_by_payor_plan_bottom = Utils.addGeneratedAttribute(payorBottom.data.paid_age_bucket_by_payor_plan, 
          ["payor", "plan_type"], "payor_and_plan_type");      
        paid_age_bucket_by_payor_plan_bottom_reduced = Utils.groupJSONObjectArrayByKeyAndRow(paid_age_bucket_by_payor_plan_bottom, "age_bucket", "payor_and_plan_type", "paid_amount");
        paid_age_bucket_by_payor_plan_bottom_keys = Utils.getUniqueKeyValueListFromJSONObjectArray(paid_age_bucket_by_payor_plan_bottom, "payor_and_plan_type");
      }
  
      //row 4:
      //paid_by_payor_plan_by_ week/... 
      //-- trend / linechart - does grouping by week
      console.log("render: dateRangeFilter.durationBy: ", dateRangeFilter.durationBy);
      let trend_paid_by_payor_plan = payor.data["paid_by_payor_plan_by_" + dateRangeFilter.durationBy];
      console.log("trend_paid_by_payor_plan: ", trend_paid_by_payor_plan);
      trend_paid_by_payor_plan = Utils.addGeneratedAttribute(trend_paid_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");      
      console.log("trend_paid_by_payor_plan: ", trend_paid_by_payor_plan);
      trend_paid_by_payor_plan._meta = payor._meta["paid_by_payor_plan_by_" + dateRangeFilter.durationBy];
      
      //paid_by_processed_as - no change

      //pr_adjustment_breakup_by_payor_plan 
      //-- top 5/bottom 5 
      //-- bar chart
      //-- TBD: https://github.com/ArtissLST/customer-ui/issues/121 - may need transposing / grouping by ded/copay/coins - single row per cat? 
      //TODO: add payor + plan...
      //let pr_adjustment_breakup_by_payor_plan = Utils.groupJSONObjectArrayByKeyAndRow(payor.data.pr_adjustment_breakup_by_payor_plan, ...)
      payor.data.pr_adjustment_breakup_by_payor_plan = Utils.addGeneratedAttribute(payor.data.pr_adjustment_breakup_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");   
      if (payorBottom) {
        payorBottom.data.pr_adjustment_breakup_by_payor_plan = Utils.addGeneratedAttribute(payorBottom.data.pr_adjustment_breakup_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");   
      }

      let adjustment_breakup_by_payor_plan_top_reduced = Utils.groupJSONObjectArrayByKeyAndRow(payor.data.adjustment_breakup_by_payor_plan, "payor_and_plan_type", "category", "adj_amount");
      let adjustment_breakup_by_payor_plan_top_keys = Utils.getUniqueKeyValueListFromJSONObjectArray(adjustment_breakup_by_payor_plan_top_reduced, "category");

      let periodDisplay = payor.dates.period_display;

      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='detailed_dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 
              serverdaterange={periodDisplay}
              title={"Payor Dashboard"} 
              //subtitle={`Based on processing of ${paid_change_summary[0].curr_paid_count} Payments and ${paid_change_summary[0].curr_submission_count} Submissions`} 
              subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}    
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
              <Tab label="Summary" {...TabHandler.a11yProps(0)} />
              <Tab label="Top Payors" {...TabHandler.a11yProps(1)} />
              <Tab label="Bottom Payors" {...TabHandler.a11yProps(2)} />
            </Tabs>
          </AppBar>

          {/*<div>{this.state.randomKey}</div>*/}

          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper} p={0} m={0}>                
            {/* row 1 */}
            <Box className={classes.chartRow}>
              {trend_paid_by_payor_plan && <LineChart width={chartWidth * 2.5} height={chartHeight} scheme="nivo" 
                  dataInput={trend_paid_by_payor_plan} 
                  meta={trend_paid_by_payor_plan._meta} 
                  title="Top Payor/Plan Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  groupBy="payor_and_plan_type" keyYAxis="paid_amount" labelForY="Paid Amount" />}
              <PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={payor.data.paid_by_processed_as}  
                meta={payor._meta.paid_by_processed_as} 
                title="Payors breakup" subtitle="$ - Paid" indexBy="processed_as" keyName="paid_amount" />
            </Box>          

            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth * 2.5} height={chartHeight} scheme="nivo" groupMode="stacked"
                dataInput={payor.data.paid_to_charge_bucket_by_payor} reverseData={false} 
                meta={payor._meta.paid_to_charge_bucket_by_payor} 
                title="Top Payors - Paid vs. Charge" subtitle="%" indexBy="payor" keys={keys_paid_to_charge_bucket_by_payor} 
              />
              <PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={payor.data.li_payment_amount_count_by_modifier}  
                meta={payor._meta.li_payment_amount_count_by_modifier} 
                title="Modifiers breakup" subtitle="$ - Paid" indexBy="modifier" keyName="payment_amount" />              
            </Box>

          </Paper>
          </TabHandler.TabPanel>
          <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper}>

            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth * 1} height={chartHeight} scheme="dark2"                
                  dataInput={payor.data.paid_to_allowed_by_payor_plan} 
                  meta={payor._meta.paid_to_allowed_by_payor_plan} 
                  title="Top Payors/Plans - Paid vs. Allowed" subtitle="%" 
                  indexBy="payor_and_plan_type" keys={["paid_to_allowed"]} />
              <BarChartHorizontal width={chartWidth * 1} height={chartHeight} scheme="paired"                
                  dataInput={payor.data.allowed_to_charge_by_payor_plan} 
                  meta={payor._meta.allowed_to_charge_by_payor_plan} 
                  title="Top Payors/Plans - Allowed vs. Charge" subtitle="%" 
                  indexBy="payor_and_plan_type" keys={["allowed_to_charge"]} />
            </Box>
            <div style={{marginBottom: 10}}></div>

            <Box className={classes.chartRow}>
            <BarChart width={chartWidth * 2.0} height={chartHeight} scheme="nivo" groupMode="stacked"                
                    dataInput={paid_age_bucket_by_payor_plan_top_reduced} 
                    meta={payor._meta.paid_age_bucket_by_payor_plan} 
                    title="Top Payors/Plans by Payment Age" subtitle="$ - Paid" 
                    indexBy="age_bucket" keys={paid_age_bucket_by_payor_plan_top_keys} legends={false} />
              {/*<BarChartHorizontal width={chartWidth * 1} height={chartHeight} scheme="nivo"  groupMode="stacked"                
                  //dataInput={payor.data.adjustment_breakup_by_payor_plan.map(item => ({payor_and_plan_type: item.payor_and_plan_type, adj_amount: item.adj_amount}))}
                  //dataInput={Utils.groupJSONObjectArrayByKeyAndSum(payor.data.adjustment_breakup_by_payor_plan, "payor_and_plan_type", "adj_amount")}
                  dataInput={adjustment_breakup_by_payor_plan_top_reduced}
                  //keys={["adj_amount"]} 
                  indexBy="payor_and_plan_type" 
                  keys={adjustment_breakup_by_payor_plan_top_keys}
                  meta={payor._meta.adjustment_breakup_by_payor_plan} 
                  title="Top Payors/Plans by Adjustment" 
              />*/}
              <BarChartHorizontal width={chartWidth * 1} height={chartHeight} scheme="nivo"  groupMode="stacked"                
                  dataInput={Utils.groupJSONObjectArrayByKeyAndSum(payor.data.adjustment_breakup_by_payor_plan, "payor_and_plan_type", "adj_amount")}
                  keys={["adj_amount"]} 
                  indexBy="payor_and_plan_type" 
                  meta={payor._meta.adjustment_breakup_by_payor_plan} 
                  title="Top Payors/Plans by Adjustment" subtitle="$ - Adjustment" 
              />
              </Box>
            <div style={{marginBottom: 10}}></div>

            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth * 2.0} height={chartHeight} scheme="nivo"                
                dataInput={payor.data.paid_by_cpt_mod_by_payor_plan} 
                meta={payor._meta.paid_by_cpt_mod_by_payor_plan} 
                title="Top (payor, plan, CPT mod) combos - Allowed vs Charge"  subtitle="%" 
                indexBy="payor_and_plan_type_and_cpt_code_and_mod" 
                keys={["allowed_to_charge"]} />
              <BarChartHorizontal width={chartWidth * 1} height={chartHeight} scheme="nivo"                
                  dataInput={Utils.groupJSONObjectArrayByKeyAndSum(payor.data.pr_adjustment_breakup_by_payor_plan, "payor_and_plan_type", "adj_amount")} 
                  meta={payor._meta.pr_adjustment_breakup_by_payor_plan} 
                  title="Top Payors/Plans by PR Adjustment" subtitle="$ - Adjustment" 
                  indexBy="payor_and_plan_type" 
                  keys={["adj_amount"]} />                  
            </Box>          
          </Paper>
          </TabHandler.TabPanel>
          <TabHandler.TabPanel key={2} value={this.state.currentTab} index={2} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper}>

            <Box className={classes.chartRow}>
              {payorBottom && (<BarChartHorizontal width={chartWidth * 1.0} height={chartHeight} scheme="dark2"                
                    dataInput={payorBottom.data.paid_to_allowed_by_payor_plan} 
                    meta={payorBottom._meta.paid_to_allowed_by_payor_plan} 
                    title="Bottom Payors/Plans - Paid vs. Allowed" subtitle="%" indexBy="payor_and_plan_type" keys={["paid_to_allowed"]} />)}
              {payorBottom && (<BarChartHorizontal width={chartWidth * 1.0} height={chartHeight} scheme="paired"                
                    dataInput={payorBottom.data.allowed_to_charge_by_payor_plan} 
                    meta={payorBottom._meta.allowed_to_charge_by_payor_plan} 
                    title="Bottom Payors/Plans - Allowed vs. Charge" subtitle="%" indexBy="payor_and_plan_type" keys={["allowed_to_charge"]} />)}
            </Box>
            <Box className={classes.chartRow}>
              {paid_age_bucket_by_payor_plan_bottom_reduced && paid_age_bucket_by_payor_plan_bottom_keys && 
              <BarChart width={chartWidth * 2} height={chartHeight} scheme="nivo" groupMode="stacked"                
                dataInput={paid_age_bucket_by_payor_plan_bottom_reduced} 
                meta={payorBottom._meta.paid_age_bucket_by_payor_plan} 
                title="Bottom Payors/Plans by Payment Age" subtitle="$ - Paid" 
                indexBy="age_bucket" keys={paid_age_bucket_by_payor_plan_bottom_keys} legends={false} />}
              {payorBottom && (<BarChartHorizontal width={chartWidth * 1} height={chartHeight} scheme="nivo"    
                  dataInput={Utils.groupJSONObjectArrayByKeyAndSum(payorBottom.data.adjustment_breakup_by_payor_plan, "payor_and_plan_type", "adj_amount")}             
                  meta={payorBottom._meta.adjustment_breakup_by_payor_plan} 
                  title="Bottom Payors/Plans by Adjustment" subtitle="$ - Adjustment" indexBy="payor_and_plan_type" keys={["adj_amount"]} />)}
            </Box>
            <Box className={classes.chartRow}>
              {payorBottom && <BarChartHorizontal width={chartWidth * 2} height={chartHeight} scheme="dark2"                
                  dataInput={payorBottom.data.paid_by_cpt_mod_by_payor_plan} 
                  meta={payorBottom._meta.paid_by_cpt_mod_by_payor_plan} 
                  title="Bottom (payor, plan, CPT mod) combos - Allowed vs Charge" subtitle="%" indexBy="payor_and_plan_type_and_cpt_code_and_mod" keys={["allowed_to_charge"]} />}
              {payorBottom && <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo"                
                  dataInput={Utils.groupJSONObjectArrayByKeyAndSum(payorBottom.data.pr_adjustment_breakup_by_payor_plan, "payor_and_plan_type", "adj_amount")}             
                  meta={payorBottom._meta.pr_adjustment_breakup_by_payor_plan} 
                  title="Bottom Payors/Plans by PR Adjustment" subtitle="$ - Adjustment" indexBy="payor_and_plan_type" keys={["adj_amount"]} />}
            </Box>          
          </Paper>
          </TabHandler.TabPanel>
        </Container>
      );
    }
  
    }
    catch (err) {
      console.log(err);
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );  
    }  
  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(PayorDashboard)
