// ShowmsgtoUsers.js
// copyright @artiss.co

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


const ShowErrorMsg = (props) => {
    const [open, setOpen] = React.useState(true);
    const {msg} = props

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

    return(
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {msg}
            </Alert>
        </Snackbar>
        )   
}

const astyle = makeStyles((theme) => ({
    header: {
        width: '80%',
        display: 'flex',
        //flexDirection: 'column',
        //justifyContent: 'center',
        //alignItems: 'center',

    },
    titletxt: {
        fontSize: 'large'
    }
}));

export {ShowErrorMsg}