import React from "react";
//import firebase from 'firebase';
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from '@material-ui/core';

//import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import "./App.css";

import LoginPage from './components/login/LoginPage'
//import HomeDashboard from "./components/dashboards/HomeDashboard";

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  }
})


class App extends React.Component {

  componentDidMount () {
    
  }

  render() {
    const { classes } = this.props;
    //console.log("Height: ", window.outerHeight, "Width: ", window.outerWidth);
    return (
      <ThemeProvider theme={theme} >
        <CssBaseline />
        <BrowserRouter>
          <div className={classes.appcontainer}>
            <LoginPage />
          </div>
        </BrowserRouter>
      </ThemeProvider>      
    );
  }
}
//'@media (min-width:320px)': {
const astyle = {
  appcontainer: {
    '@media(max-width: 1000px)': {
          // textAlign: 'center',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
    }
  }
}

export default withStyles(astyle)(App);
