// Serviceclassstory.js
// copyright @artiss.co

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@material-ui/core/LinearProgress';

import { withRouter } from 'react-router'

import * as DashboardHandler from "../../dashboards/common/DashboardHandler";
//import * as colors from '../../Colours'
import UserContext from '../../../context/UserContext'
import {callPostAPI} from '../../services/APIHandler'
//import {fbanalytics} from '../../login/Fbdetails'
import {StoryHeader} from './Storyheader'
import ServiceClassNewBiz from './Serviceclassnewbiz'
import CPTICDBreakout from './Cpticdbreakout'
import StickyTitle from '../../widgets/Stickytitle';
import {ShowErrorMsg} from '../../services/ShowmsgtoUsers'

import RankCards from '../common/Rank'
import PaidChargedAllowed from '../common/PaidChrdAllwd'
import PayorPlanCombinations from '../common/PayorPlanCombo'
import DenialDetails from '../common/DenialDetails'
import RevenueBreakout from '../common/RevBreakout'
import RevenueCountTrend from '../common/RevCntTrend'
import ModBreakout from '../common/Modbreakout'
import NoDataMsg from '../common/NodataMsg'
import {componentUpdateReqd} from '../common/Storyutils'

import * as utils from '../../services/Utils'
import ChartInspector from '../../devtools/ChartInspector'

class ServiceClassStory extends React.Component {

    constructor(props) {
        super(props)
        console.log("ServiceClassstory", this.props.location.state.showdetail.guid)
        //console.log("ServiceClass customer", this.props.customer)
        this.state = { apiInprogress: false, web_slugs: null, web_slugs2: null,
                sc_story: null, daterangemsg: 'last 6 months',
                nodata_for_daterange: false
        }        
    }

    componentDidMount() {
        //console.log("CPT CDMout", this.props.location.state.showdetail.guid)
        this.updateStorySlugs()
        this.updateSCHeaders()
        this.setState({apiInprogress: true})
    }

    componentDidUpdate(prevProps) {
        let nguid = this.props.location.state.showdetail.guid
        let pguid = prevProps.location.state.showdetail.guid
        let pcust = prevProps.customer.scope_guid
        let ncust = this.props.customer.scope_guid 

        console.log( "CPT didUpdate", pguid, nguid, pcust, ncust)
        //if((pguid !== nguid) || (pcust !== ncust) ) {
        if( componentUpdateReqd(prevProps, this.props) ) {
            console.log("have to refresh")
            this.updateStorySlugs()
            this.updateSCHeaders()
            this.setState({apiInprogress: true})
        }
    }

    updateSCHeaders = (datechoice={"literal": 'last_3_months'}) => {
        let user = this.context;
        let scguid = this.props.location.state.showdetail.guid
        let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);
        let hdrpostd = {"object_guid": scguid, 
                        ...customer_filter_criteria,
                        "transaction_date": datechoice }
        callPostAPI({callname: 'web_get_service_story', requestData: hdrpostd, 
                authtoken: user.idtoken, callback: this.scStoryHdrCallback})
    }

    updateStorySlugs = (datechoice={"literal": 'last_3_months'}) => {
        console.log("datechoice", datechoice)
        let user = this.context;
        let scguid = this.props.location.state.showdetail.guid
        let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);
        //let cptguid = this.state.showcpt.guid
        let paymentbytimeslug = null
        if(datechoice.literal === "last_3_months") {
            paymentbytimeslug = "li_payment_amount_and_count_by_week"
        } else if(datechoice.literal === "last_4_quarters") {
            paymentbytimeslug = "li_payment_amount_and_count_by_month"
        } else if(datechoice.literal === "year_to_date") {
            paymentbytimeslug = "li_payment_amount_and_count_by_month"
        } else if(datechoice.literal === "all_data") {
            paymentbytimeslug = "li_payment_amount_and_count_by_quarter"
        }
        let wspostd = {
                "table_slugs": [
                    "li_business_age",
                    "denial_summary",
                    "li_icd_counts_by_cpt",
                    "li_payment_amount_by_payor",
                    "li_payment_amount_by_plan",
                    "li_payment_amount_by_physician",
                    "li_denial_count_and_amount",
                    "li_payment_amount_by_payor_and_plan_type",
                    //"li_payment_amount_and_count_by_month",      // this name will be based on the selected time range
                    paymentbytimeslug,      // this name will be based on the selected time range
            ],
            "_cache":"false",
            "filter_criteria": {
                ...customer_filter_criteria,
                "service_class": scguid,
                "transaction_date": datechoice
                //"transaction_date":{"start":"2018/01/01","end":"2018/07/01"}
            }
        }
        callPostAPI({callname: 'web_query_table', requestData: wspostd, 
                authtoken: user.idtoken, callback: this.scStorySlugCallback})

        let wspostd2 = {
            "table_slugs": [
                "paid_change_summary",
                "li_payment_amount_count_by_modifier"
            ],
            "filter_criteria": {
                ...customer_filter_criteria,
                "service_class": scguid,
                "transaction_date": datechoice
            }    
        }
        callPostAPI({callname: 'web_query_table', requestData: wspostd2, 
                authtoken: user.idtoken, callback: this.scStorySlugCallback2})
    }

    scStorySlugCallback = (sdata) => {
        console.log("service class Slug callback", sdata)
        
        if(sdata.status === 'success') {
            this.setState({web_slugs: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
                )
        }
    }

    scStorySlugCallback2 = (sdata) => {
        console.log("service class Slug callback2", sdata)
        if(sdata.status === 'success') {
            this.setState({web_slugs2: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            this.setState({apiInprogress: false, showerror:true, errmsg: sdata.errorMessage})
        }
    }

    scStoryHdrCallback = (sdata) => {
        console.log("service class Header callback", sdata)
        if(sdata.status === 'success' && sdata.status_message === "No data for this date range") {
            this.setState({nodata_for_daterange: true})
        }
        else if(sdata.status === 'success'  && (sdata.status_message === '' || sdata.status_message === '_from_cache_')) {
            this.setState({sc_story: sdata, nodata_for_daterange: false})
        } else {
            // show alert about failure
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
                )
        }
        
    }
    
    userSelectedDate = (dtobj) => {
        console.log("userSelectedDate", dtobj)
        this.updateSCHeaders(dtobj.daterange)
        this.updateStorySlugs(dtobj.daterange)
        this.setState({apiInprogress: true})
    }

    render() {
        const {classes} = this.props
        console.log("SC story render", this.props.location.state.showdetail.guid, this.props)
        let datetxt = {'last_3_months': 'last three months', 'last_4_quarters': 'last four quarters',
            'year_to_date': 'year to date', 'all_data': 'all the data' }
        let sctitle = 'Storyboard for Service Class ' + this.props.location.state.showdetail.title

        let inspectorData = { header: {}, slug: {}, slug2: {} }
        if (!process.env.REACT_APP_CHART_INSPECTOR || process.env.REACT_APP_CHART_INSPECTOR === 'True')  {    // this is for debug
            if(this.state.sc_story && this.state.web_slugs && this.state.web_slugs2) {
                for (const [k1, v1] of Object.entries(this.state.sc_story)) {
                    inspectorData['header'][k1] = v1
                }
                for (const [k2, v2] of Object.entries(this.state.web_slugs)) {
                    inspectorData['slug'][k2] = v2
                }
                for (const [k3, v3] of Object.entries(this.state.web_slugs2)) {
                    inspectorData['slug2'][k3] = v3
                }
            } 
        }
    
        if(Boolean(this.state.web_slugs)) {
            console.log("chartdata", this.state.web_slugs.data)
            let datelit = this.state.web_slugs.args.filter_criteria.transaction_date.literal
            let datetxtmsg = datetxt[datelit]
            let sdate = this.state.web_slugs.dates.start
            let edate = this.state.web_slugs.dates.end
            //let displayrange = utils.toDateStr(sdate) + " - " + utils.toDateStr(edate)
            let displayrange = this.state.web_slugs.dates.period_display
            let revnltred = ''
            if(datelit === 'last_3_months') { revnltred = 'li_payment_amount_and_count_by_week' }
            else if(datelit === 'last_4_quarters') { revnltred = 'li_payment_amount_and_count_by_month' }
            else if(datelit === 'year_to_date') { revnltred = 'li_payment_amount_and_count_by_month' }
            else if(datelit === 'all_data') { revnltred = 'li_payment_amount_and_count_by_quarter' }

            return(
                <>
                <div className={classes.stickyhdr}>
                <StickyTitle context='story' title={sctitle} 
                    callbackForDateRange={this.userSelectedDate} 
                    showProgressBar={this.state.apiInprogress} 
                    serverdaterange={displayrange}
                    />
                </div>
                {this.state.nodata_for_daterange ? 
                    <NoDataMsg /> :
                    <Container className={classes.top} > 
                    {/* {Boolean(this.state.apiInprogress) ? <LinearProgress /> : null} */}
                    {Boolean(this.state.sc_story) ? 
                        <StoryHeader  type='service class'
                                subtype={this.props.location.state.showdetail.title}
                                data={this.state.sc_story.data.header} />
                        : null }
                    <Box className={classes.allbox}>
                    <ChartInspector _props={this.props} title={''} renderedData={inspectorData} meta={null} />
                        <Box className={classes.colbl}>
                            {Boolean(this.state.sc_story) ? 
                            <>
                            <PaidChargedAllowed
                                data={this.state.sc_story.data.header}
                                comp={this.state.web_slugs2}
                                />
                            <RankCards 
                                data={this.state.sc_story.data.header}
                                type='service class'
                                />
                                </>
                                : null }
                            {Boolean(this.state.web_slugs) ?
                            <>
                            <ServiceClassNewBiz 
                                msg={{scname: this.props.location.state.showdetail.title, time: datetxtmsg}}   // this comes from the search & api call made
                                chartdata={this.state.web_slugs.data.li_business_age}
                                />
                            <PayorPlanCombinations
                                combodata={this.state.web_slugs.data.li_payment_amount_by_payor_and_plan_type}
                                type='cpt'
                                />
                            <DenialDetails 
                                denialsummary={this.state.web_slugs.data.denial_summary}
                                denialdetails={this.state.web_slugs.data.li_denial_count_and_amount}
                                />
                                </> 
                                : null}
                        </Box>
                        <Box className={classes.colbr} >
                            <Paper className={classes.colbrpaper} >
                                <RevenueBreakout
                                    payordata={this.state.web_slugs.data.li_payment_amount_by_payor}
                                    plandata={this.state.web_slugs.data.li_payment_amount_by_plan}
                                    physdata={this.state.web_slugs.data.li_payment_amount_by_physician}
                                    type='cpt'
                                    />
                                <CPTICDBreakout
                                    icdcount={this.state.web_slugs.data.li_icd_counts_by_cpt}
                                    />
                                {Boolean(this.state.web_slugs2) ?
                                    <ModBreakout 
                                        moddata={this.state.web_slugs2.data.li_payment_amount_count_by_modifier}
                                        />
                                    : null }
                                <RevenueCountTrend
                                    //trenddata={this.state.web_slugs.data.li_payment_amount_and_count_by_month}
                                    trenddata={this.state.web_slugs.data[revnltred]}
                                    />
                            </Paper>

                        </Box>
                    </Box>
                </Container>
                }
                </>
            )
        } else {
            return(
                <div className={classes.skeleton} >
                    <LinearProgress />
                    <Skeleton variant="text"  height={20}/>
                    <Skeleton variant="rect"  height={118}/>
                </div>

            )

        }
    }

}

ServiceClassStory.contextType = UserContext; 

const astyle = (theme) => ({
    top: {
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: theme.palette.primary.main,
        backgroundColor: theme.backgroundColor,
        width: '95%',
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 20,
        // height: 800
        height: '100%'
    },
    allbox: {
        display: 'flex',
        flexDirection: 'row'
        //height: 100
    },
    colbl: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    colbr: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    colbrpaper: {
        width: '95%',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 10,
        //paddingBottom: 200,
        paddingBottom: 100,
        height: '100%',
        margin: 5
    },
    skeleton: {
        margin: '10%',
        width: '75%'
    },
    drs: {
        position: 'absolute',
        right: 50,
        top: 100
    },
    breakoutimg: {
        width: 30,
        height: 30,
    },
    stickyhdr: {
        position: 'sticky',
        top: 65,
        zIndex: 10
    }
    
})


export default withStyles(astyle, {withTheme: true})(withRouter(ServiceClassStory))