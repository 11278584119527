import React from "react";
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import ChartInspector from '../../../components/devtools/ChartInspector';
import * as colors from '../../Colours';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: "50vw"
  }
});

export default function ClaimList(props) {
  const classes = useStyles();

  let rows = null;
  console.log("ClaimList props: ", props);
  if (props && props.data && props.data.data && props.data.data.claims_for_observations)
    rows = props.data.data.claims_for_observations;

  //const [dense, setDense] = React.useState(false);
  const dense = true;

  if (!rows) return (<Paper className={classes.chartPaper}><div>No claims to show</div></Paper>)
  else return (
    <Paper className={classes.chartPaper} style={{backgroundColor: colors.grey500}} >
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", width: "100%"}} >
                <Typography variant="subtitle1" align='center'>{props.title}</Typography>
                <ChartInspector _props={props} title={props.title} renderedData={props.data} meta={props._meta} />
                <Button style={{backgroundColor: colors.bluegrey600, marginTop: 5, marginBottom: 5}} onClick={() => {props.resetClaims()}}>&nbsp;&nbsp;Close&nbsp;&nbsp;</Button>
            </div>
        </div>
        <TableContainer className={classes.table} component={Paper} 
          style={{ maxHeight: 'calc(80vh)', minWidth: 'calc(95vw)', maxWidth: 'calc(95vw)', backgroundColor: colors.grey500 }}>
        <Table aria-label="customized table" 
            size={dense ? 'small' : 'medium'}>
            <TableHead>
            <TableRow style={{ height: (dense ? 33 : 53) }}>
                <StyledTableCell width="10%">Service Date</StyledTableCell>
                <StyledTableCell width="10%">Claim ID</StyledTableCell>
                <StyledTableCell width="10%">Provider</StyledTableCell>
                <StyledTableCell width="10%">Physician</StyledTableCell>
                <StyledTableCell width="10%">Patient Name</StyledTableCell>
                <StyledTableCell>Observations</StyledTableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row, i) => (
                <StyledTableRow key={row.interval + "~" + row.provider + "~" + row.observation_text + "~" + Math.random()}>
                    <StyledTableCell width="10%">{row.service_date}</StyledTableCell>
                    <StyledTableCell width="10%" component="th" scope="row">{row.claim_number}</StyledTableCell>
                    <StyledTableCell width="10%">{row.provider}</StyledTableCell>
                    <StyledTableCell width="10%">{row.physician}</StyledTableCell>
                    <StyledTableCell width="10%">{row.patient_name}</StyledTableCell>
                    <StyledTableCell>{row.string_agg}</StyledTableCell>                    
                </StyledTableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </Paper>
  );
}
