// Storyutils.js
// copyright @artiss.co

// pprops - previous prop;  nprops - new (this) prop
const componentUpdateReqd = (pprops, nprops) => {
    let nguid = nprops.location.state.showdetail.guid
    let pguid = pprops.location.state.showdetail.guid

    if((pprops.customer===null && nprops.customer!==null) || 
            (pprops.customer!==null && nprops.customer===null) || 
            (pprops.customer && nprops.customer && pprops.customer.scope_guid!==nprops.customer.scope_guid) ||
            (pguid !== nguid) ) {
                return true
            }
    return false
}

export {componentUpdateReqd}