import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "../../App.css";
import Card from "../widgets/Card";
import * as MaterialCard from "@material-ui/core/Card";
import * as MaterialCardContent from "@material-ui/core/CardContent";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from '../widgets/charts/nivo/PieChart';
//import TreeMapChart from '../widgets/charts/nivo/TreeMapChart';
import ObservationsView from '../widgets/ObservationsView';
import StickyTitle from '../widgets/Stickytitle';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";
import SummaryDashboard from './summarydashboard/SummaryDashboard'

class HomeDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();

    this.state = {
      ...newState,
      dateRangeFilter: {
        filterName: "yesterday",
        label: "Yesterday"
      },     
      displayObservationsPayments: false,
      displayObservationsCharges: false,  
    };

    //this.fontSizeCheckRef = React.createRef();
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);  
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    let requestDataForCharges = {
      "table_slugs": [
        "charge_summary","li_charge_summary","submission_summary","li_submission_summary","charge_observations","li_charge_amount_by_payor",
        "li_charge_amount_by_physician","li_charge_amount_by_cpt","charge_amount_by_age","li_charge_count_by_payor_and_plan_type","li_charge_count_by_physician",
        "li_charge_count_by_cpt","li_charge_amount_and_count_by_day","li_charge_amount_and_count_by_week","li_charge_amount_by_service_class",
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };
    let requestDataForPayments = {
      "table_slugs": [
          "li_payment_amount_and_count_by_week","li_payment_amount_and_count_by_day","li_payment_count_by_cpt",
          "li_payment_count_by_physician","li_payment_count_by_payor_and_plan_type","li_denial_count","li_payment_amount_by_cpt",
          "li_payment_amount_by_physician","li_payment_amount_by_payor","payment_amount_by_payor","payment_observations",
          "denial_summary","payment_summary","li_payment_amount_by_service_class",
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };
    let requestDataForCurrent = {
      "table_slugs": [
          "current_receivable_summary","receivable_amount_by_age","top_payors_by_receivable_amount_cols"
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "options":{"set":"top","limit":10}
      },
    };

    this.setState({isLoadingCount: 3});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForCharges, authtoken: authtoken, callback: this.updateResponseForCharges});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForPayments, authtoken: authtoken, callback: this.updateResponseForPayments});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForCurrent, authtoken: authtoken, callback: this.updateResponseForCurrent});
  }  

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
    }));
  };

  updateResponseForCharges = (response) => {
    console.log("updateResponseForCharges: ", response);
    let charges = response;
    this.setState({charges: charges});
    this.updateLoadingDone();
  }

  updateResponseForPayments = (response) => {
    console.log("updateResponseForPayments: ", response);
    let payments = response;
    this.setState({payments: payments});
    this.updateLoadingDone();
  };

  updateResponseForCurrent = (response) => {
    console.log("updateResponseForCurrent: ", response);
    let current = response;
    this.setState({current: current});
    this.updateLoadingDone();
  };

  onChangeDateRangeFilter = (selectedFilter) => {
    console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);
    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    console.log("toggleBoolean: ", booleanKey, ":", value);
    console.log("toggleBoolean: event: ", event);
    console.log("toggleBoolean: ref: ", ref);
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  processObservations = (rows) => {
    if (!rows) return rows;

    let updatedRows = [];
    let countObservation = 0, countAnomaly = 0, countAnomalyType = 0;    
     //remove needless categories
    for (let i=0; i < rows.length; i++) {
      if (rows[i].category==="Anomaly" || rows[i].category==="Threshold") {
        updatedRows.push(rows[i]);
        countObservation = countObservation + rows[i].occurrence;
        if (rows[i].category==="Anomaly") {
          countAnomaly = countAnomaly + rows[i].occurrence;
          countAnomalyType++;
        }
      }        
    };
    rows = updatedRows;
    //sort by category
    rows.sort(function (a, b) {
      return a.category.localeCompare(b.category);
    });

    rows.summary = {
      occurrence: countObservation,
      observation_type_count: rows.length,
      anomaly_count: countAnomaly,
      anomaly_type_count: countAnomalyType
    }
    //.summaryLine = `Including x anomalies of y types`;

    return rows;
  }

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };

  render() {
    try{      
    const {classes} = this.props;

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };    

    let payments = this.state.payments;
    let charges = this.state.charges;
    let current = this.state.current;
    let dateRangeFilter = this.state.dateRangeFilter;

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;

    if (!(payments && charges && current)) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='dashboard' 
          //title={this.state.dateRangeFilter.label} 
          title={"Summary Dashboard"} 
          //subtitle={this.state.dateRangeFilter.label}
          callbackForDateRange={this.onChangeDateRangeFilter} 
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      </Container>      
    );
    else if (payments.status!=="success" || charges.status!=="success" || current.status!=="success") return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='dashboard' 
          title={"Summary Dashboard"} 
          subtitle={this.state.dateRangeFilter.label}
          callbackForDateRange={this.onChangeDateRangeFilter} 
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(payments.status!=="success") && <div>Error loading payments data</div>}
      {(charges.status!=="success") && <div>Error loading charges data</div>}
      {(current.status!=="success") && <div>Error loading current data</div>}
      </Container>      
    );
    else {

      //do data processing:
      payments.data.li_payment_count_by_payor_and_plan_type = Utils.addGeneratedAttribute(payments.data.li_payment_count_by_payor_and_plan_type, ["payor", "plan_type"], "payor_and_plan_type");
      charges.data.li_charge_count_by_payor_and_plan_type = Utils.addGeneratedAttribute(charges.data.li_charge_count_by_payor_and_plan_type, ["payor", "plan_type"], "payor_and_plan_type");
      //TODO: current.data.top_payors_by_receivable_amount_cols - age, (rank - for age), payor, receivable_amount

      //process observations:
      payments.data.payment_observations = this.processObservations(payments.data.payment_observations);
      charges.data.charge_observations = this.processObservations(charges.data.charge_observations);

      payments.data.payment_amount_by_payor = Utils.copyJSONObjectArrayKey(payments.data.payment_amount_by_payor, "charge_amount", "Charge Amount");
      payments.data.payment_amount_by_payor = Utils.copyJSONObjectArrayKey(payments.data.payment_amount_by_payor, "payment_amount", "Payment Amount");
      payments.data.li_payment_amount_by_physician = Utils.copyJSONObjectArrayKey(payments.data.li_payment_amount_by_physician, "charge_amount", "Charge Amount");
      payments.data.li_payment_amount_by_physician = Utils.copyJSONObjectArrayKey(payments.data.li_payment_amount_by_physician, "payment_amount", "Payment Amount");
      payments.data.li_payment_amount_by_cpt = Utils.copyJSONObjectArrayKey(payments.data.li_payment_amount_by_cpt, "charge_amount", "Charge Amount");
      payments.data.li_payment_amount_by_cpt = Utils.copyJSONObjectArrayKey(payments.data.li_payment_amount_by_cpt, "payment_amount", "Payment Amount");
      payments.data.li_payment_amount_by_service_class = Utils.copyJSONObjectArrayKey(payments.data.li_payment_amount_by_service_class, "charge_amount", "Charge Amount");
      payments.data.li_payment_amount_by_service_class = Utils.copyJSONObjectArrayKey(payments.data.li_payment_amount_by_service_class, "payment_amount", "Payment Amount");

      //console.log("home fontSizeCheckRef: ", this.fontSizeCheckRef.current ? this.fontSizeCheckRef.current.offsetWidth : null);
      //let characterWidth = this.fontSizeCheckRef.current ? (this.fontSizeCheckRef.current.offsetWidth/26) : 6;

      current.data.top_payors_by_receivable_amount_cols = Utils.copyJSONObjectArrayKey(current.data.top_payors_by_receivable_amount_cols, "total", "receivable_amount");


      let periodDisplay = payments.dates.period_display;

      let tooltipForChargeLineChart = function(value) {
        let addendum = "";
        try {
          let intervalString = value.point.data.x;
          let dataArray = (dateRangeFilter.filterName==="last_7_days") ? charges.data.li_charge_amount_and_count_by_day : charges.data.li_charge_amount_and_count_by_week;          
          let dataObject = Utils.getJSONObjectFromArrayForKeyValue(dataArray, "interval", intervalString);
          let count = dataObject["charge_count"];
          addendum = " (from " + Number(count).toLocaleString('en-US') + " charges)";
        }
        catch (err) {
          console.log("tooltipForChargeLineChart err: ", err);
        }
        return (value.point.serieId + " - " + value.point.data.xFormatted + ": " + Number(value.point.data.y).toLocaleString('en-US', cs1) + addendum);
      } 

      let tooltipForPaymentLineChart = function(value) {
        let addendum = "";
        try {
          let intervalString = value.point.data.x;
          let dataArray = (dateRangeFilter.filterName==="last_7_days") ? payments.data.li_payment_amount_and_count_by_day : payments.data.li_payment_amount_and_count_by_week;          
          let dataObject = Utils.getJSONObjectFromArrayForKeyValue(dataArray, "interval", intervalString);
          let count = dataObject["payment_count"];
          addendum = " (from " + Number(count).toLocaleString('en-US') + " payments)";
        }
        catch (err) {
          console.log("tooltipForPaymentLineChart err: ", err);
        }
        return (value.point.serieId + " - " + value.point.data.xFormatted + ": " + Number(value.point.data.y).toLocaleString('en-US', cs1) + addendum);
      } 
  
      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 

              title={'Summary Dashboard'}
              serverdaterange={periodDisplay}
              title={"Summary Dashboard"} 
//               subtitle={
//                 //`Based on processing of ${payments.data.payment_summary[0].payment_count} Payments and ${charges.data.charge_summary[0].charge_count} Submissions`
//                 (payments.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
//                 ? this.state.dateRangeFilter.label 
//                 : (payments.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
//                 ? this.state.prevDateRangeFilter.label 
//                 : payments.args.filter_criteria.transaction_date.literal                
//               } 
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
               <Tab label='Snapshot' {...TabHandler.a11yProps(0)} />
              <Tab label="Payments" {...TabHandler.a11yProps(1)} />
              <Tab label="Charges" {...TabHandler.a11yProps(2)} />
              <Tab label="Current" {...TabHandler.a11yProps(3)} />
             
            </Tabs>
          </AppBar>

          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} 
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
              <SummaryDashboard customer={this.props.customer} />
          </TabHandler.TabPanel>
          

          <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} 
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <Paper className={classes.sectionpaper} p={0} m={0}>                
              <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0} bgcolor="bluegrey.300">
                <Card title="Paid ($)" value={payments.data.payment_summary[0].payment_amount.toLocaleString('en-US', cs1)} 
                  subtext={`Of ${payments.data.payment_summary[0].charge_amount.toLocaleString('en-US', cs1)} charged from ${payments.data.payment_summary[0].payment_count.toLocaleString('en-US')} payments`} 
                  dataInput={payments.data.payment_summary} 
                  meta={payments._meta.payment_summary} />
                <Card title="Denials ($)" value={payments.data.denial_summary[0].denial_amount.toLocaleString('en-US', cs1)} 
                  subtext={`From ${payments.data.denial_summary[0].denial_count.toLocaleString('en-US')} denials`} 
                  dataInput={payments.data.denial_summary} 
                  meta={payments._meta.denial_summary}  />
                <Card title="Observations" 
                  value={payments.data.payment_observations.summary.occurrence.toLocaleString('en-US')} 
                  subtext={`Incl. ${payments.data.payment_observations.summary.anomaly_count.toLocaleString('en-US')} anomalies of ${payments.data.payment_observations.summary.anomaly_type_count.toLocaleString('en-US')} types`} 
                  onClick={(event, ref) => this.toggleBoolean(this.state, "displayObservationsPayments", event, ref)}
                  showNotificationsIcon={true} 
                  dataInput={payments.data.payment_observations} 
                  meta={payments._meta.payment_observations} />
                <div style={{paddingLeft: 50}}></div>                  
                {dateRangeFilter.filterName==="last_7_days" ?
                    <div>
                      <LineChart filterName={dateRangeFilter.filterName} dataInput={payments.data.li_payment_amount_and_count_by_day} meta={payments._meta.li_payment_amount_and_count_by_day} 
                        width={chartWidth * 1.5} height={chartHeight * 0.5} tickValues={2}
                        title="Daily" subtitle="$ - Payment" keyXAxis="interval" keyY1Axis="payment_amount" labelForY1="Payment Amount" 
                        scheme="nivo" commentKey={tooltipForPaymentLineChart}
                      /> 
                      {/*<LineChart filterName={dateRangeFilter.filterName} dataInput={payments.data.li_payment_amount_and_count_by_day} meta={payments._meta.li_payment_amount_and_count_by_day} 
                        width={chartWidth * 1.5} height={chartHeight * 0.6}
                        title="Daily (# - Paid)" keyXAxis="interval" keyY1Axis="payment_count" labelForY1="Payment Count" 
                        scheme="nivo" 
                      />*/}
                    </div>
                : null 
                }                
                {(dateRangeFilter.filterName==="last_4_weeks" || dateRangeFilter.filterName==="last_8_weeks") ?
                  <div>
                    <LineChart filterName={dateRangeFilter.filterName} dataInput={payments.data.li_payment_amount_and_count_by_week} meta={payments._meta.li_payment_amount_and_count_by_week} 
                      width={chartWidth * 1.5} height={chartHeight * 0.5} tickValues={2}
                      title="Weekly" subtitle="$ - Payment" keyXAxis="interval" keyY1Axis="payment_amount" labelForY1="Payment Amount" 
                      scheme="nivo" commentKey={tooltipForPaymentLineChart}
                    />
                    {/*<LineChart filterName={dateRangeFilter.filterName} dataInput={payments.data.li_payment_amount_and_count_by_week} meta={payments._meta.li_payment_amount_and_count_by_week} 
                      width={chartWidth * 1.5} height={chartHeight * 0.75}
                      title="Weekly (# - Paid)" keyXAxis="interval" keyY1Axis="payment_count" labelForY1="Payment Count" 
                      scheme="nivo" 
                    />*/}
                  </div>
                : null
                }
              </Box>
              <Popover open={this.state.displayObservationsPayments} onClose={() => this.toggleBoolean(this.state, "displayObservationsPayments", null)} 
                anchorEl={this.state.anchorEl} anchorOrigin={{vertical: 'top',horizontal: 'right'}} transformOrigin={{vertical: 'top',horizontal: 'right'}}>
                <ObservationsView data={payments.data.payment_observations}>                
                </ObservationsView>
              </Popover>
              <Box className={classes.chartRow} >
                  <BarChartHorizontal width={chartWidth * 1.5} labelLengthX={50} height={chartHeight} title="Payments by Payor" subtitle="$ - Charge vs Payment" dataInput={payments.data.payment_amount_by_payor} meta={payments._meta.payment_amount_by_payor} indexBy="payor" keys={["Charge Amount", "Payment Amount"]} scheme="nivo" />
                  <BarChartHorizontal width={chartWidth} labelLengthX={25} height={chartHeight} title="Payments by Physician" subtitle="$ - Charge vs Payment" dataInput={payments.data.li_payment_amount_by_physician} meta={payments._meta.li_payment_amount_by_physician} indexBy="physician" keys={["Charge Amount", "Payment Amount"]} scheme="dark2" />
              </Box>
              {/*<div style={{marginBottom: 30}}></div>*/}
              <Box className={classes.chartRow}>
                  {/*<PieChart type="half_donut" dataInput={payments.data.li_payment_count_by_payor_and_plan_type} indexBy="payor_and_plan_type" keyName="payment_count" scheme="nivo" 
                        width={chartWidth} height={chartHeight} title="Payment count by Payor + Plan" meta={payments._meta.li_payment_count_by_payor_and_plan_type} />*/}
                  <BarChartHorizontal width={chartWidth * 1.5} labelLengthX={50} height={chartHeight} subtitle="# - Payment" title="Payment count by Payor + Plan" dataInput={payments.data.li_payment_count_by_payor_and_plan_type} meta={payments._meta.li_payment_count_by_payor_and_plan_type} indexBy="payor_and_plan_type" keys={["payment_count"]} scheme="nivo" />
                  <BarChartHorizontal width={chartWidth} labelLengthX={25} height={chartHeight} subtitle="# - Payment" title="Payment count by Physician" dataInput={payments.data.li_payment_count_by_physician} meta={payments._meta.li_payment_count_by_physician} indexBy="physician" keys={["payment_count"]} scheme="dark2" />
              </Box> 
              <Box className={classes.chartRow} >
                <BarChartHorizontal width={chartWidth} labelLengthX={10} height={chartHeight} title="Payments by CPT" subtitle="$ - Charge vs Payment" dataInput={payments.data.li_payment_amount_by_cpt} meta={payments._meta.li_payment_amount_by_cpt} indexBy="cpt_code" keys={["Charge Amount", "Payment Amount"]} scheme="paired" />                  
                <PieChart type="half_donut" dataInput={payments.data.li_payment_count_by_cpt} indexBy="cpt_code" keyName="payment_count" scheme="paired" 
                    width={chartWidth} height={chartHeight} title="Payment count by CPT" subtitle="# - Payment" meta={payments._meta.li_payment_count_by_cpt} />
                <BarChartHorizontal width={chartWidth} labelLengthX={10} height={chartHeight * 1.25} title="Payments by Service Class" subtitle="$ - Charge vs Payment" dataInput={payments.data.li_payment_amount_by_service_class} meta={payments._meta.li_payment_amount_by_service_class} indexBy="service_class" keys={["Charge Amount", "Payment Amount"]} scheme="paired" />
              </Box>
              {/*<div style={{marginBottom: 20}}></div>*/}
            </Paper>
          </TabHandler.TabPanel>

          <TabHandler.TabPanel key={2} value={this.state.currentTab} index={2} 
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <Paper className={classes.sectionpaper}>

              <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0}>
                <Card title="Charged ($)" value={charges.data.charge_summary[0].charge_amount.toLocaleString('en-US', cs1)} 
                  subtext={`From ${charges.data.charge_summary[0].charge_count.toLocaleString('en-US')} submissions`} 
                  dataInput={charges.data.charge_summary} meta={charges._meta.charge_summary} />
                <Card title="Submissions ($)" value={charges.data.submission_summary[0].charge_amount.toLocaleString('en-US', cs1)} 
                  subtext={`From ${charges.data.submission_summary[0].charge_count.toLocaleString('en-US')} submissions`} 
                  dataInput={charges.data.submission_summary} meta={charges._meta.submission_summary} />
                <Card title="CPT (#)" value={charges.data.li_charge_summary[0].cpt_units.toLocaleString('en-US')} 
                  //subtext={`From ${charges.data.li_charge_summary[0].li_charge_count.toLocaleString('en-US')} submissions and ${charges.data.li_charge_summary[0].unique_cpt_codes.toLocaleString('en-US')} CPT codes`} 
                  subtext={`From ${charges.data.li_charge_summary[0].li_charge_count.toLocaleString('en-US')} submissions`} 
                  subtext2={`Across ${charges.data.li_charge_summary[0].unique_cpt_codes.toLocaleString('en-US')} CPT codes`} 
                  dataInput={charges.data.li_charge_summary} meta={charges._meta.li_charge_summary} />
                <Card title="Observations" 
                  value={charges.data.charge_observations.summary.occurrence.toLocaleString('en-US')} 
                  //subtext={`Incl. ${charges.data.charge_observations.summary.anomaly_count.toLocaleString('en-US')} anomalies of ${charges.data.charge_observations.summary.anomaly_type_count.toLocaleString('en-US')} types`}                   
                  subtext={`Incl. ${charges.data.charge_observations.summary.anomaly_count.toLocaleString('en-US')} anomalies`}                   
                  onClick={(event, ref) => this.toggleBoolean(this.state, "displayObservationsCharges", event, ref)}
                  showNotificationsIcon={true} 
                  dataInput={charges.data.charge_observations} meta={charges._meta.charge_observations} />
                <div style={{paddingLeft: 50}}></div>                  
                {dateRangeFilter.filterName==="last_7_days" &&
                  <LineChart filterName={dateRangeFilter.filterName} dataInput={charges.data.li_charge_amount_and_count_by_day} meta={charges._meta.li_charge_amount_and_count_by_day} 
                    width={chartWidth * 1.5} height={chartHeight * 0.5} tickValues={2}
                    title="Daily" subtitle="$ - Charged" keyXAxis="interval" keyY1Axis="charge_amount" labelForY1="Charge Amount" 
                    scheme="nivo" commentKey={tooltipForChargeLineChart}
                  />
                }                
                {(dateRangeFilter.filterName==="last_4_weeks" || dateRangeFilter.filterName==="last_8_weeks") &&
                  <LineChart filterName={dateRangeFilter.filterName} dataInput={charges.data.li_charge_amount_and_count_by_week} meta={charges._meta.li_charge_amount_and_count_by_week} 
                    width={chartWidth * 1.5} height={chartHeight * 0.5} tickValues={2}
                    title="Weekly" subtitle="$ - Charged" keyXAxis="interval" keyY1Axis="charge_amount" labelForY1="Charge Amount" 
                    scheme="nivo" commentKey={tooltipForChargeLineChart}
                  />
                }
              </Box>
              <Popover open={this.state.displayObservationsCharges} onClose={() => this.toggleBoolean(this.state, "displayObservationsCharges", null, null)} 
                anchorEl={this.state.anchorEl} anchorOrigin={{vertical: 'top',horizontal: 'right'}} transformOrigin={{vertical: 'top',horizontal: 'right'}}>
                <ObservationsView data={charges.data.charge_observations}>                
                </ObservationsView>
              </Popover>

              <Box className={classes.chartRow} >
                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} title="Charges by Payor" subtitle="$ - Charge" dataInput={charges.data.li_charge_amount_by_payor} meta={charges._meta.li_charge_amount_by_payor} indexBy="payor" keys={["charge_amount"]} scheme="nivo" />
                <BarChartHorizontal width={chartWidth} height={chartHeight} title="Charges by Physician" subtitle="$ - Charge" dataInput={charges.data.li_charge_amount_by_physician} meta={charges._meta.li_charge_amount_by_physician} indexBy="physician" keys={["charge_amount"]} scheme="dark2" />
              </Box>
              <div style={{marginBottom: 30}}></div>       
              <Box className={classes.chartRow} >
                  <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} title="Charge count by Payor + Plan" subtitle="# - Charge" dataInput={charges.data.li_charge_count_by_payor_and_plan_type} meta={charges._meta.li_charge_count_by_payor_and_plan_type} indexBy="payor_and_plan_type" keys={["charge_count"]} scheme="nivo" />
                  <BarChartHorizontal width={chartWidth} height={chartHeight} title="Charge count by Physicians" subtitle="# - Charge" dataInput={charges.data.li_charge_count_by_physician} meta={charges._meta.li_charge_count_by_physician} indexBy="physician" keys={["charge_count"]} scheme="dark2" />
              </Box>      
              <Box className={classes.chartRow} >
                <PieChart type="half_donut" dataInput={charges.data.li_charge_amount_by_cpt} meta={charges._meta.li_charge_amount_by_cpt} 
                    indexBy="cpt_code" keyName="charge_amount" scheme="paired" width={chartWidth} height={chartHeight} title="Charges by CPT" subtitle="$ - Charge" />
                <PieChart type="half_donut" dataInput={charges.data.li_charge_count_by_cpt} meta={charges._meta.li_charge_count_by_cpt} 
                  indexBy="cpt_code" keyName="charge_units" scheme="paired" width={chartWidth} height={chartHeight} title="Charge count by CPT" subtitle="# - Charge" />
                <PieChart type="half_donut" dataInput={charges.data.li_charge_amount_by_service_class} meta={charges._meta.li_charge_amount_by_service_class} 
                  indexBy="service_class" keyName="charge_amount" scheme="paired" width={chartWidth * 1.25} height={chartHeight} title="Charges by Service Class" subtitle="$ - Charge" />
              </Box>
              <div style={{marginBottom: 20}}></div>          
            </Paper>
          </TabHandler.TabPanel>

          <TabHandler.TabPanel key={3} value={this.state.currentTab} index={3} 
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <Paper className={classes.sectionpaper} >
              <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0}>
                <Card title="Receivable" subtitle="$ - Receivable" value={current.data.current_receivable_summary[0].receivable_amount.toLocaleString('en-US', cs1)} subtext={`From ${current.data.current_receivable_summary[0].charge_count.toLocaleString('en-US')} submissions`} />
              </Box>
              <Box className={classes.chartRow} >
                <BarChart reverseData={false} width={chartWidth * 1.5} height={chartHeight} title="Receivables by Age" subtitle="$ - Receivable" dataInput={current.data.receivable_amount_by_age} meta={current._meta.receivable_amount_by_age} indexBy="age" keys={["receivable_amount"]} scheme="dark2" />
                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} title="Top Payors by Receivables" subtitle="$ - Receivable" dataInput={current.data.top_payors_by_receivable_amount_cols} meta={current._meta.top_payors_by_receivable_amount_cols} indexBy="payor" keys={["receivable_amount"]} scheme="nivo" />
              </Box>
              <div style={{marginBottom: 20}}></div>       
            </Paper>
          </TabHandler.TabPanel>

          {/*
          //6px is average character width in px
          <div ref={this.fontSizeCheckRef} style={{display: 'inline-block', opacity: 1, fontSize: 12}}>abcdefghijklmnopqrstuvwxyz</div>
          */}

        </Container>
      );
    }
  
    }
    catch (err) {
      console.log(err);
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );  
    }  

  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(HomeDashboard)
