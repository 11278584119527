import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
//import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import StopIcon from '@material-ui/icons/Stop';

import "../../App.css";
import Card from "../widgets/Card";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from "../widgets/charts/nivo/PieChart";
//import ObservationsView from '../widgets/ObservationsView';
import StickyTitle from '../widgets/Stickytitle';
import * as colors from '../Colours';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";


class RevenueDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();
    let defaultDateRangeFilter = {
      filterName: "last_3_months",//year_to_date, last_four_quarters, all_data
      label: "Last 3 months",
      durationBy: "week"
    };
    this.state = {
      ...newState,
      dateRangeFilter: defaultDateRangeFilter,
      prevDateRangeFilter: defaultDateRangeFilter,
    };
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    //console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    //console.log("getData this.state: ", this.state);
    let durationBy = this.state.dateRangeFilter.durationBy;
    //console.log("getData durationBy: ", durationBy);

    //https://github.com/ArtissLST/customer-ui/issues/10#issuecomment-720486696
    let requestDataForRevenue = {
      "table_slugs": [
        //row 1:
        //"paid_allowed_charged_summary", //use paid_change_summary instead? some fields like payor count etc are missing
        "adjustment_count_and_amount_and_percentage_by_level_and_category",
        //"denial_summary",//use paid_change_summary 
        "paid_by_business_age",
        //"paid_change_summary_" + dateRangeFilter.filterName,//last_3_months",year_to_date,last_4_quarters,all_data
        "paid_change_summary",

        //row 2:
        "paid_change_comparison",
        "paid_trend",//"paid_trend_by_week",// / month / quarter 
        "paid_age_bucket",

        //row 3:
        //"adjustment_count_and_amount_and_percentage_by_level_and_category",//already mentioned above
        //NOTE: adjustment_by_processed_as and by_category - use above

        //row 4:
        "paid_by_payor",
        "paid_by_plan_type", 
        "paid_by_payor_and_plan_type",
        "paid_by_method_and_mode",
        //(dont show label)
        "paid_by_patient_profile",

        //row 5:
        //for cpt and physician - use payment amount, but dont show it in hover/legend etc
        //top for physician:
        "paid_by_physician",
        "paid_by_provider",
         //includes modifier:
        "li_payment_amount_by_cpt",
        "li_payment_amount_by_service_class",
        "paid_by_business_age_and_physician",

        //row 6:
        "paid_by_payor_by_" + durationBy,
        "paid_by_physician_by_" + durationBy,
        "li_payment_by_cpt_by_" + durationBy,
        "li_payment_by_service_class_by_" + durationBy,
        "deductible_by_" + durationBy,

        //row 7:
        //"payment_observations_summary",//missing
        "payment_observations"
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "new_biz": true,        
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };

    let requestDataForRevenueBottom = {
      "table_slugs": [
        //bottom for physician:
        "paid_by_physician",
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "new_biz": true,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"bottom","limit":10}
      }      
    }

    this.setState({isLoadingCount: 2});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForRevenue, authtoken: authtoken, callback: this.updateResponseForRevenue});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForRevenueBottom, authtoken: authtoken, callback: this.updateResponseForRevenueBottom});
  }  

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
    }));
  };

  updateResponseForRevenue = (response) => {
    //console.log("updateResponseForRevenue: ", response);
    this.setState({revenue: response});
    this.updateLoadingDone();
  }

  updateResponseForRevenueBottom = (revenueBottom) => {
    //console.log("updateResponseForRevenueBottom: ", revenueBottom);
    this.setState({revenueBottom: revenueBottom});
    this.updateLoadingDone();
  }

  onChangeDateRangeFilter = (selectedFilter) => {
    //console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);

    //use approp slugs based on date range criteria
    let durationBy = "";
    //switch (this.state.dateRangeFilter.filterName) {
    switch (selectedFilter.daterange.literal) {
      case "last_3_months": durationBy = "week"; break;
      case "year_to_date": durationBy = "month"; break;
      case "last_4_quarters": durationBy = "quarter"; break;
      case "all_data": durationBy = "quarter"; break;
      default: durationBy = "month"; break;//set default to month as "safe" default
    }  

    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext,
        durationBy: durationBy
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };

  render() {
    try{
    const {classes} = this.props

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let num1 = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let revenue = this.state.revenue;
    let revenueBottom = this.state.revenueBottom;

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;
    let dateRangeFilter = this.state.dateRangeFilter;

    if (!revenue || (revenue && revenue.status!=="success")) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='detailed_dashboard' 
          title={"Revenue Dashboard"} 
          subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}              
          callbackForDateRange={this.onChangeDateRangeFilter} 
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(revenue && revenue.status!=="success") && <div>Error loading revenue data</div>}
      </Container>      
    );
    else {
      console.log("revenue: ", revenue);
      console.log("revenueBottom: ", revenueBottom);
      console.log("this.state: ", this.state);
      console.log("this.props: ", this.props);

      let dateRangeFilter;//the one that we GOT from the latest response - or the current one while we're GETTING the next response
      if (revenue.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
        dateRangeFilter = this.state.dateRangeFilter;
      else if (revenue.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
        dateRangeFilter = this.state.prevDateRangeFilter;
      //console.log("dateRangeFilter: ", dateRangeFilter);

      //data processing:
      //row 1:
      //SUM: adjustmentsTotal - calculate from adjustment_count_and_amount_and_percentage_by_level_and_category
      let adjustmentsTotal = Utils.reduceJSONObjectArrayToSum(
        revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category, "adj_amount");
      let adjustments = {adj_amount: adjustmentsTotal};
      //console.log("adjustments: ", adjustments);      
      //SUM: calculate from paid_by_business_age //newBusiness.physician_count, newBusiness.paid_amount

      console.log("revenue.data.paid_by_business_age: ", revenue.data.paid_by_business_age);
      let newBusiness = Utils.getJSONObjectFromArrayForKeyValue(revenue.data.paid_by_business_age, "business_age", "New"); 
      console.log("newBusiness: ", newBusiness);
      //no change: paid_change_summary
      let paid_change_summary = revenue.data.paid_change_summary;
      //Card - add subtext3, subtext2


      //row 2:      
      //SUM: IF REQD: convert paid_change_comparison etc to barchart data; indexBy="interval" keys={["paid_amount"]}
      //DONE: address commentKey="paid_amount_change_percent" in BarChartHorizontal

      //** process paid_trend for "grouped" BarChart 
      //- single json object/lineitem for (prev vs current) (amount + interval) 
      //- use sequence as index vs interval
      let sequenceKeys = Utils.getUniqueKeyValueListFromJSONObjectArray(revenue.data.paid_trend, "rank");
      console.log("paid_trend sequenceKeys: ", sequenceKeys);
      //sequenceKeys.sort();
      //console.log("paid_trend sequenceKeys: ", sequenceKeys);
      //console.log("revenue.data.paid_trend: ", revenue.data.paid_trend);
      let paid_trend_by_sequence = [];
      for (let i=0; i < sequenceKeys.length; i++) {
        let sequence = sequenceKeys[i];
        ////console.log("sequence: ", sequence);
        ////console.log("revenue.data.paid_trend: ", revenue.data.paid_trend);
        let prevItemArray = revenue.data.paid_trend.filter(
          item => {
            ////console.log("item: ", item);
            ////console.log("sequence: ", sequence);
            let filterFlag = (item.period.startsWith("Previous") && item.rank==sequence);
            ////console.log("filterFlag: ", filterFlag);
            return (filterFlag);
          }
        );
        ////console.log("prevItemArray: ", prevItemArray);
        let prevItem = (prevItemArray.length===1) ? prevItemArray[0] : null;
        ////console.log("prevItem: ", prevItem);
        let currItemArray = revenue.data.paid_trend.filter(
          item => (item.period.startsWith("Current") && item.rank==sequence)
        );
        ////console.log("currItemArray: ", currItemArray);
        let currItem = (currItemArray.length===1) ? currItemArray[0] : null;
        ////console.log("currItem: ", currItem);
        let newItem = {
          sequence: sequence, 
          prev_paid_amount: prevItem ? prevItem.paid_amount : null, 
          prev_interval: prevItem ? prevItem.interval : null, 
          curr_paid_amount: currItem ? currItem.paid_amount : null, 
          curr_interval: currItem ? currItem.interval : null, 
          prevItem: prevItem,
          currItem: currItem,
        };
        paid_trend_by_sequence.push(newItem);
      }
      console.log("paid_trend_by_sequence: ", paid_trend_by_sequence);
      console.log("revenue.data.paid_trend: ", revenue.data.paid_trend);      

      /*
      TODO:
      https://github.com/ArtissLST/customer-ui/issues/116
      */

      let commentPaymentComparison = function(id, value, index, indexValue, color, data) {
        /*
        return <span style={{ color, whiteSpace: "nowrap" }}>
          {id} - {indexValue}: <b>{Number(value).toLocaleString('en-US')}</b>
          {(data.paid_count_change > 0) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}<b>{data.paid_count_change}%</b>
        </span>;
        */
        return <span style={{ backgroundColor: "#333", color: color, whiteSpace: "nowrap", display: 'flex', alignItems: 'center' }}>
          <StopIcon />
          <Typography style={{ fontSize: 14, color: colors.white }}>
            {indexValue}: <b>{Number(value).toLocaleString('en-US', cs1)}</b>
          </Typography>
          <span style={{ color: (data.paid_count_change > 0) ? "green" : "red", whiteSpace: "nowrap", display: 'flex', alignItems: 'center' }}>
            {(data.paid_count_change > 0) ? <ArrowDropUpIcon style={{}} /> : <ArrowDropDownIcon />}
            <Typography>
              <b>{data.paid_count_change}%</b>
            </Typography>
          </span>
        </span>;
      }

      let commentPaymentTrend = function(id, value, index, indexValue, color, data) {
        console.log("commentPaymentTrend: arguments: ", arguments);
        return <span style={{ backgroundColor: "#333", color: color, whiteSpace: "nowrap", display: 'flex', alignItems: 'center' }}>
          <StopIcon />
          <Typography style={{ fontSize: 14, color: colors.white }}>
            {(id=="Prev Paid Amount" ? data.prev_interval : data.curr_interval)}: <b>{Number(value).toLocaleString('en-US', cs1)}</b>
          </Typography>
        </span>;
      }      

      //row 3:
      //convert adjustment_count_and_amount_and_percentage_by_level_and_category to: 
      //SUM: a) levels (4) - processed_as, adj_amount (sum)
      //SUM: b) categories - category, adj_amount (sum)
      let adjustmentLevels = Utils.groupJSONObjectArrayByKey(
        revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category, "processed_as");
      //console.log("adjustmentLevels: ", adjustmentLevels);
      let adjustmentCategories = Utils.groupJSONObjectArrayByKeyAndSum(
        revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category, "category", "adj_amount");
      //console.log("adjustmentCategories: ", adjustmentCategories);
      adjustments = {
        ...adjustments,
        primary: adjustmentLevels["Primary"],
        additional: adjustmentLevels["Additional Payor"],
        denial: adjustmentLevels["Denial"],
        reversal: adjustmentLevels["Reversal"],
        categories: adjustmentCategories
      };
      //console.log("adjustments: ", adjustments);

      //row 4:
      //append: add payor_and_plan_type attr to revenue.data.paid_by_payor_and_plan_type
      revenue.data.paid_by_payor_and_plan_type = Utils.addGeneratedAttribute(
        revenue.data.paid_by_payor_and_plan_type, ["payor", "plan_type"], "payor_and_plan_type");
      //console.log("revenue.data.paid_by_payor_and_plan_type: ", revenue.data.paid_by_payor_and_plan_type);
      //append: add payment_method_and_mode attr to revenue.data.paid_by_method_and_mode
      revenue.data.paid_by_method_and_mode = Utils.addGeneratedAttribute(
        revenue.data.paid_by_method_and_mode, ["payment_method", "payment_mode"], "payment_method_and_mode");
      //console.log("revenue.data.paid_by_method_and_mode: ", revenue.data.paid_by_method_and_mode);
      //SUM: big and slow? create paid_by_patient_profile_state from paid_by_patient_profile - with state and paid_amount_percent - top 5
      let paid_by_patient_profile_state = Utils.groupJSONObjectArrayByKeyAndSum(
        revenue.data.paid_by_patient_profile, "patient_state", "paid_amount");
      //console.log("paid_by_patient_profile_state: ", paid_by_patient_profile_state);

      //bottom list - see revenueBottom. top list - see revenue.

      //row 5:
      let top_new_physicians = revenue.data.paid_by_business_age_and_physician.filter(
        item => (item.business_age==="New")
      );
      //console.log("top_new_physicians: ", top_new_physicians);

      //row 6:
      //DONE: groupBy, sequence in LineChart - see LineChart
      //LATER TODO: simple Treemap
      //set paid_by_payor from paid_by_payor_by_week / month / quarter
      //console.log("render: dateRangeFilter.durationBy: ", dateRangeFilter.durationBy);
      let trend_paid_by_payor = revenue.data["paid_by_payor_by_" + dateRangeFilter.durationBy];
      trend_paid_by_payor._meta = revenue._meta["paid_by_payor_by_" + dateRangeFilter.durationBy];
      //console.log("trend_paid_by_payor: ", trend_paid_by_payor);
      //set paid_by_physician from _by_week  / month / quarter
      let trend_paid_by_physician = revenue.data["paid_by_physician_by_" + dateRangeFilter.durationBy];
      trend_paid_by_physician._meta = revenue._meta["paid_by_physician_by_" + dateRangeFilter.durationBy];
      //console.log("trend_paid_by_physician: ", trend_paid_by_physician);
      //set li_payment_by_cpt from _by_week / month / quarter
      let trend_li_payment_by_cpt = revenue.data["li_payment_by_cpt_by_" + dateRangeFilter.durationBy];
      trend_li_payment_by_cpt._meta = revenue._meta["li_payment_by_cpt_by_" + dateRangeFilter.durationBy];
      //console.log("trend_li_payment_by_cpt: ", trend_li_payment_by_cpt);
      let trend_li_payment_by_service_class = revenue.data["li_payment_by_service_class_by_" + dateRangeFilter.durationBy];
      trend_li_payment_by_service_class._meta = revenue._meta["li_payment_by_service_class_by_" + dateRangeFilter.durationBy];
      //set deductible from _by_week / month / quarter
      let trend_deductible = revenue.data["deductible_by_" + dateRangeFilter.durationBy];
      trend_deductible._meta = revenue._meta["deductible_by_" + dateRangeFilter.durationBy];
      //console.log("trend_deductible: ", trend_deductible);

      //let periodDisplay = Utils.toDateStr(revenue.dates.start) + " - " + utils.toDateStr(revenue.dates.end);
      let periodDisplay = revenue.dates.period_display;

      //(Number(Utils.formatNumber(adjustments.adj_amount, 2))).toLocaleString('en-US', cs1)
      let adj_amount_display_string = adjustments.adj_amount;
      try {
        console.log("adj_amount_display_string 1: ", adj_amount_display_string);
        adj_amount_display_string = Utils.formatNumber(adj_amount_display_string, 2);
        //console.log("adj_amount_display_string 2: ", adj_amount_display_string);
        //adj_amount_display_string = adj_amount_display_string.toString();
        //console.log("adj_amount_display_string 3: ", adj_amount_display_string);
        adj_amount_display_string = Number(adj_amount_display_string).toLocaleString('en-US', cs1);
        //console.log("adj_amount_display_string 4: ", adj_amount_display_string);  
      }
      catch (err) {
        console.log("adj_amount_display_string err: ", err);
      }

      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='detailed_dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 
              serverdaterange={periodDisplay}
              title={"Revenue Dashboard"} 
              subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}              
              //`Based on processing of ${paid_change_summary[0].curr_paid_count} Payments and ${paid_change_summary[0].curr_submission_count} Submissions`
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
              <Tab label="Summary" {...TabHandler.a11yProps(0)} />
              <Tab label="Adjustments" {...TabHandler.a11yProps(1)} />
              <Tab label="Physicians" {...TabHandler.a11yProps(2)} />
              <Tab label="Trends" {...TabHandler.a11yProps(3)} />
              <Tab label="Observations" {...TabHandler.a11yProps(4)} />
            </Tabs>
          </AppBar>

          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper} p={0} m={0}>                
            <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0} bgcolor="bluegrey.300">
              {/*subtext={`From ${paid_change_summary[0].payor_count} payors and ${paid_change_summary[0].physician_count} physicians`} */}
              <Card title="Charged" value={paid_change_summary[0].curr_charge_amount.toLocaleString('en-US', cs1)} 
                dataInput={paid_change_summary} 
                meta={revenue._meta.paid_change_summary} 
                //subtext={`${(((paid_change_summary[0].curr_charge_amount - paid_change_summary[0].prev_charge_amount)*100/paid_change_summary[0].prev_charge_amount)).toLocaleString('en-US', num1)}%`} 
                subtext2={`${Math.abs(paid_change_summary[0].charge_amount_change)}%`} subtext2Icon={`${paid_change_summary[0].charge_amount_change > 0 ? "up" : "down"}`} 
              />
              <Card title="Adjustments" value={adj_amount_display_string} 
                dataInput={revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                meta={revenue._meta.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                subtext={`${(adjustments.adj_amount * 100/paid_change_summary[0].curr_charge_amount).toLocaleString('en-US', num1)}% of charged`} 
              />
              <Card title="Payments" value={paid_change_summary[0].curr_paid_amount.toLocaleString('en-US', cs1)} 
                dataInput={paid_change_summary} 
                meta={revenue._meta.paid_change_summary} 
                subtext={`${(paid_change_summary[0].curr_paid_amount * 100/paid_change_summary[0].curr_charge_amount).toLocaleString('en-US', num1)}% of charged`} 
                //subtext2={`${((paid_change_summary[0].curr_paid_amount - paid_change_summary[0].prev_paid)*100/paid_change_summary[0].prev_paid).toLocaleString('en-US', num1)}%`}
                subtext2={`${Math.abs(paid_change_summary[0].paid_amount_change)}%`} subtext2Icon={`${paid_change_summary[0].paid_amount_change > 0 ? "up" : "down"}`} 
              />
              {/*<Card title="Denials" value={revenue.data.denial_summary[0].denial_amount} 
                subtext={`From ${revenue.data.denial_summary[0].denial_count} denials`}                  
                subtext2={`${(revenue.data.denial_summary[0].denial_amount * 100/paid_change_summary[0].curr_charge_amount).toLocaleString('en-US', num1)}% of charged`} 
              />*/}
              <Card title="Denials" value={paid_change_summary[0].curr_denial_amount.toLocaleString('en-US', cs1)} 
                dataInput={paid_change_summary} 
                meta={revenue._meta.paid_change_summary} 
                subtext={`${(paid_change_summary[0].curr_denial_amount * 100/paid_change_summary[0].curr_charge_amount).toLocaleString('en-US', num1)}% of charged`} 
                //subtext2={`${((paid_change_summary[0].curr_denial_amount - paid_change_summary[0].prev_denial_amount)*100/paid_change_summary[0].prev_denial_amount).toLocaleString('en-US', num1)}%`}
                subtext2={`${(paid_change_summary[0].denial_amount_change)}%`} subtext2Icon={`${paid_change_summary[0].denial_amount_change > 0 ? "down" : "up"}`} 
              />
              <Card title="New Business Payments" value={newBusiness && newBusiness.paid_amount ? newBusiness.paid_amount.toLocaleString('en-US', cs1) : "$0"} 
                dataInput={revenue.data.paid_by_business_age} 
                meta={revenue._meta.paid_by_business_age} 
                subtext2={`${((newBusiness && newBusiness.paid_amount)
                  ? newBusiness.paid_amount * 100/paid_change_summary[0].curr_charge_amount 
                  : 0).toLocaleString('en-US', num1)}% of charged`} 
              />              
              <div style={{paddingLeft: 100}}></div>
            </Box>

            {/* row 2 */}
            {/* Payments comparison ${dateRangeFilter.durationBy} */}
            <Box className={classes.chartRow}>
              {/* DONE: use commentKey in BarChart for tooltip text */}
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={revenue.data.paid_change_comparison} 
                meta={revenue._meta.paid_change_comparison} 
                title={`Payments comparison`} subtitle="$ - Paid" indexBy="period" keys={["paid_amount"]} commentKey={commentPaymentComparison} />
              {/*<BarChart width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={current.data.paid_trend} meta={null} reverseData={false} 
                title="Payments Trend" indexBy="interval" keys={["paid_amount"]} compareKey="period" sequence="sequence" />*/}
              <BarChart width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                dataInput={paid_trend_by_sequence} reverseData={false} 
                slugData={revenue.data.paid_trend} 
                meta={revenue._meta.paid_trend}
                title="Payments Trend" subtitle="$ - Paid" indexBy="sequence" keys={["prev_paid_amount", "curr_paid_amount"]} 
                keyItems={{
                  prev_paid_amount: {keys: ["prev_interval"], item: "prevItem"},
                  curr_paid_amount: {keys: ["curr_interval"], item: "currItem"},
                }} 
                commentKey={commentPaymentTrend}
                maxLabelLength={25}
              />
              <PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="paired" 
                dataInput={revenue.data.paid_age_bucket} 
                meta={revenue._meta.paid_age_bucket}
                title="Payment age" subtitle="$ - Paid" indexBy="age_bucket" keyName="paid_amount" />
            </Box>
            <div style={{marginBottom: 20}}></div>
            
            {/* row 4 */}
            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                dataInput={revenue.data.paid_by_payor} 
                meta={revenue._meta.paid_by_payor} 
                title={`Top Payors`} subtitle="$ - Paid" indexBy="payor" keys={["paid_amount"]} />
              <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="paired" 
                dataInput={revenue.data.paid_by_payor_and_plan_type} 
                meta={revenue._meta.paid_by_payor_and_plan_type} 
                title={`Top Payors & Plans`} subtitle="$ - Paid" indexBy="payor_and_plan_type" keys={["paid_amount"]} />
            </Box>
            <Box className={classes.chartRow}>          
              <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={revenue.data.paid_by_plan_type}  
                meta={revenue._meta.paid_by_plan_type} 
                title="Top Plans" subtitle="$ - Paid" indexBy="plan_type" keyName="paid_amount" />
              <PieChart type="half_donut" width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                dataInput={revenue.data.paid_by_method_and_mode} 
                meta={revenue._meta.paid_by_method_and_mode} 
                title="Payment mode" subtitle="$ - Paid" indexBy="payment_method_and_mode" keyName="paid_amount" />
              <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={paid_by_patient_profile_state} 
                slugData={revenue.data.paid_by_patient_profile} 
                meta={revenue._meta.paid_by_patient_profile} 
                title="Top patient states" subtitle="$ - Paid" indexBy="patient_state" keyName="paid_amount" hideKey={true} />
            </Box>          

            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            {/* row 3 */}
            {/*<div><Typography variant="subtitle1">Adjustments by categories and processed levels</Typography></div>*/}
            <Box className={classes.chartRow}>
              {adjustments.categories && <PieChart type="full_donut" width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={adjustments.categories} 
                slugData={revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                meta={revenue._meta.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                title="Categories" subtitle="$ - Adjustment" indexBy="category" keyName="adj_amount" />}
              {adjustments.primary && <PieChart type="full_donut" width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={adjustments.primary} 
                slugData={revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                meta={revenue._meta.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                title="Adjustments via Primary Payor" subtitle="$ - Adjustment" indexBy="reason" keyName="adj_amount" />}
              {adjustments.additional && <PieChart type="full_donut" width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={adjustments.additional} 
                slugData={revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                meta={revenue._meta.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                title="Adjustments via Additional Payor" subtitle="$ - Adjustment" indexBy="reason" keyName="adj_amount" />}
              {adjustments.denial && <PieChart type="full_donut" width={chartWidth} height={chartHeight} scheme="paired" 
                dataInput={adjustments.denial} 
                slugData={revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                meta={revenue._meta.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                title="Denials" subtitle="$ - Adjustment" indexBy="reason" keyName="adj_amount" />}
              {adjustments.reversal && <PieChart type="full_donut" width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={adjustments.reversal} 
                slugData={revenue.data.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                meta={revenue._meta.adjustment_count_and_amount_and_percentage_by_level_and_category} 
                title="Reversal" subtitle="$ - Adjustment" indexBy="reason" keyName="adj_amount" />}
            </Box>
            <div style={{marginBottom: 20}}></div>

            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={2} value={this.state.currentTab} index={2} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            {/* row 5 */}
            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={revenue.data.paid_by_physician} 
                meta={revenue._meta.paid_by_physician}
                title={`Top Physicians`} subtitle="$ - Paid" indexBy="physician" keys={["paid_amount"]} />
              {revenueBottom && <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={revenueBottom.data.paid_by_physician} 
                meta={revenueBottom._meta.paid_by_physician}
                title={`Bottom Physicians`} subtitle="$ - Paid" indexBy="physician" keys={["paid_amount"]} />}
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="paired" 
                dataInput={revenue.data.paid_by_provider} 
                meta={revenue._meta.paid_by_provider} 
                title={`Top Providers`} subtitle="$ - Paid" indexBy="provider" keys={["paid_amount"]} />
            </Box>
            
            <Box className={classes.chartRow}>          
              <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={revenue.data.li_payment_amount_by_cpt}  
                meta={revenue._meta.li_payment_amount_by_cpt} 
                title="Top CPTs" subtitle="$ - Paid" indexBy="cpt_code" keyName="payment_amount" />
              <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={revenue.data.li_payment_amount_by_service_class}  
                meta={revenue._meta.li_payment_amount_by_service_class} 
                title="Top Service Classes" subtitle="$ - Paid" indexBy="service_class" keyName="payment_amount" />
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={top_new_physicians} 
                slugData={revenue.data.paid_by_business_age_and_physician} 
                meta={revenue._meta.paid_by_business_age_and_physician} 
                title={`Top New Physicians`} subtitle="$ - Paid" indexBy="physician" keys={["paid_amount"]} />
            </Box>            

            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={3} value={this.state.currentTab} index={3} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            {/* row 6 */}            
            <Box className={classes.chartRow}>
              {trend_paid_by_payor && <LineChart width={chartWidth * 2} height={chartHeight} scheme="nivo" 
                dataInput={trend_paid_by_payor} 
                meta={trend_paid_by_payor._meta} 
                title="Top Payor Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                groupBy="payor" keyYAxis="paid_amount" labelForY="Paid Amount" />}
              {trend_li_payment_by_cpt && <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="paired" 
                dataInput={trend_li_payment_by_cpt} 
                meta={trend_li_payment_by_cpt._meta}  
                title="Top CPT Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                groupBy="cpt_code" keyYAxis="payment_amount" labelForY="Paid Amount" />}
            </Box>
            <Box className={classes.chartRow}>
              {trend_paid_by_physician && <LineChart width={chartWidth * 2} height={chartHeight} scheme="dark2" 
                dataInput={trend_paid_by_physician} 
                meta={trend_paid_by_physician._meta} 
                title="Top Physician Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                groupBy="physician" keyYAxis="paid_amount" labelForY="Paid Amount" />}
              {trend_deductible && <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                dataInput={trend_deductible} 
                meta={trend_deductible._meta} 
                title="Top Deductible Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                keyY1Axis="deductible_amount" labelForY1="Paid Amount" />}
            </Box>

            <Box className={classes.chartRow}>
              {trend_li_payment_by_service_class && <LineChart width={chartWidth * 2} height={chartHeight} scheme="paired" 
                dataInput={trend_li_payment_by_service_class} 
                meta={trend_li_payment_by_service_class._meta} 
                title="Top Service Class Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                groupBy="service_class" keyYAxis="payment_amount" labelForY="Paid Amount" />}
            </Box>
            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={4} value={this.state.currentTab} index={4} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            {/* row 7 */}    
            <Box className={classes.chartRow}>
              {/*<PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="nivo" 
                  dataInput={Utils.groupJSONObjectArrayByKeyAndSum(revenue.data.charge_observation_summary, "category", "occurrence")}  meta={null} 
                  title="Observation categories" indexBy="category" keyName="occurrence" />*/}
              <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="paired" 
                dataInput={Utils.filterJSONObjectArray(revenue.data.payment_observations, "category", "Anomaly")} reverseData={false} 
                slugData={revenue.data.payment_observations} 
                meta={revenue._meta.payment_observations} 
                title="Top Anomalies" subtitle="#" indexBy="title"  keys={["occurrence"]} />
              <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                dataInput={Utils.filterJSONObjectArray(revenue.data.payment_observations, "category", "Threshold")} reverseData={false} 
                slugData={revenue.data.payment_observations} 
                meta={revenue._meta.payment_observations} 
                title="Top Thresholds" subtitle="#" indexBy="title"  keys={["occurrence"]} />
            </Box><Box className={classes.chartRow}>          
              <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="paired" 
                dataInput={Utils.filterJSONObjectArray(revenue.data.payment_observations, "category", "Warning")} reverseData={false} 
                slugData={revenue.data.payment_observations} 
                meta={revenue._meta.payment_observations} 
                title="Top Warnings" subtitle="#" indexBy="title"  keys={["occurrence"]} />
              <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                dataInput={Utils.filterJSONObjectArray(revenue.data.payment_observations, "category", "Review")} reverseData={false} 
                slugData={revenue.data.payment_observations} 
                meta={revenue._meta.payment_observations} 
                title="Top Reviews" subtitle="#" indexBy="title"  keys={["occurrence"]} />
            </Box>

          </Paper>
          </TabHandler.TabPanel>

        </Container>
      );
    }
  
    }
    catch (err) {
      //console.log(err);
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );  
    }  

  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(RevenueDashboard)
