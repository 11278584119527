//pie chart - whole/hole/angular https://nivo.rocks/storybook/?path=/story/pie--default

import React from 'react'
import { Pie } from '@nivo/pie'
import Typography from '@material-ui/core/Typography';
import { format } from 'd3-format'
import { withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CallMadeIcon from '@material-ui/icons/CallMade';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import ChartInspector from '../../../devtools/ChartInspector';
import ExpandedChartPopover from '../expanded/ExpandedChartPopover';
import * as Utils from '../../../services/Utils';
import * as colors from '../../../Colours';

class PieChart extends React.Component  {

    constructor(props) {    
        super(props);
        this.state = {
            displayExpandedChart: false,
        }
    }  

    openExpandedChart = () => {
        if (this.props.inceptionLevel!=1)
            this.setState({displayExpandedChart: true})
    };

    closeExpandedChart = () => {
        this.setState({displayExpandedChart: false})
    };

    render() {
        const {classes} = this.props;
        try {
            let cs1 = {
                style: "currency",
                currency: "USD",
                minimumSignificantDigits: 2,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            };    
          
            let num1 = {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            };    

            //let title = this.props.title ? this.props.title : this.props.meta.display_title;
            let title = this.props.title ? (this.props.title + (this.props.subtitle ? " (" + this.props.subtitle + ")" : "")) : this.props.meta.display_title;

            //datainput - indexBy="cpt_code" key="payment_count" 
            //data: [{"id": "erlang","label": "erlang","value": 337,"color": "hsl(344, 70%, 50%)"}]
            //console.log("dataInput: ", this.props.dataInput);
            //console.log("props: ", this.props);
            if (!this.props.dataInput) console.log("PieChart invalid props: ", this.props);
            let data = [];
            for (let i=0; i < this.props.dataInput.length; i++) {
                let item = this.props.dataInput[i];
                //console.log("item: ", item);
                //console.log("item[this.props.keyName]: ", item[this.props.keyName]);
                data.push({id: item[this.props.indexBy], label: item[this.props.indexBy], value: item[this.props.keyName], color: "hsl(344, 70%, 50%)"});
            }
            //console.log("datadataInput: ", data);

            //full pie by default - full_pie
            let startAngle=0, endAngle=360, innerRadius=0;        
            if (this.props.type === "half_donut") {
                startAngle=-135;//startAngle=-90;
                endAngle=135;//endAngle=90;
                innerRadius=0.7;
            }
            else if (this.props.type === "donut") {
                startAngle=0;
                endAngle=0;
                innerRadius=0.7;
            }

            //legend width:
            /*
            let labelItemWidth = 100;
            try {
                let maxLabelLength = Utils.getMaxValueLengthFromJSONObjectArray(this.props.dataInput, this.props.indexBy);
                console.log("maxLabelLength: ", maxLabelLength);
                let averageCharacterWidth = 6.5; //7px is average character width in px - see HomeDashboard code
                let labelWidthPadding = 15;
                labelItemWidth = (maxLabelLength * averageCharacterWidth) + labelWidthPadding;   
                labelItemWidth = (labelItemWidth > 100) ? labelItemWidth : 100; 
                console.log("labelItemWidth: ", labelItemWidth);
            }
            catch (err) {
                console.log("piechart maxLabelLength err: ", err);
            }
            */
        let labelItemWidth = Utils.getLabelItemWidth(this.props.dataInput, this.props.indexBy);
            //legend:
            let legends=[];
            if (this.props.showLegend == true || (this.props.showLegend !== false && (data.length >= 1 && data.length < 15)) ) {
                legends=[
                    {
                        anchor: this.props.inceptionLevel==1 ? 'top-left' : 'top-right',
                        direction: 'column',
                        justify: false,
                        //translateX: 0,
                        //translateX: (this.props.type === "half_donut") ? labelItemWidth * 1.7 : labelItemWidth + 10,
                        translateX: (this.props.type === "half_donut") ? labelItemWidth + 10 : labelItemWidth + 10,
                        /*
                        translateX: (this.props.type === "half_donut") ? 
                            ((this.props.inceptionLevel==1) ? labelItemWidth + 0 : labelItemWidth + 10) : 
                            ((this.props.inceptionLevel==1) ? labelItemWidth + 0 : labelItemWidth + 10),
                        */
                        translateY: 0,
                        itemWidth: labelItemWidth,
                        itemHeight: 11,
                        itemOpacity: 0.75,
                        itemsSpacing: 2,
                        symbolSize: 11,
                        itemDirection: 'left-to-right',
                        itemTextColor: colors.white,//"#333333",
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]                    
                    }
                ];
            }
            else {
                labelItemWidth = 0;
            }

            let onClick = undefined;
            //if (this.props.inceptionLevel!=1) 
              //onClick = (event, ref) => this.openExpandedChart();         

            let onClickChart = undefined;
            if (this.props.inceptionLevel==1) 
                onClickChart = (node, event) => {
                    console.log("onClickChart node: ", node);
                    console.log("onClickChart event: ", event);
                    if (this.props.onClickChart) this.props.onClickChart({node: node, event: event});
                }
  
            if (data && data.length > 0) { 
            return (
                <Paper className={classes.chartPaper}>
                {this.state.displayExpandedChart &&  Utils.showExpandedChart(this.props) &&
                    <ExpandedChartPopover {...this.props} chartType="PieChart" closeExpandedChart={this.closeExpandedChart} />
                }
                <div onClick={onClick}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}} >
                        <Typography variant="subtitle1" align='center'>{title}</Typography>
                        &nbsp;&nbsp;
                        {this.props.inceptionLevel!=1 && Utils.showExpandedChart(this.props) && <IconButton onClick={() => {this.openExpandedChart()}}>
                        <CallMadeIcon style={{ fontSize: 14 }} />
                        </IconButton>}
                        &nbsp;&nbsp;
                        <ChartInspector _props={this.props} title={title} renderedData={this.props.dataInput} meta={this.props.meta} />
                    </div>
                    <Pie
                        onClick={onClickChart}
                        //props
                        data={data}
                        width={this.props.width}
                        //width={this.props.width + labelItemWidth}
                        height={this.props.height}
                        colors={{ scheme: this.props.scheme }}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        innerRadius={innerRadius}

                        //hardcoded:
                        //margin={{ top: 10, bottom: 10, right: labelItemWidth * 2, left: labelItemWidth * 0.5}}
                        margin={{ top: 10, bottom: 10, right: labelItemWidth + 20, left: 0}}
                        padAngle={0.5}
                        cornerRadius={3}
                        theme={{
                            fontSize: '12px', 
                            textColor: colors.white, 
                            tooltip: {container: {background: '#333'}},
                            /*grid: {
                              line: {
                                stroke: "DarkSlateGrey",
                                strokeWidth: 1,
                              }
                            },*/
                          }}                                  
                        //radialLabelsLinkColor="inherit"
                        //radialLabelsLinkStrokeWidth={3}
                        //radialLabelsTextColor="inherit:darker(1.2)"     

                        //more
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        slicesLabelsSkipAngle={20}
                        radialLabelsSkipAngle={20}
                        slicesLabelsTextColor="#333333"
                        enableRadialLabels={false}
                        animate={false}
                        radialLabelsLinkDiagonalLength={3}
                        radialLabelsLinkHorizontalLength={3}
                        radialLabelsTextXOffset={3}                                   
                        //radialLabel={d => `${d.id}: ${d.value}`}//works
                        //sliceLabel={d => `${d.id}: ${d.value}`}//works
                        sliceLabel={d => format(".2~s")(d.value)}
                        //sliceLabelFormat={".2~s"}//fails
                        //labelFormat={".2~s"}//fails
                        //labelFormat={"~s"}//fails
                        //sliceLabelFormat={"~s"}//fails
                        //sliceLabel={{ format: d => `${d.value}`}}   
                        /*
                        tooltip={//undefined
                            ({ id, value, index, indexValue, color, data }) => {
                                return (Utils.nivoToolTip(id, value, index, indexValue, color, data))
                            }
                        } 
                        */              
                        tooltipFormat={value => {
                            //console.log("tooltipFormat: value: ", value);
                            if (this.props.keyName && this.props.keyName.endsWith("_amount")) return `${Number(value).toLocaleString('en-US', cs1)}`;
                            else return `${Number(value).toLocaleString('en-US', num1)}`;
                        }}                                    
                        //motionStiffness={90}
                        //motionDamping={15}
                        /*
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                translateX: 0,
                                translateY: 0,
                                itemWidth: 100,
                                itemHeight: 14,
                                itemTextColor: '#000',
                                symbolSize: 10,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: 'red',
                                            color: 'black'                    
                                        }
                                    }
                                ]
                            }
                        ]}   
                        */
                    legends={legends}                   
                    />                    
                    {this.props.inceptionLevel==1 && 
                        <Typography 
                            style={{"float": "right", "margin-left": 5, "margin-right": 50}} 
                            variant="subtitle1">Click on each slice to see data for that slice.
                        </Typography>
                    }
                </div>
                </Paper>
            );
            }
            else {
                return (
                    <Paper className={classes.chartPaper}>
                        <div style={{height: this.props.height, width: this.props.width}} >
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}} >
                                <Typography variant="subtitle1" align='center'>{title}</Typography>
                                <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
                            </div>
                            <div style={{marginBottom: 10}}></div>
                            <div style={{fontSize: 12, fontStyle: "italic", align: 'center'}}>
                                <Typography align='center'>{"No data available"}</Typography>                
                            </div>
                        </div>
                    </Paper>
                );  
            }                  
        }
        catch (err) {
            console.log("Error rendering this card: ", {props: this.props, err: err});            
            return (
                <Paper className={classes.chartPaper}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", height: this.props.height, width: this.props.width}} >
                        <Typography variant="subtitle1" align='center'>{this.props.title}</Typography>
                        <Typography variant="body1" align='center'>{"Error rendering this chart"}</Typography>
                        <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
                    </div>
                </Paper>
            );  
        }  
    }
}      


const astyle = (theme) => ({
    all: {
        //borderColor: "#20232a",
        
        //display: 'flex',
        //marginBottom: 20
        //margin: { top: 30, right: 20, bottom: 30, left: 50 },
        // flex: 1,
        // width: '100'
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        // marginBottom: 25,
        //backgroundColor: theme.palette.primary,
        
    },
    chartPaper: {
      margin: 5,
      padding: 0,
      elevation: 2,
      //backgroundColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.dark,
      //backgroundColor: 'transparent',
    },
  })
  
  
  export default withStyles(astyle, {withTheme: true})(PieChart)