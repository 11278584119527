import React from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { CSVLink, CSVDownload } from "react-csv";

class ListAsCSV extends React.Component  {
    render() {
        //headers = [
            //{ label: "First Name", key: "firstname" },
            //{ label: "Last Name", key: "lastname" },
            //{ label: "Email", key: "email" }
            //this.props.headers,
        //];
        let headers = this.props.headers;

        //data = [
            //{ firstname: "", lastname: "", email: "" },
        //];
        let data = this.props.data;

        return (
            //<CSVLink data={data} headers={headers}>Download CSV</CSVLink>
            <CSVDownload data={data} target="_blank" />
        );
    }
}

//TODO: create chart specific theme?
const astyle = (theme) => {
    //return DashboardHandler.getTheme(theme);
    return {
        tooltip: {
            backgroundColor: "#333",//"#303030",//colors.primarydark, //theme.palette.primary.dark,//theme.palette.primary.main,
        },  
        chartPaper: {
          margin: 5,
          padding: 0,
          elevation: 2,
          //backgroundColor: theme.palette.primary.main
          backgroundColor: theme.palette.primary.dark,
          //backgroundColor: 'transparent',
        },        
    }
}
  
export default withStyles(astyle, {withTheme: true})(ListAsCSV)
  