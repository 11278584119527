// ReceivableDashboard.js
// copyright @artiss.co

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
//import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'

import { withRouter } from 'react-router'

import {componentUpdateReqd} from '../stories/common/Storyutils'
import * as DashboardHandler from "./common/DashboardHandler";
import {callPostAPI} from '../services/APIHandler'
import UserContext from '../../context/UserContext'
import StickyTitle from '../widgets/Stickytitle';
import * as utils from '../services/Utils'
import * as colors from '../Colours'
import ChartInspector from '../devtools/ChartInspector'

class ReceivableDashboard extends React.Component {

    constructor(props) {
        super(props)
        //console.log("receivable dashboard", this.props.location.state.showdetail.guid)
        this.state = { apiInprogress: false, web_slugs: null, web_slugs2: null,
                 phys_story: null, daterangemsg: 'last 6 months',
                 showerror:false, errmsg: null
                }        
    }

    componentDidMount() {
        //console.log("Receiva Db", this.props.location.state.showdetail.guid)
        this.updateDashboardSlugs()
        this.setState({apiInprogress: true})
    }

    componentDidUpdate(prevProps) {
        console.log( "Receivable dashboard didUpdate")
        if( componentUpdateReqd(prevProps, this.props) ) {
            console.log("have to refresh")
            this.updateDashboardSlugs()
            this.setState({apiInprogress: true})
        }
    }

    updateDashboardSlugs = (datechoice={"literal": 'last_4_weeks'}) => {
        //console.log("datechoice", datechoice)
        let user = this.context;
        let physguid = this.props.location.state.showdetail.guid
        let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer)

        let wspostd = {
            "table_slugs": [
                "top_payors_by_receivable_amount_cols",
                "current_receivable_summary",
                "prediction_with_allowed_by_week"
            ],
            "_cache": "false",
            "filter_criteria": {
                ...customer_filter_criteria,
                "transaction_date": datechoice
            }
        }
        callPostAPI({callname: 'web_query_table', requestData: wspostd, 
                authtoken: user.idtoken, callback: this.receivableDashboardSlugCallback})

    }

    receivableDashboardSlugCallback = (sdata) => {
        console.log("Receivable Db Slug callback", sdata)
        if(sdata.status === 'success') {
            this.setState({web_slugs: sdata, apiInprogress: false})
            //console.log("receivable web slug", sdata)
        } else {
            // show alert about failure
            this.setState({apiInprogress: false, showerror:true, errmsg: sdata.errorMessage})
            // return(
            // <ShowErrorMsg msg={sdata.errorMessage} />
            // )
        }
    }

    userSelectedDate = (dtobj) => {
        this.updatePhysHeaders(dtobj.daterange)
        this.updateStorySlugs(dtobj.daterange)
        this.setState({apiInprogress: true})
    }

    receiveSummary = (classes) => {
        let sumdata = this.state.web_slugs.data.current_receivable_summary[0]
        let ccount = 0; let ramt = 0;
        if(Boolean(sumdata)) {
            ccount = sumdata.charge_count
            ramt = sumdata.receivable_amount
        }
        let ra = this.state.web_slugs.data.top_payors_by_receivable_amount_cols
        let ototal = 0; let mark = 0;
        for(let i=0; i<ra.length;i++) {
            ototal += ra[i].total
            if(ototal/ramt > 0.5) {
                mark = i+1
                break;
            }
        }
        return(
            <Paper elevation={2} className={classes.sr}>
                <Typography variant="subtitle1"> Current Receivables </Typography>
                <Typography variant="h4"> {utils.showUSD_f3(ramt)} </Typography> 
                <Typography variant="body"> from {ccount} submissions </Typography>
                <Divider style={{margin: 3}}/>
                {Boolean(mark) ? 
                <Typography variant="subtitle1"> over 50% of the outstanding payment is from {mark} payors </Typography>
                : null }
                
            </Paper>
        )
    }

    ntheme = () => { 
        let th = this.props.theme
        return { 
            textColor: th.palette.primary.contrastText,
            tooltip: {
                container: {
                    background: '#333',
                },
            },
            grid: {
                line: {
                stroke: "LightSlateGrey",
                strokeWidth: 0.5
                }
            }
        }
    }

    receiveablesAge = (classes) => {
        let ra = this.state.web_slugs.data.top_payors_by_receivable_amount_cols
        let nosentries = 0
        if(ra.length > 10) { nosentries = 10} 
        else { nosentries = ra.length }
        let chartdata = [];
        for(let i=0; i<nosentries;i++) {
            let payorn = utils.uptoN(ra[i].payor,20)
            let l30 = ra[i].less_than_30
            let b360 = ra[i].between_30_and_60
            let b690 = ra[i].between_60_and_90
            let b9120 = ra[i].between_90_and_120
            let g120 = ra[i].greater_than_120
            chartdata.push({"payor": payorn, "upto 30 days": l30, "30 to 60 days": b360, 
                            "60 to 90 days": b690, "90 to 120 days": b9120, "more then 120 days": g120})
        }
        let keys = [ "upto 30 days", "30 to 60 days", "60 to 90 days", "90 to 120 days", "more then 120 days" ]

        return(
            <Paper className={classes.c1}>
            <Typography variant="subtitle1"> Top Receivable from Payors </Typography>
            <ResponsiveBar
                data={chartdata}
                keys={keys}
                layout="horizontal"
                indexBy="payor"
                //height={100}
                groupMode={'stacked'}
                margin={{ top: 20, right: 0, bottom: 50, left: 150 }}
                //innerPadding={4}
                padding={0.2}
                colors={{ scheme: 'dark2' }}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                axisTop={null}
                axisRight={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                //labelTextColor={'#000000'}
                //labelTextColor={classes.themetext.color}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                labelFormat={'$,.2s'}
                axisLeft={{
                    //orient: 'left',
                    tickSize: 5,
                    tickPadding: 3,
                    tickRotation: 0,
                    tickValues: 5,
                    //legend: 'paid',
                    legendOffset: -50,
                    //legendPosition: 'middle',
                    //format:"$,.2s"
                }}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -20,
                    format:"$,.2s"
                }}
                // theme={{ 
                //     textColor: 'white',
                //     tooltip: {
                //         container: {
                //             background: '#333',
                //         },
                //     },
                //     grid: {
                //         line: {
                //           stroke: "LightSlateGrey",
                //           strokeWidth: 0.5
                //         }
                //     }
                // }}
                theme={this.ntheme()}
                legends={[
                    {
                        dataFrom: 'keys',
                        itemTextColor: '#ffffff',
                        anchor: 'top-left',
                        //anchor: 'top',
                        //direction: 'column',
                        direction: 'row',
                        justify: false,
                        translateX: 20,
                        //translateY: -15,
                        translateY: -20,
                        itemsSpacing: 2,
                        itemWidth: 130,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                                
                            }
                        ]
                    }
                ]}
                />
                </Paper>
            )
    }

    predictReceiveable = (classes) => {
        let prd = this.state.web_slugs.data.prediction_with_allowed_by_week 
        let lc = []; let pc = []; let linechart = []

        prd.sort((a,b) => { if( new Date(a.interval_start_date) < new Date(b.interval_start_date))
                return -1
            else 
               return 1 })
        
        for(let j=0; j<prd.length; j++) {
            if(prd[j].accuracy === 'actual') {
                lc.push({x: prd[j].interval, y: prd[j].allowed_total})
            } else if(prd[j].accuracy === 'predicted') {
                pc.push({x: prd[j].interval, y: prd[j].allowed_projection})
            } else if(prd[j].accuracy === 'mixed') {
                lc.push({x: prd[j].interval, y: prd[j].allowed_actual})
                pc.push({x: prd[j].interval, y: prd[j].allowed_projection})
            }
        } 
        linechart.push({id: "allowed", data: lc})
        linechart.push({id: "allowed predicted", data: pc})

        return(

            <div style={{height: 250}}>
                <ResponsiveLine
                    data={linechart}
                    height={200}
                    margin={{ top: 30, right: 15, bottom: 60, left: 70 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 0, max: 'auto', stacked: 'true' }}
                    curve="monotoneX"
                    lineWidth={3}
                    enableGridX={true}
                    enableGridY={true}
                    axisTop={null}
                    axisRight={null}
                    //enableArea={true}
                    //areaOpacity={0.7}
                    //areaBlendMode={'multiply'}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 10,
                        tickRotation: -40,
                        //format: values => { return utils.toDateStr(values) } 
                        format: values => { let mnt = values.substring(0,values.indexOf('/'))
                                            let d = values.substring(values.indexOf('/')+1)
                                            return  utils.month3Chars(parseInt(mnt)) + '-' + d
                                            } 
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 6,
                        tickRotation: 0,
                        tickValues: 6,
                        legend: 'allowed',
                        legendOffset: -60,
                        legendPosition: 'middle',
                        format:"$,.2s"
                    }}
                    colors={{ scheme: 'dark2' }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={4}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    //tooltip={(input) => {console.log("tp",input); return (<div> hello </div>)}}
                    theme={this.ntheme()}
                    legends={[
                        {
                            dataFrom: [ 'paid', 'prediction'],
                            itemTextColor: '#ffffff',
                            anchor: 'top-left',
                            //anchor: 'top',
                            //direction: 'column',
                            direction: 'row',
                            justify: false,
                            translateX: 20,
                            //translateY: -15,
                            translateY: -20,
                            itemsSpacing: 2,
                            itemWidth: 130,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 10,
                            
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                    
                                }
                            ]
                        }
                    ]}
            
            />
            </div>
        )
    }

    predictTable = (classes) => {
        let prd = this.state.web_slugs.data.prediction_with_allowed_by_week
        let ptable = []
        let month = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        prd.sort((a,b) => { if( new Date(a.interval_start_date) < new Date(b.interval_start_date))
                return -1
            else 
               return 1 })
        for(let j=0; j<prd.length; j++) {
            if(prd[j].accuracy === 'actual') {
                continue;
            } else {
                let v = prd[j].interval
                let dt = v.substring(v.indexOf('/')+1) + ' ' + month[ parseInt(v.substring(0,v.indexOf('/'))) ] 
                ptable.push({date: dt, count: prd[j].count, charged: prd[j].charged, pval: prd[j].allowed_projection})
            }
        } 

        return(
            <TableContainer component={Box} className={classes.ptable} >
            <Table className={classes.table} size="small" padding='checkbox' aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><span className={classes.tbltext1}> date</span></TableCell>
                        <TableCell align="left"><span className={classes.tbltext1}> count</span></TableCell>
                        <TableCell align="left"><span className={classes.tbltext1}> charged($) </span></TableCell>
                        <TableCell align="left"><span className={classes.tbltext1}> predicted($) </span></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {ptable.map((d,i) => (
                    <TableRow key={i}>
                    <TableCell component="th" scope="row" size='small' >
                        <span className={classes.tbltext1}> {d.date} </span>
                    </TableCell>
                    <TableCell align="left" size='small'><span className={classes.tbltext1}> {d.count}</span></TableCell>
                    <TableCell align="left"><span className={classes.tbltext1}> {d.charged}</span></TableCell>
                    <TableCell align="left"><span className={classes.tbltext2}> {d.pval}</span></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        )
    }

    

    predictInfo = (classes) => {

        let texts1 = 'body2'; let tco = ""
        return(
            <>
                <Typography variant="subtitle2"> Notes on prediction </Typography>
                <Typography variant={texts1} color={tco}> 1. Allowed prediction is based on historical claims processed as "Primary" </Typography>
                <Typography variant={texts1} color={tco}> 2. Allowed is computed as the total amount possibly collected across insurance payments, co-insurance, co-pay and deductiable  </Typography>
                
                <Typography variant={texts1} color={tco}> 3. Predictions is done using a Machine Learning model that is trained on your historical data that includes reponses from payors</Typography>
                <Typography variant={texts1} color={tco}> 4. Prediction model efficacy is continiously evaluated and tuned to changing conditions </Typography>
            </>
        )
    }


    render() {
        const {classes} = this.props
        let storytitle = 'Receivables Dashboard'

        let inspectorData = {}

        if(Boolean(this.state.web_slugs)) {
            inspectorData = this.state.web_slugs

            return(
                <>
                    <div className={classes.stickyhdr}>
                    <StickyTitle context='story' title={storytitle} 
                        callbackForDateRange={null}
                        showProgressBar={this.state.apiInprogress}
                        />
                    </div>
                    <Container className={classes.top} >  
                        <ChartInspector _props={this.props} title={''} renderedData={inspectorData} meta={null} />
                        <Box className={classes.row1}>
                            {this.receiveSummary(classes)}
                            {this.receiveablesAge(classes)}
                        </Box>
                        <Paper className={classes.pb}>
                            <div className={classes.pgrph}>
                                <Typography variant="subtitle1"> Receivable Prediction </Typography>
                                {this.predictReceiveable(classes)}
                                <Divider />
                                {this.predictInfo(classes)}
                            </div>
                            {this.predictTable(classes)}
                            
                        </Paper>

                    </Container>
                </>

            )
        } else {
            return(
                <div className={classes.skeleton} >
                    <LinearProgress />
                    <Skeleton variant="text"  height={20}/>
                    <Skeleton variant="rect"  height={118}/>
                </div>
            )
        }
    }

}

ReceivableDashboard.contextType = UserContext; 

const astyle = (theme) => ({
    top: {
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: colors.bluegrey600,
        backgroundColor: theme.palette.primary.main,
        width: '95%',
        marginBottom: 10,
        marginTop: 10,
        paddingTop: 10,
        paddingBottom: 20,
        // height: 800
        height: '100%'
    },
    allbox: {
        display: 'flex',
        flexDirection: 'row'
        //height: 100
    },
    colbl: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    colbr: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    colbrpaper: {
        width: '95%',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 10,
        paddingBottom: 200,
        height: '100%',
        margin: 5
    },
    skeleton: {
        margin: '10%',
        width: '75%'
    },
    drs: {
        position: 'absolute',
        right: 50,
        top: 100
    },
    stickyhdr: {
        position: 'sticky',
        top: 65,
        zIndex: 10
    },
    sr: {
        backgroundColor: theme.palette.primary.dark,
        padding: 8,
        margin: 5,
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        marginRight: '1%'
    },
    srrow: {
        display: 'flex',
        flexDirection: 'row'
    },
    row1: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        height: 200,

    },
    c1: {
        width: '75%',
        backgroundColor: 'transparent',
        padding: 6
    },
    pb: {
        width: '75%',
        //height: 250,
        backgroundColor: 'transparent',
        padding: 10,
        marginTop: 30,
        display: 'flex',
        flexDirection:'row',
        width: '100%'
    },
    tbltext1: {
        fontSize: 'small',
        color: 'white',
        whiteSpace: 'nowrap'
    },
    tbltext2: {
        fontSize: 'large',
        color: 'white'
    },
    pgrph: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
    },
    ptable: {
        width: '30%',
        paddingTop: 20
    },
    themetext: {
        color: theme.palette.primary.contrastText
    }
    
})

export default withStyles(astyle, {withTheme: true})(withRouter(ReceivableDashboard))



