import React from "react";

/**
 * @component CustomerContext
 * @description customer context. See SearchContextContainer for details
 */

export const SearchContext = React.createContext({
  /*
  //request
  requested: "SearchContext Init",
  setRequested: (value) => {
    console.log("setRequested 2: ", value);
    //requested = value;
  },

  //response
  response: {},
  setResponse: () => {},

  //help
  helpContent: {},
  setHelpContent: () => {}
  */
});

export default SearchContext;  