// Storyheader.js  - for rendering provier
// copyright @artiss.co

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import * as utils from '../../services/Utils'

const StoryHeader = (props) => {
    const classes = astyle();
    let {type, subtype, data} = props

    let dtstr = utils.monYearStr(data.since_date)
    let servdt = utils.monYearStr(data.servicing_since_date)
    let rptype = data.provider_type

    if(rptype === 'Individual') {
        return(
            <>
                <Typography variant="body1">
                    Since 
                    <Typography variant="h6" display='inline'> {dtstr} </Typography>
                    , {subtype} has rendered <Typography variant="h6" display='inline'> {data.volume} </Typography>
                    units which amounts to
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.volume_percent/100)}. </Typography>
                    The Average Unit Price (AUP) for this provider computes to
                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.aup)}</Typography>, 
                    the charged amount is
                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.charged)} </Typography> 
                    amounting to 
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.charge_percent/100)} </Typography> 
                    , the allowed amount is 

                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.allowed)} </Typography>
                    amounting to
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.allowed_percent/100)} </Typography>
                    and paid amount is 
                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.paid)} </Typography>
                    amounting to
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.paid_percent/100)} </Typography>
                    . {subtype} has been rendering since 
                    <Typography variant="h6" display='inline'> {servdt}. </Typography>
                </Typography>
            </>
        )
    } else {
        return(
            <>
                <Typography variant="body1">
                    since 
                    <Typography variant="h6" display='inline'> {dtstr} </Typography>
                    , <Typography variant="h6" display='inline'> {data.volume} </Typography>
                    units have been rendered under {subtype}.
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.volume_percent/100)}. </Typography>
                    The Average Unit Price (AUP) computes to
                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.aup)}</Typography>, 
                    the charged amount is
                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.charged)} </Typography> 
                    amounting to 
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.charge_percent/100)} </Typography> 
                    , the allowed amount is 
                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.allowed)} </Typography>
                    amounting to
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.allowed_percent/100)} </Typography>
                    and paid amount is 
                    <Typography variant="h6" display='inline'> {utils.showUSD_f1(data.paid)} </Typography>
                    amounting to
                    <Typography variant="h6" display='inline'> {utils.showPercent_f1(data.paid_percent/100)} </Typography>
                </Typography>
            </>
        )
    }

}



const astyle = makeStyles((theme) => ({
    header: {
        width: '80%',
        display: 'flex',
        //flexDirection: 'column',
        //justifyContent: 'center',
        //alignItems: 'center',

    },
    titletxt: {
        fontSize: 'large'
    }
}));

export {StoryHeader }