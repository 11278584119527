// RPcompare.js
// copyright @artiss.co

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ResponsiveBar } from '@nivo/bar'
import * as colors from '../../Colours'
import { PermDeviceInformation } from '@material-ui/icons';
import * as utils from '../../services/Utils'


const RPCompare = (props) => {
    const classes = astyle();
    let {data, rankdata} = props    
    let showlen = data.length
    let chartwidth = 300
    if(data.length > 6) {
        showlen = 6
        chartwidth = 600
    }
    let az = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q','R', 'S', 'T' ]
    let rpchart = []; let rpcountchart = []; let n2n = []
    for(let i=0; i<showlen; i++) {
        //let rname = data[i].renderer.substring(0,15)
        let rname = data[i].renderer
        let rpe = {name: az[i], charged: data[i].charged_amount, 
                        allowed: data[i].allowed_amount, paid: data[i].payment_amount }
        let rpcnt = {name: az[i], count: data[i].submission_count}
        rpchart.push(rpe)
        rpcountchart.push(rpcnt)
        n2n.push({name:rname, index: az[i]})
    }

    rpchart.sort((a,b) => {if(a.charged < b.charged) return 1; else return -1})
    rpcountchart.sort((a,b) => {if(a.count < b.count) return 1; else return -1})

    let paidrank = rankdata.paid_rank
    let volrank = rankdata.volume_rank
    let subrank = rankdata.submission_volume_rank
    let allowedrank = rankdata.allowed_rank
    let auprank = rankdata.aup_rank
    
    console.log("rp chart ", rpchart)

    const nth = (n) => {return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}
        
    return(
        <Paper className={classes.cardpaper} >
            <Typography variant="subtitle1" display='inline'>Comparing this provider with the rest</Typography>
            <Box className={classes.t1} >

            <Paper elevation={2} className={classes.scp}>
                <Typography variant="h4"> {paidrank}<sup>{nth(paidrank)} </sup></Typography> 
                <Typography variant="body2"> Paid </Typography> 
            </Paper>

            <ResponsiveBar
            data={rpchart}
            keys={["charged", "allowed", "paid" ]}
            layout="vertical"
            indexBy="name"
            groupMode={'grouped'}
            height={160}
            width={chartwidth}
            margin={{ top: 20, right: 50, bottom: 20, left: 50 }}
            //innerPadding={4}
            padding={0.2}
            innerPadding={1}
            colors={{ scheme: 'category10' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            //axisLeft={null}
            enableGridY={true}
            enableLabel={true}
            labelFormat={["$", ".2s"]}
            //labelFormat={{format: v => `${v}.2s`}}
            labelSkipWidth={15}
            labelSkipHeight={12}
            //labelTextColor={{ from: 'color' }}
            labelTextColor={'#000000'}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            tooltipFormat={value => utils.showUSD_f1(value)}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    }
                },
                //background: 'white',
                grid: {
                    line: {
                      stroke: "DarkSlateGrey",
                      strokeWidth: 1,
                      //strokeDasharray: "4 4"
                    }
                }
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 6,
                tickRotation: 0,
                tickValues: 4,
                //legend: 'paid',
                // legendOffset: -60,
                // legendPosition: 'middle',
                format:"$,.2s"
            }}
            //axisBottom={false}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -20,
                // legend: 'Age groups',
                // legendPosition: 'middle',
                // legendOffset: 0,
                //format: v => v       // call function to format number to $ in k value
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    itemTextColor: '#ffffff',
                    anchor: 'top-left',
                    //anchor: 'top',
                    //direction: 'column',
                    direction: 'row',
                    justify: false,
                    translateX: 20,
                    //translateY: -15,
                    translateY: -20,
                    itemsSpacing: 2,
                    itemWidth: 70,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                            
                        }
                    ]
                }
            ]}
            />  
            
            </Box>

            <Box className={classes.t1} >

            <Paper elevation={2} className={classes.scp}>
                <Typography variant="h4"> {subrank}<sup>{nth(subrank)} </sup></Typography> 
                <Typography variant="body2"> Count </Typography> 
            </Paper>

            <ResponsiveBar
            data={rpcountchart}
            keys={["count" ]}
            layout="vertical"
            indexBy="name"
            groupMode={'grouped'}
            height={160}
            width={chartwidth}
            margin={{ top: 5, right: 50, bottom: 55, left: 50 }}
            //innerPadding={4}
            padding={0.2}
            innerPadding={2}
            colors={{ scheme: 'category10' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            //axisLeft={null}
            enableGridY={true}
            enableLabel={false}
            //labelFormat={["$", ".2s"]}
            //labelFormat={{format: v => `${v}.2s`}}
            labelSkipWidth={15}
            labelSkipHeight={12}
            //labelTextColor={{ from: 'color' }}
            labelTextColor={'#000000'}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            //tooltipFormat={value => utils.showUSD_f1(value)}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    }
                },
                //background: 'white',
                grid: {
                    line: {
                      stroke: "DarkSlateGrey",
                      strokeWidth: 1,
                      //strokeDasharray: "4 4"
                    }
                }
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 6,
                tickRotation: 0,
                tickValues: 4,
                //legend: 'paid',
                // legendOffset: -60,
                // legendPosition: 'middle',
                //format:"$,.2s"
            }}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -20,
                // legend: 'Age groups',
                // legendPosition: 'middle',
                // legendOffset: 0,
                //format: v => v       // call function to format number to $ in k value
            }}
            // legends={[
            //     {
                    //dataFrom: 'keys',
                    //itemTextColor: '#ffffff',
                    //anchor: 'right',
                    //anchor: 'top',
                    //direction: 'column',
                    // direction: 'row',
                    // justify: false,
                    // translateX: 65,
                    //translateY: -15,
                    // translateY: 0,
                    // itemsSpacing: 2,
                    // itemWidth: 70,
                    // itemHeight: 20,
                    // itemDirection: 'left-to-right',
                    // itemOpacity: 0.85,
                    // symbolSize: 10,
                //     effects: [
                //         {
                //             on: 'hover',
                //             style: {
                //                 itemOpacity: 1
                //             }       
                //         }
                //     ]
                // }
            // ]}
            />  
            </Box>

            <TableContainer component={Paper} className={classes.n2ntc}>
            <Table className={classes.n2ntable} aria-label="simple table" size="small">
                <TableBody>
                {n2n.map((row) => (
                    <TableRow key={row.index}>
                    <TableCell component="th" scope="row" size='small' padding='none'>
                        <span style={{fontSize: 'small'}} > {row.index} {'-'}</span>
                    </TableCell>
                    <TableCell align="left" size='small' padding='none'>
                    <span style={{fontSize: 'x-small'}}> {row.name} </span> </TableCell> 
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

            
        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        //width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 5,
        paddingLeft:10,
        margin: 10,
        //height: 160
    },
    t1: {
        display: 'flex',
        justifyContent:'space-evenly',
        flexDirection: 'row',
        width: 'auto'
    },
    scp: {
        backgroundColor: colors.secondarydark,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5,
        height: '50%',
        marginTop: 20
    },
    n2ntable: {
        //width: '50%',
        paddingLeft: 5,
        
    },
    n2ntc: {
        marginLeft: 10,
        borderBlockColor: 'white',
        backgroundColor: theme.palette.primary.dark,
        width: '50%'
    }
    
    
}));

export default RPCompare;
