import React from "react";
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import ChartInspector from '../../../devtools/ChartInspector';
import * as colors from '../../../Colours';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: "50vw"
  }
});

export default function ClaimList(props) {
  const classes = useStyles();

  let rows = null;
  console.log("ListAsTable props: ", props);
  if (props && props.data)
    rows = props.data;

  //const [dense, setDense] = React.useState(false);
  const dense = true;

  if (!rows || rows.length <= 0) return (
    <Paper className={classes.chartPaper}><div>No data to show</div></Paper>
  )
  else {
    let keys = Object.keys(rows[0]);
    console.log("ListAsTable keys: ", keys);
    
    return (
      <Paper className={classes.chartPaper} style={{backgroundColor: colors.grey500, overflowX: 'auto', overflowY: 'auto'}} >
          <div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", width: "100%"}} >
                  <Typography variant="subtitle1" align='center'>{props.title}</Typography>
                  <ChartInspector _props={props} title={props.title} renderedData={props.data} meta={props._meta} />
                  {/*<Button style={{backgroundColor: colors.bluegrey600, marginTop: 5, marginBottom: 5}} onClick={() => {props.resetClaims()}}>&nbsp;&nbsp;Close&nbsp;&nbsp;</Button>*/}
              </div>
          </div>
          <TableContainer className={classes.table} component={Paper} 
            //style={{ maxHeight: 'calc(80vh)', minWidth: 'calc(95vw)', maxWidth: 'calc(95vw)', backgroundColor: colors.grey500 }}
            //style={{ minWidth: 'calc(95vw)', maxWidth: 'calc(95vw)', backgroundColor: colors.grey600 }}
            style={{ maxHeight: 'calc(93vh)', minWidth: 'calc(97vw)' }}
            //style={{ width: '100%' }}
            //style={{ backgroundColor: colors.grey500 }}
          >
          <Table stickyHeader aria-label="customized table" 
              size={dense ? 'small' : 'medium'}>
              <TableHead>
              <TableRow style={{ height: (dense ? 33 : 53) }}>
                {keys.map((key, keyIndex) => (
                  <StyledTableCell style={{fontSize: '12pt'}}>{key}</StyledTableCell>
                ))}
              </TableRow>
              </TableHead>
              <TableBody>
              {rows.map((row, rowIndex) => (
                  <StyledTableRow key={Math.random()}>
                      {keys.map((key, keyIndex) => (
                        <StyledTableCell style={{fontSize: '11pt'}}>{row[key]}</StyledTableCell>
                      ))}                      
                  </StyledTableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </Paper>
    );
  }
}
