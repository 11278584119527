// Searchtocomponent.js
// copyright @artiss.co

/**
 * @example
 * for each component, add an entry in the array, 
 * the component is the path as used in the Route 
 */

const entitytoComponent = [
    {entity: 'cpt', view: 'story', path: '/cptstory' },
    {entity: 'claim',view: 'story', path: '/claimstory'},
    {entity: 'physician',view: 'story', path: '/physicianstory'},
    {entity: 'payor',view: 'story', path: '/payorstory'},
    {entity: 'renderer',view: 'story', path: '/renderingproviderstory'},
    {entity: 'service_class', view: 'story', path: '/serviceclassstory'},

    //dashboards
    {entity: 'summary', view: 'dashboard', path: '/homedashboard'},
    {entity: 'revenue', view: 'dashboard', path: '/revenuedashboard'},//title: 'Revenue Dashboard', 
    {entity: 'payor', view: 'dashboard', path: '/payordashboard'},//title: 'Payor Dashboard', 
    {entity: 'group', view: 'dashboard', path: '/groupdashboard'},//title: 'Group Dashboard', 
    {entity: 'denial', view: 'dashboard', path: '/denialadjustmentdashboard'},
    {entity: 'observation', view: 'dashboard', path: '/observationdashboard'},
    {entity: 'submission', view: 'dashboard', path: '/submissiondashboard'},
    {entity: 'physician', view: 'dashboard', path: '/physiciandashboard'},//missing: physician
    //{entity: 'projections', view: 'dashboard', path: '/projectionsdashboard'},    
    //LATER: billing, message, operations, payment, 
    {entity: 'receivable', view: 'dashboard', path: '/receivabledashboard'},

    //what ifs:
    {entity: 'Renderer', view: 'whatif', path: '/recommendations/requisitionrouting'},

    {entity: 'none', view: 'none', path: null}
]

const SearchToComponent = (item) => {

    let gotoComponent = entitytoComponent.find((e) => {
        /*
        console.log("find e: ", e)
        if(e.view === "dashboard") {
            if (e.entity === item.entity && e.view === item.entity_view && e.title.toLowerCase() === item.title.toLowerCase()) {
                console.log("matched dashboard path ",e)
                return true
            }
            else return false           
        } else */
        if(e.entity === item.entity && e.view === item.entity_view) {
            console.log("matched path ",e)
            return true
        }
        return false
    })
    if(Boolean(gotoComponent)) {
        //console.log("gotoComponent.path: ", gotoComponent.path);
        return gotoComponent.path
    } else {
        return null
    }

}

export default SearchToComponent