// Searchri.js
// copyright @artiss.co

import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Drawer from '@material-ui/core/Drawer';
import Modal from '@material-ui/core/Modal';


//import * as api from './Restapi'
import * as colors from '../Colours'
import UserContext from '../../context/UserContext'
import SearchResults from '../search/SearchResultsList'
import {callPostAPI} from '../services/APIHandler'
import ArtissLogo from '../ArtissLogo'


class SearchRI extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedcustomer: "",
            searchprovider: true,
            searchclaim: true,
            searchcustomer: true,
            searchdashboard: true, 
            searchicd: true,
            searchcpt: true,
            searchmessage: true,
            searchpayor: true,
            searchpayment: true,
            searchresponsedata: null,
            searchtext: "",
            searchongoing: false,
            searchselected: true,
          };
          this.textInput = React.createRef();
    }


    selectCheckbox = (event) => {
        console.log( "--->", event.target.name, event.target.checked)
        this.setState({ [event.target.name] : event.target.checked });
    }

    selectedEntities = () => {
        let e = []
        if(this.state.searchclaim) e.push('claim')
        if(this.state.searchcpt) e.push('cpt')
        if(this.state.searchcustomer) e.push('customer')
        if(this.state.searchdashboard) e.push('dashboard')
        if(this.state.searchicd) e.push('icd')
        if(this.state.searchmessage) e.push('message')
        if(this.state.searchpayment) e.push('payment')
        if(this.state.searchpayor) e.push('payor')
        if(this.state.searchprovider) e.push('provider')

        if(e.length > 0)
            return e
        else {
            e.push('claim')
            return e
        } 
    }
    

    submitSearch = () => {
        console.log("search submit", this.state.searchtext)
        let sdata = {}
        //sdata['search_text'] = "NE0G008417"   //""NE7G009541"    "NE7G00257"
        sdata['search_text'] = this.state.searchtext
        //sdata["customer_guid"] = "b1e0f3ac-733b-4f8f-b792-7407add203dc"
        sdata["num_of_results"] = 10
        sdata["search_entities"] = this.selectedEntities()

        let user = this.context;
        console.log( "idtoken ->", user)

        callPostAPI({callname: 'web_search_text', requestData: sdata, 
                authtoken: user.idtoken, callback: this.updateSearchResponse})

        //api.postCall(sdata, user.idtoken, "searchtext", this.updateSearchResponse )
        this.setState({searchongoing: true})
    }

    searchtextinput = (e) => {
        if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
            console.log("key event")
          }

        console.log("search i/p", e.target.value)
        this.setState({searchtext: e.target.value})
    }

    updateSearchResponse = (rdata) => {
        console.log("updateSearchreponse called")
        this.setState({searchresponsedata: rdata, searchongoing: false})
    }


    handleModalClose = (event) => {
        console.log("close drawer")
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {

            this.setState({searchselected: false });
          //return;
        }
        this.setState({searchselected: false });
        this.props.callback(false)
        
    };

    render() {
        const {classes} = this.props
        console.log("searchri render", this.props.enable)

        return(
            <div>
                <Modal 
                    open={this.props.enable}
                    onClose={this.handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                <>
                <Paper className={classes.all}>

                    <div className={classes.rf1} >
                        {Boolean(this.state.searchongoing) ? <LinearProgress /> : null }
                        
                        <TextField
                            id="text-to-search" 
                            label="text to search"
                            defaultValue=""
                            helperText=""
                            onChange={(e) => this.searchtextinput(e) }
                            onKeyPress={(d) => { console.log("key pressed") }}
                            onSelect={() => {this.setState({searchselected: true}) }}
                            className={classes.searchtext}
                            size="medium"
                            variant="filled"
                            inputProps={{ 'aria-label': 'naked' }}
                            labelwidth={100}
                            //autoFocus={true}
                            //fullWidth={true}
                            />
                        <IconButton type="submit" className={classes.iconButton} 
                                        aria-label="search" onClick={this.submitSearch}>
                                    <SearchIcon fontSize='large' color='primary'/>
                        </IconButton>
                    </div>                
                         
                </Paper> 
                <SearchResults searchres={this.state.searchresponsedata} />
                </>
                </Modal>
            </div>
        )
    }

}

SearchRI.contextType = UserContext; 


const astyle = {
    all: {
        //marginLeft: 50,
        backgroundColor: 'grey',
        opacity: '90%',
        width: '95%',
        //height: '95%',
        marginLeft:'2%',
        marginRight: '2%',
        marginTop: '1%',
        display: 'flex',
        //flexDirection: 'column',
        justifyContent: 'center',
        //alignItems: 'center'
        
    },
    searchpaper: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10
        //paddingLeft: 25

    },
    iconButton: {
        //padding: 10,
      },
    searchtext: {
       //flex: 1,
       //borderBottomColor: 'black',
       leftMargin: 0

    },
    fields: {
        marginTop: 20
    },
    accord: {
        width: '40%',
        marginTop: 5
    },
    atext: {
        fontSize: 'small',
        color: colors.grey400
    },
    modal: {
        width: '90%',
        height: '90%',
        backgroundColor: 'grey',
        opacity: '80%',
        margin: '2%',
        padding: 10

    },
    rf1:{
        topMargin: 10,
        leftMargin: 0,
        display: 'flex',
        //justifyContent: 'center'
        
    }
}

export default withStyles(astyle)(SearchRI);
