/*
x 0. Intercept login -> home dashboard in LoginPage.js

x 1. Call '/data/web_get_system_settings', which would return system settings (including EULA version and EULA content URL)
x If the version of EULA signed is the same as current system version, EULA is good; skip and proceed to application home
x -- use web_get_user_preference and web_set_user_preference for user pref

x 2. Show the EULA in modal dialog + iframe
x a) if the user has not signed the EULA already 
x b) if the system EULA version is different from version the user has signed

3. user action:
x a) If user agrees, 
x - save 'eula' under preferences with date/time when it was signed, emailid/login id of the user and EULA url, version which he signed. 
x - Proceed to home dashboard in LoginPage
x b) if user disagrees, 
x - log user out and take them back to login page. User cannot move forward without signing EULA. 
x firebase logout: 
x LoginPage.js
x https://firebase.google.com/docs/auth/web/password-auth
x https://stackoverflow.com/questions/49860938/firebase-logout-not-working
*/

import * as APIHandler from '../services/APIHandler';

/*
//not using this; using async in componentDidMount instead 
//** https://stackoverflow.com/questions/54936559/using-async-await-in-react-component 
//https://stackoverflow.com/questions/27192621/reactjs-async-rendering-of-components
//https://www.valentinog.com/blog/await-react/
//https://medium.com/maxime-heckel/asynchronous-rendering-with-react-c323cda68f41
//https://stackoverflow.com/questions/47227550/using-await-inside-non-async-function
//https://developer.mozilla.org/en-US/docs/Glossary/IIFE
//https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
function handleEULASync(user) {
    (async () => await handleEULA(user))();
}
*/

async function handleEULA(user) {
    let response;

    response = await checkUserLoginStatus(user);
    if (response.status==="failure") return response;

    response = await checkEULAStatusForUser(user);
    //if (response.status==="failure") return response;
    //if (response.status==="success" && response.showEULA === false) return response;//all fine; no problem; proceed to home page
    //if (response.status==="success" && response.showEULA === true) return response;//show EULA
    return response;
}

async function checkUserLoginStatus(user) {
    //console.log("checkUserLoginStatus user: ", user);
    if (!user) return {status: "failure", errorMessage: "checkEULAStatusForUser user is null"};
    if (!user.idtoken) return {status: "failure", errorMessage: "checkEULAStatusForUser authtoken is null"};
    //TODO: check if token is valid, else refresh - or do that in different method?
    return {status: "success"};
}

async function checkEULAStatusForUser(user) {
    let authtoken = user.idtoken;
    //console.log("checkEULAStatusForUser user: ", user);
    let systemSettingsEULA = await APIHandler.callPostAPIAsync({callname: "web_get_system_settings", requestData: {"preference_key":"eula_signed"}, authtoken: authtoken});
    //console.log("systemSettingsEULA: ", systemSettingsEULA);
    if (!systemSettingsEULA || systemSettingsEULA.status!=="success") return {status: "failure", showEULA: false, errorMessage: "Getting systemSettingsEULA failed", systemSettingsEULA: systemSettingsEULA};
    let userSettingsEULA = await APIHandler.callPostAPIAsync({callname: "web_get_user_preference", requestData: {"preference_key":"eula_signed"}, authtoken: authtoken});
    //console.log("userSettingsEULA: ", userSettingsEULA);
    if (!userSettingsEULA || userSettingsEULA.status!=="success") return {
        status: "failure", showEULA: false, errorMessage: "Getting userSettingsEULA failed", 
        systemSettingsEULA: systemSettingsEULA, userSettingsEULA: userSettingsEULA
    };
    if (!userSettingsEULA.data || userSettingsEULA.data===false || !userSettingsEULA.data.eula || !userSettingsEULA.data.eula.version) { //user has not signed EULA
        return {status: "success", showEULA: true, systemSettingsEULA: systemSettingsEULA, userSettingsEULA: userSettingsEULA};
    }
    else if (!(userSettingsEULA.data.eula.url === systemSettingsEULA.data.eula.url && 
        userSettingsEULA.data.eula.version === systemSettingsEULA.data.eula.version)) {
        return {status: "success", showEULA: true, systemSettingsEULA: systemSettingsEULA, userSettingsEULA: userSettingsEULA};
    }
    else {
        return {status: "success", showEULA: false, systemSettingsEULA: systemSettingsEULA, userSettingsEULA: userSettingsEULA};
    }
}  

async function declineEULA(request) {
    //console.log("declineEULA called!");
    //TODO: audit this to server?
}

async function acceptEULA(request) {
    //console.log("acceptEULA called! request: ", request);
    let user = request.user;
    let handleEULAResponse = request.handleEULAResponse;

    /*
    //get systemSettingsEULA
    let systemSettingsEULA = await APIHandler.callPostAPIAsync({
        callname: "web_get_system_settings", 
        requestData: {
            "preference_key":"eula_signed"
        }, 
        authtoken: request.authtoken
    });
    */
    let systemSettingsEULA = handleEULAResponse.systemSettingsEULA;

    //save 'eula' under preferences with date/time when it was signed, emailid/login id of the user and EULA url, version which he signed. 
    let userSettingsEULA = await APIHandler.callPostAPIAsync({
        callname: "web_set_user_preference", 
        requestData: {
            preference_key:"eula_signed",
            preference: {
                //"eula": {
                //    "url": "https://api.artiss.co/eula_v1.html",
                //   "version": "1.0"
                //}        
                eula: systemSettingsEULA.data.eula
            }
        }, 
        authtoken: user.idtoken
    });    
    console.log("acceptEULA done! userSettingsEULA: ", userSettingsEULA);
    //TODO: move on to home page
    return userSettingsEULA;
}

export { 
    //handleEULASync,
    handleEULA,
    checkUserLoginStatus, 
    checkEULAStatusForUser, 
    declineEULA, 
    acceptEULA
}