// CPTicdbreakout.js
// copyright @ artiss.co 

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'

import * as colors from '../../Colours'


const CPTICDBreakout = (props) => {
    const classes = astyle();
    let {icdcount, icdpaid} = props

    let counticd = {}; let icdkey = []; let countcpt = {}; let cptkey = []
    for(let i=0;i<icdcount.length;i++ ) {
        let k1 = icdcount[i].icd_code
        let c1 = icdcount[i].cpt_code
        let v1 = icdcount[i].submission_count
        if(counticd[k1]) {
            counticd[k1] += v1
        } else {
            counticd[k1] = v1
            icdkey.push(k1)
        }
        if(countcpt[c1]) {
            countcpt[c1] += v1
        } else {
            countcpt[c1] = v1
            cptkey.push(c1)
        }
        
    }
    counticd['ICD'] = 'ICD Count'
    countcpt['CPT'] = 'CPT Count'
    let icdchart = []; let cptchart = [];
    icdchart.push(counticd)
    cptchart.push(countcpt)
    // console.log("ICD, CPT", icdchart, cptchart)
    // console.log("Keys", icdkey, cptkey)
    
    return(
        <Box className={classes.top}>
        
        <Typography variant="caption"> Volume breakup by ICD</Typography>
          <ResponsiveBar
            data={icdchart}
            keys={icdkey}
            layout="horizontal"
            indexBy="ICD"
            height={25}
            margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
            //innerPadding={4}
            padding={0}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    },
                }
            
              }}
        />
        <div style={{marginTop: 10}} />

        <Typography variant="caption"> Volume breakup by CPT</Typography>
        <ResponsiveBar
              data={cptchart}
              keys={cptkey}
              layout="horizontal"
              indexBy="CPT"
              height={25}
              margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
              //innerPadding={4}
              padding={0}
              colors={{ scheme: 'nivo' }}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
              axisTop={null}
              axisRight={null}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              theme={{ 
                  textColor: 'white',
                  tooltip: {
                      container: {
                          background: '#333',
                      },
                  }
              
                }}
        />  
            
        </Box>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    top: {
        width: '90%',
        height: 75,
        display: 'flex',
        //flex: 1,
        flexDirection: 'column',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        //backgroundColor: theme.palette.primary.dark,
        //padding: 10,
        //height: 75,
        //marginBottom:20,
        marginLeft: 10
    },
    scp: {
        backgroundColor: colors.secondarydark,
    },
    sctxt: {
        fontSize: 'large'
    },
    
}));

export default CPTICDBreakout