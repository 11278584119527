// DaterangeSelector.js
// copyright @artiss.co

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import * as Utils from '../services/Utils';

/**
 * @component DateRangeSelector
 * @property {string} context context in which the date selector is used
 * @property {function} callback parent function, called when user selects the date range
 * @property {string} serverdaterange , this is the date range the server returns in response to the selected date range
 * @return {object} {"daterange": {"literal":"last_4_weeks","start":"2020/01/01","end":"2020/08/01"}, "datetext": "last 4 weeks" } 
 * @example
 * <DateRangeSelector context='story' callback='this.userDateSelector' />
 */

class DateRangeSelector extends React.Component {
    
    constructor(props) {
        super(props)
        this.story = [{show: 'last 3 months', val: 'last_3_months'},
                        {show: 'last 4 quarters', val: 'last_4_quarters'},
                        {show: 'year to date', val: 'year_to_date'},
                        {show: 'all the data', val: 'all_data'} ]
        this.dashboard = [{show: 'Yesterday', val: 'yesterday'},
            {show: 'Last 7 Days', val: 'last_7_days'},
            {show: 'Last 4 Weeks', val: 'last_4_weeks'},
            {show: 'Last 8 Weeks', val: 'last_8_weeks'}]
        this.detailed_dashboard = [{show: 'Last 3 Months', val: 'last_3_months'},
        {show: 'Last 4 Quarters', val: 'last_4_quarters'},
        {show: 'Year To Date', val: 'year_to_date'},
        {show: 'All Data', val: 'all_data'} ]
        this.mitems = []
        if(this.props.context === 'story') {
            this.mitems = this.story
        } 
        else if(this.props.context === 'dashboard') {
            this.mitems = this.dashboard
        }
        else if(this.props.context === 'detailed_dashboard') {
            this.mitems = this.detailed_dashboard
        }
        
        this.state = { 
            selectedval: this.mitems[0].val
        }
    }
    
    handleInputChange = (event) => { 
        let ds = event.target.value
        console.log("handle event", ds)
        this.setState({selectedval: event.target.value})
        let tuple = Utils.getJSONObjectFromArrayForKeyValue(this.mitems, "val", ds);
        // send start, end dates only for custom e.g. "start":"2018/01/01","end":"2018/07/01"
        this.props.callback({"daterange": {"literal": ds}, "datetext": tuple ? tuple.show : null})
    }

    render() {
        const {classes} = this.props
        // if(this.props.serverdaterange)
        //     console.log("server time", this.props.serverdaterange)
 
        return(      
            <div className={classes.dtr}>
            
            <FormControl className={classes.formControl}>
                {/* <InputLabel shrink htmlFor="date-range-selector">
                Date Range
                </InputLabel> */}
                <Select
                    labelId="date-range-selector"
                    id="demo-simple-select"
                    value={this.state.selectedval}
                    onChange={this.handleInputChange}
                    >
                {this.mitems.map((d, i) => (<MenuItem value={d.val} key={i}> {d.show} </MenuItem>))}
                </Select>
                <span> {this.props.serverdaterange} </span>
                
            </FormControl>
            
            </div>
        )
    }

}

// "transaction_date": {"literal":"zz","start":"2020/01/01","end":"2020/08/01"}

const astyle = (theme) => ({
    formControl: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'flex-start'
    },
    dtr: {
        display: 'flex',
        flexDirection: 'column'
    }
})

export default withStyles(astyle, {withTheme: true})(DateRangeSelector)
