import React from "react";

import { ListItem, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

class SearchHelp extends React.Component {

  /*
  constructor(props) {
    super(props);
    //console.log("SearchHelp constructor called");
  }
  */

  componentDidMount() {
    console.log("SearchHelp componentDidMount called: this.props: ", this.props);
  }

  componentDidUpdate(prevProps) {
    console.log("SearchHelp componentDidUpdate called: prevProps: ", prevProps);
  }    

  searchHelpItemClicked(clickedText) {
    console.log("onClick clickedText: ", clickedText);
    this.props.setRequested(clickedText);
  }

  getListItem(item, props = {}) {
    //TODO: if required to combine classes: https://stackoverflow.com/questions/34521797/how-to-add-multiple-classes-to-a-reactjs-component
    return (
      <ListItem button className={this.props.classes.listItem} {...props} key={item.key} style={{marginTop: 0, marginBottom: 5}}
      dense={true}
      onKeyPress={(event) => this.searchHelpItemClicked(item.name)} 
      onClick={() => this.searchHelpItemClicked(item.name)} m={0} p={0}>
        {item.keywords && <ListItemText {...props} 
          primary={item.name} 
          secondary={item.keywords}
        />}          
        {!item.keywords && <ListItemText {...props} 
          primary={item.name} 
        />}          
      </ListItem>      
    );
  }

  render() {

    //let classes = this.props.classes;

    //using in render: https://stackoverflow.com/questions/46969246/calling-functions-inside-a-map-function-in-react-render
    let pastSearches = null;
    let pastSearchesString = localStorage.getItem('pastSearches');
    if (pastSearchesString) pastSearches = JSON.parse(pastSearchesString);
    return (
      <div style={{backgroundColor: "grey"}}>        
        <br />
        <Typography>&nbsp;&nbsp;Search by title or other terms you're familiar with, such as:</Typography>
        <div style={{display:"flex", flexDirection: "row"}}>
          <div style={{display:"flex", flexDirection: "column"}}>
            {this.getListItem({key: "C1R1", name: "Revenue", keywords: ["income, collection, paid, deposited"]})}
            {this.getListItem({key: "C1R2", name: "Denials", keywords: ["zero paid, reject"]})}
            {/* this.getListItem({key: "C1R3", name: "Cluster", keywords: ["tenant, partnership"]})  */}
            {this.getListItem({key: "C1R4", name: "Payment", keywords: ["wire, check, ACH, deposit"]})}
            {this.getListItem({key: "C1R5", name: "Insurance", keywords: ["payors, payers, primary, secondary, plan"]})}
            {this.getListItem({key: "C1R6", name: "Observation", keywords: ["age, submissions, workflow, anomaly, error, exceptions, system"]})}
            {this.getListItem({key: "C1R7", name: "Optimize", keywords: ["ICD, CPT, optimize"]})}
            {this.getListItem({key: "C1R8", name: "Date Range", keywords: ["yesterday, today, last week"]})}
            {this.getListItem({key: "C1R9", name: "Submission", keywords: ["charge, entry, posting, anomaly, issues"]})}
            {this.getListItem({key: "C1R10", name: "Cash Flow", keywords: ["next, expect, future, project"]})}
          </div>
          <Divider orientation="vertical" variant="inset" flexItem />
          <div style={{display:"flex", flexDirection: "column"}}>
            {this.getListItem({key: "C2R1", name: "CPT codes", keywords: ["88305, 88304"]})}
            {this.getListItem({key: "C2R2", name: "Physicians", keywords: ["John, Mary"]})}
            {this.getListItem({key: "C2R3", name: "Claims", keywords: ["09-1234567"]})}
          </div>
          <Divider orientation="vertical" variant="inset" flexItem />
          <div style={{display:"flex", flexDirection: "column", paddingLeft: 10, paddingRight:10}}>
            {!pastSearches && <div>
              <Typography>How to navigate Artiss using search:</Typography>
              <br />
              <img alt="Search Help"
                  src={require('../images/search-animate2.gif')} 
                  style={{height: 200, width: 300, borderWidth: 3, borderColor: 'white', borderRadius: 3}} />
            </div>}
            {pastSearches && <Typography>Past searches:</Typography>}            
            {pastSearches && pastSearches.map((item, i) => (
              this.getListItem({key: "C3R1_" + i, name: item, keywords: null}, {className: this.props.classes.listItemCompact})
            ))}            
            <br />
            <Typography>Take a tour</Typography>
            <br />
            <Typography>Cannot find what you're looking for? Ask for help!</Typography>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

const styles = theme => ({  
  root: {
  },
  listSection: {
  },
  listItem: {
    //backgroundColor: colors.bluegrey600,
  },
  listItemCompact: {
    marginTop: 0,
    marginBottom: 0,
  },
  ul: {
  },
});

export default withStyles(styles) (SearchHelp);