import BarChart from "../nivo/BarChart";
import BarChartHorizontal from "../nivo/BarChartHorizontal";
import LineChart from "../nivo/LineChart";
import PieChart from '../nivo/PieChart';

import React from 'react'
import { Line } from '@nivo/line'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
//import { SvgIcon } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';

import * as Utils from '../../../services/Utils';
import ChartInspector from '../../../devtools/ChartInspector';
import * as colors from '../../../Colours';
import * as ExpandedChartHandler from "./ExpandedChartHandler";

//export default 
class ExpandedChart extends React.Component  {
    render() {
        console.log("ExpandedChart constructor this.props: ", this.props);
        return (
            <React.Fragment>            
                {this.props.chartType=="BarChartHorizontal" && <BarChartHorizontal 
                    {...this.props} inceptionLevel={1} 
                    //{...this.props.chartProps} 
                    //dataInput={this.props.dataInput} 
                />}
                {this.props.chartType=="BarChart" && <BarChart 
                    {...this.props} inceptionLevel={1} 
                />}
                {this.props.chartType=="LineChart" && <LineChart 
                    {...this.props} inceptionLevel={1} 
                />}
                {this.props.chartType=="PieChart" && <PieChart 
                    {...this.props} inceptionLevel={1}
                />}
            </React.Fragment>
        );
    }
}

/*
const astyle = (theme) => ({
    all: {
        //borderColor: "#20232a",
        
        //display: 'flex',
        //marginBottom: 20
        //margin: { top: 30, right: 20, bottom: 30, left: 50 },
        // flex: 1,
        // width: '100'
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        // marginBottom: 25,
        //backgroundColor: theme.palette.primary,        
    },
    chartPaper: {
      margin: 5,
      padding: 0,
      elevation: 2,
      //backgroundColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.dark,
      //backgroundColor: 'transparent',
    },
  })  
  */

  const astyle = (theme) => {
    return ExpandedChartHandler.getTheme(theme);
  }
  
  export default withStyles(astyle, {withTheme: true})(ExpandedChart)


