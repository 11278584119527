// Responsecountdetails.js
// copyright @ artiss.co 

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'

import * as colors from '../../Colours'
import * as utils from '../../services/Utils'

// has_responses_count: 96
// no_response_count: 6
// no_response_count_percent: 0
// response_count_percent: 1
// under_process_count: 6681
// under_process_count_percent: 98

const ResponseCountDetails = (props) => {
    const classes = astyle();
    let {resdata} = props
    let rd = resdata[0]
    let totalcnt = rd.no_response_count + rd.has_responses_count + rd.under_process_count
    let tooltipmsg = 'Being processed & No response time periods are based on payor response time learnt from historic data'
    console.log("ResponseDetail", resdata, resdata[0].under_process_count_percent)

    if(!Boolean(rd)) {
        return(
            <Paper className={classes.cardpaper} > 
                <Typography variant="subtitle1"> Responses received details </Typography>
                <Typography variant="subtitle1"> No data for this date range </Typography>
            </Paper>
        )
    }

    //{no_response_count: 670, response_count_percent: 50, no_response_count_percent: 4, under_process_count_percent: 44}
    return(
        <Paper className={classes.cardpaper} > 
            <Typography variant="subtitle1"> Response breakup, total of {totalcnt} responses </Typography>

            <div className={classes.r1}>            
            <Box className={classes.b1} >
                    <Typography variant="h4"> {utils.showPercent_f1(rd.response_count_percent/100)} </Typography>
                    <Typography variant="subtitle1"> Got responses </Typography> 
            </Box>
            
            <Box className={classes.b1} >
                    <Typography variant="h4"> {utils.showPercent_f1(rd.under_process_count_percent/100)} </Typography>
                    <Typography variant="subtitle1"> Being processed </Typography>
            </Box>
            <Box className={classes.b1} >
                    <Typography variant="h4"> {utils.showPercent_f1(rd.no_response_count_percent/100)} </Typography>
                    <Typography variant="subtitle1"> No response </Typography>
            </Box>
            <Tooltip title={tooltipmsg} >
                    <InfoOutlinedIcon  className={classes.tt} fontSize='small'/>
            </Tooltip>
            </div>

        </Paper>
    )

}

const astyle = makeStyles((theme) => ({
    cardpaper: {
        // width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        //alignItems: 'center',
        backgroundColor: theme.palette.primary.dark,
        paddingLeft: 10,
        paddingTop: 5,
        margin: 5
    },
    b1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 5,
    },
    r1: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    tt: {
        marginTop:30
    }
    
}));

export default ResponseCountDetails