import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
/*
import Popover from "@material-ui/core/Popover";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "../../App.css";
import Card from "../widgets/Card";
import * as MaterialCard from "@material-ui/core/Card";
import * as MaterialCardContent from "@material-ui/core/CardContent";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from '../widgets/charts/nivo/PieChart';
//import TreeMapChart from '../widgets/charts/nivo/TreeMapChart';
import * as TabHandler from "../dashboards/common/TabHandler";
*/

//dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import RequisitionRoutingResults from './RequisitionRoutingResults';
import StickyTitle from '../widgets/Stickytitle';



import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as RecommendationHandler from "./RecommendationHandler";

class RequisitionRouting extends React.Component {
    constructor(props) {    
        super(props);

        var newState = RecommendationHandler.getNewState();

        let listAges = [];
        for (let i=0; i < 120; i++) {
            listAges.push({guid: "" + i, title: "" + i});
        }

        this.state = {
            ...newState,
            //dateRangeFilter: {filterName: "yesterday",label: "Yesterday"},     
            payor_guid: '',
            payor: '',
            plan_guid: '',
            plan: '',
            serviceClass_guid: '',
            serviceClass: '',
            duration_guid: '',
            duration: '',
            cpt_guid: '',
            cpt: '',
            referring_provider_guid: '',
            referring_provider: '',
            patient_state_guid: '',
            patient_state: '',
            patient_age_guid: '',
            patient_age: '',

            //lists:
            listCPTs: [],
            listServiceClasses: [],
            listPlans: [],
            listPayors: [],
            listReferringProviders: [],

            listDuration: [
                {title: 'Last 3 Months', guid: 'last_3_months'},
                {title: 'Last 4 Quarters', guid: 'last_4_quarters'},
                {title: 'Year To Date', guid: 'year_to_date'},
                {title: 'All Data', guid: 'all_data'}
            ],

            listStates: [
                {title: "AL", guid: "AL"},
                {title: "AK", guid: "AK"},
                {title: "AZ", guid: "AZ"},
                {title: "AR", guid: "AR"},
                {title: "CA", guid: "CA"},
                {title: "CO", guid: "CO"},
                {title: "CT", guid: "CT"},
                {title: "DE", guid: "DE"},
                {title: "FL", guid: "FL"},
                {title: "GA", guid: "GA"},
                {title: "HI", guid: "HI"},
                {title: "ID", guid: "ID"},
                {title: "IL", guid: "IL"},
                {title: "IN", guid: "IN"},
                {title: "IA", guid: "IA"},
                {title: "KS", guid: "KS"},
                {title: "KY", guid: "KY"},
                {title: "LA", guid: "LA"},
                {title: "ME", guid: "ME"},
                {title: "MD", guid: "MD"},
                {title: "MA", guid: "MA"},
                {title: "MI", guid: "MI"},
                {title: "MN", guid: "MN"},
                {title: "MS", guid: "MS"},
                {title: "MO", guid: "MO"},
                {title: "MT", guid: "MT"},
                {title: "NE", guid: "NE"},
                {title: "NV", guid: "NV"},
                {title: "NH", guid: "NH"},
                {title: "NJ", guid: "NJ"},
                {title: "NM", guid: "NM"},
                {title: "NY", guid: "NY"},
                {title: "NC", guid: "NC"},
                {title: "ND", guid: "ND"},
                {title: "OH", guid: "OH"},
                {title: "OK", guid: "OK"},
                {title: "OR", guid: "OR"},
                {title: "PA", guid: "PA"},
                {title: "RI", guid: "RI"},
                {title: "SC", guid: "SC"},
                {title: "SD", guid: "SD"},
                {title: "TN", guid: "TN"},
                {title: "TX", guid: "TX"},
                {title: "UT", guid: "UT"},
                {title: "VT", guid: "VT"},
                {title: "VA", guid: "VA"},
                {title: "WA", guid: "WA"},
                {title: "WV", guid: "WV"},
                {title: "WI", guid: "WI"},
                {title: "WY", guid: "WY"},
            ],

            listAges: listAges,

            isLoadingCount: 0,
            response: null,
        };
    }  

    componentDidMount() {
        this.getDataForRequestForm();

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);  
    }

    componentDidUpdate(prevProps,prevState) {
        let componentDidUpdateFlag = RecommendationHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
        if (componentDidUpdateFlag) this.getDataForRequestForm();        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    getDataForRequestForm() {
        console.log("this.props: ", this.props);
        if (!this.props.user) return;
        let authtoken = this.props.user.idtoken;
        if (!authtoken) return;
        let customer_filter_criteria = RecommendationHandler.getCustomerFilterCriteria(this.props.customer);

        let requestDataForRequestForm = {
            "table_slugs": [                
            ],
            "filter_criteria": {
                ...customer_filter_criteria,
                //"transaction_date": {"literal":this.state.dateRangeFilter.filterName},
                //"options":{"set":"top","limit":10}
            }
        };

        this.setState({isLoadingCount: 5});
        //APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForRequestForm, authtoken: authtoken, callback: this.updateResponseForRequestForm});
        APIHandler.callPostAPI({callname: "web_get_meta_cpts", requestData: requestDataForRequestForm, authtoken: authtoken, callback: this.updateResponseForCPTs});
        APIHandler.callPostAPI({callname: "web_get_meta_service_classes", requestData: requestDataForRequestForm, authtoken: authtoken, callback: this.updateResponseForServiceClasses});
        APIHandler.callPostAPI({callname: "web_get_meta_plans", requestData: requestDataForRequestForm, authtoken: authtoken, callback: this.updateResponseForPlans});
        APIHandler.callPostAPI({callname: "web_get_meta_payor", requestData: requestDataForRequestForm, authtoken: authtoken, callback: this.updateResponseForPayors});
        APIHandler.callPostAPI({callname: "web_get_referring_physicians", requestData: requestDataForRequestForm, authtoken: authtoken, callback: this.updateResponseForReferringProviders});
    }  

    updateLoadingDone = () => {
        this.setState((state) => ({
            isLoadingCount: state.isLoadingCount - 1,
        }));
    };

    updateResponseForCPTs = (response) => {
        console.log("updateResponseForCPTs: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listCPTs: response});
        this.updateLoadingDone();
    }
    
    updateResponseForServiceClasses = (response) => {
        console.log("updateResponseForServiceClasses: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listServiceClasses: response});
        this.updateLoadingDone();
    }
    
    updateResponseForPlans = (response) => {
        console.log("updateResponseForPlans: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listPlans: response});
        this.updateLoadingDone();
    }
    
    updateResponseForPayors = (response) => {
        console.log("updateResponseForPayors: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listPayors: response});
        this.updateLoadingDone();
    }
    
    updateResponseForReferringProviders = (response) => {
        console.log("updateResponseForReferringProviders: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listReferringProviders: response});
        this.updateLoadingDone();
    }

    
    updateResponseForRecommendedProviders = (response) => {
        console.log("updateResponseForRecommendedProviders: ", response);
        this.setState({response: response});
        this.updateLoadingDone();
    };

    /*
    onChangeDateRangeFilter = (selectedFilter) => {
        console.log("onChangeDateRangeFilter: ", selectedFilter);
        let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);
        this.setState({
            dateRangeFilter: {
                filterName: selectedFilter.daterange.literal, 
                label: selectedFilter.datetext
            }, 
            prevDateRangeFilter: prevDateRangeFilter
        });
    }
    */

    /*
    handleChangePayor = (value) => {
        console.log("handleChangePayor: ", value);
        this.setState({payor_guid: value.guid});
        this.setState({payor: value.title});
    }

    handleChangePlan = (event) => {
        console.log("handleChangePlan: ", event.target.value);
        this.setState({plan_guid: event.target.value});
    }

    handleChangeServiceClass = (event) => {
        console.log("handleChangeServiceClass: ", event.target.value);
        this.setState({serviceClass_guid: event.target.value});
    }

    handleChangeCPT = (event) => {
        console.log("handleChangeCPT: ", event.target.value);
        this.setState({cpt_guid: event.target.value});
    }

    handleChangeReferringProvider = (event) => {
        console.log("handleChangeReferringProvider: ", event.target.value);
        this.setState({referring_provider_guid: event.target.value});
    }

    handleChangeDuration = (event) => {
        console.log("handleChangeDuration: ", event.target.value);
        this.setState({duration_guid: event.target.value});
    }
    */

    getRecommendedProviders = () => {
        let authtoken = this.props.user.idtoken;
        if (!authtoken) return;
        let customer_filter_criteria = RecommendationHandler.getCustomerFilterCriteria(this.props.customer);
        let requestData = {
            //"table_slugs": [],
            "filter_criteria": {
                ...customer_filter_criteria,
                options: {
                    set:"top",
                    limit: 10
                },
                patient_state: this.state.patient_state_guid ? this.state.patient_state_guid : undefined,
                patient_age: this.state.patient_age ? (!isNaN(this.state.patient_age) ? parseInt(this.state.patient_age) : undefined) : undefined,
                cpt: this.state.cpt_guid ? this.state.cpt_guid : undefined,
                payor_guid: this.state.payor_guid ? this.state.payor_guid : undefined,
                //"transaction_date": {"literal":this.state.dateRangeFilter.filterName},
                transaction_date: {
                    literal: this.state.duration_guid ? this.state.duration_guid : "last_3_months", //"all_data",
                },
                referring_provider_guid: this.state.referring_provider_guid ?  this.state.referring_provider_guid : undefined,
                service_class: this.state.serviceClass_guid ?  this.state.serviceClass_guid : undefined,
                payor_plan: this.state.plan_guid ?  this.state.plan_guid : undefined,

            }
        };

        console.log("getRecommendedProviders called: this.state: ", this.state);
        console.log("getRecommendedProviders called: requestData: ", requestData);        

        this.setState({isLoadingCount: this.state.isLoadingCount + 1});
        APIHandler.callPostAPI({
            callname: "web_api_wif_renderer", 
            requestData: requestData, 
            authtoken: authtoken, 
            callback: this.updateResponseForRecommendedProviders
        });
    }

    render() {
        try {      
            const {classes} = this.props;

            let cs1 = {
                style: "currency",
                currency: "USD",
                minimumSignificantDigits: 2,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            };    

            let messagePayor = "", messagePlan = "", messageCPT = "", messageServiceClass = "", messageReferringProvider = "", messageDuration = "", messageStates = "", messageAge = "";

            let response = this.state.response;
            //let dateRangeFilter = this.state.dateRangeFilter;
        
            let chartWidth=this.state.width*0.25;
            let chartHeight=this.state.height*0.25;

            return (
                <Container maxWidth={false} disableGutters={false} className={classes.top} >
                    <div className={classes.stickyhdr}>
                        <StickyTitle 
                            context='recommendations' 
                            title={"Recommendations: Requisition Routing"} 
                            //subtitle={this.state.dateRangeFilter.label}
                            //serverdaterange={periodDisplay}
                            //callbackForDateRange={this.onChangeDateRangeFilter} 
                            showProgressBar={(this.state.isLoadingCount > 0)}
                        />
                    </div>

                    {/*
                    {(this.state.listPayors && this.state.listPayors.status!=="success") && <div>Error loading Payors</div>}
                    {(this.state.listPlans && this.state.listPlans.status!=="success") && <div>Error loading Plans</div>}
                    {(this.state.listServiceClasses && this.state.listServiceClasses.status!=="success") && <div>Error loading Service Classes</div>}
                    {(this.state.listCPTs && this.state.listCPTs.status!=="success") && <div>Error loading CPTs</div>}
                    {(this.state.listReferringProviders && this.state.listReferringProviders.status!=="success") && <div>Error loading ReferringProviders</div>}
                    */}

                    <Paper className={classes.sectionpaper} >
                        {/*<div className={classes.dtr}>*/}
                        <Box p={2} m={2}>
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0}>
                            <Box width="40%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation required id="payor-label">Payor</InputLabel>                                
                                    {this.state.listPayors &&this.state.listPayors.data && <Autocomplete
                                        id="payor" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({payor_guid: value.guid, payor: value.title});
                                            else this.setState({payor_guid: "", payor: "None"});
                                        }}
                                        options={this.state.listPayors.data}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messagePayor}</FormHelperText>
                                </FormControl> 
                            </Box>    
                            <div style={{marginRight: 50}}></div>  
                            <Box width="20%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation required id="plan-label">Plan</InputLabel>                                
                                    {this.state.listPlans && this.state.listPlans.data && <Autocomplete
                                        id="plan" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({plan_guid: value.guid, plan: value.title});
                                            else this.setState({plan_guid: "", plan: "None"});
                                        }}
                                        options={this.state.listPlans.data}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messagePlan}</FormHelperText>
                                </FormControl> 
                            </Box>                                                     
                            <div style={{marginRight: 50}}></div>  
                            <Box width="15%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation required id="duration-label">Duration</InputLabel>                                
                                    {this.state.listDuration && <Autocomplete
                                        id="duration" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({duration_guid: value.guid, duration: value.title});
                                            else this.setState({duration_guid: "", duration: "None"});
                                        }}
                                        options={this.state.listDuration}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messageDuration}</FormHelperText>
                                </FormControl> 
                            </Box>   
                        </Box>                        

                        <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0}>
                            <Box width="25%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation id="service-class">Service Class</InputLabel>                                
                                    {this.state.listServiceClasses && this.state.listServiceClasses.data && <Autocomplete
                                        id="service-class" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({serviceClass_guid: value.guid, serviceClass: value.title});
                                            else this.setState({serviceClass_guid: "", serviceClass: "None"});
                                        }}
                                        options={this.state.listServiceClasses.data}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messageServiceClass}</FormHelperText>
                                </FormControl> 
                            </Box>   
                            <div style={{marginRight: 50}}></div>  
                            <Box width="15%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation id="cpt">CPT</InputLabel>                                
                                    {this.state.listCPTs && this.state.listCPTs.data && <Autocomplete
                                        id="cpt" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({cpt_guid: value.guid, cpt: value.title});
                                            else this.setState({cpt_guid: "", cpt: "None"});
                                        }}
                                        options={this.state.listCPTs.data}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messageCPT}</FormHelperText>
                                </FormControl> 
                            </Box>   
                            <div style={{marginRight: 50}}></div> 
                            <Box width="35%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation id="referring_provider">Referring Physician</InputLabel>                                
                                    {this.state.listReferringProviders && this.state.listReferringProviders.data && <Autocomplete
                                        id="referring_provider" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({referring_provider_guid: value.guid, referring_provider: value.title});
                                            else this.setState({referring_provider_guid: "", referring_provider: "None"});
                                        }}
                                        options={this.state.listReferringProviders.data}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messageReferringProvider}</FormHelperText>
                                </FormControl> 
                            </Box> 
                        </Box>
                        <div style={{marginBottom: 20}}></div>                          
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0}>
                            <Box width="10%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation id="patient_state">Patient State</InputLabel>                                
                                    {this.state.listStates && <Autocomplete
                                        id="patient_state" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({patient_state_guid: value.guid, patient_state: value.title});
                                            else this.setState({patient_state_guid: "", patient_state: "None"});
                                        }}
                                        options={this.state.listStates}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messageStates}</FormHelperText>
                                </FormControl> 
                            </Box>       
                            <div style={{marginRight: 50}}></div> 
                            <Box width="10%" p={1} my={0.5}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <InputLabel shrink disableAnimation id="patient_age">Patient Age</InputLabel>                                
                                    {this.state.listAges && <Autocomplete
                                        id="patient_age" 
                                        className={classes.selectEmpty} 
                                        onChange={(event, value) => {
                                            if (value) this.setState({patient_age_guid: value.guid, patient_age: value.title});
                                            else this.setState({patient_age_guid: "", patient_age: ""});
                                        }}
                                        options={this.state.listAges}
                                        getOptionLabel={(option) => {return option.title;}}
                                        fullWidth={true}
                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                    />}
                                    <FormHelperText>{messageStates}</FormHelperText>
                                </FormControl> 
                            </Box>       
                            <div style={{marginRight: 50}}></div> 

                        </Box>
                        <div style={{marginBottom: 20}}></div>                          


                        {/*color="primary"/secondary */}
                        <Button variant="contained" onClick={() => { this.getRecommendedProviders() }}>Recommend Providers</Button>

                        <div style={{marginBottom: 20}}></div>     

                        {this.state.response && this.state.response.status!="success" && <React.Fragment />}                          
                        {this.state.response && this.state.response.status=="success" && this.state.response.data && <RequisitionRoutingResults data={this.state.response.data} />}  
                        </Box>
                    </Paper>
                </Container>      
            )
        }
        catch (err) {
            if (this.state.isLoadingCount > 0) {
                return (
                    <React.Fragment>
                    </React.Fragment>
                );      
            }
            else {
                console.log(err);
                return (
                    <React.Fragment>
                    <br />Error rendering this form
                    </React.Fragment>
                );      
            }
        }  
    }
}

const astyle = (theme) => {
    let existingTheme = RecommendationHandler.getTheme(theme); 
    let currentTheme = {
        ...existingTheme, 
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'flex-start'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        dtr: {
            display: 'flex',
            flexDirection: 'column'
        }        
    }    
    return currentTheme;
}

export default withStyles(astyle, {withTheme: true})(RequisitionRouting)
