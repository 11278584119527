import React from "react";

import UserContext from '../../../../context/UserContext';
import CustomerContext from '../../../../context/CustomerContext';
import ExpandedChartContainer from './ExpandedChartContainer';

export default function ExpandedChartContext(props) {
    const user = React.useContext(UserContext);
    //console.log("ExpandedChartContext user 1: ", user);
    return (            
        <CustomerContext.Consumer>
        {({customer, setCustomer}) => {  
            //console.log("ExpandedChartContext customer: ", customer);
            return (
                <ExpandedChartContainer {...props} user={user} customer={customer} setCustomer={setCustomer}>
                </ExpandedChartContainer>
            );
        }}
        </CustomerContext.Consumer>
    );                
}

