import React from "react";

import CustomerContext from "./CustomerContext";
import CustomerSelector from '../components/widgets/CustomerSelector';

/**
 * @component CustomerContextProvider
 * @description wrapper for customer context provider. Use this just one-time in the app, in the outermost widget as required
 * @property {object} children (optional prop set for this widget) default React element for any child nodes that need to be embedded within this provider widget
 * @property {object} customer currently selected customer / customer group
 * @function setCustomer sets the currently selected customer / customer group
 * @return {undefined} 
 * @example <CustomerSelector user={user} onSelected={setCustomer} />
 */

//introducing this class for separation of responsibility for initializing and maintaining state
class CustomerContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setCustomer = (customer) => {
      console.log("setCustomer: ", customer);
      this.setState(state => ({
        customer: customer,
        //source_guid: customer.source_guid,
      }));
     //this.setState({customer: customer});
    };

    let customer = {};

    // State also contains the updater function so it will be passed down into the context provider
    this.state = {
      customer:  customer,
      //source_guid: "",
      setCustomer: this.setCustomer
    };      
  }

  render() {
    //set selected=this.state.customer.customer_guid_tuple.source_guid if required to init
    return (
      <CustomerContext.Provider value={this.state}>  
        <CustomerSelector user={this.props.user} onSelected={this.setCustomer} />
        {this.props.children}
      </CustomerContext.Provider>
    );
  }
}

export default CustomerContextProvider;