// Serviceclassnewbiz.js
/// copyrights @ artiss.co

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { ResponsivePie } from '@nivo/pie'
//import * as colors from '../../Colours'

const ServiceClassNewBiz = (props) => {
    const classes = astyle();
    let {msg, chartdata} = props
    let newval = 0; let oldval = 0; let cdata= []
    for(let i=0; i<chartdata.length; i++) {
        for(const [key,value] of Object.entries(chartdata[i])) { 
            let d = chartdata[i]
            if(value==='New') { newval=d['paid_amount']; cdata.push({id: 'New', label: 'Paid by New Biz', value: newval})}
            if(value==='Existing') { oldval = d['paid_amount']; cdata.push({id: 'Existing', label: 'Paid by Existing Biz', value: oldval}) }
        }
    }
    let percent = ((newval/oldval)  * 100).toFixed(1)
    console.log("SCnewbix", percent)
    let percentmsg = ""
    if(!Boolean(percent)) {
        percent = 0
        percentmsg = 'No new business in ' + msg.time
    } else if(percent === '0.0') {
        percentmsg = 'No new business in ' + msg.time
    } else {
        percentmsg = percent + '% of new business in ' + msg.time
    }

    return(
        <Paper className={classes.cardpaper} >
            {/* <img src={newbiz} alt="rank" className={classes.newbizimg} /> */}
            <Container> 
            <Typography variant="h3"> {percent}% </Typography> 
            <Typography variant="body1"> {msg.scname} generated {percentmsg} </Typography> 
            </Container> 
            <ResponsivePie
                data={cdata}
                //width={100}
                height={120}
                margin={{ top: 5, bottom: 30}}
                startAngle={-90}
                endAngle={90}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'nivo' }}
                sliceLabelFormat={"~s"}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                enableRadialLabels={false}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                labelFormat={["$", ".2s"]}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateY: 20,
                        itemWidth: 125,
                        itemHeight: 14,
                        itemTextColor: '#fff',       // '#999',
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: 'red',
                                    color: 'black'
                    
                                }
                            }
                        ]
                    }
                ]}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    }
                
                  }}
                
                />

        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        //width: '80%',
        display: 'flex',
        //flexDirection: 'row',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        padding: 5,
        margin: 10,
        height: 130
    },
    newbizimg: {
        height: 30,
        width: 30
    }
}));

export default ServiceClassNewBiz