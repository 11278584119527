// Claimutils.js


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Divider from '@material-ui/core/Divider';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PanToolIcon from '@material-ui/icons/PanTool';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import * as colors from '../../Colours'
import {Table2ColNoheader, Table4ColNoheader, Table3ColNoheader, Table3ColWithHeader, Table5ColWithHeader,
            TablewithVariableCols,Line, ObsDetails, DiffDetails} from './Claimutils'

const ShowClaimDetails = (props) => {
    const classes = astyle();
    const [ttopen, setObstt] = React.useState(false);
    let matx = props.data

    return(
        <Paper className={classes.papertl} >
            <Timeline   align="left">
                
            {matx.map((d,i, arr) => {
                console.log("%%-->",d) 
                if(d.tx_type === 'service') {
                    let servicedate = d.data.data.header.service_date
                    let rp = d.data.data.header.rendering_provider
                    return(
                    <TimelineItem  key={i}>
                        <TimelineOppositeContent className={classes.oppcontent} >
                        <span className={classes.stdata}> service </span>
                        {Boolean(d.obs) ? <PanToolIcon color='error' /> : null }
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        {/* <TimelineDot>
                            <CDate date={servicedate} />
                        </TimelineDot> */}
                        <CDate date={servicedate} />
                        <TimelineConnector className={classes.tlconnect} />
                        </TimelineSeparator>
                        
                        <TimelineContent className={classes.tlcontent}>
                           
                        <div className={classes.tlc1}>
                        <Line />
                            <Paper elevation={2} className={classes.servicebox}>
                                <div className={classes.title}> rendering_provider: </div>
                                <span className={classes.titledata}> {rp} </span>
                            </Paper>
                            </div>
                            <Divider variant='inset' light />
                        </TimelineContent>
                        
                    </TimelineItem>
                    )
                    
                } else if(d.tx_type === "claim_submission") {
                    let cd = d
                    let txdate = cd.tx_date
                    let payorname= cd.payor_name; let plantype = cd.payor_plan_type
                    let charged = cd.data.charged
                    let icds = cd.data.icds
                    let lineitem = d.lineitem
                    let lt = []
                    if(Boolean(lineitem)) {
                        for(let i=0; i<lineitem.length; i++) {
                            let li = lineitem[i]
                            let cpt_mod = ""
                            if(Boolean(li.mod1)) {
                                cpt_mod = li.cpt_code + ', ' + li.mod1
                            } else {
                                cpt_mod = li.cpt_code
                            }
                            lt.push([cpt_mod, li.dx1, li.quantity, '$'+li.charged])
                        }
                    }
                    let diff = null
                    if(Boolean(d.data._diff)) {
                        // for(const [key, value] of Object.entries(d.data._diff)) {
                        //     for(const [kv, vv] of Object.entries(value)) {
                        //         let dstr = key + ':' + kv + vv
                        //         diff.push(dstr)
                        //     }
                        // }
                        diff = d.data._diff
                    }
                       
                    return(
                        <TimelineItem key={i}>
                        <TimelineOppositeContent className={classes.oppcontent} >
                        <div className={classes.obs} >
                            {Boolean(d.obs) ? 
                            <Button onClick={() => setObstt(!ttopen)} >
                                <Badge color='primary' badgeContent={d.obs.count}>
                                    <PanToolIcon color='error'/> 
                                </Badge>
                            </Button>
                            
                            : null }
                            
                            <span className={classes.stdata}> submission </span>
                        </div>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        {/* <TimelineDot variant='default' >
                            <CDate date={txdate} />
                        </TimelineDot> */}
                        <CDate date={txdate} />
                        <TimelineConnector className={classes.tlconnect} />
                        </TimelineSeparator>
                        
                        <TimelineContent className={classes.tlcontent}>
                       
                        <div className={classes.tlc1}>    
                            <Line toptext={d.sequence} />
                            <div className={classes.rowbox}>
                            <Paper elevation={2} className={classes.servicebox}>
                                <div className={classes.titledata}> {payorname} </div>
                                <span className={classes.title}> Plan: <span className={classes.sdatabright}> {plantype} </span>  </span>
                            </Paper>
                            
                                {lt.length > 0 ? 
                                    <Table4ColNoheader data={lt} /> :
                                <div>
                                    <div className={classes.title}> ICD: </div>
                                    <span className={classes.titledata}> {icds} </span> 
                                </div>
                                }
                            
                            </div>
                            <Paper elevation={2} className={classes.servicebox2}>
                            <div className={classes.title}> Charged($): </div>
                                <span className={classes.titledata}> {charged} </span>
                            </Paper>
                            
                            </div>
                            {Boolean(diff) ? 
                                <Tooltip title={<DiffDetails msg={diff} />} leaveDelay={5000}>
                                    <ControlPointIcon className={classes.difficon} />
                                </Tooltip>
                                : null
                            }
                            
                            { /* <ControlPointIcon className={classes.difficon} /> : null}  */ }
                            {Boolean(ttopen) ? <ObsDetails msg={d.obs.msg} /> : null }
                            <Divider variant='inset' light />
                        </TimelineContent>
                        
                    </TimelineItem>
                    )
                } else if(d.tx_type === "claim_response") {
                    let cd = d
                    let txdate = cd.tx_date
                    let payorname= cd.payor_name
                    let crossover = null
                    if(Boolean(cd.crossover))
                        crossover = cd.crossover.Name
                    let moneydetails = []; let moneydetailsheader = []
                    let adjdetails = []; let adjdetailsheader = []
                    let cptdetails = []; let cptdetailsheader = []
                    if(Boolean(cd.tables)) {
                        moneydetailsheader = d.tables.summary_table.header
                        moneydetails = d.tables.summary_table.data
                        adjdetails = d.tables.adj_table.data
                        adjdetailsheader = d.tables.adj_table.header
                        cptdetails = d.tables.cpt_table.data
                        cptdetailsheader = d.tables.cpt_table.header
                    }
                    
                    return(
                        <TimelineItem  key={i}>
                            <TimelineOppositeContent className={classes.oppcontent} >
                            <span className={classes.stdata}> response </span>
                            {Boolean(d.obs) ? 
                                <Tooltip title={<ObsDetails msg={d.obs.msg} />} leaveDelay={5000} >
                                    <Badge color='primary' badgeContent={d.obs.count}>
                                        <PanToolIcon color='error'/> 
                                    </Badge>
                                </Tooltip>
                                : null }


                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <CDate date={txdate} />
                            {(i+1 === arr.length) ? null
                            : 
                            <TimelineConnector className={classes.tlconnect} />
                            }
                            </TimelineSeparator>
                            
                            <TimelineContent className={classes.tlcontent} >
                              
                               <div className={classes.tlc1}>  
                                    <Line toptext={d.sequence} />
                                    <div>
                                    <Paper elevation={2} className={classes.servicebox}>
                                        <div className={classes.titledata}> {payorname} </div> 
                                    </Paper>
                                    {Boolean(crossover) ? 
                                    <Paper elevation={2} className={classes.servicebox} >
                                        <span className={classes.title}>submission transferred to <span className={classes.titledata}>{crossover} </span>for further processing</span>
                                    </Paper>
                                    : null
                                    }
                                    <Box className={classes.box2} >
                                    {cptdetails.length > 0 ? 
                                        /* <Table3ColNoheader data={adjdetails} />  */
                                        <TablewithVariableCols
                                            header={cptdetailsheader}
                                            data={cptdetails}
                                            />
                                        : null 
                                    }
                                    {adjdetails.length > 0 ? 
                                        /* <Table3ColNoheader data={adjdetails} />  Table5ColWithHeader */
                                        // <Table5ColWithHeader
                                        <TablewithVariableCols
                                            header={adjdetailsheader}
                                            data={adjdetails}
                                            />
                                        : null 
                                    }
                                    </Box>
                                    </div>
                                    <div className={classes.tbl1} >
                                    
                                {/* <Table3ColWithHeader  */}
                                    <TablewithVariableCols
                                        header={moneydetailsheader}
                                        data={moneydetails}
                                        />
                                    </div>
                                </div>
                                
                                <Divider variant='inset' light />
                            </TimelineContent>
                            
                        </TimelineItem>
                        
                    )
                } else 
                    return null
            })
            }
            </Timeline>
            
        </Paper>
        
    )

}


const mname = [' ', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const CDate = (props) => {
    const classes = astyle();
    let dt = props.date.split('-')
    let mt = parseInt(dt[1])
    let year = dt[0]; let month = mname[mt]; let day = dt[2]
    //console.log("month-", month, dt[1])
    return(
        <div className={classes.datecircle}>
                <div className={classes.mday}> {month} {" "} {day}</div>
                <div>{year}</div>
        </div>
    )
}


const astyle = makeStyles((theme) => ({
    header: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    title: {
        fontSize: 'small',
        color: colors.grey400,
        flexDirection: 'row'
    },
    titledata: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: colors.bluegrey100
    },
    nameage: {
        flexDirection: 'row'
    },
    subtitle: {
        fontSize: 'small',
        color: 'white',
        marginLeft: 50
    },
    sectitle: {
        display: 'flex',
        //flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 10,
        width: '80%'
    },
    stitle: {
        fontSize: 'small',
        color: 'grey',
    },
    sdatabright: {
        fontSize: 'small',
        color: colors.bluegrey100,
        flex: 1
    },
    stdata: {
        fontSize: 'large',
        //fontWeight: 'regular',
        color: colors.bluegrey100
    },
    shdata: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: colors.bluegrey800
    },
    papertl: {
        backgroundColor: colors.bluegrey700,
        //display: 'flex',
        //width: '90%',
    },
    
    datecircle: {
        width: 65,
        height: 60,
        borderRadius: 10,
        border: 3,
        borderColor: 'white',
        //backgroundColor: colors.bluegrey600,
        backgroundColor: colors.secondarydark,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '2 2 2'

    },
    stl: {
        marginTop: 10,
        display: 'flex',
        //flex: 1,
        justifyContent: 'flex-start',
        //justifyContent: 'space-evenly',
        position: 'relative'
    },
    mday: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
        color: colors.bluegrey100
    },
    servicebox: {
        backgroundColor: colors.primarydark,
        //flex: 2,
        padding: 5,
        marginLeft: 20,
        marginBottom: 4,
        //flexBasis: 'content',
        //width: 'auto'
        // position: 'absolute',
        // right: '10%',
        //alignSelf: 'center',
    },
    servicebox2: {
        backgroundColor: colors.primarydark,
        //flex: 2,
        padding: 5,
        marginLeft: 20,
        marginBottom: 4,
        height: '50%',
        flexBasis: '25%'
    },
    oppcontent: {
        // padding: 0,
        // margin: 10,
        // textAlign: 'left',
        // alignSelf: 'center',
        flex: 0,
        minWidth: 150
    },
    tlc1: {
        display:'flex',
        //width: '95%',
        flexDirection: 'row',
        alignItems: 'flex-start'
        //right: '10%'
    },
    rowbox: {
        display: 'flex',
        flexDirection: 'column'
    },
    hline: {
        width: 100,
        height: 4,
        backgroundColor: colors.bluegrey100
    },
    tlconnect:{
        backgroundColor: 'grey',
        minHeight: 30
    },
    tlitem: {
        //display: 'flex',
        //flexDirection: 'row',
        position: 'relative',
        left: 10
    },
    tlcontent: {
        paddingTop: 0
    },
    tbl1: {
       // width: '50%'
    },
    difficon: {
        position: 'absolute',
        right: '1%',
        bottom:'4%'
    },
    obs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    box2: {
        //width: '100%'
    }
    
}));

export default ShowClaimDetails 


// PR - deductiable, co-in, copay , other_pr_adj - patient responsibilityOA - 
// other adjustment
// PI - payer initiated reductions
// CO - contractural obligation
// CR - correction & reversal


// line 99 { /* <Tooltip title={<ObsDetails msg={d.obs.msg} />} leaveDelay={5000}> */ }