// DenialText.js
// copyright @artiss.co

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import * as utils from '../../services/Utils'

// for Denials, up is red since denails going up is bad new
const changeTrendIcon = (dir) => {
    if(dir === 'up') {
        return <TrendingUpIcon style={{color:'red'}} fontSize='default'/>
    } else if(dir === 'down') {
        return <TrendingDownIcon style={{color:'#76ff03'}} fontSize='default' />
    } else {
        return <TrendingFlatIcon />
    }
} 

const directionMsg = (rd, ent) => {

    //console.log("direction --", rd)
    const classes = astyle();
    let mnth = utils.monYearStr(rd.current_vs_previous.compare_period.start)
    let periodtype = rd.current_vs_previous.compare_period.period_type
    let dm1 = ''; let dm2 = ''; let dm3 =  ''
    let m = rd.current_vs_previous
    //let n = rd.current_vs_previous_year
    if(m.compare_change_direction === 'up') {
        dm1 = ent + ' value this ' + periodtype + ' is ' 
        dm2 = ' ' + utils.showPercent_f2(Math.abs(m.compare_change_amount_percent)/100)
                + '(' + utils.showUSD_f1(Math.abs(m.compare_change_amount)) + ')  compared to ' 
                + m.compare_period.relative_to + ' ' + m.compare_period.period_type

        if(m.compare_change_count_percent < 0) {
            dm3 = 'while denial count is down ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        } else if(m.compare_change_count_percent > 0) {
            dm3 = 'and denial count is up ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        }
                // + '. Compared to previous year ' + mnth + ' revenue has gone ' 
                // + n.compare_change_direction + ' by ' + n.compare_change_percent + '%. '

    } else if(m.compare_change_direction === 'down') {
        dm1 = ent + ' value +this ' + periodtype + ' is '
        dm2 = ' ' + utils.showPercent_f2(Math.abs(m.compare_change_amount_percent)/100)
                + ' (' + utils.showUSD_f1(Math.abs(m.compare_change_amount)) + ') compared to '
                +  m.compare_period.relative_to + ' ' + m.compare_period.period_type
        if(m.compare_change_count_percent < 0) {
            dm3 = 'and denial count is down ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        } else if(m.compare_change_count_percent > 0) {
            dm3 = 'while denial count is up ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        }
        
    }
    return (
        <>
        <div className={classes.dm} />
        <Typography display='inline' variant="subtitle1" > 
        {dm1}
        {changeTrendIcon(m.compare_change_direction)}
        {dm2} {dm3}
        </Typography>
        </>
    )
}




const cmMsg_denialclass = (ad, dir) => {
    const classes = astyle();
    
    let ccngp = Math.abs(ad.compare_change_amount_percent)
    let cm = null
    // if dir is UP, API returns the top +ve impact in change_makers_top
    // if dir is DOWN, API returns the top -ve impact in change_makers_bottom
    if(dir === 'up') {
        cm = ad.change_makers_top.category
    } else if(dir === 'down') {
        cm = ad.change_makers_bottom.category
    } else {
        console.log("change maker direction NOT RECOGNIZED",dir)
        cm = []      // set it to empty , so does not bomb
    }

    //let cm = ad.change_makers_top.class
    //console.log('cmMsg denialclass +++', ccngp)
    if(!Boolean(cm)) 
        return ''
    let tag1 = ''; let names = []
    if(cm.length === 0 || Math.abs(ccngp) < 5)
        {return ''}
    let cm_cnt = 0; let cm_amount = 0;
    for(let i=0; i<cm.length; i++) {
        cm_cnt += cm[i].change_count
        cm_amount += cm[i].change_amount
        names.push({name: cm[i].name, amt: cm[i].change_amount})
    }
    names.sort((f,s) => {if(f.amt < s.amt) {return -1}
                         else {return 1}})
    //console.log( 'denial names===', names)
    
    let msg = ''
    if(dir === 'up') {
        if(ccngp < 3) {
            tag1 = 'slight increase'
        } else if(ccngp < 8) {
            tag1 = 'increase'
        } else if(ccngp < 20) {
            tag1 = 'steep increase'  
        } else {
            tag1 = 'spike'
        }
        if(names.length === 1) {
            //msg += '. Denials were due to ' + names[0].name
            return(
                <Typography display='inline' variant='subtitle1'>
                    . Denials were due to <span className={classes.td2}> {names[0].name} </span> 
                </Typography>
            )
        } else if( names.length > 0) { 
            return(
                <>
                {names.map((d,i, arr) => {
                    if(i === 0) {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                            . {utils.capitalizeFirstLetter(tag1)} in denials mainly due to <span className={classes.td2}> {d.name} </span> 
                        </Typography>
                        )
                    } else if(i < arr.length-1) {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                                , <span className={classes.td2}> {d.name} </span> 
                            </Typography>
                        )
                    } else {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                                , <span className={classes.td2}> {d.name} </span>.
                            </Typography>
                        )
                    }
                    
                })}
                </>
            )
        } else { return null}
    } else if(dir === 'down') {
        if(ccngp < 3) {
            tag1 = 'slight decrease'
        } else if(ccngp < 8) {
            tag1 = 'decrease'
        } else if(ccngp < 20) {
            tag1 = 'modest decrease'
        } else {
            tag1 = 'sharp decline'
        }
        if(names.length === 1) {
            //msg += '. Denials are due to ' + names[0].name + '.'
            return(
                <Typography display='inline' variant='subtitle1'>
                    . Denials were due to <span className={classes.td2}> {names[0].name} </span> 
                </Typography>
            )
        } else if( names.length > 0) { 
            return(
                <>
                {names.map((d,i, arr) => {
                    if(i === 0) {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                            . {utils.capitalizeFirstLetter(tag1)} in denials mainly due to <span className={classes.td2}> {d.name} </span> 
                        </Typography>
                        )
                    } else if(i < arr.length-1) {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                                , <span className={classes.td2}> {d.name} </span> 
                            </Typography>
                        )
                    } else {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                                , <span className={classes.td2}> {d.name} </span>.
                            </Typography>
                        )
                    }
                    
                })}
                </>
            )
        } else { return null}
    }
    //return msg


}


const DenialSummaryText = (props) => {
    const classes = astyle();
    let rd = props.data.data
    //console.log( 'denialSummary ----', props.data)
    let smsg = ''
    let rdcp = rd.current_vs_previous
    let mnth = utils.monYearStr(rd.current_period.start)
    if(rd.measure === 'denial') {
        smsg = 'Denials for ' + mnth + ' , ' + rd.measurement_count + ' denied, value of ' + utils.showUSD_f2(rd.measurement_amount)
    }
    return(
        <Paper className={classes.pap}>
            <Typography variant="h6"> {smsg} </Typography>
            <Divider variant='fullWidth' light={true} />
            {directionMsg(rd, 'Denial')}
            {cmMsg_denialclass(rdcp, rdcp.compare_change_direction)}
        </Paper>
    )
}


const astyle = makeStyles((theme) => ({
    pap: {
        marginTop: 5,
        marginRight: 15,
        padding: 10,
        width: '50%'
    },
    dm: {
        paddingTop: 6
    },
    td1: {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.secondary.main
    }, 
    td2: {
        color: theme.palette.secondary.main
    }
}));

export {DenialSummaryText}
