// userContext.js

import React from "react";

export const userDetail = {
  name: "nobody",
  email: null,
  photo: null,
  role: null,
  auth: null,
  isSignedIn: false,
  idtoken: null,
  customerguid: null
};

const UserContext = React.createContext(userDetail);
export default UserContext;
