// Paidbreakup.js
// copyright @ artiss.co

// covers CPT - paid-charged-allowed details

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ResponsiveBar } from '@nivo/bar'

import * as colors from '../../Colours'

const PaidBreakup = (props) => {
    const classes = astyle();
    let {data} = props
    console.log("paidbreakup", data)
    let chartdata = []
    for(let i=0; i<data.length; i++) {
        let cd = {plan: data[i].plan_type, coins: data[i].coins_amount, copay: data[i].copay_amount, deductible: data[i].deductible_amount}
        chartdata.push(cd)
    }
    let chartheight = data.length*40 
    if(chartheight < 80) chartheight = 80;       // minimum height of graph

    return(
        <Paper className={classes.cardpaper} style={{height: (chartheight+40)}}> 
         <Typography variant="subtitle1" my={0} py={0}>Patient Responsibility</Typography>
         <ResponsiveBar
            data={chartdata}
            keys={['deductible', 'copay', 'coins']}
            layout="horizontal"
            indexBy="plan"
            groupMode={'stacked'}
            height={chartheight}
            margin={{ top: 5, right: 15, bottom: 40, left: 75 }}
            //innerPadding={4}
            padding={0.2}
            innerPadding={4}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            enableLabel={true}
            labelFormat={["$", ".2s"]}
            //labelFormat={{format: v => `${v}.2s`}}
            labelSkipWidth={15}
            labelSkipHeight={0}
            //labelTextColor={{ from: 'color' }}
            labelTextColor={'#000000'}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableGridY={false}
            theme={{ 
                textColor: { scheme: 'nivo' },
                tooltip: {
                    container: {
                        background: '#333',
                    },
                }
              }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                //legend: 'Plans',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            axisBottom={{
                format: v => v       // call function to format number to $ in k value
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    itemTextColor: '#ffffff',
                    anchor: 'top-left',
                    anchor: 'bottom-left',
                    direction: 'row',
                    justify: false,
                    translateX: -0,
                    //translateY: -15,
                    translateY: 45,
                    itemsSpacing: 2,
                    itemWidth: 70,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            />  
            {/* <Box className={classes.topobox}>
                <Typography variant="caption" my={0} py={0}> Allowed/Charged </Typography>
                <Typography variant="h6" my={-5} py={0}> {data.allowed_2_charged.toFixed(0)}% </Typography>
                <Typography variant="caption" my={0}> Paid/Allowed  </Typography> 
                <Typography variant="h6" my={-5} py={0}> {data.paid_2_allowed.toFixed(0)}% </Typography>
            </Box> */}

        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        // width: '80%',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        padding: 2,
        margin: 10,
        //height: 'auto'
    },
    scp: {
        backgroundColor: colors.secondarydark,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5
    },
    sctxt: {
        fontSize: 'large'
    },
    msg: {
        width: '50%'
    },
    topobox: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default PaidBreakup