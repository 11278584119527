// ThemeColours.js
// copyright @artiss.co

import React from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';


const darkcolours = createMuiTheme({
    palette: {
      type: 'dark', 
      primary: {
        light: '#67727b',
        main: '#37474f',                   // bluegrey800
        dark: '#102027',
        contrastText: '#ffff',
      },
      secondary: {
        light: '#ffc046',
        main: '#ff8f00',       // amber800
        dark: '#c56000',
        contrastText: '#0000'
      },
    },
    background: {
      default: '#819ca9'    // this is not working as advertised, hence the overide below
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: '#616161'    // '#819ca9'     // github.com/mui-org/material-ui/issue/12654
          }, 
        },
      },
    },
  })


  const lightcolours = createMuiTheme({
    palette: {
      type: 'light', 
      primary: {
        light: '#ffffff',
        main: '#eeeeee',      // grey200
        dark: '#bcbcbc',
        contrastText: '#000000',
      },
      secondary: {
        light: '#5e92f3',
        main: '#1565c0',         // blue800
        dark: '#003c8f',
        contrastText: '#000000',
      },
    },
    background: {
      default: '#455a64'    // this is not working as advertised, hence the overide below
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: '#455a64'     // github.com/mui-org/material-ui/issue/12654
          }, 
        },
      },
    },
  })

export {darkcolours, lightcolours}