// RenderingProviderstory.js
// copyright @ artiss.co


import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router'

import * as DashboardHandler from "../../dashboards/common/DashboardHandler";

//import * as colors from '../../Colours'
//import breakout from '../../images/breakout.png'
import UserContext from '../../../context/UserContext'
import {callPostAPI} from '../../services/APIHandler'
//import {fbanalytics} from '../../login/Fbdetails'
import {StoryHeader} from './Storyheader'
import StickyTitle from '../../widgets/Stickytitle';
import {ShowErrorMsg} from '../../services/ShowmsgtoUsers'
//import RankCards from '../common/Rank'
import PaidChargedAllowed from '../common/PaidChrdAllwd'
import PayorPlanCombinations from '../common/PayorPlanCombo'
import DenialDetails from '../common/DenialDetails'
import RevenueBreakout from '../common/RevBreakout'
import RevenueCountTrend from '../common/RevCntTrend'
import PatientProfile from '../common/PatientProfile'
//import SubmissionAge from '../common/SubmissionAge'
import RPCompare from './RPcompare'
import PaidChangeTrend from '../common/Paidchangetrend'
import ModBreakout from '../common/Modbreakout'
// import PaidBreakup from './Paidbreakup'
// import ProcessAs from './Processas'
import NoDataMsg from '../common/NodataMsg'
import {componentUpdateReqd} from '../common/Storyutils'

import * as utils from '../../services/Utils'
import ChartInspector from '../../devtools/ChartInspector'

class RenderingProviderStory extends React.Component {

    constructor(props) {
        super(props)
        //console.log("RP story", this.props.location.state.showdetail.guid)
        this.state = { apiInprogress: false, web_slugs: null, web_slugs2: null,
                 rp_story: null, 
                 showerror:false, errmsg: null, nodata_for_daterange: false
                }        
    }

    componentDidMount() {
        //console.log("RP CdM", this.props.location.state.showdetail.guid)
        this.updateStorySlugs()
        this.updateRPHeaders()
        this.setState({apiInprogress: true})
    }

    componentDidUpdate(prevProps) {
        console.log( "RP didUpdate")
        if( componentUpdateReqd(prevProps, this.props) ) {
            console.log("have to refresh")
            this.updateStorySlugs()
            this.updateRPHeaders()
            this.setState({apiInprogress: true})
        }
    }

    updateRPHeaders = (datechoice={"literal": 'last_3_months'}) => {
        let user = this.context;
        let objguid = this.props.location.state.showdetail.guid
        let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer)
        let hdrpostd = {"object_guid":objguid, 
                        ...customer_filter_criteria,
                        "transaction_date": datechoice }
        callPostAPI({callname: 'web_get_renderer_story', requestData: hdrpostd, 
                authtoken: user.idtoken, callback: this.rpStoryHdrCallback})
    }

    rpStoryHdrCallback = (sdata) => {
        console.log("RP Header callback", sdata)
        if(sdata.status === 'success' && sdata.status_message === "No data for this date range") {
            this.setState({nodata_for_daterange: true})
        } else if(sdata.status === 'success' && (sdata.status_message === '' || sdata.status_message === '_from_cache_')) {
            this.setState({rp_story: sdata, nodata_for_daterange: false})
        } else {
            // show alert about failure
            this.setState({apiInprogress: false, showerror:true, errmsg: sdata.errorMessage})
        }
    }

    updateStorySlugs = (datechoice={"literal": 'last_3_months'}) => {
        //console.log("datechoice", datechoice)
        let user = this.context;
        let objguid = this.props.location.state.showdetail.guid
        let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer)
        let paymentbytimeslug = null
        if(datechoice.literal === "last_3_months") {
            paymentbytimeslug = "li_payment_amount_and_count_by_week"
        } else if(datechoice.literal === "last_4_quarters") {
            paymentbytimeslug = "li_payment_amount_and_count_by_month"
        } else if(datechoice.literal === "year_to_date") {
            paymentbytimeslug = "li_payment_amount_and_count_by_month"
        } else if(datechoice.literal === "all_data") {
            paymentbytimeslug = "li_payment_amount_and_count_by_quarter"
        }
        let wspostd = {
            "table_slugs": [
                "denial_summary",
                "li_icd_counts_by_cpt",
                "li_denial_count_and_amount",
                 paymentbytimeslug,      // this name will be based on the selected time range
                 "li_payment_amount_by_payor",
                 
                 //"li_charge_amount_and_count_by_submission_age",
                 "li_payment_amount_by_plan",
                 //"li_payment_amount_by_physician",
                 "li_payment_amount_and_count_by_response_age",
                 //"li_payment_amount_by_processed_as",
                 "li_allowed_break_up_by_plan",
                 "li_payment_cpt_icd_combo_by_plan",
                 //"li_adjustments_by_plan",
                 "li_denial_count_and_amount_and_percentage",

                 //"li_payment_amount_by_clia",
                 //"li_payment_amount_by_rendering_provider",
                 "li_payment_amount_by_cpt",
                 "li_charge_amount_and_count_by_patient_profile",

                 "paid_by_cpt_mod_by_payor_plan",
                 "li_payment_cpt_mod",
                 "li_payment_amount_by_clia",
                 "paid_trend",
                 "li_paid_to_patient_by_payor_plan"
            ],
            "filter_criteria": {
                "rendering_provider_guid": objguid,
                ...customer_filter_criteria,
                "transaction_date": datechoice
            }
        }
        callPostAPI({callname: 'web_query_table', requestData: wspostd, 
                authtoken: user.idtoken, callback: this.rpStorySlugCallback})

        let wspostd2 = {
            "table_slugs": [
                "li_charged_allowed_payment_by_renderer", 
                "li_payment_amount_count_by_modifier",
                "paid_change_summary"
            ],
            "filter_criteria": {
                ...customer_filter_criteria,
                "transaction_date": datechoice
            }    
        }
        callPostAPI({callname: 'web_query_table', requestData: wspostd2, 
                authtoken: user.idtoken, callback: this.rpStorySlugCallback2})
    }


    rpStorySlugCallback = (sdata) => {
        console.log("RP Slug callback", sdata)
        if(sdata.status === 'success') {
            this.setState({web_slugs: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            this.setState({apiInprogress: false, showerror:true, errmsg: sdata.errorMessage})
        }
    }

    rpStorySlugCallback2 = (sdata) => {
        console.log("RP Slug callback2", sdata)
        if(sdata.status === 'success') {
            this.setState({web_slugs2: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            this.setState({apiInprogress: false, showerror:true, errmsg: sdata.errorMessage})
        }
    }

    userSelectedDate = (dtobj) => {
        this.updateRPHeaders(dtobj.daterange)
        this.updateStorySlugs(dtobj.daterange)
        this.setState({apiInprogress: true})
    }

    render() {
        const {classes} = this.props
        let storytitle = 'Storyboard for Rendering Provider ' + this.props.location.state.showdetail.title
        
        if(Boolean(this.state.showerror)) {
            //show naviate to a "good" page - since we cannot show this page
            return(
                <ShowErrorMsg msg={this.state.errmsg} />
                )
        }

        let inspectorData = { header: {}, slug: {}, slug2: {} }
        if (!process.env.REACT_APP_CHART_INSPECTOR || process.env.REACT_APP_CHART_INSPECTOR === 'True')  {    // this is for debug
            if(this.state.rp_story && this.state.web_slugs && this.state.web_slugs2) {
                for (const [k1, v1] of Object.entries(this.state.rp_story)) {
                    inspectorData['header'][k1] = v1
                }
                for (const [k2, v2] of Object.entries(this.state.web_slugs)) {
                    inspectorData['slug'][k2] = v2
                }
                for (const [k3, v3] of Object.entries(this.state.web_slugs2)) {
                    inspectorData['slug2'][k3] = v3
                }
            } 
        }

        if(Boolean(this.state.web_slugs)) {
            let datelit = this.state.web_slugs.args.filter_criteria.transaction_date.literal
            let sdate = this.state.web_slugs.dates.start
            let edate = this.state.web_slugs.dates.end
            //let displayrange = utils.toDateStr(sdate) + " - " + utils.toDateStr(edate)
            let displayrange = this.state.web_slugs.dates.period_display
            
            let revnltred = ''
            if(datelit === 'last_3_months') { revnltred = 'li_payment_amount_and_count_by_week' }
            else if(datelit === 'last_4_quarters') { revnltred = 'li_payment_amount_and_count_by_month' }
            else if(datelit === 'year_to_date') { revnltred = 'li_payment_amount_and_count_by_month' }
            else if(datelit === 'all_data') { revnltred = 'li_payment_amount_and_count_by_quarter' }
            return(
                <>
                 <div className={classes.stickyhdr}>
                 <StickyTitle context='story' title={storytitle} 
                        //subtitle={displayrange}
                        callbackForDateRange={this.userSelectedDate} 
                        showProgressBar={this.state.apiInprogress} 
                        serverdaterange={displayrange}
                        />
                 </div>
                 {this.state.nodata_for_daterange ? 
                    <NoDataMsg /> :
                 <Container className={classes.top} > 
                     {Boolean(this.state.rp_story) ? 
                        <StoryHeader  type='rendering provider'
                                subtype={this.props.location.state.showdetail.title}
                                data={this.state.rp_story.data.header} />
                        : null }
                        <Box className={classes.allbox}>
                            <ChartInspector _props={this.props} title={''} renderedData={inspectorData} meta={null} />
                            <Box className={classes.colbl}> 
                                {Boolean(this.state.rp_story) ? 
                                <>
                                <PaidChargedAllowed
                                    data={this.state.rp_story.data.header}
                                    comp={this.state.web_slugs2}
                                    />
                                </>
                                : null }
                            {Boolean(this.state.web_slugs) ?
                            <>    
                                <PayorPlanCombinations
                                    combodata={this.state.web_slugs.data.li_payment_amount_by_plan}
                                    type="Plan"
                                    /> 
                                <DenialDetails
                                    denialsummary={this.state.web_slugs.data.denial_summary}
                                    denialdetails={this.state.web_slugs.data.li_denial_count_and_amount}
                                    />
                                <PatientProfile
                                    patientdata={this.state.web_slugs.data.li_charge_amount_and_count_by_patient_profile}
                                    />
                                {Boolean(this.state.web_slugs2) && Boolean(this.state.rp_story) ? 
                                <RPCompare
                                    data={this.state.web_slugs2.data.li_charged_allowed_payment_by_renderer}
                                    rankdata={this.state.rp_story.data.header}
                                    />
                                : null }
                            </> 
                            : null}
                        </Box>
                        <Box className={classes.colbr} >
                            <Paper className={classes.colbrpaper} >
                            {/* <img src={breakout} alt="breakout" className={classes.breakoutimg} /> */}
                                <RevenueBreakout
                                    payordata={this.state.web_slugs.data.li_payment_amount_by_payor}
                                    plandata={this.state.web_slugs.data.li_payment_amount_by_plan}
                                    odata={this.state.web_slugs.data.li_payment_amount_by_cpt}
                                    physdata={this.state.web_slugs.data.li_payment_amount_by_physician}
                                    rendprvdrdata={this.state.web_slugs.data.li_payment_amount_by_rendering_provider}
                                    type='rendering provider'
                                    />
                                {Boolean(this.state.web_slugs2) ?
                                <ModBreakout 
                                    moddata={this.state.web_slugs2.data.li_payment_amount_count_by_modifier}
                                    />
                                : null }
                                <RevenueCountTrend
                                    trenddata={this.state.web_slugs.data[revnltred]}
                                    /> 

                                <PaidChangeTrend
                                    trenddata={this.state.web_slugs.data.paid_trend}
                                    dates={this.state.web_slugs.dates}
                                    meta={this.state.web_slugs._meta.paid_trend}
                                    />
                            </Paper>
                        </Box>
                    </Box>
                </Container>
                }
                </>
            )

        } else {
            return(
                <div className={classes.skeleton} >
                    <LinearProgress />
                    <Skeleton variant="text"  height={20}/>
                    <Skeleton variant="rect"  height={118}/>
                </div>

            )

        }
    }

}

RenderingProviderStory.contextType = UserContext; 

const astyle = (theme) => ({
    top: {
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: colors.bluegrey600,
        //backgroundColor: theme.palette.primary.main,
        backgroundColor: theme.backgroundColor,
        width: '95%',
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 20,
        // height: 800
        height: '100%'
    },
    allbox: {
        display: 'flex',
        flexDirection: 'row'
        //height: 100
    },
    colbl: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    colbr: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    colbrpaper: {
        width: '95%',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 10,
        paddingBottom: 200,
        height: '100%',
        margin: 5
    },
    skeleton: {
        margin: '10%',
        width: '75%'
    },
    drs: {
        position: 'absolute',
        right: 50,
        top: 100
    },
    breakoutimg: {
        width: 30,
        height: 30,
    },
    stickyhdr: {
        position: 'sticky',
        top: 65,
        zIndex: 10
    }
    
})

export default withStyles(astyle, {withTheme: true})(withRouter(RenderingProviderStory))

