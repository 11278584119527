// Stickytitle.js
// copyrights @artiss.co

/**
 * @component StickyTitle
 * @property {string} context - parent context. Currently support 'story' or 'dashboard'
 * @property {string} title - string that is displayed as title
 * @property {string} subtitle - (optional) string that is displayed as subtitle, in line below title
 * @property {function} callbackForDateRange - pass this to DateRangeSelector, if null - dont show DateRangeSelector. 
 * @property {boolean} showProgressBar - boolean to show progress bar with the sticky title
 * @property {string} serverdaterange , pass to DateRangeSelector,this is the date range the server returns in response to the selected date range
 * Note: if we add another widget later, that widget will have its own callback function
 * @return null
 * @example
 * <StickyTitle context='story' title='CPT story for 88305' callbackForDateRange=this.userDateSelector />
 * <StickyTitle context='story' title='Claim story for NE098654' 
 *      callbackForDateRange=null  
 *      showProgressBar=true
 *      serverdaterange='May 01, 2019 - May 29, 2020'
 *      />
 * 
 */

import React from 'react'
import { withStyles , withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { LinearProgress  } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as colors from '../Colours';

import DateRangeSelector from './DaterangeSelector'

class StickyTitle extends React.Component{
  state = {
    fsize: 'h4',
    subfsize: 'subtitle1',
    dpad: (this.props.context==="dashboard" || this.props.context==="detailed_dashboard") ? 3 : 10
  }

  listenScrollEvent = e => {
      //console.log('--->', window.scrollY)
    if (window.scrollY > 100) {
      this.setState({fsize: 'h6', subfsize: 'subtitle2', dpad:3})
    } else {
      this.setState({fsize:'h4', subfsize: 'subtitle1', dpad: (this.props.context==="dashboard" || this.props.context==="detailed_dashboard") ? 3 : 10})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    const {classes} = this.props
    let cb = this.props.callbackForDateRange

    const { theme } = this.props
    //console.log('Current theme: ', theme);    

    let sdr = null
    if(!Boolean(this.props.showProgressBar)) {
      sdr = this.props.serverdaterange
    }

    return(
        <div id="header">
          <div>{(this.props.showProgressBar===true) && <LinearProgress />}</div>
          <div className={(this.props.context==="dashboard" || this.props.context==="detailed_dashboard") ? classes.stkhdr_db : classes.stkhdr} style={{padding: this.state.dpad}}>
            {this.props.subtitle ? 
              <>
                <Typography variant={this.state.fsize} > {this.props.title} 
                <Typography variant={this.state.subfsize} > {this.props.subtitle} </Typography> </Typography>
              </>
              : <Typography variant={this.state.fsize} > {this.props.title} </Typography>
            }
            {cb ? 
              <DateRangeSelector context={this.props.context}
                          callback={this.props.callbackForDateRange} 
                          serverdaterange={sdr}
                          /> 
              : null }
            {this.props.handleClose && 
              <Button style={{backgroundColor: colors.bluegrey600}} onClick={() => {this.props.handleClose()}}>&nbsp;Close&nbsp;</Button>
            }
          </div>
       </div>
     )
   }
}

const astyle = (theme) => ({
    stkhdr: {
        backgroundColor: theme.palette.primary.dark,
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 4
    },
    stkhdr_db: {
      //backgroundColor: (theme.palette.type==="dark") ? '#303030' : '"#FAFAFC',//TODO: change this per theme
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: 0
  }, 
  trs: {
    transition: 'all 2s ease-in-out',
    transitionDelay: '2s'
  }
  
})

export default withStyles(astyle, {withTheme: true})(StickyTitle)