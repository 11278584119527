import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import StopIcon from '@material-ui/icons/Stop';

import "../../App.css";
import Card from "../widgets/Card";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from "../widgets/charts/nivo/PieChart";
import StickyTitle from '../widgets/Stickytitle';
import * as colors from '../Colours';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";
import ObservationList from "./observationdashboard/ObservationList";
import ClaimList from "./observationdashboard/ClaimList";

class ObservationDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();
    let defaultDateRangeFilter = {
      filterName: "yesterday",
      label: "Yesterday",
      durationBy: "day"
    };
    this.state = {
      ...newState,
      dateRangeFilter: defaultDateRangeFilter,
      prevDateRangeFilter: defaultDateRangeFilter,
      claims: null,
    };
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    //console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    //console.log("getData this.state: ", this.state);
    let durationBy = this.state.dateRangeFilter.durationBy;
    console.log("getData durationBy: ", durationBy);

    //https://github.com/ArtissLST/customer-ui/issues/10#issuecomment-720486696
    let requestDataForObservationResponses = {
      "table_slugs": [
        "response_observation_summary",
        //"payment_observations",
        ////"response_observation_pattern_details",
        //"observations_by_" + durationBy,//observations_by_day",//"observations_by_week",
        ////"claims_for_observations",        
      ],
      "filter_criteria": {
        "observation_metatype": "response",
        //"observation_type": "anomaly",
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        //"options":{"set":"top","limit":10}
      }
    };

    let requestDataForObservationResponsesAnomalies = {
      "table_slugs": [
        "payment_observations",
        "observations_by_" + durationBy,//observations_by_day",//"observations_by_week",
        "observations_by_provider_by_" + durationBy,
        ////"claims_for_observations",        
      ],
      "filter_criteria": {
        "observation_metatype": "response",
        "observation_type": "anomaly",
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        //"options":{"set":"top","limit":10}
      }
    };

    let requestDataForObservationResponsesThresholds = {
      "table_slugs": [
        "payment_observations",
        "observations_by_" + durationBy,//observations_by_day",//"observations_by_week",
        "observations_by_provider_by_" + durationBy,
        ////"claims_for_observations",        
      ],
      "filter_criteria": {
        "observation_metatype": "response",
        "observation_type": "threshold",
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        //"options":{"set":"top","limit":10}
      }
    };

    let requestDataForObservationSubmissions = {
      "table_slugs": [
        "charge_observation_summary",
        //"charge_observations",
        ////"charge_observation_pattern_details",
        //"observations_by_" + durationBy,//observations_by_day",//"observations_by_week",
        ////"claims_for_observations",        
      ],
      "filter_criteria": {
        "observation_metatype": "submission",
        //"observation_type": "anomaly",
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        //"options":{"set":"top","limit":10}
      }
    };

    let requestDataForObservationSubmissionsAnomalies = {
      "table_slugs": [
        "charge_observations",
        "observations_by_" + durationBy,//observations_by_day",//"observations_by_week",
        "observations_by_provider_by_" + durationBy,
        //"claims_for_observations",        
      ],
      "filter_criteria": {
        "observation_metatype": "submission",
        "observation_type": "anomaly",
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        //"options":{"set":"top","limit":10}
      }
    };

    let requestDataForObservationSubmissionsThresholds = {
      "table_slugs": [
        "charge_observations",
        "observations_by_" + durationBy,//observations_by_day",//"observations_by_week",
        "observations_by_provider_by_" + durationBy,
        //"claims_for_observations",        
      ],
      "filter_criteria": {
        "observation_metatype": "submission",
        "observation_type": "threshold",
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        //"options":{"set":"top","limit":10}
      }
    };

    this.setState({isLoadingCount: 4});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForObservationResponses, authtoken: authtoken, callback: this.updateResponseForObservationResponses});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForObservationResponsesAnomalies, authtoken: authtoken, callback: this.updateResponseForObservationResponsesAnomalies});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForObservationResponsesThresholds, authtoken: authtoken, callback: this.updateResponseForObservationResponsesThresholds});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForObservationSubmissions, authtoken: authtoken, callback: this.updateResponseForObservationSubmissions});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForObservationSubmissionsAnomalies, authtoken: authtoken, callback: this.updateResponseForObservationSubmissionsAnomalies});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForObservationSubmissionsThresholds, authtoken: authtoken, callback: this.updateResponseForObservationSubmissionsThresholds});
  }  

  getClaimsData = (claimsRequest) => {
    console.log("getClaimsData claimsRequest: ", claimsRequest);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);
    let durationBy = this.state.dateRangeFilter.durationBy;
    console.log("getData durationBy: ", durationBy);

    let requestData = {
      "table_slugs": [
        "claims_for_observations",        
      ],
      "filter_criteria": {
        "observation_metatype": claimsRequest.observation_metatype,//"submission",
        "observation_type": claimsRequest.observation_type,//"threshold",
        "observation_code": claimsRequest.observation_code,//"835.THD.RESPONSE_TO_SUB_AGE",
        "pattern_string": claimsRequest.pattern_string,//'{"Age": 27}',
        //"provider": claimsRequest.provider,
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        //"transaction_date": {"start":"2020/01/01","end":"2020/08/01"}
        //"options":{"set":"top","limit":10}
      }
    };

    this.setState({isLoadingCount: 1});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestData, authtoken: authtoken, callback: this.updateResponseForClaims});
  }

  updateResponseForClaims = (response) => {
    console.log("updateResponseForClaims: ", response);
    this.setState({claims: response});
    this.updateLoadingDone();
  }

  resetClaims = () => {
    console.log("resetClaims called: ");
    this.setState({claims: null});
  }

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
    }));
  };

  updateResponseForObservationResponses = (response) => {
    console.log("updateResponseForObservationResponses: ", response);
    this.setState({observationResponses: response});
    this.updateLoadingDone();
  }

  updateResponseForObservationResponsesAnomalies = (response) => {
    console.log("updateResponseForObservationResponsesAnomalies: ", response);
    this.setState({observationResponsesAnomalies: response});
    this.updateLoadingDone();
  }

  updateResponseForObservationResponsesThresholds = (response) => {
    console.log("updateResponseForObservationResponsesThresholds: ", response);
    this.setState({observationResponsesThresholds: response});
    this.updateLoadingDone();
  }

  updateResponseForObservationSubmissions = (response) => {
    console.log("updateResponseForObservationSubmissions: ", response);
    this.setState({observationSubmissions: response});
    this.updateLoadingDone();
  }

  updateResponseForObservationSubmissionsAnomalies = (response) => {
    console.log("updateResponseForObservationSubmissionsAnomalies: ", response);
    this.setState({observationSubmissionsAnomalies: response});
    this.updateLoadingDone();
  }

  updateResponseForObservationSubmissionsThresholds = (response) => {
    console.log("updateResponseForObservationSubmissionsThresholds: ", response);
    this.setState({observationSubmissionsThresholds: response});
    this.updateLoadingDone();
  }

  onChangeDateRangeFilter = (selectedFilter) => {
    //console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);

    //use approp slugs based on date range criteria
    let durationBy = "";
    //switch (this.state.dateRangeFilter.filterName) {
    switch (selectedFilter.daterange.literal) {
      case "yesterday": durationBy = "day"; break;
      case "last_7_days": durationBy = "day"; break;
      case "last_4_weeks": durationBy = "week"; break;
      case "last_8_weeks": durationBy = "week"; break;
      default: durationBy = "week"; break;//set default to week as "safe" default
    }  

    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext,
        durationBy: durationBy
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };

  render() {
    try{
    const {classes} = this.props

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let num1 = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;
    let dateRangeFilter = this.state.dateRangeFilter;

    let observationSubmissions = this.state.observationSubmissions;
    let observationSubmissionsAnomalies = this.state.observationSubmissionsAnomalies
    let observationSubmissionsThresholds = this.state.observationSubmissionsThresholds;
    let observationResponses = this.state.observationResponses;
    let observationResponsesAnomalies = this.state.observationResponsesAnomalies
    let observationResponsesThresholds = this.state.observationResponsesThresholds;

    if (!(
      observationSubmissions && observationSubmissionsAnomalies && observationSubmissionsThresholds && 
      observationResponses && observationResponsesAnomalies && observationResponsesThresholds
    ))
      return (<React.Fragment></React.Fragment>);  


    let keys_trend = ["interval", "interval_order", "observation_code", "observation_text", "observation_type"];

    //works - but we changed slug to return aggregated data:
    let trend_submission_anomaly = observationSubmissionsAnomalies.data["observations_by_" + dateRangeFilter.durationBy];
    //trend_submission_anomaly = Utils.groupJSONObjectArrayByMultipleKeysAndSum(trend_submission_anomaly, keys_trend, "occurrence", "occurrence")
    let trend_submission_threshold = observationSubmissionsThresholds.data["observations_by_" + dateRangeFilter.durationBy];
    if (trend_submission_threshold) trend_submission_threshold = Utils.groupJSONObjectArrayByMultipleKeysAndSum(trend_submission_threshold, keys_trend, "occurrence", "occurrence")
    let trend_response_anomaly = observationResponsesAnomalies.data["observations_by_" + dateRangeFilter.durationBy];
    //trend_response_anomaly = Utils.groupJSONObjectArrayByMultipleKeysAndSum(trend_response_anomaly, keys_trend, "occurrence", "occurrence")
    let trend_response_threshold = observationResponsesThresholds.data["observations_by_" + dateRangeFilter.durationBy];
    if (trend_response_threshold) trend_response_threshold = Utils.groupJSONObjectArrayByMultipleKeysAndSum(trend_response_threshold, keys_trend, "occurrence", "occurrence")

    /*
    if (dateRangeFilter.durationBy == "day") {
      //interval_order missing!
      trend_submission_anomaly = Utils.addGeneratedAttribute(trend_submission_anomaly, ["interval"], "interval_order");
      trend_submission_threshold = Utils.addGeneratedAttribute(trend_submission_threshold, ["interval"], "interval_order");
      trend_response_anomaly = Utils.addGeneratedAttribute(trend_response_anomaly, ["interval"], "interval_order");
      trend_response_threshold = Utils.addGeneratedAttribute(trend_response_threshold, ["interval"], "interval_order");
    }
    */

    let list_submission_anomaly = observationSubmissionsAnomalies.data["observations_by_provider_by_" + dateRangeFilter.durationBy];
    let list_submission_threshold = observationSubmissionsThresholds.data["observations_by_provider_by_" + dateRangeFilter.durationBy];
    let list_response_anomaly = observationResponsesAnomalies.data["observations_by_provider_by_" + dateRangeFilter.durationBy];
    let list_response_threshold = observationResponsesThresholds.data["observations_by_provider_by_" + dateRangeFilter.durationBy];
    //remove dups for pattern etc:
    let keys_list = ["interval", "provider", "observation_code", "observation_text", "observation_type"];
    //list_submission_anomaly = Utils.groupJSONObjectArrayByMultipleKeysAndSum(list_submission_anomaly, keys_list, "occurrence", "occurrence")
    if (list_submission_threshold) list_submission_threshold = Utils.groupJSONObjectArrayByMultipleKeysAndSum(list_submission_threshold, keys_list, "occurrence", "occurrence")
    //list_response_anomaly = Utils.groupJSONObjectArrayByMultipleKeysAndSum(list_response_anomaly, keys_list, "occurrence", "occurrence")
    if (list_response_threshold) list_response_threshold = Utils.groupJSONObjectArrayByMultipleKeysAndSum(list_response_threshold, keys_list, "occurrence", "occurrence")
    //sort! https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
    if (list_submission_anomaly && list_submission_anomaly.data) 
      list_submission_anomaly.data.sort((a, b) => (a.interval.localeCompare(b.interval) || 
        a.provider.localeCompare(b.provider) || 
        a.observation_text.localeCompare(b.observation_text)));
    if (list_submission_threshold && list_submission_threshold.data) 
      list_submission_threshold.data.sort((a, b) => (a.interval.localeCompare(b.interval) || 
        a.provider.localeCompare(b.provider) || 
        a.observation_text.localeCompare(b.observation_text)));
    if (list_response_anomaly && list_response_anomaly.data) 
      list_response_anomaly.data.sort((a, b) => (a.interval.localeCompare(b.interval) || 
        a.provider.localeCompare(b.provider) || 
        a.observation_text.localeCompare(b.observation_text)));
    if (list_response_threshold && list_response_threshold.data) 
      list_response_threshold.data.sort((a, b) => (a.interval.localeCompare(b.interval) || 
        a.provider.localeCompare(b.provider) || 
        a.observation_text.localeCompare(b.observation_text)));

    console.log("observationSubmissions: ", observationSubmissions);

    if (!observationSubmissions || (observationSubmissions && observationSubmissions.status!=="success")) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='dashboard' 
          title={"Observation Dashboard"} 
          subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}              
          callbackForDateRange={this.onChangeDateRangeFilter} 
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(observationSubmissions && observationSubmissions.status!=="success") && <div>Error loading observation data</div>}
      </Container>      
    );
    else {
      console.log("observationSubmissions: ", observationSubmissions);
      console.log("observationSubmissionsThresholds: ", observationSubmissionsThresholds);
      console.log("observationResponses: ", observationResponses);
      console.log("observationResponsesThresholds: ", observationResponsesThresholds);
      console.log("this.state: ", this.state);
      console.log("this.props: ", this.props);

      let dateRangeFilter;//the one that we GOT from the latest response - or the current one while we're GETTING the next response
      if (observationSubmissions.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
        dateRangeFilter = this.state.dateRangeFilter;
      else if (observationSubmissions.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
        dateRangeFilter = this.state.prevDateRangeFilter;
      //console.log("dateRangeFilter: ", dateRangeFilter);

      let periodDisplay = observationSubmissions.dates.period_display;

      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 
              serverdaterange={periodDisplay}
              title={"Observation Dashboard"} 
              subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}              
              //`Based on processing of ${paid_change_summary[0].curr_paid_count} Payments and ${paid_change_summary[0].curr_submission_count} Submissions`
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
              <Tab label="Submissions" {...TabHandler.a11yProps(0)} />
              <Tab label="Responses" {...TabHandler.a11yProps(1)} />
            </Tabs>
          </AppBar>

          <Popover open={this.state.claims} onClose={() => this.resetClaims()} 
              //anchorEl={this.state.anchorEl} 
              anchorOrigin={{vertical: 'top',horizontal: 'right'}} 
              transformOrigin={{vertical: 'top',horizontal: 'right'}}>
              <ClaimList data={this.state.claims} resetClaims={this.resetClaims} title="Claims with Observations">                
              </ClaimList>
          </Popover>

          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>
              <Box className={classes.chartRow}>
                {/*<PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(observationSubmissions.data.charge_observation_summary, "category", "occurrence")}  meta={null} 
                    slugData={observationSubmissions.data.charge_observation_summary} 
                    title="Observation categories" subtitle="#" indexBy="category" keyName="occurrence" />*/}
                <PieChart type="full_pie" width={chartWidth * 1.75} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.filterJSONObjectArray(observationSubmissionsAnomalies.data.charge_observations, "category", "Anomaly")}
                    meta={observationSubmissionsAnomalies._meta} 
                    slugData={observationSubmissionsAnomalies.data.charge_observations} 
                    title="Anomaly Categories" subtitle="#" indexBy="title" keyName="occurrence" />
                {trend_submission_anomaly && <LineChart width={chartWidth * 2} height={chartHeight} scheme="paired" 
                  dataInput={trend_submission_anomaly} 
                  meta={trend_submission_anomaly._meta}  
                  title="Anomaly Trends" subtitle="#" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  groupBy="observation_text" keyYAxis="occurrence" labelForY="Occurences" tickValues={1} />
                }
              </Box>
              <Box className={classes.chartRow}>
                <PieChart type="full_pie" width={chartWidth * 1.75} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.filterJSONObjectArray(observationSubmissionsThresholds.data.charge_observations, "category", "Threshold")}
                    meta={observationSubmissionsAnomalies._meta} 
                    slugData={observationSubmissionsAnomalies.data.charge_observations} 
                    title="Threshold Categories" subtitle="#" indexBy="title" keyName="occurrence" />
                {trend_submission_threshold && <LineChart width={chartWidth * 2} height={chartHeight} scheme="paired" 
                  dataInput={trend_submission_threshold} 
                  meta={trend_submission_threshold._meta}  
                  title="Threshold Trends" subtitle="#" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  groupBy="observation_text" keyYAxis="occurrence" labelForY="Occurences" tickValues={1} />
                }
                {/*<BarChartHorizontal width={chartWidth} height={chartHeight} scheme="paired" 
                  dataInput={Utils.filterJSONObjectArray(observationSubmissionsAnomalies.data.charge_observations, "category", "Anomaly")} reverseData={false} 
                  slugData={observationSubmissions.data.charge_observations} 
                  meta={observationSubmissions._meta.charge_observations} 
                  title="Top Anomalies" subtitle="#" indexBy="title"  keys={["occurrence"]} />
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="dark2" 
                  dataInput={Utils.filterJSONObjectArray(observationSubmissionsThresholds.data.charge_observations, "category", "Threshold")} reverseData={false} 
                  slugData={observationSubmissions.data.charge_observations} 
                  meta={observationSubmissions._meta.charge_observations} 
                  title="Top Thresholds" subtitle="#" indexBy="title"  keys={["occurrence"]} />*/}
              </Box>
              <Box className={classes.chartRow}>
                <ObservationList data={list_submission_anomaly} title="Anomalies" getClaimsData={this.getClaimsData} observation_metatype="submission" />
              </Box>
              <Box className={classes.chartRow}>
                <ObservationList data={list_submission_threshold} title="Thresholds" getClaimsData={this.getClaimsData} observation_metatype="submission" />
              </Box>
            </Paper>
          </TabHandler.TabPanel>

          <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>
              <Box className={classes.chartRow}>
                <PieChart type="full_pie" width={chartWidth * 1.75} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.filterJSONObjectArray(observationResponsesAnomalies.data.payment_observations, "category", "Anomaly")}
                    meta={observationResponsesAnomalies._meta} 
                    slugData={observationResponsesAnomalies.data.payment_observations} 
                    title="Anomaly Categories" subtitle="#" indexBy="title" keyName="occurrence" />
                {trend_response_anomaly && <LineChart width={chartWidth * 2} height={chartHeight} scheme="paired" 
                  dataInput={trend_response_anomaly} 
                  meta={trend_response_anomaly._meta}  
                  title="Anomaly Trends" subtitle="#" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  groupBy="observation_text" keyYAxis="occurrence" labelForY="Occurences" tickValues={1} />
                }
              </Box>
              <Box className={classes.chartRow}>
                <PieChart type="full_pie" width={chartWidth * 1.75} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.filterJSONObjectArray(observationResponsesThresholds.data.payment_observations, "category", "Threshold")}
                    meta={observationResponsesThresholds._meta} 
                    slugData={observationResponsesThresholds.data.payment_observations} 
                    title="Threshold Categories" subtitle="#" indexBy="title" keyName="occurrence" />
                {trend_response_threshold && <LineChart width={chartWidth * 2} height={chartHeight} scheme="paired" 
                  dataInput={trend_response_threshold} 
                  meta={trend_response_threshold._meta}  
                  title="Threshold Trends" subtitle="#" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  groupBy="observation_text" keyYAxis="occurrence" labelForY="Occurences" tickValues={1} />
                }
              </Box>
              <Box className={classes.chartRow}>
                <ObservationList data={list_response_anomaly} title="Anomalies" getClaimsData={this.getClaimsData} observation_metatype="response" />
              </Box>
              <Box className={classes.chartRow}>
                <ObservationList data={list_response_threshold} title="Thresholds" getClaimsData={this.getClaimsData} observation_metatype="response" />
              </Box>
            </Paper>
          </TabHandler.TabPanel>

        </Container>
      );
    }
  
    }
    catch (err) {
      console.log(err);
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );  
    }  

  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(ObservationDashboard)
