// CPTrevbreakout.js
// copyright @ artiss.co 

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { ResponsivePie } from '@nivo/pie'

import * as colors from '../../Colours'


const RevenueBreakout = (props) => {
    const classes = astyle();
    let {payordata, plandata, odata, physdata, rendprvdrdata, type} = props
    let payorchart = []; let planchart = []; let ochart = []; let physchart = []; let rpchart = [];
    let payortitle = ''; let plantitle = ''; let ocharttitle = ''; let phystitle = ''; let rptitle = '';
    if(Boolean(payordata)) {
        for(let i=0; (i<payordata.length && i<10); i++) {
            payorchart.push({id: payordata[i].payor, value: payordata[i].payment_amount, label: payordata[i].payor})
        }
        if(payordata.length > 10) {
            payortitle = "Top 10 payors by revenue"
        } else {
            payortitle = "Top payors by revenue"
        }
    }
    
    if(Boolean(plandata)) {
        for(let i=0; (i<plandata.length && i<10); i++) {
            planchart.push({id: plandata[i].plan_type, value: plandata[i].payment_amount, label: plandata[i].plan_type})
        }
        if(plandata.length > 10) {
            plantitle = "Top 10 Plans by revenue"
        } else {
            plantitle = "Top Plans by revenue"
        }
    }

    let typ = ''
    if(Boolean(odata) && (type === 'physician' || type === 'payor' || type === "rendering provider")) {
        typ = 'cpt_code'
        for(let i=0; (i<odata.length && i<10); i++) {
            ochart.push({id: odata[i][typ], 
                        value: odata[i].payment_amount, label: odata[i][typ]})
        }
        if(ocharttitle.length > 10) {
            ocharttitle = "Top 10 " + 'cpt'.toUpperCase() + " by revenue"
        } else {
            ocharttitle = "Top " + 'cpt'.toUpperCase() + " by revenue"
        }

    }

    if(Boolean(physdata)) {
        for(let i=0; (i<physdata.length && i<10); i++) {
            physchart.push({id: physdata[i].physician, value: physdata[i].payment_amount, label: physdata[i].physician})
        } //phystitle
        if(physdata.length > 10) {
            phystitle = "Top 10 Physicians by revenue"
        } else {
            phystitle = "Top Physicians by revenue"
        }
    }

    if(Boolean(rendprvdrdata)) {
        for(let i=0; (i<rendprvdrdata.length && i<10); i++) {
            rpchart.push({id: rendprvdrdata[i].coalesce, value: rendprvdrdata[i].payment_amount, label: rendprvdrdata[i].coalesce})
        }
        if(rendprvdrdata.length > 10) {
            rptitle = "Top 10 Rendering Providers by revenue"
        } else {
            rptitle = "Top Rendering Providers by revenue"
        }
    }

    let boxheight = 350       // default value
    if(type === 'physician' || type === "rendering provider" || type === "cpt") boxheight = 450
    else if(type === 'payor') boxheight = 550

    return(
        <Box className={classes.breaktop} style={{height: boxheight}}>
            { payorchart.length  ? 
            <>
            <Typography variant="caption">{payortitle}</Typography>
            <ResponsivePie
                data={payorchart}
                height={125}
                width={550}
                //align={'left'}
                //margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                margin={{ top: 3, bottom: 10, right: 5, left: -300}}
                startAngle={-104}
                endAngle={105}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'category10' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableSlicesLabels={false}
                enableRadialLabels={false}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={5}
                radialLabelsLinkHorizontalLength={5}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                labelFormat={["$", ".2s"]}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateY: 0,
                        translateX: -300,
                        itemWidth: -10,
                        itemHeight: 6,
                        itemsSpacing: 5,
                        itemTextColor: '#fff',
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    }
                
                  }}
                
                />
            <Divider light={true} variant='middle' />
            </>
            : null 
            }

            <Typography variant="caption">{plantitle}</Typography>
            <ResponsivePie
                data={planchart}
                height={125}
                width={550}
                margin={{ top: 3, bottom: 10, right: 5, left: -300}}
                startAngle={-104}
                endAngle={105}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'category10' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableSlicesLabels={false}
                enableRadialLabels={false}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={2}
                radialLabelsLinkHorizontalLength={5}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                labelFormat={["$", ".2s"]}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateY: 0,
                        translateX: -300,
                        itemWidth: -10,
                        itemHeight: 6,
                        itemsSpacing: 5,
                        itemTextColor: '#fff',
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    }
                
                  }}
                />
            
            { ochart.length  ? 
            <>
            <Divider light={true} variant='middle' />
                <Typography variant="caption">{ocharttitle}</Typography>
            <ResponsivePie
                data={ochart}
                height={125}
                width={550}
                margin={{ top: 3, bottom: 10, right: 5, left: -300}}
                startAngle={-104}
                endAngle={105}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'category10' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableSlicesLabels={false}
                enableRadialLabels={false}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={2}
                radialLabelsLinkHorizontalLength={5}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                labelFormat={["$", ".2s"]}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateY: 0,
                        translateX: -300,
                        itemWidth: -10,
                        itemHeight: 6,
                        itemsSpacing: 5,
                        itemTextColor: '#fff',
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    }
                
                  }}
            />  
            </>
            : null }


            { physchart.length  ? 
            <>
            <Typography variant="caption">{phystitle}</Typography>
            <ResponsivePie
                data={physchart}
                height={125}
                width={550}
                //align={'left'}
                //margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                margin={{ top: 3, bottom: 10, right: 5, left: -300}}
                startAngle={-104}
                endAngle={105}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'category10' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableSlicesLabels={false}
                enableRadialLabels={false}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={5}
                radialLabelsLinkHorizontalLength={5}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                labelFormat={["$", ".2s"]}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateY: 0,
                        translateX: -300,
                        itemWidth: -10,
                        itemHeight: 6,
                        itemsSpacing: 5,
                        itemTextColor: '#fff',
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    }
                
                  }}
                
                />
            </>
            : null 
            }

            { rpchart.length  ? 
            <>
            <Typography variant="caption">{rptitle}</Typography>
            <ResponsivePie
                data={rpchart}
                height={125}
                width={550}
                //align={'left'}
                //margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                margin={{ top: 3, bottom: 10, right: 5, left: -300}}
                startAngle={-104}
                endAngle={105}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'category10' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableSlicesLabels={false}
                enableRadialLabels={false}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={5}
                radialLabelsLinkHorizontalLength={5}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                labelFormat={["$", ".2s"]}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateY: 0,
                        translateX: -300,
                        itemWidth: -10,
                        itemHeight: 6,
                        itemsSpacing: 5,
                        itemTextColor: '#fff',
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    }
                
                  }}
                
                />
            </>
            : null 
            }
            
            
            
        </Box>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    breaktop: {
        //width: '90%',
        //height: 450,
        display: 'flex',
        //flex: 1,
        flexDirection: 'column',
        //justifyContent: 'flex-start',
        alignItems: 'flex-start',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        //padding: 10,
        //height: 75,
        margin: 10,
        position: 'relative',
    },
    pie1: {
        position: 'fixed',
        left: 10
    },
    scp: {
        backgroundColor: colors.secondarydark,
    },
    sctxt: {
        fontSize: 'large'
    }
}));

export default RevenueBreakout