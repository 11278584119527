// SearchResult.js
// copyright @artiss.co

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
//import Link from '@material-ui/core/Link';
//import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
//import Divider from '@material-ui/core/Divider';
//import MenuItem from '@material-ui/core/MenuItem';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
//import FormLabel from '@material-ui/core/FormLabel';
//import FormGroup from '@material-ui/core/FormGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress'
//import IconButton from '@material-ui/core/IconButton';
//import { Menu, Search }  from '@material-ui/icons';
import { Receipt, MonetizationOn, LocalHospital, Dashboard, AccountBalance, 
        PersonAdd, Business, Money, 
        NotInterested,  Announcement, Error, NotificationImportant, AttachMoney, 
        Group, GroupWork, Help, LiveHelp, Assistant, Star, Healing, Search, Class}  
        
        from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {
    //BrowserRouter,
    //Switch,
    //Route, 
    Redirect
  } from "react-router-dom";


//import * as api from './Restapi'
//import ClaimDetails from './Claimdetails'
//import {SearchContext, ClaimDetailContext} from '../context/restContext'
import UserContext from '../../context/UserContext';
import SearchtoComponent from './SearchToComponent';
import SearchContainer from './SearchContainer';
import * as colors from '../Colours'

const SearchResults = (props) => {
    const classes = astyle();
    const [ sitem, setItemdetails ] = useState({data: null, 
                                                search2component: null,
                                                selectedsearch: null,
                                                searchongoing: false})
    console.log("in SearchResults", props)
    let rd = props.searchres
    //const user = useContext(UserContext)

    let resp = null;
    if(Boolean(rd)) {
        resp = rd
    }
    
    const searchitemClicked = (item) => {
        console.log("seach item clicked", item)
        let navtocomponent = SearchtoComponent(item)
        console.log("search matched comp", navtocomponent)
        setItemdetails({search2component: navtocomponent, 
                            selectedsearch: item,
                            searchongoing: false})
    }

    const updateClaimDetailsResponse = (data) => {
        console.log("updateClaimDetail reponse called")
        setItemdetails({data: data, searchongoing: false})
    }

    const PaymentCard = (data) => {
        console.log("PaymentCard called", data)
        let t = data.teaser
        let paidto = t['Paid To']
        return(
            <Paper style={{height: 'auto', borderColor: 'white', borderWidth: 'thick', backgroundColor: 'transparent',
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                <Typography variant='subtitle'> Payment {data.title} details </Typography>
                <Divider variant='middle' light='true' />

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                            padding: 3}}>
                    <div>
                    <Typography variant='body1'> Paid to: {data.teaser['Paid to']} </Typography>
                    <Typography variant='body1'> Payee Acc: {data.teaser['Payee Acc #']} </Typography>
                    <Typography variant='body1'> Payor: {data.teaser['Payor']} </Typography>
                    </div>
                    <div>
                    <Typography variant='h4'> ${data.teaser['Amount']} </Typography> 
                    <Typography variant='body1'> {data.teaser['Date']}, {data.teaser['Method']}  </Typography>
                    </div>
                </div>
            </Paper>
        )
    }

    if(Boolean(sitem.search2component)) {
        SearchContainer.hide();
        console.log("state item type set to cpt", sitem)
        //SearchContainer.hide();//closes search popover; calling this directly/via callback in searchitemClicked didn't work
        return(       
            <div>
                <Redirect to={{pathname: sitem.search2component,
                                state: {showdetail: sitem.selectedsearch}}} />
            </div>
        ) 
    } else if(Boolean(resp) && resp.status === 'success') {
        var res = resp.data.results
        var nosresult = resp.data.num_of_results
        console.log("s res-->", res, nosresult)
        if(nosresult === 0 || !res) {
            return(
                <Paper className={classes.paper} >
                    <div className={classes.noresult}>&nbsp;No entities found matching your search criteria. Please check and try again.</div>
                </Paper>
            )
        }
        else {
            return(
            <div className={classes.resdiv} >
            {Boolean(sitem.searchongoing) ?  <LinearProgress /> : null }
            <Paper className={classes.paper}>
                <List component="nav" className={classes.root} subheader={<li />}>
                {res && res.map((r,i) => {

                    let teaserTextGen = function(teaser) {
                        let teaserKeys = Object.keys(teaser);
                        //console.log("teaserTextGen teaserKeys: ", teaserKeys);
                        let resultString = "";
                        for (let i=0; i < teaserKeys.length; i++) {
                            resultString = resultString + teaserKeys[i] + ": " + teaser[teaserKeys[i]];
                            if (i < teaserKeys.length - 1) resultString = resultString + " | "
                        }
                        //console.log("teaserTextGen resultString: ", resultString);
                        return resultString;
                    }

                    if(r.entity === 'payment' && r.entity_view === 'card') {
                        return PaymentCard(r)
                    } 
                    else {
                        let tt = r.teaser; 
                        let teasetext = teaserTextGen(tt);
                        /*
                        let teasetext = ""
                        if (r.entity_view === 'dashboard') {
                            teasetext = 'Description: ' + tt["Description"] + ' | Keywords: ' + tt["Keywords"];
                        }
                        else if (r.entity === 'cpt') {
                            //teasetext = '';
                            //teasetext = 'Provider: ' + tt["Customer"] + ' | Since: ' + tt["Since"];
                            teasetext = 'Since: ' + tt["Since"];
                        }
                        else if (r.entity_view === 'whatif') { // && r.entity === 'Renderer'
                            teasetext = 'Description: ' + tt["Description"];
                        }
                        else if (r.entity ===  'claim') {
                            teasetext = tt["First Name"]  + " " + tt["Last Name"] + ' | Policy #: ' + (tt["Policy#"] ? tt["Policy#"] : "") + ' | Service Date: ' + tt["Service"]
                        }
                        else if (r.entity ===  'customer') {
                            teasetext = tt["Type"]  + " (" + tt["Provider Type"] + ')';
                        }
                        else if (r.entity ===  'payor') {
                            //teasetext = JSON.stringify(tt);
                            teasetext = 'Payor eID: ' + tt["eID"] + ' | Code: ' + tt["Code"];
                        }
                        else if (r.entity ===  'provider' || r.entity ===  'biller') {
                            teasetext = 'NPI: ' + tt["NPI#"] + ' | Provider Type: ' + tt["Type"]; 
                        }
                        else if (r.entity ===  'payment' && r.entity_view === 'dashboard') {
                            teasetext = 'payor: ' + tt.payor_name + ' | mode: ' + tt.mode + ' | method: ' + tt.method + ' | payment date: ' + tt.payment_date;
                        }
                        else if (r.entity ===  'physician' || r.entity ===  'renderer') {
                            teasetext = 'NPI: ' + tt["NPI#"] + ' | Provider Type: ' + tt["Type"] + ' | Provider: ' + tt["Customer"]; 
                        }        
                        else if(r.entity === 'service_class') {
                            teasetext = 'Customer: ' + tt['Customer'] + '| since ' + tt['Since']
                        }                 
                        
                        //TODO: teasetext for group, icd, message - see SearchResults.js and below list
                        */

                    return(
                    <li key={i} className={classes.listSectiSearchToComponenton}>
                        <ul className={classes.ul}>
                            <ListItem button key={`item-${i}`} className={classes.listitem}
                                onKeyPress={(event) => {console.log("onKeyPress: ", event); searchitemClicked(r);}} 
                                onClick={() => searchitemClicked(r)}>
                                <ListItemIcon>
                                    {r.entity ===  'cpt' && <Healing />}
                                    {r.entity ===  'claim' && <Receipt />}
                                    {r.entity ===  'denial' && <Announcement />}
                                    {r.entity ===  'receivable' && <MonetizationOn />}
                                    {r.entity ===  'revenue' && <MonetizationOn />}
                                    {r.entity ===  'submission' && <Receipt />}
                                    {r.entity ===  'summary' && <Dashboard />}
                                    {r.entity ===  'renderer' && <Business />}
                                    {r.entity ===  'customer' && <Business />}
                                    {r.entity ===  'biller' && <Business />}
                                    {r.entity ===  'provider' && <LocalHospital />}
                                    {r.entity ===  'payor' && <AccountBalance />}
                                    {r.entity === 'physician' && <PersonAdd />}
                                    {r.entity === 'dashboard' && <Dashboard />}
                                    {r.entity === 'payment' && <MonetizationOn />}
                                    {r.entity === 'group' && <GroupWork />}
                                    {r.entity === 'observation' && <Search />}
                                    {r.entity_view === 'whatif' && r.entity === 'Renderer' && <Assistant />}
                                    {r.entity === 'service_class' && <Class />}
                                </ListItemIcon>                                
                                <ListItemText primary={r.title} secondary={teasetext} />
                            </ListItem>
                        </ul>
                    </li>
                )}})}
                </List>     
            </Paper>   
            </div>            
            )
        }

    } else {
        return null
    }

}


const astyle = makeStyles((theme) => ({
    detail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
    },
    paper: {
        marginTop: 25,
        marginBottom: 30,
        width: '100%',
    },
    resdiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    entitytext: {
        fontSize: 'small',
        fontWeight: 'Book'
    },
    titletext: {
        fontSize: 'large',
        fontWeight: 'Semi-bold',
    },
    listitem: {
        //backgroundColor: colors.bluegrey600,
        backgroundColor: 'transparent',
    },
    ul: {
        //marginRight: 5,   //20,
        paddingLeft: 1
    },
    usertext: {
        fontSize: 'small'
    },
    searchres: {
        marginTop: 15,
       //marginLeft: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    divider: {
        marginTop: 3
    },
    sbut: {
        margin: 0,
        padding: 0
    },
    noresult: {
        margin: 20,
        fontSize: 'large'
    }

}))

export default SearchResults