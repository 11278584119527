//https://material-ui.com/components/cards/

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import ChartInspector from '../devtools/ChartInspector';

const useStyles = makeStyles({
  root: {
    display: "inline-block",
    //minWidth: 275,
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 12,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

export default function SimpleCard(props) {
  try {
    const classes = useStyles();
    //const bulletspan = <span className={classes.bullet}>•</span>;

    let myRef = React.createRef();

    let getIcon = function(icon) {
      //console.log("getIcon: ", icon);
      switch (icon) {
        case "up": return <ArrowDropUpIcon />; break;
        case "down": return <ArrowDropDownIcon />; break;
        default: return null; break;
      }
    }

    /*
          return <span style={{ color, whiteSpace: "nowrap", display: 'flex', alignItems: 'center' }}>
            <Typography>
              {indexValue}: <b>{Number(value).toLocaleString('en-US')}</b>
            </Typography>
            <span style={{ color: (data.paid_count_change > 0) ? "green" : "red", whiteSpace: "nowrap", display: 'flex', alignItems: 'center' }}>
              {(data.paid_count_change > 0) ? <ArrowDropUpIcon style={{}} /> : <ArrowDropDownIcon />}
              <Typography>
                <b>{data.paid_count_change}%</b>
              </Typography>
            </span>
          </span>;

    */

    let renderSubText = function(subtext, subtextIcon) {
      if (subtext)
        return <span style={{ 
            //flexBasis: "fit-content",
            //flexBasis: "auto",
            color: (subtextIcon==="up") ? "green" : (subtextIcon==="down") ? "red" : undefined,
            whiteSpace: "nowrap", 
            display: 'flex', alignItems: 'center' 
          }}>
          <Typography variant="body2" component="p">
            {subtext}
          </Typography>
          {getIcon(subtextIcon)}
        </span>
      else return <React.Fragment></React.Fragment>
    }

    return (
      //<div style={{display : inline-block 'table-cell', height: "100%"}}>
      <Card className={classes.root} onClick={(event) => {if (props.onClick) props.onClick(event, myRef)}} ref={myRef}>
        <CardContent>        
          <div style={{display: 'flex', alignItems: 'center', flexWrap: "nowrap"}} >            
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {props.title}
              {props.showNotificationsIcon && <IconButton aria-label="settings" size="small" p={0} m={0}>
                  <NotificationsActiveIcon p={0} m={0} />
                </IconButton>}
            </Typography>
            <React.Fragment>&nbsp;&nbsp;&nbsp;</React.Fragment>
            <ChartInspector _props={props} title={props.title} renderedData={props.dataInput} meta={props.meta} />
          </div>
          <Typography variant="h5" component="h2">
            {props.value}
          </Typography>
          {/*<Typography className={classes.pos} color="textSecondary"></Typography>*/}
          {renderSubText(props.subtext, props.subtextIcon)}
          {renderSubText(props.subtext2, props.subtext2Icon)}
          {renderSubText(props.subtext3, props.subtext3Icon)}
        </CardContent>
        {/*
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
        */}
      </Card>
      //</div>
    );
  }
  catch (err) {
    console.log("Error rendering this card: ", {props: props, err: err});
    return (
      <React.Fragment>
        Error rendering this card
      </React.Fragment>
    );  
  }
}
