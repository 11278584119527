import React from 'react';
//import  { Redirect } from 'react-router-dom'
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { Typography } from '@material-ui/core';

import * as EULAHandler from '../services/EULAHandler';
import * as colors from '../Colours';

class EULAView extends React.Component {
    constructor(props) {    
        super(props);
        //console.log("EULAView constructor called");

        this.state = {
            anchorEl: null,
            showEULAView: true,
            userAcceptedEULA: false
        };
    }

    async acceptEULA() {
        this.handleClose();
        let response = await EULAHandler.acceptEULA(this.props);
        this.setState({showEULAView: false, userAcceptedEULA: true});
        console.log("EULA accepted. Reloading after response: ", response);
        //window.location.reload();
    }

    async declineEULA() {
        this.handleClose();
        let response = await EULAHandler.declineEULA(this.props);
        this.setState({showEULAView: false, userAcceptedEULA: false});
        console.log("EULA declined. User has been logged out. Reloading after response: ", response);
        //window.location.reload();
    }

    simulateClick = (event) => {
        console.log("simulateClick e:", event);
        if (event) event.click();
    };
    
    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };
  
    handleClose = () => {
        this.setState({anchorEl: null});
    };
  
    render() {
        console.log("EULAView render this.state: ", this.state);
        if (!this.state.showEULAView) {
            if (this.state.userAcceptedEULA) {
                //let path = "/homedashboard";
                //console.log("redirecting to " + path);
                //return <div><Redirect to={{pathname: path}} /></div>
                //return <div><Redirect to={path} /></div>
                return <div></div>
            }
            else {
                console.log("logging out user");
                this.props.user.logout();
                return <div></div>
            }
        }
        else { //if (this.state.showEULAView) {
            console.log("Showing EULA...");

            let open = Boolean(this.state.anchorEl);
            let id = open ? 'simple-popover' : undefined;    
        
            let handleEULAResponse = this.props.handleEULAResponse;
            //let user = this.props.user;
            let url = handleEULAResponse.systemSettingsEULA.data.eula.url;
            let version = handleEULAResponse.systemSettingsEULA.data.eula.version;

            //https://stackoverflow.com/questions/33913737/inserting-the-iframe-into-react-component
            let iframeHTML = '<iframe style="width: 100%; height: 100%;" scrolling="yes" title="Artiss RI EULA" src="' + url + 
                '" frameborder="no" allowtransparency="true" allowfullscreen="true">Artiss RI EULA</iframe>'; 

            function IframeView(params) {
                return (<div style={{backgroundColor: colors.grey400, height: "40vh", width: "40vw"}} dangerouslySetInnerHTML={ {__html:  params.iframe ? params.iframe : ""}} />);
            }            

            return (
                <div 
                ref={this.simulateClick}
                onClick={this.handleClick} 
                >        
                    {/*<Typography>Showing EULA...</Typography>*/}
                    <Popover 
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}   
                    anchorReference="anchorPosition"
                    anchorPosition={{ 
                        top: window.innerHeight * 0.33, 
                        left: window.innerWidth * 0.33, 
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    >
                        <div>
                            <Typography variant="h4" style={{marginLeft: 10}}>Artiss RI End-user License Agreement</Typography>
                            <Typography style={{marginLeft: 10}}>Please review and accept this agreement to proceed with RI application</Typography>
                            <IframeView iframe={iframeHTML} />
                            <div>
                                &nbsp;&nbsp;<Button style={{backgroundColor: colors.bluegrey600, marginTop: 5, marginBottom: 5}} onClick={() => {this.acceptEULA()}}>&nbsp;&nbsp;Accept&nbsp;&nbsp;</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;<a href="#" onClick={() => this.declineEULA()}>Decline</a>
                            </div>
                            {/*
                            &nbsp;&nbsp;<Button style={{backgroundColor: colors.bluegrey600}} onClick={() => {this.acceptEULA()}}>&nbsp;&nbsp;Accept&nbsp;&nbsp;</Button>
                            &nbsp;&nbsp;<a href={this.declineEULA()}>Decline</a>
                            */}
                            
                        </div>                
                    </Popover>    
                </div>        
            );
        }
    }
}

export default EULAView;