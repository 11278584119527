import React from 'react'
import Typography from '@material-ui/core/Typography';
import { Bar, ResponsiveBar } from '@nivo/bar';
import { withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
//import { BoxLegendSvg } from "@nivo/legends";
//import { categoricalColorSchemes } from '@nivo/colors';
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CallMadeIcon from '@material-ui/icons/CallMade';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import ChartInspector from '../../../devtools/ChartInspector';
import ExpandedChartPopover from '../expanded/ExpandedChartPopover';
//import ExpandedChartButton from '../expanded/ExpandedChartButton';
import * as Utils from '../../../services/Utils';
import * as colors from '../../../Colours';

class BarChartHorizontalComponent extends React.Component  {

  constructor(props) {    
    super(props);
    this.state = {
      displayExpandedChart: false,
    }
  }  

  openExpandedChart = () => {
    if (this.props.inceptionLevel!=1)
      this.setState({displayExpandedChart: true})
  };

  closeExpandedChart = () => {
    this.setState({displayExpandedChart: false})
  };

  /*
  static displayExpandedChart = false;
  openExpandedChart = () => {
    console.log("openExpandedChart called");
    if (this.props.inceptionLevel!=1) {
        BarChartHorizontalComponent.displayExpandedChart = true;
        this.forceUpdate();
    }
  };
  closeExpandedChart = () => {
    BarChartHorizontalComponent.displayExpandedChart = false;
    this.forceUpdate();
  };  
  */

  render() {
    const {classes} = this.props;
    try {
      //console.log( "theme", theme)
      
      let cs1 = {
        style: "currency",
        currency: "USD",
        minimumSignificantDigits: 2,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      };    
  
      let num1 = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      };    

      /*
      //https://nivo.rocks/storybook/?path=/story/bar--custom-tooltip
      let tooltip = ({ id, value, color }) => (
        <strong style={{ color }}>
            {id}: {value}
        </strong>
      );
      */

      //change the keys!
      let keys = this.props.keys;
      //let dataInput = this.props.dataInput;
      //let dataInput = Object.assign({}, this.props.dataInput);
      //https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
      //console.log("this.props.dataInput: ", this.props.dataInput);
      let dataInput = this.props.dataInput.map(a => Object.assign({}, a));      
      //let updatedKeys = keys;
      let updatedKeys = [];
      for (let i=0; i < keys.length; i++) {
        let updatedKey = Utils.convertFieldNameToLabel(keys[i]);
        updatedKeys.push(updatedKey);
        dataInput = Utils.copyJSONObjectArrayKey(dataInput, keys[i], updatedKey)
      }

      let maxLabelLength = Utils.getMaxValueLengthFromJSONObjectArray(dataInput, this.props.indexBy);
      //let averageCharacterWidth = 6.5; //7px is average character width in px - see HomeDashboard code
      let averageCharacterWidth = (this.props.averageCharacterWidth ? this.props.averageCharacterWidth : 6.5); //7px is average character width in px - see HomeDashboard code
      let labelWidthPadding = 15;
      //console.log("indexBy:", this.props.indexBy, "; maxLabelLength: ", maxLabelLength);
      var commonProps = {
          groupMode: this.props.groupMode ? this.props.groupMode : "grouped",
          //barWidth: 10,//https://github.com/plouc/nivo/issues/285
          //width: 500,
          //height: 250,
          margin: { 
            top: 0, 
            right: 20, //labelItemWidth + 20, 
            bottom: (keys.length > 1) ? 53 : 25,             
            left: (this.props.labelLength ? ((this.props.labelLength * averageCharacterWidth) + labelWidthPadding) : 
              this.props.labelWidthPercent ? (this.props.width * this.props.labelWidthPercent / 100) : 
              (maxLabelLength > 0) ? ((maxLabelLength * averageCharacterWidth) + labelWidthPadding) : (this.props.width * 0.5))
          },//needed for label spacing; do not change!
          padding: ((dataInput.length <= 1 && keys.length <= 1) ? 0.8 : 0.2),
          innerPadding: 2,//0  
          //label: "value",
          //label: d => {let str = (""+d.value).substring(0, 10); return str;},
          //labelTextColor: 'inherit:darker(1.4)',
          //labelTextColor: 'inherit:lighter(1.4)',
          //labelTextColor: 'white',
          //labelTextColor: 'theme.text.primary',
          labelSkipWidth: 24,
          labelSkipHeight: 10,      
          animate: false,
          //tooltip: tooltip,

          //https://nivo.rocks/guides/colors/
          //reverse: true,

          // formatting labels
          // formatting axis tick value
          //axisLeft: { format: v => `${v}%` },        
          
          //https://nivo.rocks/guides/axes/
          //theme: axis: {textColor: '#eee', fontSize: '20px', tickColor: '#eee'}
      };

      //let title = this.props.title ? this.props.title : this.props.meta.display_title;
      let title = this.props.title ? (this.props.title + (this.props.subtitle ? " (" + this.props.subtitle + ")" : "")) : this.props.meta.display_title;

      //increase chart width for long labels!
      //let updatedWidth = Math.max(commonProps.margin.left * 1.7, this.props.width);
      let updatedWidth = Math.max(commonProps.margin.left * (this.props.labelFactor ? this.props.labelFactor : 1.7), this.props.width);

      //legend: https://nivo.rocks/guides/legends/
      //sample: https://stackblitz.com/edit/react-8brd3g
      //other ref:
      //https://github.com/plouc/nivo/issues/308
      //https://github.com/plouc/nivo/issues/204
      //https://github.com/plouc/nivo/issues/791
      let legends = [];
      /*
      const BarLegend = ({ height, legends, width }) => (
        <React.Fragment>
          {legends.map(legend => (
            <BoxLegendSvg
              key={JSON.stringify(legend.data.map(({ id }) => id))}
              {...legend}
              containerHeight={height}
              containerWidth={width}
            />
          ))}
        </React.Fragment>
      );    
      const { category10 } = categoricalColorSchemes
      */   


      //turn on legend only for charts with 2/more metrics (e.g. paid vs charged)
      //update: show legend if flag set to true!
      let showLegends = ((this.props.keys.length > 1 && this.props.legends!=false) || (this.props.legends==true));
      //console.log("showLegends: ", this.props.title + ":" + showLegends);
      if (showLegends) {


        //legendKey was required only to find width; we already have keys so using that!
        let maxItemLength = 10;
        //if (this.props.slugData) maxItemLength = Utils.getMaxValueLengthFromJSONObjectArray(this.props.slugData, this.props.legendKey);
        if (this.props.keys) maxItemLength = Utils.getMaxValueLengthFromStringArray(this.props.keys);
        let itemWidth = this.props.legendItemWidth ? this.props.legendItemWidth : 
          //(this.props.legendKey ? ((maxItemLength) * 7.5) + 15 : 100);
          (this.props.keys ? (maxItemLength * (this.props.itemCharacterWidth ? this.props.itemCharacterWidth : 5)) + 25 : 100);
        
        let legendDirection = this.props.legendDirection;

        /*
        let columnSettings = {
          anchor: 'bottom-left',
          direction: 'column',
          justify: false,
          translateX: 100,//itemWidth + 10,
          translateY: 50,
          itemWidth: itemWidth,
          itemHeight: 11,
          itemOpacity: 0.75,
          itemsSpacing: 2,
          itemDirection: 'left-to-right',
        }
        let rowSettings = {
          anchor: 'bottom-left',            
          direction: 'row',
          justify: false,
          translateX: this.props.legendTranslateX 
            ? (this.props.legendTranslateX + 5)
            //: 0,
            //: ((updatedWidth * -0.5) + 20),half of chart size: fails for short labels
            : ((commonProps.margin.left * -1) + 5),
          translateY: 50,//make this relative
          //legendItemWidth = (maxLegendItemLength * averageCharacterWidth) + labelWidthPadding;
          itemWidth: itemWidth,
          itemHeight: 10,
          itemOpacity: 0.75,
          itemsSpacing: 20,
          itemDirection: 'left-to-right',
        }
        */

        legends = [
          {
            dataFrom: 'keys',
            //https://github.com/plouc/nivo/issues/204 
            //https://github.com/plouc/nivo/issues/791
            /*
            //almost works - except for the color!
            data: this.props.keys.map((id, index) => ({
              //color: legends.text.fill,//colors[index],//https://github.com/plouc/nivo/issues/204
              color: category10[index],
              id,
              label: Utils.convertFieldNameToLabel(id)
            })),            
            */
            //...rowSettings,
            //...rowSettings && (this.props.legendDirection!=="column"),
            //...columnSettings && (this.props.legendDirection==="column"),

            anchor: (legendDirection==="column") ? 'top-right' : 'bottom-left',            
            direction: (legendDirection==="column") ? 'column' : 'row',
            justify: false,
            translateX: (legendDirection==="column") ? (itemWidth + 10) : 
              (this.props.legendTranslateX 
              ? (this.props.legendTranslateX + 5)
              //: 0,
              //: ((updatedWidth * -0.5) + 20),half of chart size: fails for short labels
              : ((commonProps.margin.left * -1) + 5)),
            translateY: (legendDirection==="column") ? 2 : 50,//make this relative
            //legendItemWidth = (maxLegendItemLength * averageCharacterWidth) + labelWidthPadding;
            itemWidth: itemWidth,
            itemHeight: 10,
            itemOpacity: 0.75,
            itemsSpacing: (legendDirection==="column") ? 2 : 20,
            itemDirection: 'left-to-right',

            itemOpacity: 0.85,
            symbolSize: 10,
            itemTextColor: colors.white,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 1
                    }
                }
            ]
          }
        ];

        //if (this.props.legendDirection!=="column") Object.assign(legends, rowSettings);//default
        //else Object.assign(legends, columnSettings);

        //provide margin for column legend if configured!
        if (this.props.legendDirection === "column") {
          commonProps.margin.right = itemWidth + 50;//20
          //legends.translateX =  -2 * (itemWidth);
        }

      }


      let data;
      if (this.props.reverseData && this.props.reverseData===false) {
        data = dataInput;
      }
      else {
        //reverse data 
        //TODO: chart widget vs dashboard caller preprocess
        //TODO: move cloning and reverse to utils - along with preprocess 
        //1. clone. direct reverse didn't work, so trying on a clone
        //https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
        data = dataInput.map(a => ({...a}));
        //2. reverse clone
        data = data.reverse();
      }

      let updatedHeight = this.props.height;
      //reduce height for less number of rows - doesn't work well
      //try {updatedHeight = this.props.height * (Math.min(data.length, 10) / 10);}catch (err) {}
      //if (data.length < 2) updatedHeight = updatedHeight * 0.5;    
      //add spacing for legend
      //if (this.props.keys.length > 1)
      if (showLegends) 
        updatedHeight += 30;

      //theme tooltip: https://github.com/plouc/nivo/blob/master/packages/bar/stories/bar.stories.js#L202    

      //console.log("BarChartHorizontal data: title: ", title, ":", dataInput);

      let onClick = undefined;
      //if (this.props.inceptionLevel!=1)
        //onClick = (event, ref) => this.openExpandedChart();

      let onClickChart = undefined;
      if (this.props.inceptionLevel==1) 
        onClickChart = (node, event) => {
          console.log("onClickChart node: ", node);
          console.log("onClickChart event: ", event);
          if (this.props.onClickChart) this.props.onClickChart({node: node, event: event});
        }
  
      if (data && data.length > 0) {
      return (      
        <Paper className={classes.chartPaper}>
        {this.state.displayExpandedChart && Utils.showExpandedChart(this.props) &&
          <ExpandedChartPopover {...this.props} chartType="BarChartHorizontal" closeExpandedChart={this.closeExpandedChart} />
        }
        {/*<ExpandedChartPopover {...this.props} chartType="BarChartHorizontal" displayExpandedChart= />*/}
        {/*BarChartHorizontalComponent.displayExpandedChart &&
          <ExpandedChartPopover {...this.props} chartType="BarChartHorizontal" closeExpandedChart={this.closeExpandedChart} />
        */}        
        {/*<ExpandedChartButton {...this.props} chartType="BarChartHorizontal" />*/}
        <div onClick={onClick}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", width: "100%"}} >
            <Typography variant="subtitle1" align='center'>{title}</Typography>
            &nbsp;&nbsp;
            {this.props.inceptionLevel!=1 && Utils.showExpandedChart(this.props) && <IconButton onClick={() => {this.openExpandedChart()}}>
              <CallMadeIcon style={{ fontSize: 14 }} />
            </IconButton>}
            &nbsp;&nbsp;
            <ChartInspector _props={this.props} title={title} renderedData={dataInput} meta={this.props.meta} />
          </div>
          {/*<span>{this.props.meta.description}</span>*/}
          <Bar 
            onClick={onClickChart}
            className={classes.all} 
            {...commonProps} 
            {...this.props} 
            keys={updatedKeys} 
            width={updatedWidth} 
            height={updatedHeight} 
            data={data} 
            layout="horizontal" 
            enableGridY={false} 
            enableGridX={true}  
            colors={{ scheme: this.props.scheme }}  
            axisBottom={{ format: "~s", tickValues: 4 }} 
            theme={{
              fontSize: '12px', 
              textColor: colors.white, 
              tooltip: {container: {background: '#333'}},
              grid: {
                line: {
                  stroke: "DarkSlateGrey",
                  strokeWidth: 1,
                }
              },
            }}             
            //layers={["grid", "axes", "bars", "markers", BarLegend]}
            legends={legends} 
            //tickValues: 5 doesn't work!!
            labelFormat=".2~s"
            //TODO: customize this further per https://github.com/plouc/nivo/blob/master/packages/tooltip/src/components/BasicTooltip.js
            tooltip={this.props.commentKey 
              ? ({ id, value, index, indexValue, color, data }) => {
              //console.log("tooltip params: id: ", id, "; value: ", value, "; index:", index, "; indexValue:", indexValue, "; data:", data);
              /*
              return (
                <span style={{ color, width: "100%" }}>
                    {id} - {indexValue}: <b>{Number(value).toLocaleString('en-US')}</b>
                    {this.props.commentKey(data)}
                </span>
              );}*/
              return (this.props.commentKey(id, value, index, indexValue, color, data))}            
              : undefined
              /*: ({ id, value, index, indexValue, color, data }) => {
                return (Utils.nivoToolTip(id, value, index, indexValue, color, data))
              }*/
            } 
            tooltipFormat={value => {
              //console.log("tooltipFormat: value: ", value);
              if (this.props.keys && this.props.keys[0] && this.props.keys[0].endsWith("_amount")) return `${Number(value).toLocaleString('en-US', cs1)}`;
              else return `${Number(value).toLocaleString('en-US', num1)}`;
            }}

            //label={d => {
              //console.log("barchart label d:", d); 
              //if (this.props.commentKey) return `${d.id}: ${d.value}: ${d[this.props.commentKey]}`; 
              //else return `${d.id}: ${d.value}`;
              //return `${d.id}: ${d.value}`;
            //}}
          />        
        </div>
        </Paper>
      );
      }
      else {
          //<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", height: this.props.height, width: this.props.width}} >
          return (
          <Paper className={classes.chartPaper}>
            <div style={{height: this.props.height, width: this.props.width}} >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}} >
                  <Typography variant="subtitle1" align='center'>{title}</Typography>
                  <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
                </div>
                <div style={{marginBottom: 10}}></div>
                <div style={{fontSize: 12, fontStyle: "italic", align: 'center'}}>
                  <Typography align='center'>{"No data available"}</Typography>                
                </div>
            </div>
          </Paper>
        );  
      }
    }
    catch (err) {
      console.log("Error rendering this card: ", {props: this.props, err: err});
      return (
        <Paper className={classes.chartPaper}>
          <div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: "wrap", height: this.props.height, width: this.props.width}} >
              <Typography variant="subtitle1" align='center'>{this.props.title}</Typography>
              <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
              <div style={{marginBottom: 10}}></div>
              <div style={{fontSize: 12, fontStyle: "italic", align: 'center'}}>{"Error rendering this chart"}</div>
          </div>
        </Paper>
      );  
    }
  }
}


const astyle = (theme) => ({
  all: {
      //borderColor: "#20232a",
      
      //display: 'flex',
      //marginBottom: 20
      //margin: { top: 30, right: 20, bottom: 30, left: 50 },
      // flex: 1,
      // width: '100'
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // marginBottom: 25,
      //backgroundColor: theme.palette.primary,
      
  },
  chartPaper: {
    margin: 5,
    padding: 0,
    elevation: 2,
    //backgroundColor: theme.palette.primary.main,
    //backgroundColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.dark,
    //backgroundColor: 'transparent',
  },

  palette: {
    //primary: {
      //main: "#607D8B",
    //},
    secondary: {
      main: '#8A979D',      
    },
  },
    
})


export default withStyles(astyle, {withTheme: true})(BarChartHorizontalComponent)