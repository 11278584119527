// Fbdetails.js
// copyright @artiss.co

// all firebase init and var exports

var firebase;
var fbanalytics;

const InitFirebase = () => {

    firebase = require('firebase');
    require('firebase/app')
    require('firebase/analytics')
    require('firebase/auth')
    //var firebaseui = require('firebaseui');
    
    var firebaseConfig = {
        apiKey: "AIzaSyCyBIjs_exvUaBst_usqhFpQSmusMD3toU",
        authDomain: "instant-bonbon-259705.firebaseapp.com",
        databaseURL: "https://instant-bonbon-259705.firebaseio.com",
        projectId: "instant-bonbon-259705",
        storageBucket: "instant-bonbon-259705.appspot.com",
        messagingSenderId: "1035367451441",
        appId: "1:1035367451441:web:acb73e7d947727c0b1fe10",
        measurementId: "G-DS86ESEV85",
    };
    
    // Initialize Firebase
    var fbapp = firebase.initializeApp(firebaseConfig);
    fbanalytics = firebase.analytics();

}

export {InitFirebase, firebase, fbanalytics}



