// Storyheader.js  - for Physician Story
// copyright @ artiss.co

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import * as utils from '../../services/Utils'

const StoryHeader = (props) => {
    const classes = astyle();
    let {type, subtype, data} = props

    let dtstr = utils.monYearStr(data.since_date)
    let servdt = utils.monYearStr(data.servicing_since_date)
    let cs1 = {
        style: "currency",
        currency: "USD",
        minimumSignificantDigits: 2,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }

    return(
        <>
            <Typography variant="body1">
                Since 
                <Typography variant="h6" display='inline'> {dtstr} </Typography>
                , {subtype} has referred <Typography variant="h6" display='inline'> {data.volume} </Typography>
                units which amounts to
                <Typography variant="h6" display='inline'> {data.volume_percent.toFixed(0)}%. </Typography>
                The Average Unit Price (AUP) computes to
                <Typography variant="h6" display='inline'> {data.aup.toLocaleString('en-US', cs1)}</Typography>, 
                the charged amount is
                <Typography variant="h6" display='inline'> {data.charged.toLocaleString('en-US', cs1)} </Typography> 
                amounting to 
                <Typography variant="h6" display='inline'> {data.charge_percent.toFixed(0)}% </Typography> 
                , the allowed amount is 
                <Typography variant="h6" display='inline'> {data.allowed.toLocaleString('en-US', cs1)} </Typography>
                amounting to
                <Typography variant="h6" display='inline'> {data.allowed_percent.toFixed(0)}% </Typography>
                and paid amount is 
                <Typography variant="h6" display='inline'> {data.paid.toLocaleString('en-US', cs1)} </Typography>
                amounting to
                <Typography variant="h6" display='inline'> {data.paid_percent.toFixed(0)}% </Typography>
                . {subtype} has been referring to us since 
                <Typography variant="h6" display='inline'> {servdt} </Typography>
            </Typography>
        </>
    )

}



const astyle = makeStyles((theme) => ({
    header: {
        width: '80%',
        display: 'flex',
        //flexDirection: 'column',
        //justifyContent: 'center',
        //alignItems: 'center',

    },
    titletxt: {
        fontSize: 'large'
    }
}));

export {StoryHeader }