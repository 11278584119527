import React from 'react'
//import { generateCountriesData, sets } from '@nivo/generators'
//import range from 'lodash/range'
//import random from 'lodash/random'
//import { useTheme } from '@nivo/core'
import { Bar } from '@nivo/bar'
import Typography from '@material-ui/core/Typography';
import { withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CallMadeIcon from '@material-ui/icons/CallMade';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import ChartInspector from '../../../devtools/ChartInspector';
import ExpandedChartPopover from '../expanded/ExpandedChartPopover';
import * as Utils from '../../../services/Utils';
import * as colors from '../../../Colours';

class BarChartComponent extends React.Component  {

  constructor(props) {    
    super(props);
    this.state = {
      displayExpandedChart: false,
    }
  }  

  openExpandedChart = () => {
    if (this.props.inceptionLevel!=1)
      this.setState({displayExpandedChart: true})
  };

  closeExpandedChart = () => {
    this.setState({displayExpandedChart: false})
  };

  render() {
    const {classes} = this.props;

    try {

      let cs1 = {
        style: "currency",
        currency: "USD",
        minimumSignificantDigits: 2,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      };    
  
      let num1 = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      };    

      //let title = this.props.title ? this.props.title : this.props.meta.display_title;
      let title = this.props.title ? (this.props.title + (this.props.subtitle ? " (" + this.props.subtitle + ")" : "")) : this.props.meta.display_title;

      let maxLabelLength = 25;
      if (this.props.maxLabelLength) maxLabelLength = this.props.maxLabelLength;
      else if (this.props.keys) maxLabelLength = Utils.getMaxValueLengthFromJSONObjectArray(this.props.dataInput, this.props.keys);
      let averageCharacterWidth = 6.5; //7px is average character width in px - see HomeDashboard code
      let labelWidthPadding = 15;
      let labelItemWidth = 0;      

      //turn on legend only for charts with 2/more metrics (e.g. paid vs charged)
      let legends = [];
      if (this.props.keys.length > 1 && this.props.legends!=false) {
        labelItemWidth = (maxLabelLength * averageCharacterWidth) + labelWidthPadding;
        legends = [
          {
            dataFrom: 'keys',
            //anchor: 'bottom-left', direction: 'row',
            anchor: 'top-right', direction: 'column',
            justify: false,
            //translateX: 0,
            translateX: labelItemWidth + 10,
            /*translateX: this.props.legendTranslateX 
              ? this.props.legendTranslateX 
              : (commonProps.margin.left * -1),*/
            //translateY: 50,//make this relative
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: labelItemWidth,//120,
            itemHeight: 11,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 11,
            itemTextColor: colors.white,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 1
                    }
                }
            ]
          }
        ];
      }      
      //console.log("BarChart legends: ", legends);

      var commonProps = {
        groupMode: this.props.groupMode ? this.props.groupMode : "grouped",
        //width: 400,
        //height: 250,
        margin: { 
          top: 10, 
          right: 20 + labelItemWidth, 
          //bottom: (this.props.keys.length > 1) ? 50 : 25, 
          bottom: 25,
          left: 40
        },//needed for label spacing; do not change!
        padding: 0.2,
        innerPadding: 2,      
        labelTextColor: 'inherit:darker(1.4)',
        //labelFormat: { width: 50 },
        //label: "value",
        labelSkipWidth: 25,
        labelSkipHeight: 16,
        animate: false,  
    };      

      //change the keys!
      let keys = this.props.keys;
      //let dataInput = this.props.dataInput;
      //let dataInput = Object.assign({}, this.props.dataInput);
      //https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
      console.log("this.props.dataInput: ", this.props.dataInput);
      let dataInput = this.props.dataInput.map(a => Object.assign({}, a));      
      let updatedKeys = [];
      for (let i=0; i < keys.length; i++) {
        let updatedKey = Utils.convertFieldNameToLabel(keys[i]);
        updatedKeys.push(updatedKey);
        dataInput = Utils.copyJSONObjectArrayKey(dataInput, keys[i], updatedKey)
      }

      let data;
      if (this.props.reverseData===true) {
        //let data = dataInput;
        //reverse data 
        //1. clone. direct reverse didn't work, so trying on a clone
        //https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
        data = dataInput.map(a => ({...a}));
        //2. reverse clone
        data = data.reverse();
        //3. remove data from props??
      }
      else {
        data = dataInput;
      }

      //bar chart with compare https://nivo.rocks/storybook/?path=/story/bar--grouped 

      //TODO: use keyItems in BarChart for tooltip text

      let updatedHeight = this.props.height;
      //add spacing for legend
      //if (this.props.keys.length > 1) updatedHeight += 70;

      let onClick = undefined;
      //if (this.props.inceptionLevel!=1) 
        //onClick = (event, ref) => this.openExpandedChart();   

      let onClickChart = undefined;
      if (this.props.inceptionLevel==1) 
        onClickChart = (node, event) => {
          console.log("onClickChart node: ", node);
          console.log("onClickChart event: ", event);
          if (this.props.onClickChart) this.props.onClickChart({node: node, event: event});
        }

      if (data && data.length > 0) {
        return (
          <Paper className={classes.chartPaper}>    
          {this.state.displayExpandedChart &&  Utils.showExpandedChart(this.props) &&
            <ExpandedChartPopover {...this.props} chartType="BarChart" closeExpandedChart={this.closeExpandedChart} />
          }
          <div onClick={onClick}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}} >            
              <Typography variant="subtitle1" align='center'>{title}</Typography>
              &nbsp;&nbsp;
              {this.props.inceptionLevel!=1 && Utils.showExpandedChart(this.props) && <IconButton onClick={() => {this.openExpandedChart()}}>
                <CallMadeIcon style={{ fontSize: 14 }} />
              </IconButton>}
              &nbsp;&nbsp;
              <ChartInspector _props={this.props} title={title} renderedData={dataInput} meta={this.props.meta} />
            </div>
            {/*<span>{this.props.meta.description}</span>*/}
            <Bar 
              onClick={onClickChart}
              {...commonProps} 
              {...this.props} 
              height={updatedHeight} 
              keys={updatedKeys} 
              data={data} 
              axisLeft={{ format: "~s", tickValues: 4 }} 
              theme={{
                fontSize: '12px', 
                textColor: colors.white, 
                tooltip: {container: {background: '#333'}},
                grid: {
                  line: {
                    stroke: "DarkSlateGrey",
                    strokeWidth: 1,
                  }
                },
              }} 
              colors={{ scheme: this.props.scheme }}  
              legends={legends} 
              labelFormat=".3s" 
              /*tooltip={({ id, value, index, indexValue, color, data }) => {
                  return (Utils.nivoToolTip(id, value, index, indexValue, color, data))
              }}*/  
              tooltip={this.props.commentKey 
                ? ({ id, value, index, indexValue, color, data }) => {
                console.log("tooltip params: id: ", id, "; value: ", value, "; index:", index, "; indexValue:", indexValue, "; data:", data);
                /*
                return (
                  <span style={{ color, width: "100%" }}>
                      {id} - {indexValue}: <b>{Number(value).toLocaleString('en-US')}</b>
                      {this.props.commentKey(data)}
                  </span>
                );}*/
                return (this.props.commentKey(id, value, index, indexValue, color, data))}            
                : undefined
                /*: ({ id, value, index, indexValue, color, data }) => {
                  return (Utils.nivoToolTip(id, value, index, indexValue, color, data))
                }*/
              } 
              tooltipFormat={value => {
                if (this.props.keys && this.props.keys[0] && this.props.keys[0].endsWith("_amount")) return `${Number(value).toLocaleString('en-US', cs1)}`;
                else return `${Number(value).toLocaleString('en-US', num1)}`;
              }}  
            />
            {/*{(this.props.keys.length > 1) && <div style={{marginBottom: 100}}></div>}*/}
          </div>
          </Paper>
        );  
      }
      else {
        return (
          <Paper className={classes.chartPaper}>
            <div style={{height: this.props.height, width: this.props.width}} >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap"}} >
                  <Typography variant="subtitle1" align='center'>{title}</Typography>
                  <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
                </div>
                <div style={{marginBottom: 10}}></div>
                <div style={{fontSize: 12, fontStyle: "italic", align: 'center'}}>
                  <Typography align='center'>{"No data available"}</Typography>                
                </div>
            </div>
          </Paper>
        );  
      }
    }
    catch (err) {
      console.log("Error rendering this card: ", {props: this.props, err: err});
      return (
        <Paper className={classes.chartPaper}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", height: this.props.height, width: this.props.width}} >
              <Typography variant="subtitle1" align='center'>{this.props.title}</Typography>
              <Typography variant="body1" align='center'>{"Error rendering this chart"}</Typography>
              <ChartInspector _props={this.props} title={this.props.title} renderedData={this.props.dataInput} meta={this.props.meta} />
          </div>
        </Paper>
      );
    }
  }
    
}


const astyle = (theme) => ({
  all: {
      //borderColor: "#20232a",
      
      //display: 'flex',
      //marginBottom: 20
      //margin: { top: 30, right: 20, bottom: 30, left: 50 },
      // flex: 1,
      // width: '100'
      // flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      // marginBottom: 25,
      //backgroundColor: theme.palette.primary,
      
  },
  chartPaper: {
    margin: 5,
    padding: 0,
    elevation: 2,
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.dark,
    //backgroundColor: 'transparent',
  },
})


export default withStyles(astyle, {withTheme: true})(BarChartComponent)