import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
//import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import "../../App.css";
import Card from "../widgets/Card";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from "../widgets/charts/nivo/PieChart";
//import ObservationsView from '../widgets/ObservationsView';
import StickyTitle from '../widgets/Stickytitle';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";


class DenialAdjustmentDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();
    let defaultDateRangeFilter = {
      filterName: "last_3_months",//year_to_date, last_four_quarters, all_data
      label: "Last 3 months",
      durationBy: "week"
    };
    this.state = {
      ...newState,
      dateRangeFilter: defaultDateRangeFilter,
      prevDateRangeFilter: defaultDateRangeFilter,
    };
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    console.log("getData this.state: ", this.state);
    let durationBy = this.state.dateRangeFilter.durationBy;
    console.log("getData durationBy: ", durationBy);

    //https://github.com/ArtissLST/customer-ui/issues/10#issuecomment-720486696
    let requestDataForDenialAdjustment = {
      "table_slugs": [
        //row 1:
        "denial_summary",

        //row 2:
        "li_avoidable_by_" + durationBy,//month/week//quarter
        "li_avoidable_denial_count_and_amount_and_percentage",
        "li_avoidable_reason_class_by_" + durationBy,
        "zero_paid_by_processed_as",
        
        //row 3:
        "li_avoidable_by_payor",
        "li_avoidable_by_plan",
        "li_avoidable_by_payor_plan",

        //row 4:
        "li_avoidable_by_physician",
        "li_avoidable_by_provider",
        "li_avoidable_by_cpt",
        "li_avoidable_by_icd",

        //row 7:
        "li_adjustment_by_class",
        "li_adjustment_by_category",
        "li_adjustment_by_reason_class",
        "li_adjustment_by_class_category_reason_class",
        "li_adjustment_by_reason",
        //paid by patient:
        "li_paid_to_patient_by_payor_plan",
        "li_paid_to_patient_by_" + durationBy,
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };

    let requestDataForDenialAdjustmentBottom = {
      "table_slugs": [
        //row 5:
        "li_avoidable_by_payor",
        "li_avoidable_by_plan",
        "li_avoidable_by_payor_plan",

        //row 6:
        "li_avoidable_by_physician",
        "li_avoidable_by_provider",
        "li_avoidable_by_cpt",
        "li_avoidable_by_icd",
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"bottom","limit":10}
      }      
    }

    //this.setState({isLoadingCount: 2});
    this.setState({isLoadingCount: 1});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForDenialAdjustment, authtoken: authtoken, callback: this.updateResponseForDenialAdjustment});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForDenialAdjustmentBottom, authtoken: authtoken, callback: this.updateResponseForDenialAdjustmentBottom});
  }  

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
    }));
  };

  updateResponseForDenialAdjustment = (response) => {
    console.log("updateResponseForDenialAdjustment: ", response);
    this.setState({denialAdjustment: response});
    this.updateLoadingDone();
  }

  updateResponseForDenialAdjustmentBottom = (denialAdjustmentBottom) => {
    console.log("updateResponseForDenialAdjustmentBottom: ", denialAdjustmentBottom);
    this.setState({denialAdjustmentBottom: denialAdjustmentBottom});
    this.updateLoadingDone();
  }

  onChangeDateRangeFilter = (selectedFilter) => {
    console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);

    //use approp slugs based on date range criteria
    let durationBy = "";
    //switch (this.state.dateRangeFilter.filterName) {
    switch (selectedFilter.daterange.literal) {
      case "last_3_months": durationBy = "week"; break;
      case "year_to_date": durationBy = "month"; break;
      case "last_4_quarters": durationBy = "quarter"; break;
      case "all_data": durationBy = "quarter"; break;
      default: durationBy = "month"; break;//set default to month as "safe" default
    }  

    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext,
        durationBy: durationBy
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };

  render() {
    try{
    const {classes} = this.props

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };    

    let num1 = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let denialAdjustment = this.state.denialAdjustment;
    let denialAdjustmentBottom = this.state.denialAdjustmentBottom;

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;
    let dateRangeFilter = this.state.dateRangeFilter;

    if (!denialAdjustment || (denialAdjustment && denialAdjustment.status!=="success")) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='detailed_dashboard' 
          title={"Denial & Adjustment Dashboard"} 
          subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""} 
          callbackForDateRange={this.onChangeDateRangeFilter} 
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(denialAdjustment && denialAdjustment.status!=="success") && <div>Error loading denialAdjustment data</div>}
      </Container>      
    );
    else {
      console.log("denialAdjustment: ", denialAdjustment);
      console.log("denialAdjustmentBottom: ", denialAdjustmentBottom);
      console.log("this.state: ", this.state);
      console.log("this.props: ", this.props);

      let dateRangeFilter;//the one that we GOT from the latest response - or the current one while we're GETTING the next response
      if (denialAdjustment.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
        dateRangeFilter = this.state.dateRangeFilter;
      else if (denialAdjustment.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
        dateRangeFilter = this.state.prevDateRangeFilter;
      console.log("dateRangeFilter: ", dateRangeFilter);

      //data processing:
      //row 2:
      let trend_li_avoidable = denialAdjustment.data["li_avoidable_by_" + dateRangeFilter.durationBy];
      trend_li_avoidable._meta = denialAdjustment._meta["li_avoidable_by_" + dateRangeFilter.durationBy];
      let trend_li_avoidable_reason_class = denialAdjustment.data["li_avoidable_reason_class_by_" + dateRangeFilter.durationBy];
      trend_li_avoidable_reason_class._meta = denialAdjustment._meta["li_avoidable_reason_class_by_" + dateRangeFilter.durationBy];

      //row 3:
      denialAdjustment.data.li_avoidable_by_payor_plan = Utils.addGeneratedAttribute(denialAdjustment.data.li_avoidable_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");
      if (denialAdjustmentBottom && denialAdjustmentBottom.data) denialAdjustmentBottom.data.li_avoidable_by_payor_plan = Utils.addGeneratedAttribute(denialAdjustmentBottom.data.li_avoidable_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");

      denialAdjustment.data.li_avoidable_by_payor = Utils.toStringForJSONObjectArray(denialAdjustment.data.li_avoidable_by_payor, "avoidable", "avoidableAsString");
        
      //row 7:
      denialAdjustment.data.li_adjustment_by_class_category_reason_class = Utils.addGeneratedAttribute(denialAdjustment.data.li_adjustment_by_class_category_reason_class, 
            ["adj_class", "category", "reason"], "adj_class_category_and_reason");
      let trend_li_paid_to_patient = denialAdjustment.data["li_paid_to_patient_by_" + dateRangeFilter.durationBy];
      trend_li_paid_to_patient._meta = denialAdjustment._meta["li_paid_to_patient_by_" + dateRangeFilter.durationBy];
      denialAdjustment.data.li_paid_to_patient_by_payor_plan = Utils.addGeneratedAttribute(denialAdjustment.data.li_paid_to_patient_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");

      let periodDisplay = denialAdjustment.dates.period_display;

      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='detailed_dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 
              serverdaterange={periodDisplay}
              title={"Denial & Adjustment Dashboard"} 
              subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""} 
              //`Based on processing of ${denialAdjustment.data.denial_summary[0].denial_count.toLocaleString('en-US', num1)} denials`
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
              <Tab label="Summary" {...TabHandler.a11yProps(0)} />
              <Tab label="Most Denials" {...TabHandler.a11yProps(1)} />
              <Tab label="Least Denials" {...TabHandler.a11yProps(2)} />
              <Tab label="Adjustments" {...TabHandler.a11yProps(3)} />
            </Tabs>
          </AppBar>
          
          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper} p={0} m={0}>      
            <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0} bgcolor="bluegrey.300">
              {/* row 1 */}          
              <Card title="Denials" value={denialAdjustment.data.denial_summary[0].denial_amount.toLocaleString('en-US', cs1)} 
                subtext={`From ${denialAdjustment.data.denial_summary[0].denial_count.toLocaleString('en-US', num1)} denials`} 
                dataInput={denialAdjustment.data.denial_summary} 
                meta={denialAdjustment._meta.denial_summary} 
              />
              {/*<div style={{paddingLeft: 100}}></div>*/}
            </Box>

            {/* row 2 
            TODO: code colorBy="avoidable" (diff shades?) for LineChart?, BarChartHorizontal, PieChart?
            */}
            <Box className={classes.chartRow}>
                <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                    //dataInput={trend_li_avoidable} 
                    dataInput={trend_li_avoidable.map(item => ({...item, avoidableString: (item.avoidable ? "Avoidable" : "Non-avoidable" )}))}
                    meta={trend_li_avoidable._meta} 
                    title="Denial Trends" subtitle="$ - Denial" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                    groupBy="avoidableString" keyYAxis="denial_amount" labelForY="Denial Amount" />
                <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                    dataInput={trend_li_avoidable_reason_class} 
                    meta={trend_li_avoidable_reason_class._meta} 
                    title="Denial Trends by Reason" subtitle="$ - Denial" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                    groupBy="reason" keyYAxis="denial_amount" labelForY="Denial Amount" colorBy="avoidable" />
            </Box>
            <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="paired" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_denial_count_and_amount_and_percentage, "reason", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_denial_count_and_amount_and_percentage} 
                    title={`Top Denial Reasons`} subtitle="$ - Denial" indexBy="reason" keys={["denial_amount"]} /> 
                <PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="dark2" 
                    dataInput={denialAdjustment.data.zero_paid_by_processed_as} 
                    meta={denialAdjustment._meta.zero_paid_by_processed_as} 
                    title="Zero Paid by Processed As" subtitle="$ - Charge" indexBy="processed_as" keyName="charge_amount" />
            </Box>

          </Paper>
          </TabHandler.TabPanel>
          <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper}>

            {/* row 3 */}
            {/*<Typography variant="h5" display="inline">&nbsp;&nbsp;&nbsp;&nbsp;Most Denials</Typography>*/}
            <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 1.25} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_by_payor, "payor", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_payor} 
                    title={`Payors with most Denials`} subtitle="$ - Denial" indexBy="payor" keys={["denial_amount"]} colorBy="avoidable" />
                {/*<BarChartHorizontal width={chartWidth * 1.25} height={chartHeight} scheme="nivo" groupMode="stacked"
                  dataInput={Utils.groupJSONObjectArrayByKeyAndRow(denialAdjustment.data.li_avoidable_by_payor, "payor", "avoidableAsString", "denial_amount")} reverseData={false} 
                  slugData={denialAdjustment.data.li_avoidable_by_payor}
                  meta={denialAdjustment._meta.li_avoidable_by_payor}
                  title="Payors with most Denials" subtitle="$ - Denial" legends={true} legendDirection="column" averageCharacterWidth={8}
                  indexBy="customer" 
                  keys={Utils.getUniqueKeyValueListFromJSONObjectArray(denialAdjustment.data.li_avoidable_by_payor, "avoidableAsString")}
                  />*/}
                <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="dark2" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_by_plan, "plan_type", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_plan} 
                    title={`Plans with most Denials`} subtitle="$ - Denial" indexBy="plan_type" keyName="denial_amount" colorBy="avoidable" />
                <BarChartHorizontal width={chartWidth * 1.25} height={chartHeight} scheme="paired" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_by_payor_plan, "payor_and_plan_type", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_payor_plan} 
                    title={`Payors & Plans with most Denials`} subtitle="$ - Denial" indexBy="payor_and_plan_type" keys={["denial_amount"]} colorBy="avoidable" />
            </Box>

            {/* row 4 */}
            <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_by_physician, "physician", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_physician} 
                    title={`Physicians with most Denials`} subtitle="$ - Denial" indexBy="physician" keys={["denial_amount"]} colorBy="avoidable" />                 
                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_by_provider, "provider", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_provider} 
                    title={`Providers with most Denials`} subtitle="$ - Denial" indexBy="provider" keys={["denial_amount"]} colorBy="avoidable" />                 
            </Box>
            <Box className={classes.chartRow}>
                <PieChart type="half_donut" width={chartWidth * 1.5} height={chartHeight} scheme="paired" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_by_cpt, "cpt_code", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_cpt} 
                    title={`CPT codes with most Denials`} subtitle="$ - Denial" indexBy="cpt_code" keyName="denial_amount" colorBy="avoidable" />
                <PieChart type="half_donut" width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustment.data.li_avoidable_by_icd, "icd_code", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_icd} 
                    title={`ICD codes with most Denials`} subtitle="$ - Denial" indexBy="icd_code" keyName="denial_amount" colorBy="avoidable" />
            </Box>

          </Paper>
          </TabHandler.TabPanel>
          <TabHandler.TabPanel key={2} value={this.state.currentTab} index={2} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper}>

            {/* row 5 bottom */}
            {/*<Typography variant="h5" display="inline">&nbsp;&nbsp;&nbsp;&nbsp;Least Denials</Typography>*/}
            {denialAdjustmentBottom && <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustmentBottom.data.li_avoidable_by_payor, "payor", "denial_amount")} 
                    meta={denialAdjustmentBottom._meta.li_avoidable_by_payor} 
                    title={`Payors with least Denials`} subtitle="$ - Denial" indexBy="payor" keys={["denial_amount"]} colorBy="avoidable" />                 
                <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="dark2" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustmentBottom.data.li_avoidable_by_plan, "plan_type", "denial_amount")} 
                    meta={denialAdjustmentBottom._meta.li_avoidable_by_plan} 
                    title={`Plans with least Denials`} subtitle="$ - Denial" indexBy="plan_type" keyName="denial_amount" colorBy="avoidable" />
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="paired" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustmentBottom.data.li_avoidable_by_payor_plan, "payor_and_plan_type", "denial_amount")} 
                    meta={denialAdjustmentBottom._meta.li_avoidable_by_payor_plan} 
                    title={`Payors & Plans with least Denials`} subtitle="$ - Denial" indexBy="payor_and_plan_type" keys={["denial_amount"]} colorBy="avoidable" />
            </Box>}

            {/* row 6 bottom */}
            {denialAdjustmentBottom && <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustmentBottom.data.li_avoidable_by_physician, "physician", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_physician} 
                    title={`Physicians with least Denials`} subtitle="$ - Denial" indexBy="physician" keys={["denial_amount"]} colorBy="avoidable" />                 
                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustmentBottom.data.li_avoidable_by_provider, "provider", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_provider} 
                    title={`Providers with least Denials`} subtitle="$ - Denial" indexBy="provider" keys={["denial_amount"]} colorBy="avoidable" />                 
            </Box>}            
            {denialAdjustmentBottom && <Box className={classes.chartRow}>
                <PieChart type="half_donut" width={chartWidth * 1.5} height={chartHeight} scheme="paired" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustmentBottom.data.li_avoidable_by_cpt, "cpt_code", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_cpt} 
                    title={`CPT codes with least Denials`} subtitle="$ - Denial" indexBy="cpt_code" keyName="denial_amount" colorBy="avoidable" />
                <PieChart type="half_donut" width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(denialAdjustmentBottom.data.li_avoidable_by_icd, "icd_code", "denial_amount")} 
                    meta={denialAdjustment._meta.li_avoidable_by_icd} 
                    title={`ICD codes with least Denials`} subtitle="$ - Denial" indexBy="icd_code" keyName="denial_amount" colorBy="avoidable" />
            </Box>}            

          </Paper>
          </TabHandler.TabPanel>
          <TabHandler.TabPanel key={3} value={this.state.currentTab} index={3} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper}>

            {/* row 7 */}
            {/*<Typography variant="h5" display="inline">&nbsp;&nbsp;&nbsp;&nbsp;Adjustments</Typography>*/}
            <Box className={classes.chartRow}>
                <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="nivo" 
                    dataInput={denialAdjustment.data.li_adjustment_by_class}  
                    meta={denialAdjustment._meta.li_adjustment_by_class} 
                    title="Top Adjustments by class" subtitle="$ - Adjustment" indexBy="adj_class" keyName="adj_amount" />
                <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="dark2" 
                    dataInput={denialAdjustment.data.li_adjustment_by_category}  
                    meta={denialAdjustment._meta.li_adjustment_by_category} 
                    title="Top Adjustments by category" subtitle="$ - Adjustment" indexBy="category" keyName="adj_amount" />
                <PieChart type="half_donut" width={chartWidth * 1.25} height={chartHeight} scheme="paired" 
                    dataInput={denialAdjustment.data.li_adjustment_by_reason_class}  
                    meta={denialAdjustment._meta.li_adjustment_by_reason_class} 
                    title="Top Adjustments by reason class" subtitle="$ - Adjustment" indexBy="reason" keyName="adj_amount" />
            </Box>
            <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                    dataInput={denialAdjustment.data.li_adjustment_by_class_category_reason_class} 
                    meta={denialAdjustment._meta.li_adjustment_by_class_category_reason_class} 
                    title={`Top Adjustments by class, category and reason class`} subtitle="$ - Adjustment" indexBy="adj_class_category_and_reason" keys={["adj_amount"]} />                 
              <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                  dataInput={trend_li_paid_to_patient} 
                  meta={trend_li_paid_to_patient._meta} 
                  title="Patient Payment Trends" subtitle="$ - Patient Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  keyY1Axis="patient_paid_amount" labelForY1="Patient Paid Amount" />
            </Box>
            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth * 0.5} height={chartHeight} scheme="nivo" 
                      dataInput={denialAdjustment.data.li_adjustment_by_reason} 
                      meta={denialAdjustment._meta.li_adjustment_by_reason} 
                      title={`Top Adjustments by reason`} subtitle="$ - Adjustment" indexBy="reason" keys={["adj_amount"]} />                 
              <PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                    dataInput={denialAdjustment.data.li_paid_to_patient_by_payor_plan}  
                    meta={denialAdjustment._meta.li_paid_to_patient_by_payor_plan} 
                    title={`Patient Payments by Payors`} subtitle="$ - Patient Paid" indexBy="payor_and_plan_type" keyName="patient_paid_amount" />
            </Box>
            

          </Paper>
          </TabHandler.TabPanel>

        </Container>
      );
    }
  
    }
    catch (err) {
      console.log(err);
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );  
    }  
  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(DenialAdjustmentDashboard)
