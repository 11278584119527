import React from "react";
import InputBase from "@material-ui/core/InputBase";
//import TextField from "@material-ui/core/TextField";
import SearchContainer from "../search/SearchContainer";
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
//import { colors } from "@material-ui/core";
//import { fade } from "@material-ui/core/styles";
//import { makeStyles } from '@material-ui/core/styles';

class SearchField extends React.Component {

    constructor(props) {
        super(props);
        //console.log("SearchField constructor called: props: ", props);
        this.state = {
            //passing context via props per https://stackoverflow.com/questions/56641669/how-to-use-react-context-api-with-getderivedstatefromprops
            requested: props.context.requested
        }

        //https://stackoverflow.com/questions/38093760/how-to-access-a-dom-element-in-react-what-is-the-equilvalent-of-document-getele
        this.myRef = React.createRef();

        /*
        this.classes = {
            ...this.props,
            ...astyle()
        };
        */        
    }

    //update state if incoming props (including context!) changed
    componentDidUpdate(prevProps) {
        if (this.props.context.requested !== prevProps.context.requested) {
            //console.log("componentDidUpdate UPDATING! from: ", prevProps.context.requested, "to: ", this.props.context.requested);
            this.setState({
                requested: this.props.context.requested
            });
        }
    }    

    showPopoverOnEvent(event){
        if (this.props.context.parent === "appbar") {
            //find co-ords and send it to SearchContainer
            //console.log("onFocus event: ", event);
            const element = this.myRef.current;
            //https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
            var clientRect = element.getBoundingClientRect();
            let coords = ({left: clientRect.left + document.body.scrollLeft,
                    top: clientRect.top + document.body.scrollTop});
            //console.log("coords: ", coords);                                
            SearchContainer.show(coords);    
        }
    }

    render() {
        const {classes} = this.props;
        //console.log("classes: ", classes);        
        //console.log("SearchField render called");
        //marginLeft: 10, marginRight: 10, 

        //let classes = this.classes;

        //disabled autocomplete for 
        //(1) showing popover on focus without google introducing its autocomplete 
        //- https://stackoverflow.com/questions/37503656/react-doesnt-render-autocomplete-off
        //- https://stackoverflow.com/questions/47775041/disable-autofill-in-chrome-63/47822599
        //- https://stackoverflow.com/questions/50604671/programmatically-disabling-chrome-auto-fill
        //https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
        //(2) google introducing white bg in search input text - see onFocus
        return (
            <div style={{"display": "flex", "justifyContent": "center", "alignItems": "center"}}>
                {this.props.context.parent === "appbar" && <React.Fragment>&nbsp;<SearchIcon />&nbsp;</React.Fragment>}
                
                {/*
                https://github.com/mui-org/material-ui/issues/18334
                https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete 
                https://github.com/mui-org/material-ui/issues/2653
                TODO: upgrade? https://stackoverflow.com/questions/62110017/material-ui-autocomplete-custom-renderinput
                https://stackoverflow.com/questions/56122219/in-material-ui-when-do-we-use-input-vs-textfield-for-building-a-form 
                https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
                <TextField renderInput={params => (
                */}
                    <InputBase 
                        ref={this.myRef}
                        autoComplete="new-password"//"off"
                        placeholder="Type your search criteria and press Enter key..."
                        classes={{ root: classes.inputRoot, input: classes.inputInput }}
                        style={{ 
                            backgroundColor: "grey", 
                            paddingLeft: 10, paddingRight: 10, 
                            paddingTop: 4, paddingBottom: 2,
                            borderTopLeftRadius: 3, borderTopRightRadius: 3,
                            borderBottomLeftRadius: 3, borderBottomRightRadius: 3,
                            //width: (this.props.context.width && (this.props.context.width > 0)) ? this.props.context.width : "50vw", 
                            width: this.props.context.width ? this.props.context.width : "100%"
                        }}
                        inputProps={{ 
                            "aria-label": "search",
                            //autoComplete: 'new-password',//'off',
                            form: {
                                //autoComplete: 'new-password',//'off',
                            },
                        }}
                        onFocus={(event) => {
                            //white background on focus for textinput for autocomplete is chrome issue
                            //https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
                            this.showPopoverOnEvent(event);
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                this.props.context.setRequested(event.target.value);
                                this.showPopoverOnEvent(event);
                            }
                        }}
                        onChange={(event) => {
                            this.setState({requested: event.target.value});
                            if (!event.target.value || event.target.value.trim()==="") {
                                console.log("onChange: event.target.value: ", event.target.value);
                                this.props.context.setRequested(event.target.value);
                                this.showPopoverOnEvent(event);
                            }
                        }}
                        name="requested"
                        value={this.state.requested}
                    />                
                {/*)} />*/}
            </div>


        );
    }    
}

/*
const astyle = makeStyles((theme) => ({
    search: {
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
    },    
}));
*/

export default withStyles({withTheme: true})(SearchField)
