import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
//import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import "../../App.css";
import Card from "../widgets/Card";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from "../widgets/charts/nivo/PieChart";
//import ObservationsView from '../widgets/ObservationsView';
import StickyTitle from '../widgets/Stickytitle';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";


class SubmissionDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();
    let defaultDateRangeFilter = {
      filterName: "last_3_months",//year_to_date, last_four_quarters, all_data
      label: "Last 3 months",
      durationBy: "week"
    };
    this.state = {
      ...newState,
      dateRangeFilter: defaultDateRangeFilter,
      prevDateRangeFilter: defaultDateRangeFilter,
    };
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    console.log("getData this.state: ", this.state);
    let durationBy = this.state.dateRangeFilter.durationBy;
    console.log("getData durationBy: ", durationBy);

    //https://github.com/ArtissLST/customer-ui/issues/10#issuecomment-720486696
    let requestDataForSubmission = {
      "table_slugs": [
        //row 1:
        "li_submission_summary",
        "re_submission_summary",

        //row 2:
        "li_charge_amount_by_payor",
        "li_charge_amount_by_payor_plan",
        "li_charge_amount_by_plan",
        "li_charge_amount_by_plan_by_" + durationBy,//month/week//quarter

        //row 3:
        "paper_vs_electronic_submissions",
        "submissions_vs_responses",

        //row 4:
        "li_charge_amount_and_count_by_submission_age",
        "li_charge_amount_and_count_by_submission_age_by_payor",
        "li_charge_amount_and_count_by_submission_age_by_cpt",
        "li_charge_amount_and_count_by_" + durationBy,//month/week//quarter

        //row 5:
        "re_submission_attempts",
        "re_submission_charge_amount_by_age",
        "re_submission_charge_amount_and_count_by_" + durationBy,//month/week//quarter

        //row 6: //COUNT, NOT $ -  TBD
        "charge_observation_summary",
        "charge_observations",
        //"charge_observation_pattern_details",
        //TODO: (CALL pattern on tooltip)
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };

    let requestDataForSubmissionBottom = {
      "table_slugs": [
        //nothing for now
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"bottom","limit":10}
      }      
    }

    //this.setState({isLoadingCount: 2});
    this.setState({isLoadingCount: 1});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForSubmission, authtoken: authtoken, callback: this.updateResponseForSubmission});
    //APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForSubmissionBottom, authtoken: authtoken, callback: this.updateResponseForSubmissionBottom});
  }  

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
    }));
  };

  updateResponseForSubmission = (response) => {
    console.log("updateResponseForSubmission: ", response);
    this.setState({submission: response});
    this.updateLoadingDone();
  }

  updateResponseForSubmissionBottom = (submissionBottom) => {
    console.log("updateResponseForSubmissionBottom: ", submissionBottom);
    this.setState({submissionBottom: submissionBottom});
    this.updateLoadingDone();
  }

  onChangeDateRangeFilter = (selectedFilter) => {
    console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);

    //use approp slugs based on date range criteria
    let durationBy = "";
    //switch (this.state.dateRangeFilter.filterName) {
    switch (selectedFilter.daterange.literal) {
      case "last_3_months": durationBy = "week"; break;
      case "year_to_date": durationBy = "month"; break;
      case "last_4_quarters": durationBy = "quarter"; break;
      case "all_data": durationBy = "quarter"; break;
      default: durationBy = "month"; break;//set default to month as "safe" default
    }  

    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext,
        durationBy: durationBy
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };

  render() {
    try{
    const {classes} = this.props

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };    

    let num1 = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let submission = this.state.submission;
    let submissionBottom = this.state.submissionBottom;

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;
    let dateRangeFilter = this.state.dateRangeFilter;

    if (!submission || (submission && submission.status!=="success")) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='detailed_dashboard' 
          title={(dateRangeFilter && dateRangeFilter.label) ? "Submission Dashboard: " + dateRangeFilter.label : "Submission Dashboard"} 
          callbackForDateRange={this.onChangeDateRangeFilter} 
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(submission && submission.status!=="success") && <div>Error loading submission data</div>}
      </Container>      
    );
    else {
      console.log("submission: ", submission);
      console.log("submissionBottom: ", submissionBottom);
      console.log("this.state: ", this.state);
      console.log("this.props: ", this.props);

      let dateRangeFilter;//the one that we GOT from the latest response - or the current one while we're GETTING the next response
      if (submission.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
        dateRangeFilter = this.state.dateRangeFilter;
      else if (submission.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
        dateRangeFilter = this.state.prevDateRangeFilter;
      console.log("dateRangeFilter: ", dateRangeFilter);

      //data processing:
      //row 2:
      submission.data.li_charge_amount_by_payor_plan = Utils.addGeneratedAttribute(submission.data.li_charge_amount_by_payor_plan, 
        ["payor", "plan_type"], "payor_and_plan_type");            
      let trend_li_charge_amount_by_plan = submission.data["li_charge_amount_by_plan_by_" + dateRangeFilter.durationBy];
      trend_li_charge_amount_by_plan._meta = submission._meta["li_charge_amount_by_plan_by_" + dateRangeFilter.durationBy];

      //row 4:
      //by age
      let li_charge_amount_and_count_by_submission_age_by_payor = submission.data.li_charge_amount_and_count_by_submission_age_by_payor;
      let keys_for_li_charge_amount_and_count_by_submission_age_by_payor = Utils.getUniqueKeyValueListFromJSONObjectArray(li_charge_amount_and_count_by_submission_age_by_payor, "payor");
      console.log("keys_for_li_charge_amount_and_count_by_submission_age_by_payor: ", keys_for_li_charge_amount_and_count_by_submission_age_by_payor);
      li_charge_amount_and_count_by_submission_age_by_payor = Utils.groupJSONObjectArrayByKeyAndRow(li_charge_amount_and_count_by_submission_age_by_payor, "age_bucket", "payor", "charge_amount");
      console.log("li_charge_amount_and_count_by_submission_age_by_payor: ", li_charge_amount_and_count_by_submission_age_by_payor);

      //by CPT:
      let li_charge_amount_and_count_by_submission_age_by_cpt = submission.data.li_charge_amount_and_count_by_submission_age_by_cpt;
      let keys_for_li_charge_amount_and_count_by_submission_age_by_cpt = Utils.getUniqueKeyValueListFromJSONObjectArray(li_charge_amount_and_count_by_submission_age_by_cpt, "cpt_code");
      console.log("keys_for_li_charge_amount_and_count_by_submission_age_by_cpt: ", keys_for_li_charge_amount_and_count_by_submission_age_by_cpt);
      li_charge_amount_and_count_by_submission_age_by_cpt = Utils.groupJSONObjectArrayByKeyAndRow(li_charge_amount_and_count_by_submission_age_by_cpt, "age_bucket", "cpt_code", "charge_amount");
      console.log("li_charge_amount_and_count_by_submission_age_by_cpt: ", li_charge_amount_and_count_by_submission_age_by_cpt);
      
      let trend_li_charge_amount_and_count = submission.data["li_charge_amount_and_count_by_" + dateRangeFilter.durationBy];
      trend_li_charge_amount_and_count._meta = submission._meta["li_charge_amount_and_count_by_" + dateRangeFilter.durationBy];
      
      //row 5:
      let re_submission_attempts = [
        {count: submission.data.re_submission_attempts[0].attempts_2, attempts: "2 Attempts"},
        {count: submission.data.re_submission_attempts[0].attempts_3, attempts: "3 Attempts"},
        {count: submission.data.re_submission_attempts[0].attempts_3_to_5, attempts: "4-5 Attempts"},
        {count: submission.data.re_submission_attempts[0].attempts_gt_5, attempts: ">5 Attempts"},        
      ];
      console.log("re_submission_attempts: ", re_submission_attempts);
      let trend_re_submission_charge_amount_and_count = submission.data["re_submission_charge_amount_and_count_by_" + dateRangeFilter.durationBy];
      trend_re_submission_charge_amount_and_count._meta = submission._meta["re_submission_charge_amount_and_count_by_" + dateRangeFilter.durationBy];

      let periodDisplay = submission.dates.period_display;

      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='detailed_dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 
              serverdaterange={periodDisplay}
              title={"Submission Dashboard"} 
              //`Based on processing of ${submission.data.li_submission_summary[0].li_charge_count.toLocaleString('en-US', num1)} Submissions`
              subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""} 
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
              <Tab label="Summary" {...TabHandler.a11yProps(0)} />
              <Tab label="Workflow" {...TabHandler.a11yProps(1)} />
              <Tab label="Observations" {...TabHandler.a11yProps(2)} />
            </Tabs>
          </AppBar>

          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper} p={0} m={0}>      
            <Box display="flex" flexDirection="row" justifyContent="flex-start" p={1} m={0} bgcolor="bluegrey.300">
              {/* row 1 */}          
              <Card title="Submissions" value={submission.data.li_submission_summary[0].charge_amount.toLocaleString('en-US', cs1)} 
                dataInput={submission.data.li_submission_summary} meta={submission._meta.li_submission_summary} 
                subtext={`From ${submission.data.li_submission_summary[0].li_charge_count.toLocaleString('en-US', num1)} Submissions`} 
              />
              <Card title="Re-submissions" value={submission.data.re_submission_summary[0].resub_charge_amount.toLocaleString('en-US', cs1)} 
                dataInput={submission.data.re_submission_summary} meta={submission._meta.re_submission_summary} 
                subtext={`From ${submission.data.re_submission_summary[0].resub_submission_count.toLocaleString('en-US', num1)} Re-submissions of ${submission.data.re_submission_summary[0].submission_count.toLocaleString('en-US', num1)} Submissions`} 
                subtext2={`${submission.data.re_submission_summary[0].resub_submission_count_percent}% by count`} 
              />
              {/* ${submission.data.re_submission_summary[0].resub_charge_amount_percent}% by amount,  */}
              {/* row 3 */}          
              <Card title="Paper Submissions" value={submission.data.paper_vs_electronic_submissions[0].paper_charge_amount.toLocaleString('en-US', cs1)} 
                dataInput={submission.data.paper_vs_electronic_submissions} meta={submission._meta.paper_vs_electronic_submissions} 
                subtext={`From ${submission.data.paper_vs_electronic_submissions[0].paper_submission_count.toLocaleString('en-US', num1)} paper submissions of ${submission.data.paper_vs_electronic_submissions[0].submission_count.toLocaleString('en-US', num1)} Submissions`} 
                subtext2={`${submission.data.paper_vs_electronic_submissions[0].paper_charge_amount_percent}% by amount, ${submission.data.paper_vs_electronic_submissions[0].paper_charge_count_percent}% by count`} 
              />
              <Card title="Submissions vs Responses" value={`Response: ${submission.data.submissions_vs_responses[0].response_count_percent.toLocaleString('en-US', num1)}%`} 
                dataInput={submission.data.submissions_vs_responses} meta={submission._meta.submissions_vs_responses} 
                subtext={`No response: ${submission.data.submissions_vs_responses[0].no_response_count_percent.toLocaleString('en-US', num1)}%`} 
                subtext2={`Under process: ${submission.data.submissions_vs_responses[0].under_process_count_percent.toLocaleString('en-US', num1)}%`} 
              />

              {/*<div style={{paddingLeft: 100}}></div>*/}
            </Box>

            {/* row 2 */}          
            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={submission.data.li_charge_amount_by_payor} meta={submission._meta.li_charge_amount_by_payor} 
                title={`Top Payors`} subtitle="$ - Charge" indexBy="payor" keys={["charge_amount"]} /> 
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={submission.data.li_charge_amount_by_payor_plan} meta={submission._meta.li_charge_amount_by_payor_plan} 
                title={`Top Payors & Plans`} subtitle="$ - Charge" indexBy="payor_and_plan_type" keys={["charge_amount"]} /> 
              <PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="paired" 
                dataInput={submission.data.li_charge_amount_by_plan}  meta={submission._meta.li_charge_amount_by_plan} 
                title="Plans" subtitle="$ - Charge" indexBy="plan_type" keyName="charge_amount" />
            </Box>

            <Box className={classes.chartRow}>
              {/* row 4A */}   
              <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                  dataInput={trend_li_charge_amount_and_count} meta={trend_li_charge_amount_and_count._meta} 
                  title="Submission Trends" subtitle="$ and # - Charge" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  keyY1Axis="charge_count" labelForY1="Charge Count" 
                  keyY2Axis="charge_amount" labelForY2="Charge Amount" 
                />
              {/* row 2A */}   
              <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                  dataInput={trend_li_charge_amount_by_plan} meta={trend_li_charge_amount_by_plan._meta} 
                  title="Plan Trends" subtitle="$ - Charge" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  groupBy="plan_type" keyYAxis="charge_amount" labelForY="Charge Amount" />
            </Box>

            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            {/* row 4 */}          
            <Box className={classes.chartRow}>
              <PieChart type="full_pie" width={chartWidth} height={chartHeight} scheme="nivo" 
                  dataInput={submission.data.li_charge_amount_and_count_by_submission_age}  meta={submission._meta.li_charge_amount_and_count_by_submission_age} 
                  title="Submission Age" subtitle="$ - Charge" indexBy="age_bucket" keyName="charge_amount" />
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" groupMode="stacked"
                dataInput={li_charge_amount_and_count_by_submission_age_by_payor} meta={submission._meta.li_charge_amount_and_count_by_submission_age_by_payor} reverseData={false} 
                title="Top Payors by Submission Age" subtitle="$ - Charge" indexBy="age_bucket"  
                keys={keys_for_li_charge_amount_and_count_by_submission_age_by_payor} legends={false}
              />
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" groupMode="stacked"
                dataInput={li_charge_amount_and_count_by_submission_age_by_cpt} meta={submission._meta.li_charge_amount_and_count_by_submission_age_by_cpt} reverseData={false} 
                title="Top CPTs by Submission Age" subtitle="$ - Charge" indexBy="age_bucket"  
                keys={keys_for_li_charge_amount_and_count_by_submission_age_by_cpt} legends={false}
              />
            </Box>

            {/* row 5 */}    
            <Box className={classes.chartRow}>
              <PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                  dataInput={re_submission_attempts}  subtitle="#" meta={submission._meta.re_submission_attempts} 
                  title="Re-submission attempts" indexBy="attempts" keyName="count" />
              <PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                  dataInput={submission.data.re_submission_charge_amount_by_age}  meta={submission._meta.re_submission_charge_amount_by_age} 
                  title="Re-submission amount by age" subtitle="$ - Charge" indexBy="age" keyName="charge_amount" />
              <LineChart width={chartWidth * 2} height={chartHeight} scheme="dark2" 
                  dataInput={trend_re_submission_charge_amount_and_count} meta={trend_re_submission_charge_amount_and_count._meta} 
                  title="Re-submission Trend" subtitle="$ - Charge" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                  keyY1Axis="resub_charge_amount" labelForY1="Re-submission Charge Amount" />
            </Box>

            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={2} value={this.state.currentTab} index={2} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            {/* row 6 */}    
            <Box className={classes.chartRow}>
              <PieChart type="full_pie" width={chartWidth * 0.9} height={chartHeight} scheme="nivo" 
                  dataInput={Utils.groupJSONObjectArrayByKeyAndSum(submission.data.charge_observation_summary, "category", "occurrence")} meta={submission._meta.charge_observation_summary} 
                  slugData={submission.data.charge_observation_summary} 
                  title="Observation categories" subtitle="#" indexBy="category" keyName="occurrence" />
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="paired" 
                dataInput={Utils.filterJSONObjectArray(submission.data.charge_observations, "category", "Anomaly")} meta={submission._meta.charge_observations} reverseData={false} 
                slugData={submission.data.charge_observations} 
                title="Top Anomalies" subtitle="#" indexBy="title"  keys={["occurrence"]} />
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="dark2" 
                dataInput={Utils.filterJSONObjectArray(submission.data.charge_observations, "category", "Threshold")} meta={submission._meta.charge_observations} reverseData={false} 
                slugData={submission.data.charge_observations} 
                title="Top Thresholds" subtitle="#" indexBy="title"  keys={["occurrence"]} />
            </Box>
            <Box className={classes.chartRow}>
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="paired" 
                dataInput={Utils.filterJSONObjectArray(submission.data.charge_observations, "category", "Warning")} meta={submission._meta.charge_observations} reverseData={false} 
                slugData={submission.data.charge_observations} 
                title="Top Warnings" subtitle="#" indexBy="title"  keys={["occurrence"]} />
              <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                dataInput={Utils.filterJSONObjectArray(submission.data.charge_observations, "category", "Review")} meta={submission._meta.charge_observations} reverseData={false} 
                slugData={submission.data.charge_observations} 
                title="Top Reviews" subtitle="#" indexBy="title"  keys={["occurrence"]} />
            </Box>

            </Paper>
            </TabHandler.TabPanel>
          </Container>
        );
      }
    }
    catch (err) {
      console.log(err);
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );  
    }  
  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(SubmissionDashboard)
