import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
//import AccountCircleIcon from '@material-ui/icons/AccountCircle'
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
//import Typography from '@material-ui/core/Typography';
//import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
//import AccountCircle from '@material-ui/icons/AccountCircle';
//import Switch from '@material-ui/core/Switch';
//import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import SliderSwitch from '@material-ui/core/Switch'
//import FormControlLabel from '@material-ui/core/FormControlLabel'

import * as colors from '../Colours'

class ProfileMenu extends React.Component {
    constructor(props) {    
        super(props);
        //console.log("EULAView constructor called");

        this.state = {
            anchorEl: null,
        };
    }

    /*
    handleChange(event) {
      setAuth(event.target.checked);
    };
    */
  
    handleMenu = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };
  
    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleThemeChange = () => {
        console.log("handleThemeChange called: props: ", this.props);
        if (this.props.handleThemeChange) this.props.handleThemeChange();
        this.handleClose();
    }

    logout = () => {
        console.log("logging out user");
        this.props.user.logout();
    }
        
    render() {
        let open = Boolean(this.state.anchorEl);
        let classes = astyle();

        return (
        <div>
            <Button
                aria-label="Profile"
                aria-controls="menu-appbar-profile"
                aria-haspopup="true"
                onClick={this.handleMenu}
                //color="white"
                style={{marginRight: 0}}
                //className={classes.menuButton}
            >
                <span style={{fontSize: 'small'/*, color: "white"*/}}> {this.props.user.name} <ArrowDropDownIcon /></span>
            </Button>
            <Menu
                id="menu-appbar-profile"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={open}
                onClose={this.handleClose}   
                //MenuListProps={{ disablePadding: true, dense: true }}
                MenuListProps={{ dense: true }}                
            >
                <div style={{ backgroundColor: colors.artissdark_lighter }}>
                    <Button onClick={this.handleClose} style={{width: "100%"}}>
                        <span style={{fontSize: 'small'/*, color: "white"*/}}> {this.props.user.name} <ArrowDropUpIcon /></span>
                    </Button>
                    {/*alignment issue! https://github.com/mui-org/material-ui/issues/11016*/}
                    <MenuItem dense={true} p={0} m={0} onClick={this.handleThemeChange} style={{ fontSize: 'small'/*, color: "white"*/ }}>Theme
                        <SliderSwitch size="small" checked={this.props.darktheme} onChange={this.handleThemeChange} />
                    </MenuItem>
                    <MenuItem dense={true} p={0} m={0} onClick={this.logout} style={{ fontSize: 'small'/*, color: "white"*/ }}>Sign out</MenuItem>
                </div>
            </Menu>
        </div>    
        );
    }
}

const astyle = (theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        //marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },    
    //specific:
    detail: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
    },
    usertext: {
        fontSize: 'small'
    },
})

export default withStyles(astyle)(ProfileMenu);