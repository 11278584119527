// RevenueText.js
// copyright @artiss.co

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import * as utils from '../../services/Utils'


const changeTrendIcon = (dir) => {
    if(dir === 'up') {
        return <TrendingUpIcon style={{color:'#76ff03'}} fontSize='default'/>
    } else if(dir === 'down') {
        return <TrendingDownIcon style={{color:'red'}} fontSize='default' />
    } else {
        return <TrendingFlatIcon />
    }
} 

const directionMsg = (rd, ent) => {

    //console.log("direction --", rd)
    const classes = astyle();
    let mnth = utils.monYearStr(rd.current_vs_previous.compare_period.start)
    let periodtype = rd.current_vs_previous.compare_period.period_type
    let dm1 = ''; let dm2 = ''; let dm3 = ''
    let m = rd.current_vs_previous
    //let n = rd.current_vs_previous_year
    if(m.compare_change_direction === 'up') {
        dm1 = ent + ' this ' + periodtype + ' is '
        dm2 = 'compared to ' + m.compare_period.relative_to + ' ' + m.compare_period.period_type 
                + ' revenue of ' + utils.showUSD_f2(Math.abs(m.measurement_amount)) + '.'

        dm3 = 'The change is ' + utils.showPercent_f2(Math.abs(m.compare_change_amount_percent)/100) 
                + '(' + utils.showUSD_f1(Math.abs(m.compare_change_amount)) + ')'

    } else if(m.compare_change_direction === 'down') {
        dm1 = ent + ' this ' + periodtype + ' is '
        dm2 = 'compared to ' + m.compare_period.relative_to + ' ' + m.compare_period.period_type 
                + ' revenue of ' + utils.showUSD_f2(Math.abs(m.measurement_amount)) + '.'
        dm3 = 'The change is ' + utils.showPercent_f2(Math.abs(m.compare_change_amount_percent)/100) 
                + '(' + utils.showUSD_f1(Math.abs(m.compare_change_amount)) + ')'
    }
    return (
        <>
        <div className={classes.dm} />
        <Typography display='inline' variant="subtitle1" > 
        {dm1}
        {changeTrendIcon(m.compare_change_direction)}
        {dm2} {dm3}
        </Typography>
        </>
    )
}

// analyze changemaker and return key phrases 
// cm is []
const cmMsg = (cm, dir) => {
    //console.log('cmMsg +++', cm)
    if(cm.length === 0) 
        return null
    let entries = cm.length
    let cm_percent = 0; let cm_val = 0;
    for(let i=0; i<cm.length; i++) {
        cm_percent += cm[i].change_amount_percent
        cm_val += cm[i].change_amount
    }
    if( cm_percent < 0.8) {
        return null
    }
    let tag1 = ''
    let names = []
    if(dir === 'up') {
        if(cm_percent < 3) {
            tag1 = 'slight increase'
        } else if(cm_percent < 8) {
            tag1 = 'increase'
        } else if(cm_percent < 20) {
            tag1 = 'healthy increase'
            for(let i=0; i<cm.length; i++) {
                names.push(cm[i].name)
            }
        } else {
            tag1 = 'spike'
            for(let i=0; i<cm.length; i++) {
                names.push(cm[i].name)
            }
        }
    } else if(dir === 'down') {
        if(cm_percent < 3) {
            tag1 = 'slight decrease'
        } else if(cm_percent < 8) {
            tag1 = 'decrease'
        } else if(cm_percent < 20) {
            tag1 = 'modest decrease'
        } else {
            tag1 = 'sharp decline'
        }
    }
    let total_percent = cm_percent
    let total_val = cm_val
    //console.log( 'cmMsg --', tag1, total_percent, total_val )
    return {tag: tag1, total_percent: total_percent, total_val: total_val, names: names}
}

const cmMsg_serviceclass = (ad, dir) => {
    const classes = astyle();
    
    let ccngp = Math.abs(ad.compare_change_amount_percent)
    // if dir is UP, API returns the top +ve impact in change_makers_top
    // if dir is DOWN, API returns the top -ve impact in change_makers_bottom
    let cm = null
    if(dir === 'up') {
        cm = ad.change_makers_top.service_class
    } else if(dir === 'down') {
        cm = ad.change_makers_bottom.service_class
    } else {
        console.log("change maker direction NOT RECOGNIZED",dir)
        cm = []      // set it to empty , so does not bomb
    }
    //let cm = ad.change_makers_top.service_class
    //console.log('cmMsg serviceclass +++', ccngp)
    
    let tag1 = ''; let names = []
    if(cm.length === 0 || Math.abs(ccngp) < 5)
        {return ''}
    let cm_percent = 0; let cm_amount = 0; let cm_count_totalpercent = 0; let cm_count_total = 0
    for(let i=0; i<cm.length; i++) {
        if(dir === 'down' && cm[i].change_amount_percent < 0) {
            cm_percent += cm[i].change_amount_percent
            cm_amount += cm[i].change_amount
            names.push({name: cm[i].name, amt: cm[i].change_amount})
        } else if(dir === 'up' && cm[i].change_amount_percent > 0) {
            cm_percent += cm[i].change_amount_percent
            cm_amount += cm[i].change_amount
            names.push({name: cm[i].name, amt: cm[i].change_amount})
        }
        cm_count_totalpercent += cm[i].change_count_percent
        cm_count_total += cm[i].change_count   
    }
    names.sort((f,s) => {if(f.amt < s.amt) {return 1}
                         else {return -1}})
    //console.log( 'names===', names)
    let change_count_msg = ''
    if(cm_count_totalpercent === 0) {change_count_msg = 'was flat'}
    else if(cm_count_totalpercent < 0) {change_count_msg = 'went down'}
    else {change_count_msg = 'went up'}
    
    let msg = ''
    if(dir === 'up') {
        if(ccngp < 3) {
            tag1 = 'slight increase'
        } else if(ccngp < 8) {
            tag1 = 'increase'
        } else if(ccngp < 20) {
            tag1 = 'healthy increase'  
        } else {
            tag1 = 'spike'
        }
        return(
            <>
            <Typography variant='subtitle1' display='inline'>
                {/* . Service class caused a <span className={classes.td1}> {tag1} </span> of ({utils.showUSD_f2(cm_amount)}) */}
                . Service class volumes  <span className={classes.td1}> {change_count_msg} </span> by {utils.showPercent_f2(Math.abs(ad.compare_change_count_percent)/100)}
            </Typography>
            {names.map((d,i, arr) => {
                if(i === 0) {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                        . Revenue increase came from <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))})
                    </Typography>
                    )
                } else if(i < arr.length-1) {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                            , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}) 
                        </Typography>
                    )
                } else {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                            , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}).
                        </Typography>
                    )
                }
                
            })}
            </>
        )
        
    } else if(dir === 'down') {
        if(ccngp < 3) {
            tag1 = 'slight decrease'
        } else if(ccngp < 8) {
            tag1 = 'decrease'
        } else if(ccngp < 20) {
            tag1 = 'modest decrease'
        } else {
            tag1 = 'sharp decline'
        }
        return(
            <>
            <Typography variant='subtitle1' display='inline'>
                {/* . Service class caused a <span className={classes.td1}> {tag1} </span> of ({utils.showUSD_f2(cm_amount)}) */}
                . Service class volumes  <span className={classes.td1}> {change_count_msg} </span> by ({utils.showPercent_f2(Math.abs(ad.compare_change_count_percent)/100)})
            </Typography>
            {names.map((d,i, arr) => {
                if(i === 0) {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                        . Revenue decrease was mainly due to <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))})
                    </Typography>
                    )
                } else if(i < arr.length-1) {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                            , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}) 
                        </Typography>
                    )
                } else {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                            , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}).
                        </Typography>
                    )
                }
                
            })}
            </>
        )
    }
    //return msg
}

const cmMsg_exitentry = (ad) => {
    const classes = astyle();

    if(!Boolean(ad)) {
        return ''
    }
    //console.log("entry exit", ad)
    let period = ad.compare_period.period_type
    let dir = ad.compare_change_direction; let msg = ''
    if(dir === 'down')  {
        let ex = ad.new_exitors; 
        if(ex.physicians.length > 0) {
            let exphy = [...ex.physicians]
            exphy.sort((f,s) => {
                if(f.change_amount < s.change_amount) {return 1}
                else { return -1}
            })
            if(exphy.length > 2) {
                msg += 'Physicians missing this ' + period + ' are '
                msg += exphy[0].name + ', ' + exphy[1].name + ', ' + exphy[2].name
            }
            return(
                <>
                    {exphy.map((d,i, arr) => {
                        if(i === 0) {
                            return(
                                <Typography key={i} display='inline' variant='subtitle1'>
                                Physicians missing this {period} 
                            </Typography>
                            )
                        } else if(i < arr.length-1) {
                            return(
                                <Typography key={i} display='inline' variant='subtitle1'>
                                    , <span className={classes.td2}> {d.name} </span>  
                                </Typography>
                            )
                        } else {
                            return(
                                <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                                    , <span className={classes.td2}> {d.name} </span>.
                                </Typography>
                            )
                        }
                        
                    })}
                </>
            )
        } else 
            return null
    } else if(dir === 'up') {
        let ent = ad.new_entrants
        if(ent.physicians.length > 0) {
            let entphy = [...ent.physicians]
            entphy.sort((f,s) => {
                if(f.change_amount < s.change_amount) {return 1}
                else { return -1}
            })
            return(
                <>
                    {entphy.map((d,i, arr) => {
                        if(i === 0) {
                            return(
                                <Typography key={i} display='inline' variant='subtitle1'>
                                Physicians contributing to this {period} are
                            </Typography>
                            )
                        } else if(i < arr.length-1) {
                            return(
                                <Typography key={i} display='inline' variant='subtitle1'>
                                    , <span className={classes.td2}> {d.name} </span>  
                                </Typography>
                            )
                        } else {
                            return(
                                <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                                    , <span className={classes.td2}> {d.name} </span>.
                                </Typography>
                            )
                        }
                        
                    })}
                </>
            )
        } else {
            return null
        }
    }

    //return msg

}



const RevenueSummaryText = (props) => {
    const classes = astyle();
    let rd = props.data.data
    let c = rd.current_vs_previous
    //console.log( 'revenueSummary ----', props.data)
    let smsg = ''
    let rdcp = rd.current_vs_previous
    let mnth = utils.monYearStr(rd.current_period.start)
    if(rd.measure === 'revenue') {
        smsg = 'Revenue for ' + mnth + ' is ' + utils.showUSD_f2(rd.measurement_amount)
    }
    return(
        <Paper className={classes.pap}>
            <Typography variant="h6"> {smsg} </Typography>
            <Divider variant='fullWidth' light={true} />
            {directionMsg(rd, 'Revenue')}
            {cmMsg_serviceclass(c, c.compare_change_direction)}
            {cmMsg_exitentry(c)}
        </Paper>
    )
}


const astyle = makeStyles((theme) => ({
    pap: {
        margin: 15,
        padding: 10,
        width: '50%'
    },
    dm: {
        paddingTop: 6
    },
    td1: {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.secondary.main
    }, 
    td2: {
        color: theme.palette.secondary.main
    }
}));

export {RevenueSummaryText}
