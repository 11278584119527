// PayorPlanCombo.js
/// copyrights @ artiss.co

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TreeItem from '@material-ui/lab/TreeItem';

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveTreeMap } from '@nivo/treemap'
import * as colors from '../../Colours'
import flagit from '../../images/flagit.png'

import * as utils from '../../services/Utils'
import * as colours from '../../Colours'

//import {treedata} from './nivodata'

const PayorPlanCombinations = (props) => {
    const classes = astyle();
    let {combodata, type} = props      // li_payment_count_by_payor_and_plan_type
    console.log("combodata", combodata)
    let tmc = {}
    tmc = {name: 'PayoyPlan', children: [] }
    let carr = []; let maxi = 0; let currmax = 0; let ctbl = [];
    for(let i=0; i<combodata.length; i++) {
        let ppstr = ""
        if(type === "Payor Plan" || type === 'cpt') {
            ppstr = (i+1).toString() + '. ' + combodata[i].payor + ' - ' + combodata[i].plan_type
        } else if(type === "Plan") {
            ppstr = (i+1).toString() +  '. ' + combodata[i].plan_type
        }
        //let pval = ((combodata[i].payment_amount/combodata[i].charge_amount) * 100)
        let pval= combodata[i].payment_amount
        let sd = {name: ppstr, value: Math.floor(pval) }
        if(currmax < pval) {
            currmax = pval; maxi = i
        }
        carr.push(sd)
        let cval = combodata[i].charge_amount
        let ct = { ppstr, cval, pval }
        ctbl.push(ct)
    }
    console.log("ctbl len", ctbl.length)

    let topandbot = true; let ctbtbl = []    // table data
    if( ctbl.length > 5) {
        for(let j=0; j<3;j++) {
            ctbtbl.push(ctbl[j])
        }
        for(let k=ctbl.length-3;k<ctbl.length;k++) {
            ctbtbl.push(ctbl[k])
        }
    } else {   // length is less then 5, copy all of it
        topandbot = false
        for(let j=0; j<ctbl.length;j++) {
            ctbtbl.push(ctbl[j])
        }
    }
    console.log("ctb table ", ctbtbl)

    carr = carr.sort()
    let best1 = ""; let best2 = ""; let best3 = ""; let bot3 = ""; let bot2 = ""; let bot1 = ""
    if(carr.length > 3) {
         best1 = "1. " + carr[0].name
         best2 = "2. " + carr[1].name
         best3 = "3. " + carr[2].name
         bot3 = (carr.length-1).toString() + '. ' + carr[carr.length-1].name
         bot2 = (carr.length-2).toString() + '. ' + carr[carr.length-2].name
         bot1 = (carr.length-3).toString() + '. ' + carr[carr.length-3].name
    } else {
         best1 = Boolean(carr[0]) ? "1. " + carr[0].name : ""
         best2 = Boolean(carr[1]) ? "2. " + carr[1].name : ""
         best3 = Boolean(carr[2]) ? "3. " + carr[2].name : ""
    }
    tmc.children = carr
    console.log("tmc", tmc, carr)

    const uptoN = (str) => {
        let n = 55
        return (str.length > n) ? (str.substr(0, n-2) + '..') : (str + ' '.repeat(n-str.length))
    }

    let hmapheight = 150

    const Table3ColNoheader = (tbldata) => {
        const classes = astyle();
        let tabledata = tbldata

        console.log("PayorPlan tbl", tabledata)
    
        return(
            <TableContainer component={Paper} className={classes.servicebox2}>
            <Table className={classes.table} size="small" padding='checkbox' aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell> </TableCell>
                        <TableCell align="right">Charged</TableCell>
                        <TableCell align="right">Paid</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {tabledata.map((d,i) => (
                    <TableRow key={i}
                        className={(i<3) ? classes.toptext1 : classes.bottext1}> 
                    <TableCell component="th" scope="row" size='small' >
                        <span className={classes.tbltext1}> {d.ppstr} </span>
                    </TableCell>
                    <TableCell align="right" size='small'><span 
                        className={(i<3) ? classes.toptext1 : classes.bottext1}> {utils.showUSD_f2(d.cval)}</span></TableCell>
                    <TableCell align="right" size='small'><span 
                        className={(i<3) ? classes.toptext1 : classes.bottext1}> {utils.showUSD_f2(d.pval)}</span></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
           </TableContainer>
        )
    }

    let showtree = false
    
    return(

        <Paper className={classes.cardpaper} >
            {/* <img src={flagit} alt="breakout" className={classes.flagitimg} /> */}
            {Boolean(showtree) ? 
            <>
            <Box className={classes.combotxt}> 
{            <Typography variant="subtitle1" align="left" noWrap={true}> Revenue rank - {type} combos </Typography>
}            <Typography variant="caption" display="block" noWrap={true}> {uptoN(best1)} </Typography> 
            <Typography variant="caption" display="block" noWrap={true}> {uptoN(best2)} </Typography> 
            <Typography variant="caption" display="block" noWrap={true}> {uptoN(best3)} </Typography> 
            <Divider variant='middle'  />
            <Typography variant="caption" display="block" noWrap={true} whiteSpace='nowrap'> {uptoN(bot1)} </Typography> 
            <Typography variant="caption" display="block" noWrap={true}> {uptoN(bot2)} </Typography> 
            <Typography variant="caption" display="block" noWrap={true}> {uptoN(bot3)} </Typography>
            </Box>
            <ResponsiveTreeMap
                root={tmc}
                height={hmapheight}
                width={200}
                identity="name"
                value="value"
                innerPadding={3}
                outerPadding={3}
                margin={{ top: 10, right: 5, bottom: 10, left: 5 }}
                label="value"
                labelFormat=".0s"
                labelSkipSize={12}
                //labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
                labelTextColor={'black'}
                colors={{ scheme: 'nivo' }}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.3 ] ] }}
                animate={true}
                motionStiffness={90}
                motionDamping={11}
            />
            </>
            : 
            <>
                {Boolean(topandbot) ? 
                <Typography variant="subtitle1" align="left" noWrap={true}> Payor-Plan 
                    <span style={{backgroundColor: '#186A3B'}}> Top </span> & 
                    <span style={{backgroundColor: '#B7950B'}}> Bottom </span> 
                        3 rank</Typography>
                : 
                <Typography variant="subtitle1" align="left" noWrap={true}> Payor-Plan Ranking 
                </Typography>
                }
                {Table3ColNoheader(ctbtbl)}
            </>

            }

        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        //width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.primary.dark,
        padding: 5,
        margin: 10,
        //height: 150,
        //height: 200,
        aspectRatio: 2      // width/height
    },
    combotxt: {
        paddingLeft: 5,
    },
    flagitimg: {
        width: 25,
        height: 25
    },
    servicebox2: {
        backgroundColor: colors.primarylight,
        //flex: 2,
        padding: 5,
        marginLeft: 20,
        marginBottom: 4,
        //marginRight: 4,
        width: '95%'
        //height: '50%'
    },
    toptext1: {
        fontSize: 'small',
        //textDecoration: 'underline',
        textDecorationColor: 'white',
        backgroundColor: colours.green600
    },
    bottext1: {
        fontSize: 'small',
        //textDecoration: 'underline',
        textDecorationColor: 'yellow',
        backgroundColor: colours.orange800
        
    }
}));

export default PayorPlanCombinations;
