const baseAPIURL = "https://api.artiss.co/data/"

//https://reactjs.org/docs/faq-ajax.html
function callAPI(options) {
    let {method, callname, requestData, authtoken, callback} = options;
    console.log( "callAPI: auth token: ", authtoken);
    console.log( "callAPI: options: ", options);
    let url = baseAPIURL + callname;
    let header = 'Bearer ' + authtoken;
    let urlOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': header
        },
        body: (method==="post") ? JSON.stringify(requestData) : undefined
    };
    //console.log( "callAPI: url: ", url);
    //console.log( "callAPI: urlOptions: ", urlOptions);
    fetch(url, urlOptions)
    .then(res => {
        if(!res.ok) {
            console.log("callAPI return error: ", res);
            if (callback) callback({status: "failure", error: "result not ok", errorMessage: "result not ok", result: res});
        } 
        else {
            //console.log("callAPI response: ", res);
            return res.json();
        }
    }).then((dataReturned) => {
        //console.log("callAPI got data: ", dataReturned);
        if (callback) callback(dataReturned);//callback({status: "success", ...dataReturned});
    })
    .catch((e) => {
        console.log("callAPI error: ", e);
        console.log("callAPI error for options: ", options);
        if (callback) callback({status: "failure", error: e, errorMessage: e.message});
    })
}

function callPostAPI(options) {
    options.method = "post";
    callAPI(options);
}

function callGetAPI(options) {
    options.method = "get";
    callAPI(options);
}

async function callAPIAsync(options) {
    let {method, callname, requestData, authtoken} = options;
    //console.log( "callAPI: options: ", options);
    let url = baseAPIURL + callname;
    let header = 'Bearer ' + authtoken;
    let urlOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': header
        },
        body: (method==="post") ? JSON.stringify(requestData) : undefined
    };
    let response = await fetch(url, urlOptions)
    .then(res => res.json())
    .catch((e) => ({status: "failure", error: e, errorMessage: e.message}));
    return response;
}

async function callPostAPIAsync(options) {
    options.method = "post";
    return callAPIAsync(options);
}

async function callGetAPIAsync(options) {
    options.method = "get";
    return callAPI(callAPIAsync);
}

export { 
    callPostAPI, callGetAPI, 
    callPostAPIAsync, callGetAPIAsync
}