// Storyheader.js   - for CPT Story
// copyright @ artiss.co

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AccountBoxIcon from '@material-ui/icons/AccountBox';

import podium from '../../images/podium.svg'

const StoryHeader = (props) => {
    const classes = astyle();
    let {type, subtype, data} = props

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", 
            "Sep", "Oct", "Nov", "Dec"];
    let dt = new Date(data.since_date)
    let dtstr = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear()

    return(
        <>
            <Typography variant="body1">
                Since 
                <Typography variant="h6" display='inline'> {dtstr} </Typography>
                , service volume for {subtype} is <Typography variant="h6" display='inline'> {data.volume} </Typography>
                units. Out of the total volume from 
                <Typography variant="h6" display='inline'> {data.total_cpts} </Typography> 
                types of {type.toUpperCase()} codes, {subtype} accounts for
                <Typography variant="h6" display='inline'> {data.volume_percent}% </Typography>
                of the total volume at an Average Unit Price(AUP) of
                <Typography variant="h6" display='inline'> ${data.aup}</Typography>
                . {type.toUpperCase()} {subtype} accounted for 
                <Typography variant="h6" display='inline'> {data.charge_percent}% </Typography> 
                of the total charged amount,
                <Typography variant="h6" display='inline'> {data.allowed_percent}% </Typography>
                of the total allowed amount, and
                <Typography variant="h6" display='inline'> {data.paid_percent}% </Typography>
                of total paid amount.
            </Typography>
        </>
    )

}



const astyle = makeStyles((theme) => ({
    
    header: {
        width: '80%',
        display: 'flex',
        //flexDirection: 'column',
        //justifyContent: 'center',
        //alignItems: 'center',

    },
    titletxt: {
        fontSize: 'large'
    }
}));

export {StoryHeader }