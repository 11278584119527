import React from "react";

import Popover from "@material-ui/core/Popover";
import { Container, Box, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Visibility } from '@material-ui/icons';

import * as colors from '../../../Colours';
import ExpandedChartContext from './ExpandedChartContext';

class ExpandedChartPopover extends React.Component {
    constructor(props) {
        super(props);
        console.log("ExpandedChartPopover constructor props: ", props);
        this.state = {
            //show: props.displayExpandedChart,
            show: true,
        };
    }

    show = () => {
        this.setState({ show: true });
    }

    hide = () => {
        this.setState({ show: false });
    }

    handleClick = (event) => {
        //this.setState({anchorEl: event.currentTarget});
        this.show();
    };
    
    handleClose = () => {
        //this.setState({anchorEl: null});
        this.hide();
        if (this.props.closeExpandedChart) this.props.closeExpandedChart();
    };
    
    render() {
        //console.log("ExpandedChartButton show: ", this.state.show);

        const id = this.state.show ? 'simple-popover' : undefined;

        //const user = React.useContext(UserContext);

        return (
            <React.Fragment>  
                {this.state.show === true && <Popover 
                    id={id}
                    open={this.state.show}
                    //anchorEl={this.state.anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}
                    anchorOrigin={{vertical: 'top',horizontal: 'left',}}
                    onClose={this.handleClose}                   
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    //m={0} p={0}
                >
                    <Box style={{ minHeight: 'calc(100vh)', maxHeight: 'calc(100vh)', minWidth: 'calc(100vw)', maxWidth: 'calc(100vw)' }}>
                        {/*{this.props.title}&nbsp;
                        <Button style={{backgroundColor: colors.bluegrey600}} onClick={() => {this.handleClose()}}>&nbsp;Close&nbsp;</Button>
                        */}
                        <ExpandedChartContext 
                            {...this.props} 
                            //user={user} customer={customer} setCustomer={setCustomer} 
                            handleClose={this.handleClose}
                        />
                    </Box>
                </Popover>}         
            </React.Fragment> 
        );
    }
}

export default ExpandedChartPopover;
