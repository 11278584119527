// Cptdenials.js
/// copyrights @ artiss.co

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { ResponsiveBubble } from '@nivo/circle-packing'
import { ResponsiveBubbleCanvas } from '@nivo/circle-packing'
import { ResponsiveBar } from '@nivo/bar'
import * as colors from '../../Colours'
import { PermDeviceInformation } from '@material-ui/icons';


const PatientProfile = (props) => {
    const classes = astyle();
    let {patientdata} = props    

    let st = []; let stdict = null; let legendkey = []
    const getStatedict = (pst) => {
        for(let i=0; i<st.length; i++) {
            if(st[i].name === pst) { return st[i] }
        }
        let l = st.push({name: pst, val:0})
        legendkey.push(pst)
        return st[l-1]
    }

    let to18charge = 0; let to30charge = 0; let to50charge = 0; let to70charge = 0; let to90charge = 0; let m90charge = 0;
    let to18f = 0; let to18m = 0; let to30f = 0; let to30m = 0; let to50m = 0; let to50f = 0; 
    let to70m = 0; let to70f = 0; let to90m = 0; let to90f = 0; let m90m = 0; let m90f = 0;
    let to18cnt = 0; let to30cnt = 0; let to50cnt = 0; let to70cnt = 0; let to90cnt = 0; let m90cnt = 0;
    let subscriber = 0; let notsubscriber = 0;

    if(patientdata.length === 0) {
        return(
                <Paper className={classes.cardpaper} >
                    <Box className={classes.t1} >
                    <Typography variant="subtitle1" display='inline'> Paid by Patient Gender & Age group </Typography> 
                    <Typography variant="body" display='inline'> No data for this date range</Typography> 
                    </Box>
                </Paper>
                )

    } else {

        for(let i=0; i<patientdata.length; i++) {
            let pd = patientdata[i]
            if(pd.age_bucket === "< 18 years") 
                { to18cnt += pd.charge_count; to18charge += pd.charge_amount;
                    if(pd.patient_gender === "M") {  to18m += pd.charge_amount }
                    else { to18f += pd.charge_amount }
                }
            else if(pd.age_bucket === "19-30 years") 
                { to30cnt += pd.charge_count; to30charge += pd.charge_amount;
                    if(pd.patient_gender === "M") {  to30m += pd.charge_amount }
                    else { to30f += pd.charge_amount }
                }
            else if(pd.age_bucket === "31-50 years") 
                { to50cnt += pd.charge_count; to50charge += pd.charge_amount;
                    if(pd.patient_gender === "M") {  to50m += pd.charge_amount }
                    else { to50f += pd.charge_amount }
                }
            else if(pd.age_bucket === "51-70 years")
            { to70cnt += pd.charge_count; to70charge += pd.charge_amount;
                if(pd.patient_gender === "M") {  to70m += pd.charge_amount }
                else { to70f += pd.charge_amount }
            }
            else if(pd.age_bucket === "71-90 years") 
            { to90cnt += pd.charge_count; to90charge += pd.charge_amount;
                if(pd.patient_gender === "M") {  to90m += pd.charge_amount }
                else { to90f += pd.charge_amount }
            }
            else if(pd.age_bucket === ">91 years") 
            { m90cnt += pd.charge_count; m90charge += pd.charge_amount;
                if(pd.patient_gender === "M") {  m90m += pd.charge_amount }
                else { m90f += pd.charge_amount }
            }

            stdict = getStatedict(pd.patient_state)
            stdict.val += pd.charge_count

            if(Boolean(pd.patient_is_subscriber)) ++subscriber;
            else ++notsubscriber;

        } 

        let to18c = {name: "up to 18yrs", val: to18cnt, children: []}
        let to30c = {name: "18 to 30yrs", val: to30cnt, children: []}
        let to50c = {name: "30 to 50yrs", val: to50cnt, children: []}
        let to70c = {name: "50 to 70yrs", val: to70cnt, children: []}
        let to90c = {name: "70 to 90yrs", val: to90cnt, children: []}
        let m90c = {name: "more then 90yrs", val: m90cnt, children: []}
        to18c.children.push(...[{name:"M", val: to18m}, {name:"F", val:to18f}])
        to30c.children.push(...[{name:"M", val: to30m}, {name:"F", val:to30f}])
        to50c.children.push(...[{name:"M", val: to50m}, {name:"F", val:to50f}])
        to70c.children.push(...[{name:"M", val: to70m}, {name:"F", val:to70f}])
        to90c.children.push(...[{name:"M", val: to90m}, {name:"F", val:to90f}])
        m90c.children.push(...[{name:"M", val: m90m}, {name:"F", val:m90f}])
        let countroot = {name: 'count', children: [] }
        countroot.children.push(to18c, to30c, to50c, to70c, to90c, m90c)

        let stateroot = {name: 'states', children: [] }
        stateroot.children.push(...st)

        let chartdata = []
        let to18cb = {name: "up to 18yrs", "Male": to18m, "Female": to18f}
        let to30cb = {name: "18 to 30yrs", "Male": to30m, "Female": to30f}
        let to50cb = {name: "30 to 50yrs", "Male": to50m, "Female": to50f}
        let to70cb = {name: "50 to 70yrs", "Male": to70m, "Female": to70f}
        let to90cb = {name: "70 to 90yrs", "Male": to90m, "Female": to90f}
        let m90cb = {name: "90+ yrs", "Male": m90m, "Female": m90f}
        chartdata.push(to18cb, to30cb, to50cb, to70cb, to90cb, m90cb)
        
        console.log("patientprofile ", countroot, stateroot, chartdata)
            
        return(
            <Paper className={classes.cardpaper} >
                <Box className={classes.t1} >

                <Typography variant="subtitle1" display='inline'> Paid by Patient Gender & Age group </Typography> 
                <ResponsiveBar
                data={chartdata}
                keys={["Male", "Female"  ]}
                layout="horizontal"
                indexBy="name"
                groupMode={'grouped'}
                height={160}
                margin={{ top: 5, right: 80, bottom: 55, left: 70 }}
                //innerPadding={4}
                padding={0.2}
                innerPadding={1}
                colors={{ scheme: 'category10' }}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                axisTop={null}
                axisRight={null}
                //axisLeft={null}
                enableGridX={true}
                enableLabel={true}
                labelFormat={["$", ".2s"]}
                //labelFormat={{format: v => `${v}.2s`}}
                labelSkipWidth={15}
                labelSkipHeight={12}
                //labelTextColor={{ from: 'color' }}
                labelTextColor={'#000000'}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    },
                    grid: {
                        line: {
                        stroke: "DarkSlateGrey",
                        strokeWidth: 1,
                        //strokeDasharray: "4 4"
                        }
                    }
                
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -5,
                    //legend: 'Paid',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -40,
                    tickValues: 4,
                    format:"$.2s"
                    // legend: 'Age groups',
                    // legendPosition: 'middle',
                    // legendOffset: 0,
                    //format: v => v       // call function to format number to $ in k value
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        itemTextColor: '#ffffff',
                        anchor: 'top',
                        //anchor: 'bottom-left',
                        direction: 'row',
                        justify: false,
                        translateX: 65,
                        //translateY: -15,
                        translateY: -10,
                        itemsSpacing: 2,
                        itemWidth: 70,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                />  
                </Box>

                <Box className={classes.t1} >
                <Typography variant="subtitle1" display='inline'> Patient count by state</Typography> 
                <ResponsiveBubble
                    root={stateroot}
                    height={130}
                    width={100}
                    margin={{ top: 10, right: 4, bottom: 10, left: 4 }}
                    identity="name"
                    value="val"
                    colors={{ scheme: 'nivo' }}
                    padding={6}
                    labelTextColor={{ from: 'theme', modifiers: [ [ 'darker', 0.8 ] ] }}
                    borderWidth={2}
                    borderColor={{ from: 'color' }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={12}
                    theme={{ 
                        tooltip: {
                            container: {
                                background: '#333',
                            },
                        }
                    
                    }}
                />
                </Box>
                
            </Paper>
        )     
    }   // else   
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        //width: '80%',
        display: 'flex',
        //flexDirection: 'row',
        justifyContent: 'space-around',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 5,
        paddingLeft:5,
        margin: 10,
        height: 180
    },
    t1: {
        display: 'flex',
        flexDirection: 'column'
    }
    
    
}));

export default PatientProfile;
