import React from 'react';
import { Container, Box, Paper } from "@material-ui/core";
//import Popover from "@material-ui/core/Popover";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import "../../App.css";
import Card from "../widgets/Card";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from "../widgets/charts/nivo/PieChart";
//import ObservationsView from '../widgets/ObservationsView';
import StickyTitle from '../widgets/Stickytitle';

import * as APIHandler from '../services/APIHandler';
import * as Utils from '../services/Utils';

import * as DashboardHandler from "../dashboards/common/DashboardHandler";
import * as TabHandler from "../dashboards/common/TabHandler";


class PhysicianDashboard extends React.Component {
  constructor(props) {    
    super(props);

    var newState = DashboardHandler.getNewState();
    let defaultDateRangeFilter = {
      filterName: "last_3_months",//year_to_date, last_four_quarters, all_data
      label: "Last 3 months",
      durationBy: "week"
    };
    this.state = {
      ...newState,
      dateRangeFilter: defaultDateRangeFilter,
      prevDateRangeFilter: defaultDateRangeFilter,
    };
  }  

  componentDidMount() {
    this.getData();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }

  componentDidUpdate(prevProps,prevState) {
    let componentDidUpdateFlag = DashboardHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
    if (componentDidUpdateFlag) this.getData();        
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getData() {
    console.log("this.props: ", this.props);
    if (!this.props.user) return;
    let authtoken = this.props.user.idtoken;
    if (!authtoken) return;
    let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer);

    console.log("getData this.state: ", this.state);
    let durationBy = this.state.dateRangeFilter.durationBy;
    console.log("getData durationBy: ", durationBy);

    //https://github.com/ArtissLST/customer-ui/issues/10#issuecomment-720486696
    let requestDataForPhysician = {
      "table_slugs": [
        //row 1:
        "paid_by_physician",
        "paid_by_provider",
        "li_payment_count_by_cpt",
        "paid_by_business_age_and_physician",
        "paid_allowed_charged_summary_by_business_age",
        //row 2:
        "paid_by_physician_by_" + durationBy,//month/week//quarter
        "li_payment_count_by_icd",
        "li_payment_by_cpt_by_" + durationBy,
        "li_payment_cpt_icd_combo",
        //row 3:
        "paid_by_patient_profile"
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "new_biz": true,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"top","limit":10}
      }
    };

    let requestDataForPhysicianBottom = {
      "table_slugs": [
        "paid_by_physician",
        "paid_by_business_age_and_physician",
      ],
      "filter_criteria": {
        ...customer_filter_criteria,
        "new_biz": true,
        "transaction_date": {"literal":this.state.dateRangeFilter.filterName},
        "options":{"set":"bottom","limit":10}
      }      
    }

    this.setState({isLoadingCount: 2});
    //this.setState({isLoadingCount: 1});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForPhysician, authtoken: authtoken, callback: this.updateResponseForPhysician});
    APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForPhysicianBottom, authtoken: authtoken, callback: this.updateResponseForPhysicianBottom});
  }  

  updateLoadingDone = () => {
    this.setState((state) => ({
      isLoadingCount: state.isLoadingCount - 1,
    }));
  };

  updateResponseForPhysician = (response) => {
    console.log("updateResponseForPhysician: ", response);
    this.setState({physician: response});
    this.updateLoadingDone();
  }

  updateResponseForPhysicianBottom = (physicianBottom) => {
    console.log("updateResponseForPhysicianBottom: ", physicianBottom);
    this.setState({physicianBottom: physicianBottom});
    this.updateLoadingDone();
  }

  onChangeDateRangeFilter = (selectedFilter) => {
    console.log("onChangeDateRangeFilter: ", selectedFilter);
    let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);

    //use approp slugs based on date range criteria
    let durationBy = "";
    //switch (this.state.dateRangeFilter.filterName) {
    switch (selectedFilter.daterange.literal) {
      case "last_3_months": durationBy = "week"; break;
      case "year_to_date": durationBy = "month"; break;
      case "last_4_quarters": durationBy = "quarter"; break;
      case "all_data": durationBy = "quarter"; break;
      default: durationBy = "month"; break;//set default to month as "safe" default
    }  

    this.setState({
      dateRangeFilter: {
        filterName: selectedFilter.daterange.literal, 
        label: selectedFilter.datetext,
        durationBy: durationBy
      }, 
      prevDateRangeFilter: prevDateRangeFilter
    });
  }

  toggleBoolean = (state, booleanKey, event, ref) => {
    let value = state[booleanKey];
    this.setState({anchorEl: ref});
    this.setState({[booleanKey]: !value});
  };

  handleTabChange = (event, newValue) => {
    this.setState({currentTab: newValue});
  };
  
  render() {
    try{
    const {classes} = this.props

    let cs1 = {
      style: "currency",
      currency: "USD",
      minimumSignificantDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };    

    let num1 = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    };    

    let physician = this.state.physician;
    let physicianBottom = this.state.physicianBottom;

    let chartWidth=this.state.width*0.25;
    let chartHeight=this.state.height*0.25;
    let dateRangeFilter = this.state.dateRangeFilter;

    if (!physician || (physician && physician.status!=="success")) return (
      <Container maxWidth={false} disableGutters={false} className={classes.top} >
      <div className={classes.stickyhdr}>
        <StickyTitle 
          context='detailed_dashboard' 
          title={"Physician Dashboard"} 
          subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}          
          callbackForDateRange={this.onChangeDateRangeFilter} 
          showProgressBar={(this.state.isLoadingCount > 0)}
        />
      </div>
      {(physician && physician.status!=="success") && <div>Error loading physician data</div>}
      </Container>      
    );
    else {
      console.log("physician: ", physician);
      console.log("physicianBottom: ", physicianBottom);
      console.log("this.state: ", this.state);
      console.log("this.props: ", this.props);

      let dateRangeFilter;//the one that we GOT from the latest response - or the current one while we're GETTING the next response
      if (physician.args.filter_criteria.transaction_date.literal === this.state.dateRangeFilter.filterName) 
        dateRangeFilter = this.state.dateRangeFilter;
      else if (physician.args.filter_criteria.transaction_date.literal === this.state.prevDateRangeFilter.filterName) 
        dateRangeFilter = this.state.prevDateRangeFilter;
      console.log("dateRangeFilter: ", dateRangeFilter);

      //data processing:
      //row 2:
      let trend_paid_by_physician = physician.data["paid_by_physician_by_" + dateRangeFilter.durationBy];
      trend_paid_by_physician._meta = physician._meta["paid_by_physician_by_" + dateRangeFilter.durationBy];
      let trend_li_payment_by_cpt = physician.data["li_payment_by_cpt_by_" + dateRangeFilter.durationBy];
      trend_li_payment_by_cpt._meta = physician._meta["li_payment_by_cpt_by_" + dateRangeFilter.durationBy];
      physician.data.li_payment_cpt_icd_combo = Utils.addGeneratedAttribute(physician.data.li_payment_cpt_icd_combo, 
        ["cpt_code", "icd_code"], "cpt_and_icd_code");
      physician.data.paid_by_patient_profile = Utils.addGeneratedAttribute(physician.data.paid_by_patient_profile, 
        ["patient_state", "patient_gender", "age_bucket"], "patient_demographics");           
    
      let periodDisplay = physician.dates.period_display;

      return (      
        <Container maxWidth={false} disableGutters={false} className={classes.top} >
          <div className={classes.stickyhdr}>
            <StickyTitle 
              context='detailed_dashboard' 
              callbackForDateRange={this.onChangeDateRangeFilter} 
              serverdaterange={periodDisplay}
              title={"Physician Dashboard"} 
              subtitle={(dateRangeFilter && dateRangeFilter.label) ? dateRangeFilter.label : ""}          
              showProgressBar={(this.state.isLoadingCount > 0)}
            />
          </div>

          <AppBar position="static">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              aria-label="Search Results"
              className={classes.tabsRoot}
            >
              <Tab label="Physicians" {...TabHandler.a11yProps(0)} />
              <Tab label="Trends" {...TabHandler.a11yProps(1)} />
              <Tab label="Clinical Info & Demographics" {...TabHandler.a11yProps(2)} />
            </Tabs>
          </AppBar>

          <TabHandler.TabPanel key={0} value={this.state.currentTab} index={0} className={classes.tabPanel}>
          <Paper className={classes.sectionpaper} p={0} m={0}>      
            {/* row 1 */}       
            <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                    dataInput={physician.data.paid_by_physician} 
                    meta={physician._meta.paid_by_physician} 
                    title={`Top Physicians`} subtitle="$ - Paid" indexBy="physician" keys={["paid_amount"]} />                
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="dark2" 
                    dataInput={physician.data.paid_by_provider} 
                    meta={physician._meta.paid_by_provider} 
                    title={`Top Providers`} subtitle="$ - Paid" indexBy="provider" keys={["paid_amount"]} />                
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="dark2" 
                    //dataInput={Utils.filterJSONObjectArray(physician.data.paid_by_business_age_and_physician, "business_age", "New")} 
                    dataInput={physician.data.paid_by_business_age_and_physician} 
                    slugData={physician.data.paid_by_business_age_and_physician} 
                    meta={physician._meta.paid_by_business_age_and_physician} 
                    title={`Top New Physicians`} subtitle="$ - Paid" indexBy="physician" keys={["paid_amount"]} />                
            </Box>
            <Box className={classes.chartRow}>
                <BarChartHorizontal width={chartWidth} height={chartHeight} scheme="nivo" 
                    //dataInput={Utils.filterJSONObjectArray(physician.data.paid_allowed_charged_summary_by_business_age, "business_age", "New")} 
                    dataInput={physician.data.paid_allowed_charged_summary_by_business_age} 
                    slugData={physician.data.paid_allowed_charged_summary_by_business_age} 
                    meta={physician._meta.paid_allowed_charged_summary_by_business_age} 
                  title={`New Physicians Paid Comparison`} subtitle="$" indexBy="physician" keys={["paid_amount", "allowed_amount", "charge_amount"]} />
                {/*<PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                    dataInput={paid_allowed_charged_summary_by_business_age}  
                    slugData={physician.data.paid_allowed_charged_summary_by_business_age} 
                    meta={physician._meta.paid_allowed_charged_summary_by_business_age} 
                    title="New Physicians Paid Comparison" indexBy="type" keyName="amount" />*/}
                {physicianBottom && <BarChartHorizontal width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                    dataInput={physicianBottom.data.paid_by_physician} 
                    meta={physicianBottom._meta.paid_by_physician} 
                    title={`Bottom Physicians`} subtitle="$ - Paid" indexBy="physician" keys={["paid_amount"]} />}
                {physicianBottom && <BarChartHorizontal width={chartWidth * 0.75} height={chartHeight} scheme="dark2" 
                    //dataInput={Utils.filterJSONObjectArray(physicianBottom.data.paid_by_business_age_and_physician, "business_age", "New")} 
                    dataInput={physicianBottom.data.paid_by_business_age_and_physician} 
                    slugData={physicianBottom.data.paid_by_business_age_and_physician} 
                    meta={physicianBottom._meta.paid_by_business_age_and_physician} 
                    title={`Bottom New Physicians`} subtitle="$ - Paid" indexBy="physician" keys={["paid_amount"]} />}
            </Box>

            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={1} value={this.state.currentTab} index={1} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            {/* row 2 */}     
            <Box className={classes.chartRow}>
                <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="nivo" 
                    dataInput={trend_paid_by_physician} 
                    meta={trend_paid_by_physician._meta} 
                    title="Top Physician Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                    groupBy="physician" keyYAxis="paid_amount" labelForY="Paid Amount" />
                <LineChart width={chartWidth * 1.5} height={chartHeight} scheme="dark2" 
                    dataInput={trend_li_payment_by_cpt} 
                    meta={trend_li_payment_by_cpt._meta} 
                    title="Top CPT Trends" subtitle="$ - Paid" keyXAxis="interval" sequence="interval_order" filterName={dateRangeFilter.filterName} 
                    groupBy="cpt_code" keyYAxis="payment_amount" labelForY="Paid Amount" />
            </Box>  

            </Paper>
            </TabHandler.TabPanel>
            <TabHandler.TabPanel key={2} value={this.state.currentTab} index={2} className={classes.tabPanel}>
            <Paper className={classes.sectionpaper}>

            <Box className={classes.chartRow}>
                <PieChart type="half_donut" width={chartWidth} height={chartHeight} scheme="nivo" 
                    dataInput={physician.data.li_payment_count_by_cpt}  
                    meta={physician._meta.li_payment_count_by_cpt} 
                    title="Top CPT codes" subtitle="# - Paid" indexBy="cpt_code" keyName="payment_count" />
                <PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                    dataInput={physician.data.li_payment_count_by_icd}  
                    meta={physician._meta.li_payment_count_by_icd} 
                    title="Top ICD codes" subtitle="$ - Paid" indexBy="icd_code" keyName="payment_amount" />
                <BarChartHorizontal width={chartWidth * 0.75} height={chartHeight} scheme="dark2" 
                    dataInput={physician.data.li_payment_cpt_icd_combo} 
                    meta={physician._meta.li_payment_cpt_icd_combo} 
                    title={`Top CPT / ICD codes`} subtitle="$ - Paid" indexBy="cpt_and_icd_code" keys={["payment_amount"]} />               
            </Box>

            <Box className={classes.chartRow}>
                <PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(physician.data.paid_by_patient_profile, "age_bucket", "paid_amount")}
                    slugData={physician.data.paid_by_patient_profile} 
                    meta={physician._meta.paid_by_patient_profile} 
                    title="Patient Age" subtitle="$ - Paid" indexBy="age_bucket" keyName="paid_amount" />
                <PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="dark2" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(physician.data.paid_by_patient_profile, "patient_gender", "paid_amount")}
                    slugData={physician.data.paid_by_patient_profile} 
                    meta={physician._meta.paid_by_patient_profile} 
                    title="Patient Gender" subtitle="$ - Paid" indexBy="patient_gender" keyName="paid_amount" />
                <PieChart type="full_pie" width={chartWidth * 0.75} height={chartHeight} scheme="nivo" 
                    dataInput={Utils.groupJSONObjectArrayByKeyAndSum(physician.data.paid_by_patient_profile, "patient_state", "paid_amount")}
                    slugData={physician.data.paid_by_patient_profile} 
                    meta={physician._meta.paid_by_patient_profile} 
                    title="Patient State" subtitle="$ - Paid" indexBy="patient_state" keyName="paid_amount" />
                <BarChartHorizontal width={chartWidth * 0.75} height={chartHeight} scheme="dark2" 
                    dataInput={physician.data.paid_by_patient_profile} 
                    meta={physician._meta.paid_by_patient_profile} 
                    title={`Patient Demographics`} subtitle="$ - Paid" indexBy="patient_demographics" keys={["paid_amount"]} />               
            </Box>
          </Paper>
          </TabHandler.TabPanel>

        </Container>
      );
    }
  
    }
    catch (err) {
      console.log(err);
      return (
        <React.Fragment>
          <br />
        </React.Fragment>
      );  
    }  
  }
}

const astyle = (theme) => {
  return DashboardHandler.getTheme(theme);
}

export default withStyles(astyle, {withTheme: true})(PhysicianDashboard)
