import React from "react";
//import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

import SearchContextConsumer from "./SearchContextConsumer";
//import SearchContext from "../../context/SearchContext";
//import SearchField from "./SearchField";
//cyclic ref!
import SearchContainer from "./SearchContainer";
//import { useRef } from "react";

/*
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2)
  }
}));
*/

export default function SimplePopover(props) {

  //console.log("SearchPopover called");

  //const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    console.log("handleClick event:", event);
    setAnchorEl(event.currentTarget);
    /*
    //https://stackoverflow.com/questions/10086427/what-is-the-exact-difference-between-currenttarget-property-and-target-property
    if (props.show===true && myRef && myRef.current && !myRef.current.contains(event.target)) {
      SearchContainer.hide();
    }
    else if (props.show!==true) {
      setAnchorEl(event.currentTarget);
    }
    */
  };

  const handleClose = (event) => {
    console.log("handleClose called: event: ", event);
    setAnchorEl(null);
    SearchContainer.hide();
  };

  //https://stackoverflow.com/questions/40091000/simulate-click-event-on-react-element
  const simulateClick = (event) => {
    console.log("simulateClick e:", event);
    if (event) event.click();
  };

  //const open = Boolean(anchorEl);
  const open = props.show;
  const id = open ? "simple-popover" : undefined;

  console.log("SearchPopover props.coords: ", props.coords);

  //const myRef = useRef();

  return (    
    <div 
    //ref={myRef}
    ref={simulateClick}
    aria-describedby={id}
    variant="contained"
    color="primary"
    onClick={handleClick} 
    style={{
      ...props.coords ? { left: props.coords.left, top: props.coords.top, position:'absolute' } : {},
      borderRadius: 0
    }}
    >
      <Popover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        //anchorReference="anchorPosition"
        //anchorPosition={{ top: props.coords.top, left: props.coords.left }}        
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <SearchContextConsumer />
      </Popover>
    </div>    

  );
}
