import React from "react";
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import ChartInspector from '../../../components/devtools/ChartInspector';
import * as colors from '../../Colours';

//https://material-ui.com/components/tables/

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: "50vw"
  }
});

export default function ObservationList(props) {
  const classes = useStyles();

  let rows = props.data;

  //const [dense, setDense] = React.useState(false);
  const dense = true;

  let getClaimsData = function(row) {
    console.log("getClaimsData row: ", row);
    props.getClaimsData({
        observation_metatype: props.observation_metatype,//"submission",
        observation_type: row.observation_type,//"threshold",
        observation_code: row.observation_code,//"835.THD.RESPONSE_TO_SUB_AGE",
        //pattern_string: JSON.stringify(row.pattern),//'{"Age": 27}',
        pattern_string: row.pattern,//'{"Age": 27}',
        //provider: row.provider,
        row: row
    })
  }

  return (
    <Paper className={classes.chartPaper}>
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", width: "100%"}} >
                <Typography variant="subtitle1" align='center'>{props.title}</Typography>
                <ChartInspector _props={props} title={props.title} renderedData={props.data} meta={props._meta} />
            </div>
        </div>
        <TableContainer className={classes.table} component={Paper} style={{ maxHeight: 'calc(50vh)', minWidth: 'calc(90vw)', maxWidth: 'calc(90vw)' }}>
        <Table aria-label="customized table" 
            size={dense ? 'small' : 'medium'}>
            <TableHead>
            <TableRow style={{ height: (dense ? 33 : 53) }}>
                <StyledTableCell width="8%">Date</StyledTableCell>
                <StyledTableCell width="20%">Provider</StyledTableCell>
                <StyledTableCell width="30%">Category</StyledTableCell>
                <StyledTableCell width="30%">Pattern</StyledTableCell>
                <StyledTableCell width="8%" align="right">Count #</StyledTableCell>
                <StyledTableCell></StyledTableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows && rows.map((row, i) => (
                <StyledTableRow key={row.interval + "~" + row.provider + "~" + row.observation_text + "~" + Math.random() }>
                    <StyledTableCell width="8%" component="th" scope="row">{row.interval}</StyledTableCell>
                    <StyledTableCell width="20%">{row.provider}</StyledTableCell>
                    <StyledTableCell width="30%">{row.observation_text}</StyledTableCell>
                    <StyledTableCell width="30%">{JSON.stringify(row.pattern)}</StyledTableCell>
                    <StyledTableCell width="8%" align="right">{row.occurrence}</StyledTableCell>
                    <StyledTableCell>
                        <IconButton type="submit" className={classes.iconButton} aria-label="search" 
                            onClick={() => {getClaimsData(row)}}
                            style={{backgroundColor: colors.bluegrey600, padding: 0}} small="small">
                            <SearchIcon fontSize='small' color='primary'/>
                        </IconButton>
                    </StyledTableCell>
                </StyledTableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </Paper>
  );
}
