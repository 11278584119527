import React from "react";

import {callPostAPI} from '../services/APIHandler'
import SearchField from "./SearchField";
import SearchTabs from "./SearchTabs";
import SearchHelp from "./SearchHelp";
import * as Utils from '../services/Utils';

class SearchResults extends React.Component {

  constructor(props) {
    super(props);
    //console.log("SearchResults constructor called: props: ", props);
    this.myInput = React.createRef();
    this.state = {
      searchResults: null,
      categorizedSearchResults: {}
    }
  }

  componentDidMount() {
    console.log("SearchResults componentDidMount called: this.props.requested: ", this.props.requested);
    if (this.props.requested && this.props.requested.length > 0)
      this.callSearchAPI(this.props.requested);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  updateWindowDimensions = () => {
    //console.log(this.myInput.current.offsetWidth)
    //this.setState({ width: window.innerWidth, height: window.innerHeight });
    this.setState({ width: this.myInput.current.offsetWidth, height: this.myInput.current.offsetHeight });
  }  

  //update state if incoming props (including context!) changed
  componentDidUpdate(prevProps) {
    if (this.props.requested !== prevProps.requested) {
      console.log("SearchResults componentDidUpdate UPDATING! requested from: ", prevProps.requested, "to: ", this.props.requested);
      if (this.props.requested && this.props.requested.length > 0)
        this.callSearchAPI(this.props.requested);
    }

    if (this.props.idtoken !== prevProps.idtoken) {
      console.log("SearchResults componentDidUpdate UPDATING! idtoken from: ", prevProps.idtoken, "to: ", this.props.idtoken);
    }
  }    

  searchResultsCallback = (searchResults) => {
    //TODO: process results into different buckets
    console.log("callSearchAPI searchResults: ", searchResults);

    let categorizedSearchResults = {};
    if (searchResults && searchResults.data && searchResults.data.results)
      categorizedSearchResults = Utils.groupJSONObjectArrayByKey(searchResults.data.results, "entity");
    //console.log("callSearchAPI categorizedSearchResults: ", categorizedSearchResults);

    let keys = Object.keys(categorizedSearchResults);
    for (let i=0; i < keys.length; i++) {
      let key = keys[i];
      let fullCopy = Utils.deepCloneJSONObject(searchResults);
      fullCopy.data.results = categorizedSearchResults[key];
      categorizedSearchResults[key] = fullCopy;
    }

    this.setState({
      searchResults: searchResults, 
      categorizedSearchResults: categorizedSearchResults
    });
  };

  callSearchAPI = (requested) => {
    //console.log("callSearchAPI called: requested: ", requested);
    let selectedEntities = ['claim', 'cpt', 'customer', 'dashboard', 'icd', 
                           'message', 'payment', 'payor', 'provider', 'whatif', 'service_class'];
    let searchRequestData = {
      search_text: requested, //getting this directly to avoid race condition with setState
      num_of_results: 10, 
      search_entities: selectedEntities
    };
    //console.log("searchRequestData: ", searchRequestData);
    let params = {
      callname: 'web_search_text', 
      requestData: searchRequestData, 
      authtoken: this.props.idtoken, 
      callback: this.searchResultsCallback
    };
    console.log("callSearchAPI params: ", params);
    callPostAPI(params);
  }

  render() {
    console.log("SearchResults render called: this.props: ", this.props);
    console.log("SearchResults render called: this.props.requested.length: ", this.props.requested ? this.props.requested.length : null);
    return (
        <div id="searchResultDiv" ref={this.myInput} style={{width: "100%"}} >
          <SearchField context={{
            requested: this.props.requested, 
            setRequested: this.props.setRequested, 
            parent: "popover",
            //width: this.state.width
          }} />                 
          {this.state.searchResults && 
          (this.props.requested && this.props.requested.trim()!=="") &&
          <SearchTabs 
            searchResults={this.state.searchResults} 
            categorizedSearchResults={this.state.categorizedSearchResults} 
            requested={this.props.requested} 
            setRequested={this.props.setRequested} 
          />}
          {(!this.props.requested || this.props.requested.trim()==="") &&
          <SearchHelp
            //requested={this.props.requested} 
            setRequested={this.props.setRequested} 
          />}
        </div>
    );
  }
}

export default SearchResults;