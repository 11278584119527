import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import InfoIcon from '@material-ui/icons/Info';
import ListIcon from '@material-ui/icons/List';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { red, orange, green } from '@material-ui/core/colors';

//https://material-ui.com/components/tables/

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: "50vw"
  }
});

const observationIcon = (category) => {
  if(category === 'Anomaly')  {
      return <WarningRoundedIcon style={{ color: red[500] }} />
  } 
  else if(category === 'Threshold') {
      return <PublishRoundedIcon style={{ color: orange[500] }} />
  } 
  else if(category === 'Warn')  {
      return <ErrorRoundedIcon style={{ color: orange[500] }} />
  }
  else if(category === 'Info')  {
    return <InfoIcon style={{ color: green[500] }} />
  }
  else if(category === 'Audit')  {
    return <ListIcon style={{ color: green[500] }} />
  }
  else {
    return <AddCircleOutlineIcon style={{ color: green[500] }} />
  }
}                

export default function RequisitionRoutingResults(props) {
  const classes = useStyles();

  let rows = props.data;

  //const [dense, setDense] = React.useState(false);
  const dense = true;

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="customized table" 
        size={dense ? 'small' : 'medium'}>
        <TableHead>
          <TableRow style={{ height: (dense ? 33 : 53) }}>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Rendering Provider</StyledTableCell>
            <StyledTableCell>CPT & Modifier</StyledTableCell>
            <StyledTableCell align="right">Claim #</StyledTableCell>
            <StyledTableCell align="right">Allowed/Charge %</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={row.title}>
              <StyledTableCell component="th" scope="row">{i + 1}</StyledTableCell>
              <StyledTableCell>{row.provider}</StyledTableCell>
              <StyledTableCell>{row.cpt} {row.modifier}</StyledTableCell>
              <StyledTableCell align="right">{row.claim_count}</StyledTableCell>
              <StyledTableCell align="right">{row.allowed_to_charge}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
