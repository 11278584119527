// Claimstory.js
// copyright @artiss.co

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@material-ui/core/LinearProgress';


import {callPostAPI} from '../../services/APIHandler'
import ClaimDetails from './Claimdetails'
import UserContext from '../../../context/UserContext'
import {ShowErrorMsg} from '../../services/ShowmsgtoUsers'
import ChartInspector from '../../devtools/ChartInspector'

class ClaimStory extends React.Component {
    constructor(props) {
        super()
        this.state = {claimdata: null, searchongoing: false}
    }
    
    claimStorydataCallback = (sdata) => {
        console.log("clam callback", sdata)
        if(sdata.status === 'success') {
            this.setState({claimdata: sdata, searchongoing: false})
        } else {
            // TODO: on error navigate , else where?
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
            )
        }
        //this.setState({claimdata: sdata, searchongoing: false})
    }

    componentDidMount() {
        let user = this.context;
        let claimguid = this.props.location.state.showdetail.guid
        let hdrpostd = {"object_guid":claimguid }
        callPostAPI({callname: 'web_get_claim', requestData: hdrpostd, 
                        authtoken: user.idtoken, callback: this.claimStorydataCallback})
        this.setState({searchongoing: true})
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.state.showdetail.guid !== this.props.location.state.showdetail.guid) {
            console.log('Claimstory - calim guid has changed')
            let user = this.context;
            let claimguid = this.props.location.state.showdetail.guid
            let hdrpostd = {"object_guid":claimguid }
            callPostAPI({callname: 'web_get_claim', requestData: hdrpostd, 
                            authtoken: user.idtoken, callback: this.claimStorydataCallback})
            this.setState({searchongoing: true})
        }
    }


    render() {
        const {classes} = this.props

        let inspectorData = null
        if (!process.env.REACT_APP_CHART_INSPECTOR || process.env.REACT_APP_CHART_INSPECTOR === 'True')  {    // this is for debug
            inspectorData = this.state.claimdata
        }
        if(Boolean(this.state.claimdata) && this.state.claimdata.status === 'success') {
            return(
                <div >
                <ClaimDetails data={this.state.claimdata} />
                <ChartInspector _props={this.props} title={''} renderedData={inspectorData} meta={null} />
                </div>
            )
        } else {
            return(
                <div className={classes.skeleton} >
                    <LinearProgress />
                    <Skeleton variant="text"  height={20}/>
                    <Skeleton variant="rect"  height={118}/>
                </div>
            )
        }
    }


}

ClaimStory.contextType = UserContext; 

const astyle = (theme) => ({
    top: {
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: colors.bluegrey600,
        backgroundColor: theme.palette.primary.main,
        width: '95%',
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 20,
        // height: 800
        height: '100%'
    },
    skeleton: {
        margin: '10%',
        width: '75%'
    },
})

export default withStyles(astyle, {withTheme: true})(ClaimStory)


