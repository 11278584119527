/*
async function callPostAPIAsync(options) {
    options.method = "post";
    return callAPIAsync(options);
}
*/

function getNewState(props) {
    var newState = {
        dateRangeFilter: {},////yesterday, last_7_days, last_4_weeks, last_8_weeks, last_3_months, year_to_date, last_four_quarters, all_data
        //dateRangeFilter: props.dateRangeFilter,
        //dateRangeFilter: {filterName: "yesterday", label: "Yesterday"},
        prevDateRangeFilter: {},
        //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
        width: 0, 
        height: 0,
        //currentTab: 0,
    };

    return newState;
}
  
function componentDidUpdate(prevProps, prevState, thisProps, thisState) {
    //console.log("RecommendationHandler componentDidUpdate called: prevProps: ", prevProps);
    //console.log("RecommendationHandler componentDidUpdate called: thisProps: ", thisProps);
    if (
        //(prevState.dateRangeFilter.filterName!==thisState.dateRangeFilter.filterName) || 
        (
        (prevProps.customer===null && thisProps.customer!==null) || 
        (prevProps.customer!==null && thisProps.customer===null) || 
        (prevProps.customer && thisProps.customer && prevProps.customer.scope_guid!==thisProps.customer.scope_guid)
        )
    ) 
    {
        return true;
    }  
    return false;
}
  
function getCustomerFilterCriteria(customer) {
    //console.log("getCustomerFilterCriteria customer: ", customer);
    //let customer_filter_criteria = customer.customer_filter_criteria;//TODO: this should work! using workaround instead:
    let customer_guid_tuple;
    if (customer && customer.scope_guid==="ALL") customer_guid_tuple = {};//{customer_guid: null};
    else if (customer && customer.scope==="customer") customer_guid_tuple = {customer_guid: customer.scope_guid};
    else if (customer && customer.scope==="customer_group") customer_guid_tuple = {customer_group_guid: customer.scope_guid};
    //console.log("getData customer_filter_criteria: ", customer_guid_tuple);  
    return customer_guid_tuple;
}
  
  
function getTheme(theme) {
    /*
    //TODO color schema
    paper - light blue - just like on summary page
    response - same as paper
    text field background - same as search box - #808080
    form background - same as on cpt story background #435761

    */
    return {
        top: {
            marginTop: 0,//10
        },
        all: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 0,//25
        },
        chartRow: {
            marginTop: 10,
            marginBottom: 20,
            display: "flex" ,
            justifyContent: "space-around",
            //justifyContent: "flex-start",
            //height: 250,
            width: '100%',
        },
        sectionpaper: {
            paddingBottom: 0,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 0,
            backgroundColor: theme.palette.primary.main
        },
        sectionHeader: {
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 0,//10,
        },
        stickyhdr: {
            position: 'sticky',
            top: 64,
            zIndex: 10
        },
        tabsRoot: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper
        },  
        tabPanel: {
            borderTopLeftRadius: 4, 
            borderTopRightRadius: 4
        },
        tooltip: {
            backgroundColor: theme.palette.primary.dark,
            //backgroundColor: theme.palette.primary.main,
        },
    }
}
  
export { 
    getNewState, componentDidUpdate, getCustomerFilterCriteria, getTheme
}    