import React from "react";

import SearchContext from "./SearchContext";
import SearchContainer from '../components/search/SearchContainer';

/**
 * @component SearchContextProvider
 * @description wrapper for search context provider. Use this just one-time in the app, in the outermost widget as required
 * @property {object} children (optional prop set for this widget) default React element for any child nodes that need to be embedded within this provider widget
 * @property {object} requested search criteria
 * @property {object} response search response
 * @property {object} helpContent search help content
 * @function setRequested sets the requested search criteria
 * @function setResponse sets the search response (typically returned from the server)
 * @function setHelpContent sets the search help content
 * @return {undefined} 
 * @example <CustomerSelector user={user} onSelected={setCustomer} />
 */

//introducing this class for separation of responsibility for initializing and maintaining state
class SearchContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.setRequested = (value) => {
      console.log("setRequested: ", value);
      this.setState(state => ({
        requested: value
      }));
      this.appendRequestedInLocalStorage(value);
    };

    this.appendRequestedInLocalStorage = (value) => {
      if (value && value.trim()!=="") {
        value = value.trim();
        let pastSearches = null;
        let pastSearchesString = localStorage.getItem('pastSearches');
        if (pastSearchesString) pastSearches = JSON.parse(pastSearchesString);
        else pastSearches = [];
        if (pastSearches[0] && (pastSearches[0] !== value)) { //add only if not the same as last one
          //add to beginning of array
          pastSearches.unshift(value);
          //keep only last 10
          pastSearches = pastSearches.slice(0, 10);
          pastSearchesString = JSON.stringify(pastSearches)
          localStorage.setItem('pastSearches', pastSearchesString);
        }  
      }
    }

    this.setResponse = (value) => {
      console.log("setResponse: ", value);
      this.setState(state => ({
        response: value
      }));
    };

    this.setHelpContent = (value) => {
      console.log("setHelpContent: ", value);
      this.setState(state => ({
        helpContent: value
      }));
    };

    let requested = "";
    let response = "Response";
    let helpContent = "Help";

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      requested:  requested, //this.props.requested ? this.props.requested : "NA",
      setRequested: this.setRequested,
      response: response,
      setResponse: this.setResponse,
      helpContent: helpContent,
      setHelpContent: this.setHelpContent
    };      
  }

  render() {
    return (
      <SearchContext.Provider value={this.state}>  
        <SearchContainer /> 
        {this.props.children}
      </SearchContext.Provider>
    );
  }
}

export default SearchContextProvider;