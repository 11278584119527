import React from "react";

import SearchPopover from "./SearchPopover";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

/*
App Bar -> SearchField -> 
SearchContainer (singleton) -> SearchPopover (popover) ->  SearchContextConsumer (context consumer) -> 
*SearchResults* (API/data/UI) -> SearchField + SearchTabs (tabs) ->  
SearchTabPanel (tab panel - not used) + tab panels + SearchResultsList(view) -> SearchToComponent (link) -> component
*/

//aka Container aka SearchResultContainer
class SearchContainer extends React.Component {
  static show(coords) {
    SearchContainer.__singletonRef.__show(coords);
  }

  static hide() {
    SearchContainer.__singletonRef.__hide();
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      coords: null
    };
    SearchContainer.__singletonRef = this;
  }

  __show(coords) {
    console.log("__show called with coords: ", coords);
    this.setState({ show: true, coords: coords });
  }

  __hide() {
    //console.log("__hide called");
    this.setState({ show: false });
  }

  //https://material-ui.com/components/click-away-listener/
  //ALT: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
  handleClickAway() {
    console.log("SearchContainer handleClickAway called");
    //this.__hide();
  }

  render() {
    //console.log("SearchContainer render called");
    if (this.state.show === true) {
      return (
        <div>
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <SearchPopover coords={this.state.coords} show={this.state.show} />
          </ClickAwayListener>         
          {this.props.children} 
        </div>
      );
    } else {
      return null;
    }
  }
}

export default SearchContainer;
