import React from "react";

import EULAView from "./EULAView";

class EULAContainer extends React.Component {
  static show(coords, user, handleEULAResponse) {
    EULAContainer.__singletonRef.__show(coords, user, handleEULAResponse);
  }

  static hide() {
    EULAContainer.__singletonRef.__hide();
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      coords: null,
      user: null,
      handleEULAResponse: null,
    };
    EULAContainer.__singletonRef = this;
  }

  __show(coords, user, handleEULAResponse) {
    console.log("__show called with coords: ", coords);
    this.setState({ show: true, coords: coords, user: user, handleEULAResponse: handleEULAResponse });
  }

  __hide() {
    //console.log("__hide called");
    this.setState({ show: false });
  }

  render() {
    //console.log("SearchContainer render called");
    if (this.state.show === true) {
      return (
        <EULAView handleEULAResponse={this.state.handleEULAResponse} user={this.state.user} />
      );
    } else {
      return null;
    }
  }
}

export default EULAContainer;
