// SubmissionText.js
// copyright @artiss.co

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import * as utils from '../../services/Utils'

const changeTrendIcon = (dir) => {
    if(dir === 'up') {
        return <TrendingUpIcon style={{color:'#76ff03'}} fontSize='default'/>
    } else if(dir === 'down') {
        return <TrendingDownIcon style={{color:'red'}} fontSize='default' />
    } else {
        return <TrendingFlatIcon />
    }
} 

const directionMsg = (rd, ent) => {

    //console.log("direction --", rd)
    const classes = astyle();
    let mnth = utils.monYearStr(rd.current_vs_previous.compare_period.start)
    let periodtype = rd.current_vs_previous.compare_period.period_type
    let dm1 = ''; let dm2 = ''; let dm3 =  ''
    let m = rd.current_vs_previous
    //let n = rd.current_vs_previous_year
    if(m.compare_change_direction === 'up') {
        dm1 = ent + ' this ' + periodtype + ' is ' 
        dm2 = ' ' + utils.showPercent_f2(Math.abs(m.compare_change_amount_percent)/100)
                + '(' + utils.showUSD_f1(Math.abs(m.compare_change_amount)) + ')  compared to ' 
                + m.compare_period.relative_to + ' ' + m.compare_period.period_type + ' charge of ' + utils.showUSD_f1(Math.abs(m.measurement_amount)) 

        if(m.compare_change_count_percent < 0) {
            dm3 = 'while submission count is down ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        } else if(m.compare_change_count_percent > 0) {
            dm3 = 'and submission count is up ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        }
                // + '. Compared to previous year ' + mnth + ' revenue has gone ' 
                // + n.compare_change_direction + ' by ' + n.compare_change_percent + '%. '

    } else if(m.compare_change_direction === 'down') {
        dm1 = ent + ' this ' + periodtype + ' is '
        dm2 = ' ' + utils.showPercent_f2(Math.abs(m.compare_change_amount_percent)/100)
                + ' (' + utils.showUSD_f1(Math.abs(m.compare_change_amount)) + ') compared to '
                +  m.compare_period.relative_to + ' ' + m.compare_period.period_type + 'charge of ' + utils.showUSD_f1(Math.abs(m.measurement_amount)) 
        if(m.compare_change_count_percent < 0) {
            dm3 = 'and submission count is down ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        } else if(m.compare_change_count_percent > 0) {
            dm3 = 'while submission count is up ' + utils.showPercent_f2(Math.abs(m.compare_change_count_percent/100))
                    + '(' + Math.abs(m.compare_change_count) + ')'
        }
        
    }
    return (
        <>
        <div className={classes.dm} />
        <Typography display='inline' variant="subtitle1" > 
        {dm1}
        {changeTrendIcon(m.compare_change_direction)}
        {dm2} {dm3}
        </Typography>
        </>
    )
}


const cmMsg_physicians = (ad) => {
    const classes = astyle();
    
    let ccngp = Math.abs(ad.compare_change_amount_percent)
    let cm = null
    let dir = ad.compare_change_direction;
    // if dir is UP, API returns the top +ve impact in change_makers_top
    // if dir is DOWN, API returns the top -ve impact in change_makers_bottom
    if(dir === 'up') {
        cm = ad.change_makers_top.physicians
    } else if(dir === 'down') {
        cm = ad.change_makers_bottom.physicians
    } else {
        console.log("change maker direction NOT RECOGNIZED",dir)
        cm = []      // set it to empty , so does not bomb
    }
    
    let tag1 = ''; let names = []
    if(cm.length === 0 || Math.abs(ccngp) < 5)
        {return ''}
    let cm_percent = 0; let cm_amount = 0;
    for(let i=0; i<cm.length; i++) {
        if(dir === 'down' && cm[i].change_amount_percent < 0) {
            cm_percent += cm[i].change_amount_percent
            cm_amount += cm[i].change_amount
            names.push({name: cm[i].name, amt: cm[i].change_amount})
        } else if(dir === 'up' && cm[i].change_amount_percent > 0) {
            cm_percent += cm[i].change_amount_percent
            cm_amount += cm[i].change_amount
            names.push({name: cm[i].name, amt: cm[i].change_amount})
        }   
    }
    names.sort((f,s) => {if(f.amt < s.amt) {return 1}
                         else {return -1}})
    if(names.length > 10) {
        names = names.slice(0,9)
    }

    console.log( "--->", ccngp, cm_amount, names)
    
    let msg = ''
    if(dir === 'up') {
        if(ccngp < 3) {
            tag1 = 'slight increase'
        } else if(ccngp < 8) {
            tag1 = 'increase'
        } else if(ccngp < 20) {
            tag1 = 'healthy increase'  
        } else {
            tag1 = 'spike'
        }
        return(
            <>
            <Typography variant='subtitle1' display='inline'>
                . Physicians caused a <span className={classes.td1}> {tag1} </span> of {utils.showUSD_f2(Math.abs(cm_amount))}
            </Typography>
            {names.map((d,i, arr) => {
                if(i === 0) {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                        . Physicians that contributed to the {tag1} include <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))})
                    </Typography>
                    )
                } else if(i < arr.length-1) {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                            , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}) 
                        </Typography>
                    )
                } else {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                            , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}).
                        </Typography>
                    )
                }
                
            })}
            </>
        )   
    } else if(dir === 'down') {
        if(ccngp < 3) {
            tag1 = 'slight decrease'
        } else if(ccngp < 8) {
            tag1 = 'decrease'
        } else if(ccngp < 20) {
            tag1 = 'modest decrease'
        } else {
            tag1 = 'sharp decline'
        }
        return(
                <>
                <Typography variant='subtitle1' display='inline'>
                    . Physicians caused the <span className={classes.td1}> {tag1} </span> of ({utils.showUSD_f2(Math.abs(cm_amount))})
                </Typography>
                {names.map((d,i, arr) => {
                    if(i === 0) { 
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                            . Physicians that caused the {tag1} include <span className={classes.td2}>{d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))})
                        </Typography>
                        )
                    } else if(i < arr.length-1) {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                                , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}) 
                            </Typography>
                        )
                    } else {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1' pl='5'>
                                , <span className={classes.td2}> {d.name} </span> ({utils.showUSD_f2(Math.abs(d.amt))}).
                            </Typography>
                        )
                    }
                    
                })}
                </>
            )
    }
    //return msg


}


const SubmissionsSummaryText = (props) => {
    const classes = astyle();
    let rd = props.data.data
    let c = rd.current_vs_previous
    //console.log( 'submssionsummary ----', props.data)
    let smsg = ''
    let rdcp = rd.current_vs_previous
    let mnth = utils.monYearStr(rd.current_period.start)
    if(rd.measure === 'charge') {
        smsg = 'Charges for ' + mnth + ' is ' + utils.showUSD_f2(rd.measurement_amount) 
                    + ' from ' + rd.measurement_count + ' submissions'
    }
    return(
        <Paper className={classes.pap}>
            <Typography variant="h6"> {smsg} </Typography>
            <Divider variant='fullWidth' light={true} />
            {directionMsg(rd, 'Charges')}
            {cmMsg_physicians(c)}
        </Paper>
    )
}


const astyle = makeStyles((theme) => ({
    pap: {
        margin: 15,
        padding: 10,
        width: '50%'
    },
    dm: {
        paddingTop: 6
    },
    td1: {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.secondary.main
    }, 
    td2: {
        color: theme.palette.secondary.main
    }
}));

export {SubmissionsSummaryText}
