// Paidchangetrend.js
// copyright @artiss.co

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ResponsiveBar } from '@nivo/bar'

import * as colors from '../../Colours'
import * as utils from '../../services/Utils'

const PaidChangeTrend = (props) => {
    const classes = astyle();

    let {trenddata, dates, meta} = props

    let titlemsg = ''; let ckey = ''; let pkey = ''; let nt = '';
    if(meta.resolution === 'Weeks') {
    //if(trenddata[0].period.includes("Weeks")) {
        titlemsg = 'Comparing Paid by weeks'
        ckey = 'current-week'; pkey = 'previous-week'; nt = "Wk";
    } else if(meta.resolution === 'Months') {
     // else if(trenddata[0].period.includes("Quarters")) {
        titlemsg = 'Comparing Paid by months'
        ckey = 'current-month'; pkey = 'previous-month'; nt = 'Qtr';
    } else if(meta.resolution === 'Quarters') {
        titlemsg = 'Comparing Paid by quarters'
        ckey = 'current-Qtr'; pkey = 'previous-Qtr'; nt = 'Month';
    }
    let chartdata = [];

    for(let i=0; i<trenddata.length; ) {
        let t0 = trenddata[i]; let t1 = trenddata[i+1]; let cd = {}
        let ntag = nt + t0.rank.toString()
        if(Boolean(t1) && t0.rank === t1.rank) {  // these two are related
            let nm = t0.interval + ' | ' + t1.interval
            if(t0.period.includes("Current") && t1.period.includes("Previous")) {
                cd = { name:nm, [ckey]: t0.paid_amount, [pkey]: t1.paid_amount }
                i = i + 2
            } else if(t0.period.includes("Previous") && t1.period.includes("Current")) {
                cd = { name:nm, [ckey]: t1.paid_amount, [pkey]: t0.paid_amount }
                i = i + 2
            }
        } else {
            if(t0.period.includes("Current")) {
                cd = { name:ntag, [ckey]: t0.paid_amount, [pkey]: 0 }
                i += 1
            } else if(t0.period.includes("Previous")) {
                cd = { name:ntag, [ckey]: 0, [pkey]: t0.paid_amount }
                i += 1
            } else {
                i++ ;    // being defensive - dont want a forever loop
                cd = {}
            }
        }
        chartdata.push(cd)
    }

    console.log("PaidChangeTrend ", chartdata)

    return(
       
        <Box className={classes.breaktop} >
            <Typography variant="subtitle1"> {titlemsg} </Typography>
            <Typography variant="caption"> current: {dates.period_display} </Typography>
            <Typography variant="caption">  |  previous: {dates.previous_period_display} </Typography>
            <ResponsiveBar
            data={chartdata}
            keys={[ckey, pkey  ]}
            layout="vertical"
            indexBy="name"
            groupMode={'grouped'}
            height={200}
            margin={{ top: 40, right: 50, bottom: 65, left: 45 }}
            //innerPadding={4}
            padding={0.2}
            innerPadding={2}
            colors={{ scheme: 'category10' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            //axisLeft={null}
            enableGridY={true}
            enableLabel={true}
            labelFormat={["$", ".2s"]}
            //labelFormat={{format: v => `${v}.2s`}}
            labelSkipWidth={15}
            labelSkipHeight={12}
            //labelTextColor={{ from: 'color' }}
            labelTextColor={'#000000'}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    },
                },
                grid: {
                    line: {
                      stroke: "DarkSlateGrey",
                      strokeWidth: 1,
                      //strokeDasharray: "4 4"
                    }
                }
            
              }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 6,
                tickRotation: 0,
                tickValues: 5,
                legend: 'paid',
                legendOffset: -60,
                legendPosition: 'middle',
                format:"$,.2s"
            }}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -40,
                
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    itemTextColor: '#ffffff',
                    //anchor: 'right',
                    anchor: 'bottom-left',
                    direction: 'row',
                    justify: false,
                    translateX: 75,
                    //translateY: -15,
                    translateY: -100,
                    itemsSpacing: 2,
                    itemWidth: 150,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            />   

        </Box>  
    )

}



const astyle = makeStyles((theme) => ({
    breaktop: {
        width: '95%',
        height: 120,
        //flex: 1,
        flexDirection: 'column',
        //justifyContent: 'flex-start',
        alignItems: 'flex-start',
        //backgroundColor: colors.primarydark,
        paddingBottom: 10,
        paddingLeft: 10,
        marginBottom: 40,
        //height: 75,
        marginTop: 50,
        //position: 'relative',
    },
    pie1: {
        position: 'fixed',
        left: 10
    },
    scp: {
        backgroundColor: colors.secondarydark,
    },
    sctxt: {
        fontSize: 'large'
    },
    
}));

export default PaidChangeTrend