import React from 'react';
import Typography from '@material-ui/core/Typography';

//aka addGeneratedAttributeToJSONObjectArray
function addGeneratedAttribute(jsonObjectArray, generateFromAttributeList, generatedAttributeName, separator) { //, generateAttributeFunction=null
    if (jsonObjectArray) {
      for (let i=0; i < jsonObjectArray.length; i++) {
        let generatedAttributeValue = null;
        for (let j=0; j < generateFromAttributeList.length; j++) {
            let attributeName = generateFromAttributeList[j];
            let attributeValue = jsonObjectArray[i][attributeName];
            if (!attributeValue) attributeValue = "";
            if (!generatedAttributeValue) generatedAttributeValue = attributeValue;
            else {
              if (separator) generatedAttributeValue = generatedAttributeValue + separator + attributeValue;
              else generatedAttributeValue = generatedAttributeValue + " + " + attributeValue;//use generateAttributeFunction here for custom generations
            }
        }
        jsonObjectArray[i][generatedAttributeName] = generatedAttributeValue;
      }
    }
    return jsonObjectArray;
}

function addSplitAttributes(jsonObjectArray, generateFromAttributeList, generatedAttributeName, separator) { //, generateAttributeFunction=null
  if (jsonObjectArray) {
    for (let i=0; i < jsonObjectArray.length; i++) {
      let generatedAttributeValue = jsonObjectArray[i][generatedAttributeName];
      let splitStrings = generatedAttributeValue.split(separator);
      for (let j=0; j < generateFromAttributeList.length; j++) {
          let attributeName = generateFromAttributeList[j];
          let attributeValue = splitStrings[j];
          jsonObjectArray[i][attributeName] = attributeValue;
      }
    }
  }
  return jsonObjectArray;
}

//TODO: add function to generateGroupedData for nivo chart - for current.top_payors_by_receivable_amount
/*
top_payors_by_receivable_amount
{age: ">120 days", payor: "Aetna", rank: 5, receivable_amount: 1810402}
{age: ">120 days", payor: "AmeriChoice of New York Personal Care Plus (Medicare)", rank: 5, receivable_amount: 26288517.05}
*/
//function generateGroupedData(jsonObjectArray, groupedBy, otherColumns) {
//}

/** 
 *  @function toDateStr
 *  @param {string} dt - a Date string 
 *  @return {string} - date string - dd-Mon-yyyy
 *  @example
 *     toDateStr('12-03-2020') returns 3 Dec 2020
 */
const toDateStr = (d) =>  {
    let dt = new Date(d)
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", 
                    "Sep", "Oct", "Nov", "Dec"];
    let dtstr = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear()
    return dtstr

}

/** 
 *  @function monYearStr
 *  @param {string} dt - a Date string 
 *  @return {string} - date string - Mon yyyy
 *  @example
 *     toDateStr('12-03-2020') returns Dec 2020
 */
const monYearStr = (d) =>  {
    let dt = new Date(d)
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", 
                    "Sep", "Oct", "Nov", "Dec"];
    let dtstr = months[dt.getMonth()] + " " + dt.getFullYear()
    return dtstr

}

const month3Chars = (m) => {
  var months = [" ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", 
                    "Sep", "Oct", "Nov", "Dec"];
  if(m > 0 && m < 13) return months[m]
  else return '-'

}

/*TODO: optimize this with direct sum and/or use map/reduce/filter
https://medium.com/poka-techblog/simplify-your-javascript-use-map-reduce-and-filter-bd02c593cc2d
https://atendesigngroup.com/articles/array-map-filter-and-reduce-js
https://stackoverflow.com/questions/40774697/how-to-group-an-array-of-objects-by-key
https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects?noredirect=1&lq=1
*/
function groupJSONObjectArrayByKeyAndSum(array, key, summedAttribute) {  
  try {
    let groupedObject = groupJSONObjectArrayByKey(array, key);
    let keys = Object.keys(groupedObject);
    let result = [];
    for (let i=0; i < keys.length; i++) {
      let keyValue = keys[i];
      let keyObjectList = groupedObject[keyValue];
      let keySum = 0;
      for (let j=0; j < keyObjectList.length; j++) {
        keySum += keyObjectList[j][summedAttribute];
      }
      result.push({[key]: keyValue, [summedAttribute]: keySum});
    }
    //console.log("groupJSONObjectArrayByKeyAndSum: result: ", result);
    return result;  
  }
  catch (err) {
    console.log("groupJSONObjectArrayByKeyAndSum err: ", err);
    return null;
  }
}

//https://stackoverflow.com/questions/46794232/group-objects-by-multiple-properties-in-array-then-sum-up-their-values
function groupJSONObjectArrayByMultipleKeysAndSum(array, keys, summedAttribute, newSummedAttribute) {
  //console.log("groupJSONObjectArrayByMultipleKeysAndSum 1: array: ", array);

  if (!array) return null;
  
  const result = [...array.reduce((r, o) => {
    let key = "";
    for (let i=0; i < keys.length; i++) key = key + o[keys[i]] + "~";   
    //console.log("key: ", key); 
    const item = r.get(key) || Object.assign({}, o);//{[newSummedAttribute]: 0,}
    if (!item[newSummedAttribute]) item[newSummedAttribute] = 0;    
    item[newSummedAttribute] += o[summedAttribute];  
    return r.set(key, item);
  }, new Map).values()];  

  /*
  var helper = {};
  var result = array.reduce(function(r, o) {
    for (let i=0; i < keys.length; i++) key = key + keys[i] + "~";    
    console.log("groupJSONObjectArrayByMultipleKeysAndSum key: ", key);
  
    if(!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key][newSummedAttribute] += o[newSummedAttribute];
    }  
    return r;
  }, []);
  */

  //console.log("groupJSONObjectArrayByMultipleKeysAndSum 3: result: ", result);
  return result;
}

function groupJSONObjectArrayByKeyAndRow(array, key, subkey, metric) {  
  try {
    let groupedObject = groupJSONObjectArrayByKey(array, key);
    let keys = Object.keys(groupedObject);
    let result = [];
    for (let i=0; i < keys.length; i++) {
      let keyValue = keys[i];
      let keyObjectList = groupedObject[keyValue];
      let newObject = {
        [key]: keyValue
      };
      for (let j=0; j < keyObjectList.length; j++) {
        let subkeyValue = keyObjectList[j][subkey];
        let metricValue = keyObjectList[j][metric];
        newObject[subkeyValue] = metricValue;
      }
      result.push(newObject);
    }
    return result;  
  }
  catch (err) {
    console.log("groupJSONObjectArrayByKeyAndSum err: ", err);
    return null;
  }
}


function reduceJSONObjectArrayToSum(array, summedAttribute) {  
  try {
    let sum = 0;
    for (let i=0; i < array.length; i++) {
      let item = array[i];
      sum += item[summedAttribute];
    }
    return sum;  
  }
  catch (err) {
    console.log("reduceJSONObjectArrayToSum err: ", err);
    return null;
  }
}


//returns single JSON object with object array indexed by key
function groupJSONObjectArrayByKey(array, key) {
  try {
    //console.log("groupJSONObjectArrayByKey: key: ", key, "; array: ", array);
    let categorizedObject = {};
    for (let i=0; i < array.length; i++) {
      let keyValue = array[i][key];
      let list = categorizedObject[keyValue];
      if (!list) {
        list = [];
        categorizedObject[keyValue] = list;
      }
      list.push(array[i]);
    }
    //console.log("categorizedObject: ", categorizedObject);
    return categorizedObject;
  /*
    //https://stackoverflow.com/questions/40774697/how-to-group-an-array-of-objects-by-key
    console.log("groupJSONObjectArrayByKey: key: ", key, "; array: ", array);
    return array.reduce((hash, obj) => {
      if(obj[key] === undefined) return hash; 
      return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
    }, {});
    */
  }
  catch (err) {
    console.log("groupJSONObjectArrayByKey err: ", err);
    return null;
  }
}

function deepCloneJSONObject(jsonObject) {
  //https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
  //https://www.samanthaming.com/tidbits/70-3-ways-to-clone-objects/
  var deepClone = JSON.parse(JSON.stringify(jsonObject));
  return deepClone;
}

function shallowCloneJSONObject(jsonObject) {
  let shallowClone = Object.assign({}, jsonObject);
  return shallowClone;
}

/** 
 *  @function getJSONObjectFromArrayForKeyValue
 *  @param {array} jsonObjectArray - list of JSON Objects
 *  @param {string} key - key of property being looked up
 *  @param {string} value - value of property being looked up
 *  @return {object} - JSON object with matching key and value
 *  @example
 *     getJSONObjectFromArrayForKeyValue[{id: "1", name: "ABC"}, {id: "2", name: "XYZ"}, "id", "1"] returns {id: "1", name: "ABC"}
 */
//ALT: https://stackoverflow.com/questions/2166765/search-an-array-for-matching-attribute
function getJSONObjectFromArrayForKeyValue(jsonObjectArray, key, value) {
  if (!jsonObjectArray) return null;
  for (var i=0; i < jsonObjectArray.length; i++) {
    if (jsonObjectArray[i][key]===value) {
      return jsonObjectArray[i];
    }
  }
  return null;
}

function getIndexFromJSONObjectArrayForKeyValue(jsonObjectArray, key, value) {
  if (!jsonObjectArray) return null;
  for (var i=0; i < jsonObjectArray.length; i++) {
    if (jsonObjectArray[i][key]===value) {
      return i;
    }
  }
  return null;
}

/** 
 *  @function showUSD_f1
 *  @param {float} m - float of currency vale 
 *  @return {string} - a string display 
 *  @example
 *     showUDS-f1(200.32) returns $200
 */
const showUSD_f1 = (m) =>  {
  return(
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      useGrouping: true,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(m) 
  )
}

/** 
 *  @function showUSD_f2
 *  @param {float} m - float of currency vale 
 *  @return {string} - a string display 
 *  @example
 *     showUDS-f1(2000.32) returns $2K
 */
const showUSD_f2 = (m) =>  {
  return(
    new Intl.NumberFormat("en-US", {
      style: "currency",
      notation: 'compact',
      currency: "USD",
      useGrouping: true,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(m) 
  )
}

/** 
 *  @function showUSD_f3
 *  @param {float} m - float of currency vale 
 *  @return {string} - a string display 
 *  @example
 *     showUDS-f3(2000.32) returns $2.3K
 */
const showUSD_f3 = (m) =>  {
  return(
    new Intl.NumberFormat("en-US", {
      style: "currency",
      notation: 'compact',
      currency: "USD",
      useGrouping: true,
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    }).format(m) 
  )
}

/** 
 *  @function showPercent_f1
 *  @param {float} - routine multiplies by 100, and rounds off to nearest 10
 *  @return {string} - a string display 
 *  @example
 *     showUDS-f1(0.236) returns 24%
 *     does not return any digits after the point
 */

const showPercent_f1 = (p) => {
  return(
    new Intl.NumberFormat("en-US", {
      style: "percent",
      useGrouping: true,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(p) 
  )
}

/** 
 *  @function showPercent_f2
 *  @param {float} - routine multiplies by 100, and rounds off to nearest 10
 *  @return {string} - a string display 
 *  @example
 *     showUDS-f2(0.0045) returns 0.4%
 *     returns up to one decimal after the point
 */
const showPercent_f2 = (p) => {
  return(
    new Intl.NumberFormat("en-US", {
      style: "percent",
      useGrouping: true,
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    }).format(p) 
  )
}


/** 
 *  @function uptoN
 *  @param {str} - string to truncate
 *  @param {nos} - length of string
 *  @return {string} - truncated string of length nos + ..
 *  @example
 *     uptoN("lazy fox jumped over", 10) returns "lazy fox.."
 */

const uptoN = (str, n) => {
  return (str.length > n) ? (str.substr(0, n-2) + '..') : (str + ' '.repeat(n-str.length))
}

/** 
 *  @function capitalizeFirstLetter
 *  @param {s} - string to capitalize first letter
 *  @return {string} - capitalized string with first letter
 *  @example
 *     uptoN("lazy fox jumped over") returns "Lazy fox.."
 */

const capitalizeFirstLetter = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

//https://stackoverflow.com/questions/17780508/selecting-distinct-values-from-a-json 
//https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
//ALT: https://stackoverflow.com/a/45886147
//e.g. let uniquePayors = Array.from(new Set(this.props.dataInput.map((item) => item.payor)));
function getUniqueKeyValueListFromJSONObjectArray(jsonObjectArray, key) {
  var lookup = {};
  var result = [];  
  //for (var item, i = 0; item = jsonObjectArray[i++];) {
  for (let i = 0; jsonObjectArray && (i < jsonObjectArray.length); i++) {
    let item = jsonObjectArray[i];
    let value = item[key];  
    if (!(value in lookup)) {
      lookup[value] = 1;
      result.push(value);
    }
  }  
  return result;
}

function getMaxValueLengthFromJSONObjectArray(jsonObjectArray, key) {
  var maxLength = 0;
  try {
    //for (var item, i = 0; item = jsonObjectArray[i++];) {
    for (let i = 0; i < jsonObjectArray.length; i++) {
      let item = jsonObjectArray[i];
      let value = item[key];  
      if (value && value.length > maxLength) maxLength = value.length;
    }    
  }
  catch (err) {
    console.log("getMaxValueLengthFromJSONObjectArray error: ", err);
  }
  return maxLength;
}

function getMaxValueLengthFromStringArray(array) {
  var maxLength = 0;
  try {
    for (let i = 0; i < array.length; i++) {
      let value = array[i];
      if (value && value.length > maxLength) maxLength = value.length;
    }    
  }
  catch (err) {
    console.log("getMaxValueLengthFromStringArray error: ", err);
  }
  return maxLength;
}

function getLabelItemWidth(jsonObjectArray, key) {
  let minLength = 50;
  let labelItemWidth = minLength;
  try {
      let maxLabelLength = getMaxValueLengthFromJSONObjectArray(jsonObjectArray, key);
      //console.log("maxLabelLength: ", maxLabelLength);
      let averageCharacterWidth = 6.5; //7px is average character width in px - see HomeDashboard code
      let labelWidthPadding = 15;
      labelItemWidth = (maxLabelLength * averageCharacterWidth) + labelWidthPadding;   
      labelItemWidth = (labelItemWidth > minLength) ? labelItemWidth : minLength; 
      //console.log("labelItemWidth: ", labelItemWidth);
  }
  catch (err) {
      console.log("getLabelItemWidth err: ", err);
  }
  finally {
    return labelItemWidth;
  }
}

function getMinAndMaxKeyValuesFromJSONObjectArray(jsonObjectArray, key) {
  var min = null, max = null; 
  for (let i = 0; i < jsonObjectArray.length; i++) {
    let item = jsonObjectArray[i];
    let value = item[key];  
    if (min==null) min = value;
    if (max==null) min = value;
    if (value < min) min = value;
    if (value > max) max = value;
  }  
  return {min: min, max: max};
}

function filterJSONObjectArray(jsonObjectArray, key, value) {
  let result = jsonObjectArray.filter(item => (item[key] === value));
  //console.log("filterJSONObjectArray jsonObjectArray: ", jsonObjectArray);
  //console.log("filterJSONObjectArray result: ", result);
  return result;
}

function formatNumber(number, decimals) {
  let multiple = Math.pow(10, decimals);
  return (Math.round(number * multiple) / multiple).toFixed(decimals);
}

//change "paid_amount" to "Paid Amount"
function convertFieldNameToLabel(id) {
  let idUpdated = id.replace(/_/g, ' ');
  idUpdated = idUpdated.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());  
  //console.log("convertFieldNameToLabel: id: ", id, "; idUpdated: ", idUpdated);
  return idUpdated;
}

function nivoToolTip(id, value, index, indexValue, color, data) {
  try {
    /*
    return <span style={{ color, whiteSpace: "nowrap" }}>
      {id} - {indexValue}: <b>{Number(value).toLocaleString('en-US')}</b>
      {(data.paid_count_change > 0) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}<b>{data.paid_count_change}%</b>
    </span>;
    */

    let idUpdated = convertFieldNameToLabel(id);

    return <span style={{ color, whiteSpace: "nowrap", display: 'flex', alignItems: 'center' }}>
      <Typography>
        {idUpdated} - {indexValue}: <b>{Number(value).toLocaleString('en-US')}</b>
      </Typography>
    </span>;
  }
  catch (err) {
    console.log("nivoToolTip error: ", err);
    return undefined;//doesnt work; we want to back off to default in case this function fails
  }
}

//https://stackoverflow.com/questions/13391579/how-to-rename-json-key
function renameObjectKey ( obj, oldKey, newKey ) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}

function renameJSONObjectArrayKey(json, oldkey, newkey) {   
  json.forEach( obj => renameObjectKey( obj, oldkey, newkey ) );
  return json;
  /* 
  return Object.keys(json).reduce((s,item) => 
       item == oldkey ? ({...s,[newkey]:json[oldkey]}) : ({...s,[item]:json[item]}),{})   
  */
}
 
function copyObjectKey ( obj, oldKey, newKey ) {
  obj[newKey] = obj[oldKey];
}

function copyJSONObjectArrayKey(json, oldkey, newkey) {   
  json.forEach( obj => copyObjectKey( obj, oldkey, newkey ) );
  return json;
}

//https://stackoverflow.com/questions/4878756/how-to-capitalize-first-letter-of-each-word-like-a-2-word-city
function toTitleCase(str) {
  return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

//https://stackoverflow.com/questions/8175093/simple-function-to-sort-an-array-of-objects
function sortJSONObjectArrayByKey(array, key) { 
  return array.sort(function(a, b)
  {
   var x = a[key]; var y = b[key];
   return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

function toStringForJSONObjectArray(array, key, newKey) {
  for (let i=0; i < array.length; i++) {
    array[i][newKey] = "" + array[i][key];
  }
  return array;
}

function showExpandedChart(props) {
  if (process.env.REACT_APP_EXPANDED_CHART && process.env.REACT_APP_EXPANDED_CHART === 'True') return true;
  const showExpandedChartForSlugs = [
    "li_avoidable_by_cpt",
    "li_avoidable_by_icd"
  ];
  if (props && props.meta && props.meta._slug) {
    if (showExpandedChartForSlugs.includes(props.meta._slug))
      return true;
  }
  return false;
}

//TODO: function copyJSONObjectArrayFieldNameToLabel(json, oldkey, newkey)

export { 
  addGeneratedAttribute, 
  addSplitAttributes,
  monYearStr,
  toDateStr,
  month3Chars,
  groupJSONObjectArrayByKey, 
  groupJSONObjectArrayByKeyAndSum,
  groupJSONObjectArrayByKeyAndRow,
  groupJSONObjectArrayByMultipleKeysAndSum,
  sortJSONObjectArrayByKey,
  reduceJSONObjectArrayToSum,
  deepCloneJSONObject, 
  shallowCloneJSONObject,
  getJSONObjectFromArrayForKeyValue,
  getIndexFromJSONObjectArrayForKeyValue,
  showUSD_f1,
  showUSD_f2,
  showUSD_f3,
  showPercent_f1,
  showPercent_f2,
  uptoN,
  getUniqueKeyValueListFromJSONObjectArray,
  getMaxValueLengthFromJSONObjectArray,
  getMaxValueLengthFromStringArray,
  getMinAndMaxKeyValuesFromJSONObjectArray,
  filterJSONObjectArray,
  formatNumber,
  convertFieldNameToLabel,
  renameObjectKey,
  renameJSONObjectArrayKey,
  copyObjectKey,
  copyJSONObjectArrayKey,
  toTitleCase,
  capitalizeFirstLetter,
  toStringForJSONObjectArray,
  
  //UI specific:
  getLabelItemWidth,
  nivoToolTip,
  showExpandedChart,
}
