// SummaryDashboard.js
// copyright @artiss.co

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import * as DashboardHandler from "../common/DashboardHandler";
import {callPostAPI} from '../../services/APIHandler'
import UserContext from '../../../context/UserContext'
import * as utils from '../../services/Utils'
import * as colors from '../../Colours'
import {SubmissionsSummaryText} from './SubmissionText'
import {RevenueSummaryText} from './RevenueText'
import {DenialSummaryText} from './DenialText'
import {ReceivableSummaryText} from './ReceivablesText'
import {ShowErrorMsg} from '../../services/ShowmsgtoUsers'

//import {summary_db_data, summary_charge_data, summary_denial_data, summary_receivable_data} from './summary-db-json'

class SummaryDashboard extends React.Component {
    
    constructor(props) {
        super(props)
        //console.log("receivable dashboard", this.props.location.state.showdetail.guid)
        this.state = { apiInprogress: false, revenue_story: null, charge_story: null,
                 denial_story: null, receivable_story: null, showerror:false, errmsg: null
                }        
    }

    componentDidMount() {
        this.updateSummaryStories()
        this.setState({apiInprogress: true})
    }

    componentDidUpdate(prevProps) {
        let pprops = prevProps
        let nprops = this.props
        if((pprops.customer===null && nprops.customer!==null) || 
            (pprops.customer!==null && nprops.customer===null) || 
            (pprops.customer && nprops.customer && pprops.customer.scope_guid!==nprops.customer.scope_guid)) {
                console.log("have to refresh summarydashboard")
                this.updateSummaryStories()
                this.setState({apiInprogress: true})
            }
    }

    updateSummaryStories = () => {
        let user = this.context;
        //console.log("user", user.idtoken)
        let customer_filter_criteria = DashboardHandler.getCustomerFilterCriteria(this.props.customer)
        let hdrpostd = {"transaction_date": {"literal": "this_month"}, ...customer_filter_criteria, }
        callPostAPI({callname: 'web_get_revenue_story', requestData: hdrpostd, 
                authtoken: user.idtoken, callback: this.revenueStoryCallback})
        callPostAPI({callname: 'web_get_charge_story', requestData: hdrpostd, 
                authtoken: user.idtoken, callback: this.chargeStoryCallback})
        callPostAPI({callname: 'web_get_denial_story', requestData: hdrpostd, 
                authtoken: user.idtoken, callback: this.denialStoryCallback})
        callPostAPI({callname: 'web_get_receivable_story', requestData: hdrpostd, 
                authtoken: user.idtoken, callback: this.receivableStoryCallback})
        
    }

    revenueStoryCallback = (sdata) => {
        console.log("revenue Story callback", sdata)
        if(sdata.status === 'success') {
            this.setState({revenue_story: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
                )
        }
    }

    chargeStoryCallback = (sdata) => {
        console.log("charge Story callback", sdata)
        if(sdata.status === 'success') {
            this.setState({charge_story: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
                )
        }
    }

    denialStoryCallback = (sdata) => {
        console.log("denial Story callback", sdata)
        if(sdata.status === 'success') {
            this.setState({denial_story: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
                )
        }
    }

    receivableStoryCallback = (sdata) => {
        console.log("receivable Story callback", sdata)
        if(sdata.status === 'success') {
            this.setState({receivable_story: sdata, apiInprogress: false})
        } else {
            // show alert about failure
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
                )
        }
    }

    render() {
        const {classes} = this.props

        try {
        
            return(
                <>
                <Container className={classes.top}>
                    {Boolean(this.state.revenue_story) ?
                        <RevenueSummaryText data={this.state.revenue_story} />
                    : null }
                    {Boolean(this.state.charge_story) ? 
                        <SubmissionsSummaryText data={this.state.charge_story} />
                        : null }
                </Container>
                <Container className={classes.top}>
                    {Boolean(this.state.denial_story) ?
                        <DenialSummaryText data={this.state.denial_story} />
                        : null }
                    {Boolean(this.state.receivable_story) ?
                        <ReceivableSummaryText data={this.state.receivable_story} />
                        : null }
                </Container>
                </>
            )
        } catch (err) {
            console.log(err);
            return (
              <React.Fragment>
                <br />Unable to render dashboard, refresh or try changing filter date, provider
              </React.Fragment>
            );  
        }
    }
}

SummaryDashboard.contextType = UserContext; 

const astyle = (theme) => ({
    top: {
        display: 'flex',
        flexDirection: 'row',
        //backgroundColor: theme.palette.primary.main,
        backgroundColor: 'transparent',
        width: '100%',
        marginBottom: 10,
        marginTop: 10,
        paddingTop: 10,
        paddingBottom: 20,
    },
})


export default withStyles(astyle, {withTheme: true})(SummaryDashboard)
