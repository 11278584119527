// PaidChrdAllwd.js
// copyright @ artiss.co

// covers CPT - paid-charged-allowed details

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ResponsiveBar } from '@nivo/bar'

import * as colors from '../../Colours'
import * as utils from '../../services/Utils'
import { FindReplaceSharp } from '@material-ui/icons';

/** 
 *  @function PaidChargedAllowed
 *  @property data   , data from slug 'web_get_*_story'
 *  @property comp   , data from slug 'paid_change_summary' 
 *  @example
 *     <PaidChargedAllowed
            data={this.state.rp_story.data.header}
            comp={this.state.web_slugs2}
            />
 */

const PaidChargedAllowed = (props) => {
    const classes = astyle();
    let {data, comp} = props
    let cd = {index: 'Charged-Allowed-Paid',  allowed: data.allowed, charged: data.charged, paid: data.paid}
    let chartdata = []
    chartdata.push(cd)
    let cardht = 200

    const xoy = (x,y) => {return (<> <sup>x</sup> &frasl; <sub> y </sub> </>) }
    let paidtrend = 0; let paidprevtm = ''; let paidcurrtm = ''; let trendmsg = ''; 
    let trendup = false; let tooltipmsg = ''
    if(Boolean(comp)) {
        paidtrend = comp.data.paid_change_summary[0].paid_amount_change
        paidprevtm = comp.data.paid_change_summary[0].previous_period_display
        paidcurrtm = comp.data.paid_change_summary[0].current_period_display
        tooltipmsg = "comparison between current time period (" + paidcurrtm + 
                        ") and previous time period (" + paidprevtm + ")"
        
        if(paidtrend > 0) {
            trendmsg = utils.showPercent_f2(paidtrend/100) 
            trendup = true
        } else if(paidtrend < 0) {
            trendmsg = utils.showPercent_f2(Math.abs(paidtrend)/100) 
        } 

    }
    
 
    return(
        <Paper className={classes.cardpaper} style={{height: cardht}}> 
        <Typography variant="subtitle1" my={0} py={0}> Allowed-Charged-Paid </Typography>
        
         <ResponsiveBar
            data={chartdata}
            keys={['charged', 'allowed', 'paid']}
            layout="horizontal"
            indexBy="index"
            groupMode={'grouped'}
            height={100}
            margin={{ top: 15, right: 10, bottom: 0, left: 10 }}
            //innerPadding={4}
            padding={0.2}
            innerPadding={2}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            enableLabel={true}
            labelFormat={["$", ".2s"]}       // this gives a warning but works
            //labelFormat={{format: v => `${v}.2s`}}  // this format is supposed to work
            labelSkipWidth={0}
            labelSkipHeight={0}
            //labelTextColor={{ from: 'color' }}
            labelTextColor={'#000000'}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableGridY={false}
            legends={[
                {
                    dataFrom: 'keys',
                    itemTextColor: '#ffffff',
                    height: 80,
                    //anchor: 'bottom',
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: -100,
                    translateY: -10,
                    itemsSpacing: 2,
                    itemWidth: 70,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    },
                }
            
              }}
            />  
            {Boolean(comp) ? 
            <>
                <Box className={classes.topobox}>
                    <sup>Allowed</sup>&frasl;<sub>Charged</sub><Typography variant="h6" my={-5} py={0}>
                    &nbsp; {data.allowed_2_charged.toFixed(0)}%  </Typography> 
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <sup>Paid</sup>&frasl;<sub>Allowed</sub>
                    <Typography variant="h6" my={-5} py={0}>  &nbsp; {data.paid_2_allowed.toFixed(0)}% 
                    </Typography> 
                </Box>
                
                {Boolean(trendup) ? 
                <Box className={classes.topobox}>
                    <Typography variant="subtitle1" my={-5} py={0}> Paid <TrendingUpIcon /> </Typography>
                    <Typography variant="h6" my={-5} py={0}>  {trendmsg} </Typography>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Tooltip title={tooltipmsg}  >
                        <InfoOutlinedIcon  fontSize='small'/>
                    </Tooltip>
                </Box> 
                : 
                <Box className={classes.topobox}>
                <Typography variant="subtitle1" my={-5} py={0}> Paid <TrendingDownIcon />  </Typography>
                &nbsp; <Typography variant="h6" my={-5} py={0}>  {trendmsg} </Typography>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Tooltip title={tooltipmsg} >
                        <InfoOutlinedIcon  fontSize='small'/>
                    </Tooltip>
                </Box>
                }
            </>
                : null }
               

        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        // width: '80%',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        padding: 5,
        margin: 10,
        //height: 110
    },
    scp: {
        backgroundColor: colors.secondarydark,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5
    },
    sctxt: {
        fontSize: 'large'
    },
    msg: {
        width: '50%'
    },
    topobox: {
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center'
        // flexDirection: 'column'
    }
}));

export default PaidChargedAllowed