// Colours.js
// copyrights artiss.co

// ref:
// https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=263238&secondary.color=F44336&primary.text.color=ffffff&secondary.text.color=000000

export const purple600 = '#8e24aa'
export const bluegrey100 = '#cfd8dc'
export const bluegrey200 = '#b0becf'
export const bluegrey300 = '#90a4ae'
export const bluegrey400 = '#78909c'
export const bluegrey500 = '#607d8b'
export const bluegrey600 = '#546e7a'
export const bluegrey700 = '#455a64'
export const bluegrey800 = '#74474f'
export const bluegrey900 = '#263238'
export const brown500 = '#795548'
export const brown600 = '#6d4c41'
export const green600 = '#43a047'
export const orange800 = '#ef6c00'

export const primarybluegrey = bluegrey900
export const primarylight = '#4f5b62'
export const primarydark = '#000a12'
// export const secondaryred = '#f44336'   - red
// export const secondarylight = '#ff7961'
// export const secondarydark = '#ba000d'
export const secondarycolor = '#f57c00'
export const secondarylight = '#ffad42'
export const secondarydark = '#bb4d00'
export const grey400 = '#bdbdbd'
export const grey500 = '#9e9e9e'
export const grey600 = '757575'
export const artissprimary = '#8e24aa'
export const artisslight = '#c158dc'
export const artissdark = '#5c007a'
export const artissdark_lighter = '#6f0094'
export const white = '#FFFFFF'