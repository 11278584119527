// Processas.js
// copyright @ artiss.co


import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ResponsiveBar } from '@nivo/bar'

import * as colors from '../../Colours'
import PaidChargedAllowed from '../common/PaidChrdAllwd';

const ProcessAs = (props) => {
    const classes = astyle();
    let {data} = props
    let primcount=0; let seccount=0; let tercount=0;
    let primpay=0; let secpay=0; let terpay=0;
    for(let i=0; i<data.length; i++) {
        if(data[i].processed_as === 'Primary') {primcount = data[i].payment_count; primpay = data[i].payment_amount}
        else if(data[i].processed_as === 'Secondary') {seccount = data[i].payment_count; secpay = data[i].payment_amount}
        else if(data[i].processed_as === 'Tertiary') {tercount = data[i].payment_count; terpay = data[i].payment_amount }
    }
    let cd = {index: 'count',  primary: primcount, secondary: seccount, tertiary: tercount }
    let pd = {index: 'paid', primary: primpay, secondary: secpay, tertiary: terpay }
    let countchart = []; let paidchart = []
    countchart.push(cd)
    paidchart.push(pd)


    return(
        <Paper className={classes.cardpaper} > 
         <Typography variant="subtitle1" m={0} p={0}> Count of processed as </Typography>
         <ResponsiveBar
            data={countchart}
            keys={['primary', 'secondary', 'tertiary']}
            layout="horizontal"
            indexBy="index"
            groupMode={'stacked'}
            height={40}
            margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
            //innerPadding={4}
            padding={0.4}
            innerPadding={2}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            enableLabel={true}
            //labelFormat={["$", ".2s"]}
            //labelFormat={{format: v => `${v}.2s`}}
            labelSkipWidth={0}
            labelSkipHeight={0}
            //labelTextColor={{ from: 'color' }}
            labelTextColor={'#000000'}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableGridY={false}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    },
                }
            }}
            />  
            <Typography variant="subtitle1" my={0} py={0}> Paid value for processed as </Typography>
            <ResponsiveBar
            data={paidchart}
            keys={['primary', 'secondary', 'tertiary']}
            layout="horizontal"
            indexBy="index"
            groupMode={'stacked'}
            height={40}
            margin={{ top: -5, right: 10, bottom: 0, left: 10 }}
            //innerPadding={4}
            padding={0.4}
            innerPadding={2}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            axisTop={null}
            axisRight={null}
            enableLabel={true}
            labelFormat={["$", ".2s"]}
            //labelFormat={{format: v => `${v}.2s`}}
            labelSkipWidth={0}
            labelSkipHeight={0}
            //labelTextColor={{ from: 'color' }}
            labelTextColor={'#000000'}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableGridY={false}
            legends={[
                {
                    dataFrom: 'keys',
                    itemTextColor: '#ffffff',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: -50,
                    translateY: 5,
                    itemsSpacing: 2,
                    itemWidth: 70,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            theme={{ 
                textColor: 'white',
                tooltip: {
                    container: {
                        background: '#333',
                    },
                }
            }}
            />  
            {/* <Box className={classes.topobox}>
                <Typography variant="caption" my={0} py={0}> Allowed/Charged </Typography>
                <Typography variant="h6" my={-5} py={0}> {data.allowed_2_charged.toFixed(0)}% </Typography>
                <Typography variant="caption" my={0}> Paid/Allowed  </Typography> 
                <Typography variant="h6" my={-5} py={0}> {data.paid_2_allowed.toFixed(0)}% </Typography>
            </Box> */}

        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        // width: '80%',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        padding: 5,
        margin: 10,
        height: 150
    },
    scp: {
        backgroundColor: colors.secondarydark,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5
    },
    sctxt: {
        fontSize: 'large'
    },
    msg: {
        width: '50%'
    },
    topobox: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default ProcessAs