// Claimdetails.js

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import {ClaimHeader} from './Claimutils'
import ShowClaimDetails from './Showclaimdetails'
//import ShowRelated from './Showrelated'
import UserContext from '../../../context/UserContext'
import {callPostAPI} from '../../services/APIHandler'
import StickyTitle from '../../widgets/Stickytitle';
import * as colors from '../../Colours'
import {ShowErrorMsg} from '../../services/ShowmsgtoUsers'

class ClaimDetails extends React.Component {

    constructor(props) {
        super(props)
        //let cd = this.props.location.state.data
        let cd = this.props.data
        let detail = cd.data.detail
        let matx = [{'tx_type': 'service', 'data': cd, 'guid': cd.data.entity.guid}]  // guid of the claim
        matx.push(...detail)
        this.state = { cd: cd,              // claim detail
                        matx: matx,         // array of claim in time seq 
                        related: cd.data.related,
                        observation: null
                        }
    }

    componentDidMount() {
        this.getmoreClaimDetails()
    }

    refreshforNewClaim = () => {
        //console.log( "refresh for newclaim", this.state.cd.data.entity.guid)
        this.getmoreClaimDetails()
        this.setState({}, null)  // setState callback to null
    }

    componentDidUpdate(prevProps) {
        if(this.props.data.data.entity.guid !== prevProps.data.data.entity.guid) {
            //console.log("Claim guid has changed")
            let detail = this.props.data.data.detail
            let matx = [{'tx_type': 'service', 'data': this.props.data, 'guid': this.props.data.data.entity.guid}]  // guid of the claim
            matx.push(...detail)
            // call setState with a callback, this is to make sure that API to get further details of
            // this new claim are called only after setState variable are set, this ensures that 
            // we avoid the siuation where API callback returns but the state variable are not yet 
            // set for the new claim.
            this.setState({cd: this.props.data, matx: matx, related: this.props.data.data.related, 
                                observations: null}, this.refreshforNewClaim)
            }
    }

    getmoreClaimDetails = () => {
        //console.log("calling getmoreClaimDetails..")

        let user = this.context;
        let claimat = this.state.matx

        for(let i=0; i<claimat.length; i++) {
            if(claimat[i].tx_type === 'claim_submission')  {
                let sdata = {}
                //sdata['customer_guid'] = "b1e0f3ac-733b-4f8f-b792-7407add203dc"
                sdata['object_guid'] = claimat[i].guid
                
                callPostAPI({callname: 'web_get_claim_submission', requestData: sdata, 
                            authtoken: user.idtoken, callback: this.updateSubmissionDetailsResponse})

                //api.postCall(sdata, user.idtoken, "web_get_claim_submission", this.updateSubmissionDetailsResponse )
            } else if(claimat[i].tx_type === 'claim_response')  {
                let sdata = {}
                //sdata['customer_guid'] = "b1e0f3ac-733b-4f8f-b792-7407add203dc"
                sdata['object_guid'] = claimat[i].guid
                callPostAPI({callname: 'web_get_claim_response', requestData: sdata, 
                            authtoken: user.idtoken, callback: this.updateResponseDetailsResponse})

                //api.postCall(sdata, user.idtoken, "responsedetail", this.updateResponseDetailsResponse )
            }
        }
        let sdata = {}
        sdata['object_guid'] = this.props.data.data.entity.guid
        sdata['object_type'] = 'claim'
        callPostAPI({callname: 'web_get_observations', requestData: sdata, 
                        authtoken: user.idtoken, callback: this.updateClaimObservation})
    } 

    render() {
        const {classes} = this.props
        
        let cd = this.state.cd
        let header = cd.data.header
        let claimat = this.state.matx
        //console.log('-->', claimat)
        let claimtitle = 'Storyboard for Claim ' + header.claim_number
        
        return(
            <>
            {/* <div className={classes.all} >  */ }
                <div className={classes.stickyhdr}>
                    <StickyTitle title={claimtitle} callback={null} />
                </div>
                <Container className={classes.top} >
                    <ClaimHeader header={header} />
                    <Divider light variant='fullWidth' /> 
                    <ShowClaimDetails data={claimat} />
                    {/* <ShowRelated data={related} /> */}
                </Container>
            { /* </div>  */ }
            </>
        )
    }

    findTLelement = (gid) => {
        let m = this.state.matx
        for(let i=0;i<m.length;i++) {
            if(m[i].guid === gid) {
                return i
            }
        }
        return -1
    }

    updateClaimObservation = (sdata) => {
        console.log("got claimObservations", sdata)
        if(sdata.status !== 'success') {
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
            )
        }
        let omatx = this.state.matx
        let d = sdata.data
        for(let i=0; i<d.length; i++) {
            if(d[i].category !== 'Audit') {
                let tli = this.findTLelement(d[i].entity.guid)
                if(tli !== -1) {
                    if(Boolean(omatx[tli]['obs'])) {
                        let cnt = omatx[tli]['obs'].count; cnt += 1
                        //let dstr = d[i].category + ':' + d[i].title
                        let obsitem = d[i].data
                        let cty = d[i].category
                        if(Boolean(omatx[tli]['obs']['msg'][cty])) {
                            let amsg = omatx[tli]['obs']['msg']
                            let dstr = ""
                            for(const [kv, vv] of Object.entries(obsitem)) {
                                    if(typeof(vv) === 'string')
                                        dstr = dstr + vv + ", "
                                    }
                            let darr = amsg[cty]; dstr = dstr +  d[i].title
                            darr.push(dstr)
                            amsg[cty] = darr
                            omatx[tli]['obs'] = {'show': true, 'count': cnt, 'msg': amsg}
                        } else {
                            let amsg = omatx[tli]['obs']['msg']
                            let darr = []; let dstr = ""
                            for(const [kv, vv] of Object.entries(obsitem)) {
                                if(typeof(vv) === 'string')
                                    dstr = dstr + vv + ", "
                                }
                            dstr = dstr + d[i].title
                            darr.push(dstr)
                            amsg[cty] = darr
                            omatx[tli]['obs'] = {'show': true, 'count': cnt, 'msg': amsg}
                        }
                        //let darr = omatx[tli]['obs']['msg'][cty]
                        // let darr = omatx[tli]['obs']['msg']
                        //darr.push(dstr)
                        //omatx[tli]['obs'] = {'show': true, 'count': cnt, 'msg': darr}
                    } else {
                        let cty = d[i].category
                        let obsitem = d[i].data; let dstr = ""
                        for(const [kv, vv] of Object.entries(obsitem)) {
                            if(typeof(vv) === 'string')
                                dstr = dstr + vv + ", "
                            }
                        dstr = dstr  + d[i].title
                        let ctyentry = { [cty]: [dstr]}
                        //darr.push(dstr)
                        // let dstr = d[i].category + ':' + d[i].title
                        // let darr = []; darr.push(dstr)
                        omatx[tli]['obs'] = {'show': true, 'count': 1,'msg': ctyentry}
                    }   
                    //console.log("---- Observation for: ", omatx[tli]['obs'])
                }  
            }
        }
        this.setState({observation: d, matx: omatx})
    }

    updateSubmissionDetailsResponse = (sdata) => {
        console.log("got submission details", sdata)
        if(sdata.status !== 'success') {
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
            )
        }
        let ma = this.state.matx
        for(let i=0; i<ma.length;i++) {
            if(ma[i].guid === sdata.data.entity.guid &&      // this submission detail is for this entry
                sdata.data.entity.type === "claim_submission") {
                    ma[i]['lineitem'] = []
                    ma[i]['lineitem'].push(...sdata.data.detail.line_items)
                    ma[i]['related'] = []
                    ma[i]['related'].push(...sdata.data.related)
                    //console.log( 'got match for submission data:', i)
                    this.setState({matx: ma})
            }
        }
    }

    updateResponseDetailsResponse= (sdata) => {
        console.log("got response details", sdata)
        if(sdata.status !== 'success') {
            return(
                <ShowErrorMsg msg={sdata.errorMessage} />
            )
        }
        let ma = this.state.matx
        for(let i=0; i<ma.length;i++) {
            if(ma[i].guid === sdata.data.entity.guid &&      // this submission detail is for this entry
                sdata.data.entity.type === "claim_response") {
                    ma[i]['lineitem'] = []
                    if(Boolean(sdata.data.detail.line_items)) {
                        ma[i]['lineitem'].push(...sdata.data.detail.line_items)
                    }
                    ma[i]['related'] = []
                    ma[i]['related'].push(...sdata.data.related)
                    ma[i]['crossover'] = sdata.data.detail.cross_over
                    ma[i]['diff'] = sdata.data.detail.diff_from_previous
                    ma[i]['tables'] = sdata.data.tables
                    //console.log( 'got match for response data:', i)
                    this.setState({matx: ma})
            }
        }
    }

}

ClaimDetails.contextType = UserContext; 

const astyle = (theme) => ({
    all: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 25
        //width: '100%'
        
    },
    top: {
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: colors.bluegrey600,
        backgroundColor: theme.palette.primary.main,
        //width: '95%',
        marginBottom: 10,
        paddingTop: 10,
        paddingBottom: 20,
        // height: 800
        height: '100%',
        alignItems: 'center'
    },
    tlpaper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.bluegrey600,
        flex: 1,
        width: '75%'
        // flexDirection: 'column',
        // alignItems: 'flex-start'
    },
    stickyhdr: {
        position: 'sticky',
        top: 65,
        //width: '80%',
        zIndex: 10,
        //marginBottom: 5,

    }
    
})

export default withStyles(astyle)(ClaimDetails)


