// CPTRevCountTrend.js
// copyright @artiss.co


import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


import { ResponsiveLine } from '@nivo/line'

import * as colors from '../../Colours'
import * as utils from '../../services/Utils'

const RevenueCountTrend = (props) => {
    const classes = astyle();

    let {trenddata} = props
    
    trenddata.sort((a,b) => { if( new Date(a.interval_order) < new Date(b.interval_order))
                                     return -1
                                 else 
                                    return 1 })
    let cntd = []; let paidd = []
    for(let i=0; i<trenddata.length; i++) {
        let d = new Date(trenddata[i].interval_order)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        let year = d.getFullYear()
        let xdate = year + '-' + month + '-' + day
        cntd.push({x: xdate, y: trenddata[i].payment_count})
        paidd.push({x: xdate, y: trenddata[i].payment_amount})
        }

    let countdic = {id: 'count', data: cntd}
    let paiddic = {id: 'paid', data: paidd}
    let counttrend = []; let paidtrend = []
    counttrend.push(countdic)
    paidtrend.push(paiddic)

    // TODO X axis format depends on quarter-month-week
    
    return(
        <Box className={classes.breaktop} >
            <Typography variant="subtitle1">  Trends</Typography>
            <div style={{height: 100}} >
            <ResponsiveLine
                data={counttrend}
                height={110}
                margin={{ top: 5, right: 15, bottom: 20, left: 70 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                lineWidth={3}
                enableGridX={true}
                enableGridY={true}
                axisTop={null}
                axisRight={null}
                enableArea={true}
                areaOpacity={0.7}
                areaBlendMode={'multiply'}
                axisBottom={null}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 5,
                    legend: 'volume count',
                    legendOffset: -60,
                    legendPosition: 'middle'
                }}
                colors={{ scheme: 'category10' }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={4}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh={true}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    },
                    grid: {
                        line: {
                          stroke: "DarkSlateGrey",
                          strokeWidth: 1,
                          //strokeDasharray: "4 4"
                        }
                      }
                
                  }}
                />
                </div>
            
                <div style={{height: 200}} >
            <ResponsiveLine
                data={paidtrend}
                height={200}
                margin={{ top: 10, right: 15, bottom: 60, left: 70 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                //yFormat={["$", ".2s"]}
                lineWidth={3}
                enableGridX={true}
                enableGridY={true}
                axisTop={null}
                axisRight={null}
                enableArea={true}
                areaOpacity={0.7}
                areaBlendMode={'multiply'}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 10,
                    tickRotation: -40,
                    //legend: 'time',
                    //legendOffset: 20,
                    //legendPosition: 'left',
                    //format: values => { return utils.monYearStr(values) } 
                    format: values => { return utils.toDateStr(values) } 
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 6,
                    tickRotation: 0,
                    tickValues: 6,
                    legend: 'paid',
                    legendOffset: -60,
                    legendPosition: 'middle',
                    format:"$,.2s"
                }}
                colors={{ scheme: 'category10' }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={4}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="y"
                pointLabelYOffset={-12}
                useMesh={true}
                theme={{ 
                    textColor: 'white',
                    tooltip: {
                        container: {
                            background: '#333',
                        },
                    },
                    grid: {
                        line: {
                          stroke: "DarkSlateGrey",
                          strokeWidth: 1,
                          //strokeDasharray: "4 4"
                        }
                      }
                  }}
            

                // legends={[
                //     {
                //         anchor: 'bottom-right',
                //         direction: 'column',
                //         justify: false,
                //         translateX: 100,
                //         translateY: 0,
                //         itemsSpacing: 0,
                //         itemDirection: 'left-to-right',
                //         itemWidth: 80,
                //         itemHeight: 20,
                //         itemOpacity: 0.75,
                //         symbolSize: 12,
                //         symbolShape: 'circle',
                //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
                //         effects: [
                //             {
                //                 on: 'hover',
                //                 style: {
                //                     itemBackground: 'rgba(0, 0, 0, .03)',
                //                     itemOpacity: 1
                //                 }
                //             }
                //         ]
                //     }
                // ]}
            />
            </div>
            
            
        </Box>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    breaktop: {
        width: '95%',
        //height: 120,
        //flex: 1,
        flexDirection: 'column',
        //justifyContent: 'flex-start',
        alignItems: 'flex-start',
        //backgroundColor: colors.primarydark,
        paddingBottom: 10,
        marginBottom: 40,
        //height: 75,
        marginTop: 50,
        //position: 'relative',
    },
    pie1: {
        position: 'fixed',
        left: 10
    },
    scp: {
        backgroundColor: colors.secondarydark,
    },
    sctxt: {
        fontSize: 'large'
    },
    
}));

export default RevenueCountTrend
