import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import SearchContainer from "./SearchContainer";
import SearchResultsList from './SearchResultsList';
//import * as colors from '../Colours';

//aka tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{width: '50vw', height: '80vh'}}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  
}));

//needed for "button in tabs" issue per https://stackoverflow.com/questions/58103542/material-ui-button-in-a-tab-list
const ButtonInTabs = ({ style, onClick, children }) => {
  return (
    <div style={style} onClick={onClick} children={children}>
      <IconButton onClick={() => {onClick()}}>
        <CloseIcon />
      </IconButton>
    </div>    
  );
};

export default function SearchTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  let { searchResults, categorizedSearchResults, requested } = props;//setRequested
  //console.log("SearchTabs categorizedSearchResults: ", categorizedSearchResults);
  //console.log("SearchTabs categorizedSearchResults.keys: ", Object.keys(categorizedSearchResults));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //indicatorColor="primary", textColor="primary"      

  return (
    <div className={classes.root}>
    <AppBar position="static">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Search Results"
        className={classes.root}
      >
        <Tab label="All" {...a11yProps(0)} />
        {categorizedSearchResults && Object.keys(categorizedSearchResults) && Object.keys(categorizedSearchResults).map(function(key, i){
          //console.log("SearchTabs categorizedSearchResults key: ", key);
          let tabLabel = key + " (" + categorizedSearchResults[key].data.results.length + ")";
          return <Tab key={i} label={tabLabel} {...a11yProps(i+1)} />;
        })}        
        <ButtonInTabs style={{"marginLeft":"auto", "marginRight":"10"}} onClick={() => {SearchContainer.hide()}}>
        </ButtonInTabs>      
      </Tabs>
    </AppBar>
    <TabPanel key={0} value={value} index={0} 
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <Typography>Showing top results for "{requested}":</Typography>
      <SearchResultsList searchres={searchResults} />
      {/*<button onClick={() => {setRequested('item one');}}>Set Search</button>*/}
    </TabPanel>
    {categorizedSearchResults && Object.keys(categorizedSearchResults) && Object.keys(categorizedSearchResults).map(function(key, i){
      //console.log("SearchTabs categorizedSearchResults key: ", key);
      //console.log("SearchTabs categorizedSearchResults categorizedSearchResults[key]: ", categorizedSearchResults[key]);
      return (
        <TabPanel key={i+1} value={value} index={i+1} 
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
          <Typography>Showing top {key}s for "{requested}":</Typography>
          <SearchResultsList searchres={categorizedSearchResults[key]} />
        </TabPanel>
      );
    })}
  </div>
  )
}