import React from 'react';

//tabs
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

//start tabs functions
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={0}>
            {children}
            </Box>
        )}
        </div>
    );
}
    
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};
    
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}
    
//needed for "button in tabs" issue per https://stackoverflow.com/questions/58103542/material-ui-button-in-a-tab-list
function ButtonInTabs({ style, onClick, children }) {
    return (
        <div style={style} onClick={onClick} children={children}>
        <IconButton onClick={() => {onClick()}}>
            <CloseIcon />
        </IconButton>
        </div>    
    );
};    
//end tabs functions

export { 
    TabPanel, a11yProps, ButtonInTabs
}