import React from 'react';
import { Container, Box, Paper, Link } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
/*
import Popover from "@material-ui/core/Popover";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "../../App.css";
import Card from "../widgets/Card";
import * as MaterialCard from "@material-ui/core/Card";
import * as MaterialCardContent from "@material-ui/core/CardContent";
import BarChart from "../widgets/charts/nivo/BarChart";
import BarChartHorizontal from "../widgets/charts/nivo/BarChartHorizontal";
import LineChart from "../widgets/charts/nivo/LineChart";
import PieChart from '../widgets/charts/nivo/PieChart';
//import TreeMapChart from '../widgets/charts/nivo/TreeMapChart';
import * as TabHandler from "../dashboards/common/TabHandler";
*/

//dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import StickyTitle from '../../Stickytitle';
import Typography from '@material-ui/core/Typography';
import * as colors from '../../../Colours';

import * as APIHandler from '../../../services/APIHandler';
import * as Utils from '../../../services/Utils';

import * as ExpandedChartHandler from "./ExpandedChartHandler";
import ExpandedChart from "./ExpandedChart";
import ListAsTable from "./ListAsTable";
import ListAsCSV from "./ListAsCSV";

class ExpandedChartContainer extends React.Component {
    constructor(props) {    
        super(props);
        console.log("ExpandedChartContainer props: ", props);

        this.listTableRef = React.createRef();

        var newState = ExpandedChartHandler.getNewState();

        let listAges = [];
        for (let i=0; i < 120; i++) {
            listAges.push({guid: "" + i, title: "" + i});
        }

        //e.g. "filter_fields": _claim_number,cpt,customer_guid,options,payor_guid,referring_provider_guid,rendering_provider_guid,transaction_date"
        //used: cpt payor_guid referring_provider_guid rendering_provider_guid transaction_date"
        //skipped: _claim_number customer_guid options
        let filter_fields_array = props.meta.meta_data.filter_fields.split(',');

        let incomingSlugData = {};
        incomingSlugData[props.meta._slug] = props.dataInput;
        console.log("incomingSlugData: ", incomingSlugData);
        let newState2 = {
            ...newState,

            //init to source chart data
            response: { 
                status: "success",
                //data: this.props.dataInput,
                data: incomingSlugData
            },

            //dateRangeFilter: {filterName: "yesterday",label: "Yesterday"},     
            payor_guid: '',
            payor: '',
            plan_guid: '',
            plan: '',
            serviceClass_guid: '',
            serviceClass: '',
            //TODO: set duration_guid from incoming metadata originally
            //"transaction_date": {"literal":this.state.dateRangeFilter.filterName},
            //transaction_date: {
                //literal: this.state.duration_guid ? this.state.duration_guid : "last_3_months", //"all_data",
            //},
            duration_guid: '',
            duration: '',
            count_guid: '',
            count: '',
            cpt_guid: '',
            cpt: '',
            referring_provider_guid: '',
            referring_provider: '',
            patient_state_guid: '',
            patient_state: '',
            patient_age_guid: '',
            patient_age: '',

            //lists:
            listCPTs: [],
            listServiceClasses: [],
            listPlans: [],
            listPayors: [],
            listReferringProviders: [],

            listDuration: [
                {title: 'Yesterday', guid: 'yesterday'},
                {title: 'Last 7 Days', guid: 'last_7_days'},
                {title: 'Last 4 Weeks', guid: 'last_4_weeks'},
                //{title: 'Last 8 Weeks', guid: 'last_8_weeks'},
                {title: 'Last 3 Months', guid: 'last_3_months'},
                {title: 'Last 4 Quarters', guid: 'last_4_quarters'},
                {title: 'Year To Date', guid: 'year_to_date'},
                {title: 'All Data', guid: 'all_data'}
            ],

            listCount: [
                {title: 'Top 10', guid: 'top_10'},
                {title: 'Top 20', guid: 'top_20'},
                {title: 'Bottom 10', guid: 'bottom_10'},
                {title: 'Bottom 20', guid: 'bottom_20'},
                {title: 'All', guid: 'all'},
            ],

            listStates: [
                {title: "AL", guid: "AL"},
                {title: "AK", guid: "AK"},
                {title: "AZ", guid: "AZ"},
                {title: "AR", guid: "AR"},
                {title: "CA", guid: "CA"},
                {title: "CO", guid: "CO"},
                {title: "CT", guid: "CT"},
                {title: "DE", guid: "DE"},
                {title: "FL", guid: "FL"},
                {title: "GA", guid: "GA"},
                {title: "HI", guid: "HI"},
                {title: "ID", guid: "ID"},
                {title: "IL", guid: "IL"},
                {title: "IN", guid: "IN"},
                {title: "IA", guid: "IA"},
                {title: "KS", guid: "KS"},
                {title: "KY", guid: "KY"},
                {title: "LA", guid: "LA"},
                {title: "ME", guid: "ME"},
                {title: "MD", guid: "MD"},
                {title: "MA", guid: "MA"},
                {title: "MI", guid: "MI"},
                {title: "MN", guid: "MN"},
                {title: "MS", guid: "MS"},
                {title: "MO", guid: "MO"},
                {title: "MT", guid: "MT"},
                {title: "NE", guid: "NE"},
                {title: "NV", guid: "NV"},
                {title: "NH", guid: "NH"},
                {title: "NJ", guid: "NJ"},
                {title: "NM", guid: "NM"},
                {title: "NY", guid: "NY"},
                {title: "NC", guid: "NC"},
                {title: "ND", guid: "ND"},
                {title: "OH", guid: "OH"},
                {title: "OK", guid: "OK"},
                {title: "OR", guid: "OR"},
                {title: "PA", guid: "PA"},
                {title: "RI", guid: "RI"},
                {title: "SC", guid: "SC"},
                {title: "SD", guid: "SD"},
                {title: "TN", guid: "TN"},
                {title: "TX", guid: "TX"},
                {title: "UT", guid: "UT"},
                {title: "VT", guid: "VT"},
                {title: "VA", guid: "VA"},
                {title: "WA", guid: "WA"},
                {title: "WV", guid: "WV"},
                {title: "WI", guid: "WI"},
                {title: "WY", guid: "WY"},
            ],

            listAges: listAges,

            isLoadingCount: 0,
            //response: null,

            filter_fields_array: filter_fields_array,
        };
        console.log("newState2: ", newState2);
        this.state = newState2;
    }  

    componentDidMount() {
        this.getDataForPage();

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);  
    }

    componentDidUpdate(prevProps,prevState) {
        let componentDidUpdateFlag = ExpandedChartHandler.componentDidUpdate(prevProps, prevState, this.props, this.state);
        console.log("componentDidUpdateFlag: ", componentDidUpdateFlag);
        if (componentDidUpdateFlag) {
            this.getDataForPage();       
        }
        else {
            if (this.state.responseList && this.state.responseList.status=="success" && this.state.responseList.data) {
                const listTableElement = this.listTableRef.current;
                //</div>const element = document.getElementById(this.state.media);
                listTableElement.scrollIntoView({behavior: 'smooth'});     
            }    
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    //https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    getDataForPage() {
        console.log("ExpandedChartContainer getDataForPage props: ", this.props);
        if (!this.props.user) return;
        let authtoken = this.props.user.idtoken;
        if (!authtoken) return;
        let customer_filter_criteria = ExpandedChartHandler.getCustomerFilterCriteria(this.props.customer);
        console.log("customer_filter_criteria: ", customer_filter_criteria);

        let requestDataForPage = {
            "table_slugs": [                
            ],
            "filter_criteria": {
                ...customer_filter_criteria,
                //"transaction_date": {"literal":this.state.dateRangeFilter.filterName},
                //"options":{"set":"top","limit":10}
            }
        };

        this.setState({isLoadingCount: 5});
        ////APIHandler.callPostAPI({callname: "web_query_table", requestData: requestDataForPage, authtoken: authtoken, callback: this.updateResponseForPage});
        APIHandler.callPostAPI({callname: "web_get_meta_cpts", requestData: requestDataForPage, authtoken: authtoken, callback: this.updateResponseForCPTs});
        APIHandler.callPostAPI({callname: "web_get_meta_service_classes", requestData: requestDataForPage, authtoken: authtoken, callback: this.updateResponseForServiceClasses});
        APIHandler.callPostAPI({callname: "web_get_meta_plans", requestData: requestDataForPage, authtoken: authtoken, callback: this.updateResponseForPlans});
        APIHandler.callPostAPI({callname: "web_get_meta_payor", requestData: requestDataForPage, authtoken: authtoken, callback: this.updateResponseForPayors});
        APIHandler.callPostAPI({callname: "web_get_referring_physicians", requestData: requestDataForPage, authtoken: authtoken, callback: this.updateResponseForReferringProviders});

        this.setState({isLoadingCount: 0});
    }  

    updateLoadingDone = () => {
        this.setState((state) => ({
            isLoadingCount: state.isLoadingCount - 1,
        }));
    };

    updateResponseForCPTs = (response) => {
        //console.log("updateResponseForCPTs: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        //console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listCPTs: response});
        this.updateLoadingDone();
    }
    
    updateResponseForServiceClasses = (response) => {
        //console.log("updateResponseForServiceClasses: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        //console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listServiceClasses: response});
        this.updateLoadingDone();
    }
    
    updateResponseForPlans = (response) => {
        console.log("updateResponseForPlans: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listPlans: response});
        this.updateLoadingDone();
    }
    
    updateResponseForPayors = (response) => {
        //console.log("updateResponseForPayors: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        //console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listPayors: response});
        this.updateLoadingDone();
    }
    
    updateResponseForReferringProviders = (response) => {
        //console.log("updateResponseForReferringProviders: ", response);
        response.data = Utils.sortJSONObjectArrayByKey(response.data, "title");
        //console.log("updateResponseForCPTs response.data: ", response.data);
        //response.data.unshift({guid: "", title: "None"});
        this.setState({listReferringProviders: response});
        this.updateLoadingDone();
    }

    
    updateResponseForChart = (response) => {
        console.log("updateResponseForChart: ", response);
        
        //https://github.com/ArtissLST/customer-ui/issues/248#issuecomment-782857829
        //TODO: 
        //WHAT: process response data for those charts that don't use slug response data directly but instead need it pre-processed
        //WHERE: this needs to be done on all slugs; review this feature across all dashboards -> all charts. 
        //HOW: move the data processing to a service helper ChartDataProcessor / SlugDataToChartDataMapper? that will be called from; 
        //(1) dashboard and (2) ExpandedChart (this!) (3) Search/NLP later.  
        //-- use ChartType along with SlugName to future-proof each processor?
        //ALSO: link slug and chart type (Pie, Bar, Bar H, Line etc), so it can be rendered via search/NLP. SlugChartMapper / SlugToChartLinker?
        //PLUS: review all the above for the PlusCharts

        this.setState({response: response});
        //reset the list since its no longer relevant:
        this.setState({responseList: null});
        //this.setState({responseData: response.data[this.props.meta._slug]})
        this.updateLoadingDone();
    };

    updateResponseForList = (responseList) => {
        console.log("updateResponseForList: ", responseList);
        this.setState({
            responseList: responseList,  
            showListAsCSV: false,
        });
        //this.setState({responseData: response.data[this.props.meta._slug]})
        this.updateLoadingDone();
    };

    /*
    onChangeDateRangeFilter = (selectedFilter) => {
        console.log("onChangeDateRangeFilter: ", selectedFilter);
        let prevDateRangeFilter = Object.assign({}, this.state.dateRangeFilter);
        this.setState({
            dateRangeFilter: {
                filterName: selectedFilter.daterange.literal, 
                label: selectedFilter.datetext
            }, 
            prevDateRangeFilter: prevDateRangeFilter
        });
    }
    */

    /*
    handleChangePayor = (value) => {
        console.log("handleChangePayor: ", value);
        this.setState({payor_guid: value.guid});
        this.setState({payor: value.title});
    }

    handleChangePlan = (event) => {
        console.log("handleChangePlan: ", event.target.value);
        this.setState({plan_guid: event.target.value});
    }

    handleChangeServiceClass = (event) => {
        console.log("handleChangeServiceClass: ", event.target.value);
        this.setState({serviceClass_guid: event.target.value});
    }

    handleChangeCPT = (event) => {
        console.log("handleChangeCPT: ", event.target.value);
        this.setState({cpt_guid: event.target.value});
    }

    handleChangeReferringProvider = (event) => {
        console.log("handleChangeReferringProvider: ", event.target.value);
        this.setState({referring_provider_guid: event.target.value});
    }

    handleChangeDuration = (event) => {
        console.log("handleChangeDuration: ", event.target.value);
        this.setState({duration_guid: event.target.value});
    }
    */

    applyFilter = (applyFilterOptions) => {
        let applyFilterOptions_Criteria = (applyFilterOptions && applyFilterOptions.filter_criteria) ? applyFilterOptions.filter_criteria : undefined;
        console.log("applyFilter applyFilterOptions_Criteria: ", applyFilterOptions_Criteria);        
        let callAPI = (applyFilterOptions && applyFilterOptions.callAPI) ? applyFilterOptions.callAPI : "web_query_table";

        console.log("this.props: ", this.props);
        let authtoken = this.props.user.idtoken;
        if (!authtoken) return;
        let customer_filter_criteria = ExpandedChartHandler.getCustomerFilterCriteria(this.props.customer);
        console.log("applyFilter customer_filter_criteria: ", customer_filter_criteria);        
        let options;
        switch (this.state.count_guid) {
            case "top_10": options = {set:"top",limit: 10}; break;
            case "top_20": options = {set:"top",limit: 20}; break;
            case "bottom_10": options = {set:"bottom",limit: 10}; break;
            case "bottom_20": options = {set:"bottom",limit: 20}; break;
        }
        let requestData = {
            "table_slugs": [
                //get table slug from metadata
                this.props.meta._slug,
            ],
            "filter_criteria": {
                ...customer_filter_criteria,
                patient_state: this.state.patient_state_guid ? this.state.patient_state_guid : undefined,
                patient_age: this.state.patient_age ? (!isNaN(this.state.patient_age) ? parseInt(this.state.patient_age) : undefined) : undefined,
                cpt: this.state.cpt_guid ? this.state.cpt_guid : undefined,
                payor_guid: this.state.payor_guid ? this.state.payor_guid : undefined,
                transaction_date: {
                    literal: this.state.duration_guid ? this.state.duration_guid : "last_3_months", //"all_data",
                },
                //options:{"set":"top","limit":10},
                options: this.props.meta && this.props.meta._filter && this.props.meta._filter.options ? this.props.meta._filter.options : undefined,
                referring_provider_guid: this.state.referring_provider_guid ?  this.state.referring_provider_guid : undefined,
                service_class: this.state.serviceClass_guid ?  this.state.serviceClass_guid : undefined,
                payor_plan: this.state.plan_guid ?  this.state.plan_guid : undefined,

                ...applyFilterOptions_Criteria,//this will override CPT etc with whats clicked
                options: options,//{set:"top",limit: 10},

            }
        };

        console.log("applyFilter called: this.state: ", this.state);
        console.log("applyFilter called: requestData: ", requestData);        

        //reset state ahead of call:
        this.setState({responseList: null});

        this.setState({isLoadingCount: this.state.isLoadingCount + 1});
        APIHandler.callPostAPI({
            callname: callAPI,//"web_query_table",
            requestData: requestData, 
            authtoken: authtoken, 
            callback: (callAPI=="web_query_table") ? this.updateResponseForChart : this.updateResponseForList
        });
    }

    buildFilterCriteria = (options) => {
        console.log("ExpandedChart buildFilterCriteria options: ", options);
        let criteria = {};
        switch (options.slug) {
            case "li_avoidable_by_icd": criteria = {"icd_code": options.onClickChartData.node.id}; break;
            case "li_avoidable_by_cpt": criteria = {"cpt_code": options.onClickChartData.node.id}; break;
        }
        console.log("ExpandedChart buildFilterCriteria criteria: ", criteria);
        return criteria;
    }

    onClickChart = (options) => {
        console.log("ExpandedChart onClickChart options: ", options);
        let criteria = this.buildFilterCriteria({onClickChartData: options, slug: this.props.meta._slug});
        this.applyFilter({
            callAPI: "web_get_raw_data",
            filter_criteria: criteria,
        });
    }

    setShowListAsCSV = () => {
        this.setState({
            showListAsCSV: true,
        });        
    }

    render() {
        console.log("this.state: ", this.state);
        try {      
            const {classes} = this.props;
            const { theme } = this.props;

            let cs1 = {
                style: "currency",
                currency: "USD",
                minimumSignificantDigits: 2,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            };    

            let messagePayor = "", messagePlan = "", messageCPT = "", messageServiceClass = "", messageReferringProvider = "", messageDuration = "", messageStates = "", messageAge = "";

            let response = this.state.response;
            //let dateRangeFilter = this.state.dateRangeFilter;
        
            let chartWidth=this.state.width*0.25;
            let chartHeight=this.state.height*0.25;

            return (
                <Container maxWidth={false} disableGutters={true} className={classes.top}  p={0} m={0}
                    //className={classes.sectionpaper} 
                >
                    {/*<Box p={0} m={0}>*/}

                    {/*
                    {(this.state.listPayors && this.state.listPayors.status!=="success") && <div>Error loading Payors</div>}
                    {(this.state.listPlans && this.state.listPlans.status!=="success") && <div>Error loading Plans</div>}
                    {(this.state.listServiceClasses && this.state.listServiceClasses.status!=="success") && <div>Error loading Service Classes</div>}
                    {(this.state.listCPTs && this.state.listCPTs.status!=="success") && <div>Error loading CPTs</div>}
                    {(this.state.listReferringProviders && this.state.listReferringProviders.status!=="success") && <div>Error loading ReferringProviders</div>}
                    */}

                    {/* 
                    e.g (filter_fields_array.indexOf("payor_guid") > -1) 
                    payor_guid cpt referring_provider_guid rendering_provider_guid transaction_date                    
                    */}

                    <Paper className={classes.sectionpaper} p={0} m={0}>
                        <div className={classes.stickyhdr_not}>
                            <div style={{backgroundColor: theme.palette.primary.dark,display: 'flex',justifyContent: 'space-between',borderRadius: 4}}>
                                {this.props.subtitle ? 
                                <>
                                    <Typography style={{marginLeft: 10}} variant={'h4'} > {this.props.title} 
                                    {/*<Typography variant={'subtitle1'} > {this.props.subtitle} </Typography>*/}
                                    </Typography>
                                </>
                                : <Typography style={{marginLeft: 10}} variant={'h4'} > {this.props.title} </Typography>
                                }
                                {this.props.handleClose && 
                                    <IconButton style={{marginRight: 50}} onClick={() => {this.props.handleClose()}}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                            </div>
                        </div>

                        {/*<div className={classes.dtr}>*/}
                        {/*<Box p={0} m={0} mx={2}>*/}
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" p={0} m={0}>
                            <Box className={classes.filterColumn} display="flex" flexDirection="column" justifyContent="flex-start" p={1} my={0.5}>
                                <Box p={0} my={0.5}>
                                    {(this.state.filter_fields_array.indexOf("payor_guid") > -1) && <FormControl className={classes.formControl} fullWidth={true}>
                                        <InputLabel shrink disableAnimation required id="payor-label">Payor</InputLabel>                                
                                        {this.state.listPayors && this.state.listPayors.data && <Autocomplete
                                            id="payor" 
                                            className={classes.selectEmpty} 
                                            onChange={(event, value) => {
                                                if (value) this.setState({payor_guid: value.guid, payor: value.title, responseList: null});
                                                else this.setState({payor_guid: "", payor: "None", responseList: null});
                                            }}
                                            options={this.state.listPayors.data}
                                            getOptionLabel={(option) => {return option.title;}}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                        />}
                                        <FormHelperText>{messagePayor}</FormHelperText>
                                    </FormControl>} 
                                </Box>    
                                <Box p={0} my={0.5}>
                                    {(this.state.filter_fields_array.indexOf("plan_guid") > -1) && <FormControl className={classes.formControl} fullWidth={true}>
                                        <InputLabel shrink disableAnimation required id="plan-label">Plan</InputLabel>                                
                                        {this.state.listPlans && this.state.listPlans.data && <Autocomplete
                                            id="plan" 
                                            className={classes.selectEmpty} 
                                            onChange={(event, value) => {
                                                if (value) this.setState({plan_guid: value.guid, plan: value.title, responseList: null});
                                                else this.setState({plan_guid: "", plan: "None", responseList: null});
                                            }}
                                            options={this.state.listPlans.data}
                                            getOptionLabel={(option) => {return option.title;}}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                        />}
                                        <FormHelperText>{messagePlan}</FormHelperText>
                                    </FormControl>} 
                                </Box>                                                     
                                <Box p={0} my={0.5}>
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <InputLabel shrink disableAnimation required id="duration-label">Duration</InputLabel>                                
                                        {this.state.listDuration && <Autocomplete
                                            id="duration" 
                                            className={classes.selectEmpty} 
                                            onChange={(event, value) => {
                                                if (value) this.setState({duration_guid: value.guid, duration: value.title, responseList: null});
                                                else this.setState({duration_guid: "", duration: "None", responseList: null});
                                            }}
                                            options={this.state.listDuration}
                                            getOptionLabel={(option) => {return option.title;}}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                        />}
                                        <FormHelperText>{messageDuration}</FormHelperText>
                                    </FormControl>
                                </Box>   
                                <Box p={0} my={0.5}>
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <InputLabel shrink disableAnimation required id="duration-label">Count</InputLabel>                                
                                        {this.state.listCount && <Autocomplete
                                            id="count" 
                                            className={classes.selectEmpty} 
                                            onChange={(event, value) => {
                                                if (value) this.setState({count_guid: value.guid, count: value.title, responseList: null});
                                                else this.setState({count_guid: null, count: "None", responseList: null});
                                            }}
                                            options={this.state.listCount}
                                            getOptionLabel={(option) => {return option.title;}}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                        />}
                                        <FormHelperText>{messageDuration}</FormHelperText>
                                    </FormControl>
                                </Box>   
                                <Box p={0} my={0.5}>
                                    {(this.state.filter_fields_array.indexOf("serviceClass_guid") > -1) && <FormControl className={classes.formControl} fullWidth={true}>
                                        <InputLabel shrink disableAnimation id="service-class">Service Class</InputLabel>                                
                                        {this.state.listServiceClasses && this.state.listServiceClasses.data && <Autocomplete
                                            id="service-class" 
                                            className={classes.selectEmpty} 
                                            onChange={(event, value) => {
                                                if (value) this.setState({serviceClass_guid: value.guid, serviceClass: value.title, responseList: null});
                                                else this.setState({serviceClass_guid: "", serviceClass: "None", responseList: null});
                                            }}
                                            options={this.state.listServiceClasses.data}
                                            getOptionLabel={(option) => {return option.title;}}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                        />}
                                        <FormHelperText>{messageServiceClass}</FormHelperText>
                                    </FormControl>} 
                                </Box>   
                                <Box p={0} my={0.5}>
                                    {(this.state.filter_fields_array.indexOf("cpt") > -1) && <FormControl className={classes.formControl} fullWidth={true}>
                                        <InputLabel shrink disableAnimation id="cpt">CPT</InputLabel>                                
                                        {this.state.listCPTs && this.state.listCPTs.data && <Autocomplete
                                            id="cpt" 
                                            className={classes.selectEmpty} 
                                            onChange={(event, value) => {
                                                if (value) this.setState({cpt_guid: value.guid, cpt: value.title, responseList: null});
                                                else this.setState({cpt_guid: "", cpt: "None", responseList: null});
                                            }}
                                            options={this.state.listCPTs.data}
                                            getOptionLabel={(option) => {return option.title;}}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                        />}
                                        <FormHelperText>{messageCPT}</FormHelperText>
                                    </FormControl>}
                                </Box>   
                                <Box p={0} my={0.5}>
                                    {(this.state.filter_fields_array.indexOf("referring_provider_guid") > -1) && <FormControl className={classes.formControl} fullWidth={true}>
                                        <InputLabel shrink disableAnimation id="referring_provider">Referring Physician</InputLabel>                                
                                        {this.state.listReferringProviders && this.state.listReferringProviders.data && <Autocomplete
                                            id="referring_provider" 
                                            className={classes.selectEmpty} 
                                            onChange={(event, value) => {
                                                if (value) this.setState({referring_provider_guid: value.guid, referring_provider: value.title, responseList: null});
                                                else this.setState({referring_provider_guid: "", referring_provider: "None", responseList: null});
                                            }}
                                            options={this.state.listReferringProviders.data}
                                            getOptionLabel={(option) => {return option.title;}}
                                            fullWidth={true}
                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" InputLabelProps={{shrink: true}} fullWidth={true} />}
                                        />}
                                        <FormHelperText>{messageReferringProvider}</FormHelperText>
                                    </FormControl>}
                                </Box> 
                                <Box p={1} m={0.5} display="flex" flexDirection="column" justifyContent="center" >
                                    <Button variant="contained" onClick={() => { this.applyFilter() }}>Apply</Button>
                                    <div style={{marginBottom: 5}}></div> 
                                    <Button variant="contained" onClick={() => { this.applyFilter({callAPI: "web_get_raw_data"}) }}startIcon={<ExitToAppIcon />}>Export As CSV</Button>
                                </Box>                            
                            </Box>

                            <Box>
                                {this.state.response && this.state.response.status!="success" && <React.Fragment />}                          
                                {this.state.response && this.state.response.status=="success" && this.state.response.data && 
                                    <Box display="flex" flexDirection="column" justifyContent="flex-start" p={0} m={0}>
                                        <Box display="flex" flexDirection="row" justifyContent="flex-start" p={0} m={0}>
                                            <ExpandedChart 
                                                {...this.props} 
                                                dataInput={this.state.response.data[this.props.meta._slug]} 
                                                //width={this.props.width * 2}
                                                width={this.state.width * 0.8}
                                                //height={this.props.height * 2}
                                                height={this.state.height * 0.8}
                                                //onClickChart={() => this.onClickChart()}
                                                onClickChart={this.onClickChart}
                                            />
                                        </Box>
                                        <div style={{marginBottom: 20}}></div> 
                                    </Box>
                                }  
                                {/*</Box>*/}
                            </Box>
                        </Box>
                        <Box>
                            <div ref={this.listTableRef}>
                                {this.state.responseList && this.state.responseList.status=="success" && this.state.responseList.data && 
                                this.state.responseList.data[this.props.meta._slug] && 
                                this.state.responseList.data[this.props.meta._slug].length > 50 &&
                                <ListAsCSV data={this.state.responseList.data[this.props.meta._slug]} />}
                                {this.state.responseList && this.state.responseList.status=="success" && this.state.responseList.data && 
                                this.state.responseList.data[this.props.meta._slug] && 
                                this.state.responseList.data[this.props.meta._slug].length <= 50 && 
                                <Box>
                                    <Typography>
                                        &nbsp;Listing {this.state.responseList.data[this.props.meta._slug].length} rows.&nbsp; 
                                        <Link href="#" onClick={() => { this.setShowListAsCSV() }} color="inherit" underline="always">Export List As CSV</Link>
                                        {/*<Link style={{float: "right", "margin-right": 10}} href="#" onClick={() => { this.setShowListAsCSV() }} color="inherit" underline="always">Export List As CSV</Link>*/}
                                    </Typography>                                                                
                                    <ListAsTable data={this.state.responseList.data[this.props.meta._slug]} />
                                    {this.state.showListAsCSV && <ListAsCSV data={this.state.responseList.data[this.props.meta._slug]} />}
                                </Box>}
                            </div>
                            <div style={{marginBottom: 20}}></div>                                 
                        </Box>
                    </Paper>
                    {/*</Box>*/}
                </Container>      
            )
        }
        catch (err) {
            if (this.state.isLoadingCount > 0) {
                return (
                    <React.Fragment>
                    </React.Fragment>
                );      
            }
            else {
                console.log(err);
                return (
                    <React.Fragment>
                    <br />Error rendering this page
                    </React.Fragment>
                );      
            }
        }  
    }
}

const astyle = (theme) => {
    let existingTheme = ExpandedChartHandler.getTheme(theme); 
    let currentTheme = {
        ...existingTheme, 
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'flex-start'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        dtr: {
            display: 'flex',
            flexDirection: 'column'
        },
        //https://stackoverflow.com/questions/54491645/media-query-syntax-for-reactjs
        filterColumn: {
            '@media(max-width: 1000px)': {
                width: '33%',
            },
            '@media(min-width: 1001px)': {
                width: '20%',
            }
        }
    }    
    return currentTheme;
}

export default withStyles(astyle, {withTheme: true})(ExpandedChartContainer)
