import React from "react";

import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
//import { Typography } from '@material-ui/core';
//import { FormatListNumbered } from "@material-ui/icons";
import ReactJson from 'react-json-view'
import { Visibility } from '@material-ui/icons';

import * as colors from '../Colours';

/** 
 * @component
 * @ChartInspector
 * @param title
 * @param renderedData
 * @param meta
 * @return renders popup that shows web query sent to the backed
 * 
 */

class ChartInspector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    show = () => {
        this.setState({ show: true });
    }

    hide = () => {
        this.setState({ show: false });
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
        this.show();
    };
    
    handleClose = () => {
        this.setState({anchorEl: null});
        this.hide();
    };
    
    //https://github.com/mac-s-g/react-json-view/issues/131
	handleCopy = (copy) => {
		navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'))
	}    

    render() {
        //console.log("ChartInspector show: ", this.state.show);

        const id = this.state.show ? 'simple-popover' : undefined;

        if (process.env.REACT_APP_CHART_INSPECTOR && process.env.REACT_APP_CHART_INSPECTOR === 'True') {    // show only for debug

            return (
                <React.Fragment>
                    <Button style={{margin: 0, padding: 0}} onClick={this.handleClick}><Visibility style={{ fontSize: 1 }} /></Button>
                    {this.state.show === true && <Popover 
                        id={id}
                        open={this.state.show}
                        //anchorEl={this.state.anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}
                        anchorOrigin={{vertical: 'top',horizontal: 'left',}}
                        onClose={this.handleClose}                   
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div>{this.props.title}&nbsp;<Button style={{backgroundColor: colors.bluegrey600}} onClick={() => {this.handleClose()}}>&nbsp;Close&nbsp;</Button></div>
                        <ReactJson
                            src={this.props} 
                            enableClipboard={this.handleCopy}
                            theme="tomorrow" collapsed={1} sortKeys={true}
                        />
                    </Popover>}         
                </React.Fragment> 
            );
        } else {
                return null
        }
        /*
        {

            Object.keys(this.props).map((key, i) => (
                <Typography>
                    <p>
                        <span>{key}: {this.props[key]}</span>
                        <pre>{key}: {JSON.stringify(this.props[key], null, 2)}</pre>
                    </p>
                </Typography>
            ))
        }
        */        
    }
}

export default ChartInspector;
