// Alloptions-sdraw.js

import React from 'react';
import clsx from 'clsx';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
//import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
//import SearchIcon from '@material-ui/icons/Search'
//import HomeIcon from '@material-ui/icons/Home'

//import SliderSwitch from '@material-ui/core/Switch'
//import FormControlLabel from '@material-ui/core/FormControlLabel'
import { BrowserRouter, Route, Switch, MemoryRouter, Link } from 'react-router-dom';


import ArtissLogo from '../ArtissLogo'
//import LoginDetails from '../login/LoginDetails'
import ProfileMenu from '../login/ProfileMenu'
import HomeDashboard from '../dashboards/HomeDashboard'
import RevenueDashboard from '../dashboards/RevenueDashboard'
import PayorDashboard from '../dashboards/PayorDashboard'
import GroupDashboard from '../dashboards/GroupDashboard'
import SubmissionDashboard from '../dashboards/SubmissionDashboard'
import DenialAdjustmentDashboard from '../dashboards/DenialAdjustmentDashboard'
import ObservationDashboard from '../dashboards/ObservationDashboard'
import PhysicianDashboard from '../dashboards/PhysicianDashboard'
import * as colors from '../Colours'
import UserContext from '../../context/UserContext';
import CPTstory from '../stories/CPTstory/CPTstory'
import ClaimStory from '../stories/Claimstory/Claimstory'
import PhysicianStory from '../stories/Physicianstory/Physicianstory'
import PayorStory from '../stories/Payorstory/Payorstory'
import RenderingProviderStory from '../stories/Renderingproviderstory/RenderingProviderstory'
import {fbanalytics} from '../login/Fbdetails'
import SearchRI from './Searchri'
import ReceivableDashboard from '../dashboards/ReceivableDashboard'
import ServiceClassStory from '../stories/Serviceclassstory/Serviceclassstory'

import SearchField from '../search/SearchField';
import SearchContext from '../../context/SearchContext';
import CustomerContext from '../../context/CustomerContext';
import CustomerSelector from '../widgets/CustomerSelector';

import {darkcolours, lightcolours} from './ThemeColours'
import RequisitionRouting from '../recommendations/RequisitionRouting';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logindetail: {
    position: 'absolute',
    right: '1%'
    },
content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  maincontent: {
      display: 'flex',
      flexDirection: 'column',
    //changed this from 100 to 10 to eliminate top space for dashboard. TBD if we need to keep it state-driven based on dashboard vs story
      marginTop: 10,
  },
  ab: {
    backgroundColor: colors.artissdark
  }
}));

export default function AllOptionsSwipeableDrawer(props) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    left: false,
    //open: false
  });
  const [darktheme, setDarkState] = React.useState(true)
  //const themechoice = darktheme ? 'dark' : 'light'
  const themechoice = darktheme ? darkcolours : lightcolours
  const [searchselect, setSearchSelect] = React.useState(false)
  //const [requestedState, setRequestedState] = React.useState("")

  const handleThemeChange = () => {
    console.log("handleThemeChange called");
    setDarkState(!darktheme)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const searchClick = (event) => {
    console.log("searchclicked")
    setSearchSelect(true)
  }

  const cancelSearch = (val) => {
    console.log("cancel search", val)
    setSearchSelect(val)
  }

  const user = React.useContext(UserContext);
  //console.log("AllOptionsSwipeableDrawer user: ", user);

  fbanalytics.logEvent('login', {method: 'google'})

  /* <ThemeProvider theme={theme} >  */ 

  return (
    <ThemeProvider theme={themechoice} >
    <CustomerContext.Consumer>
      {({customer, setCustomer}) => {
        console.log('Draweer---', customer)
        return (      
    <div >
        <CssBaseline />
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
            [classes.appBarShift]: state.left,
            },classes.ab)}
          >
            <Toolbar >
                <ArtissLogo base='white'/>
                <div style={{marginLeft: 50}}></div>

                <div style={{width: "48vw"}}>
                  <SearchContext.Consumer>
                    {({requested, setRequested}) => {
                      return (<SearchField 
                        classes={{ root: classes.inputRoot, input: classes.inputInput }}
                        context={{requested: requested, setRequested: setRequested, parent: "appbar"}} />)
                    }}
                  </SearchContext.Consumer>
                </div>

                <div style={{"marginLeft":"auto", "marginRight":20}}>
                  <CustomerSelector user={user} onSelected={setCustomer} />
                </div>
                <div style={{"marginRight":0}}> 
                  <ProfileMenu user={user} handleThemeChange={handleThemeChange} darktheme={darktheme} /> 
                </div>       
            </Toolbar>
        </AppBar>

        <div className={classes.maincontent}>
          <Switch> 
              <Route exact path="/" >   
                <HomeDashboard 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/homedashboard" >
                <HomeDashboard 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/revenuedashboard" >
                <RevenueDashboard 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/payordashboard" >
                <PayorDashboard 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/groupdashboard" >
                <GroupDashboard 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/submissiondashboard" >
                <SubmissionDashboard 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/denialadjustmentdashboard" >
                <DenialAdjustmentDashboard 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/observationdashboard" >
                  <CustomerContext.Consumer>
                    {({customer, setCustomer}) => {
                      return (<ObservationDashboard 
                        user={user} 
                        customer={customer} setCustomer={setCustomer} 
                      />)
                    }}
                  </CustomerContext.Consumer>
              </Route>
              <Route exact path="/physiciandashboard" >
                <PhysicianDashboard 
                    user={user} 
                    customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/recommendations/requisitionrouting" >
                <RequisitionRouting 
                  user={user} 
                  customer={customer} setCustomer={setCustomer} 
                />
              </Route>
              <Route exact path="/receivabledashboard"> 
                    <ReceivableDashboard customer={customer} setCustomer={setCustomer} />
              </Route>
              <Route exact path="/cptstory"> 
                    <CPTstory customer={customer} setCustomer={setCustomer} />
              </Route>
              <Route exact path="/claimstory" component={ClaimStory} />
              <Route exact path="/physicianstory"> 
                    <PhysicianStory customer={customer} setCustomer={setCustomer} />
              </Route>
              <Route exact path="/payorstory"> 
                    <PayorStory customer={customer} setCustomer={setCustomer} />
              </Route>
              <Route exact path="/renderingproviderstory"> 
                    <RenderingProviderStory customer={customer} setCustomer={setCustomer} />
              </Route>
              <Route exact path="/serviceclassstory"> 
                    <ServiceClassStory customer={customer} setCustomer={setCustomer} />
              </Route>
          </Switch>
        </div>

        {searchselect ? <SearchRI callback={cancelSearch} enable={true} /> 
          : <SearchRI callback={cancelSearch} enable={false} /> }
    
    </div>
    )}}
    </CustomerContext.Consumer>    
    </ThemeProvider>
  );
}