import React from "react";

import SearchContext from "../../context/SearchContext";
import UserContext from '../../context/UserContext'
import SearchResults from "./SearchResults";

export default function SearchContextConsumer(props) {
    //console.log("SearchContextConsumer called");
    return (
        <UserContext.Consumer>
        {({idtoken}) => {
            return (
                <SearchContext.Consumer> 
                    {({requested, setRequested}) => {
                        return (
                            <SearchResults idtoken={idtoken} requested={requested} setRequested={setRequested} />
                        );
                    }}
                </SearchContext.Consumer>
            )
            }}
        </UserContext.Consumer>
    );
}
  