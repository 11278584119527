// SubmissionAge.js
/// copyrights @ artiss.co

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { ResponsiveBar } from '@nivo/bar'
import * as colors from '../../Colours'

const SubmissionAge = (props) => {
    const classes = astyle();
    let {subdata, type} = props    
    let keys = []
    let sdata = {}
    console.log("submissionage",type, subdata)
    if( type === "Service to submission") {
        sdata = { index: 'Paid by Service to submission age' }
        for(let i=0; i<subdata.length; i++) {
            //let sab = subdata[i].submission_age_bucket
            let sab = subdata[i].age_bucket
            keys.push(sab)
            sdata[sab] = subdata[i].charge_amount
        }
        
    } else if(type === "Submission to Response") {
        sdata = { index: 'Paid by Service to Response age' }
        for(let i=0; i<subdata.length; i++) {
            //let sab = subdata[i].submission_age_bucket
            let sab = subdata[i].age_bucket
            keys.push(sab)
            sdata[sab] = subdata[i].payment_amount
        }
    }
    

    let chartdata = []
    if(subdata.length > 0)
        chartdata.push(sdata) 
    
    console.log("submission age ", chartdata)
        
    return(
        <Paper className={classes.cardpaper} >
            <Typography variant="subtitle1" display='inline'> {type} age</Typography>
            {chartdata.length === 0 ? 
                <Typography variant="body" display='inline'> no data for this date range</Typography>
            :   null }
            <ResponsiveBar
                        data={chartdata}
                        keys={keys}
                        layout="horizontal"
                        indexBy="index"
                        groupMode={'grouped'}
                        //height={25}
                        margin={{ top: 0, right: 10, bottom: 5, left: 10 }}
                        //innerPadding={4}
                        padding={0.2}
                        innerPadding={2}
                        colors={{ scheme: 'nivo' }}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        enableLabel={true}
                        labelFormat={["$", ".2s"]}
                        //labelFormat={{format: v => `${v}.2s`}}
                        labelSkipWidth={0}
                        labelSkipHeight={0}
                        //labelTextColor={{ from: 'color' }}
                        labelTextColor={'#000000'}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        enableGridY={false}
                        legends={[
                            {
                                dataFrom: 'keys',
                                itemTextColor: '#ffffff',
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                //translateX: -40,
                                translateY: 5,
                                itemsSpacing: 2,
                                itemWidth: 80,
                                //itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 10,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        theme={{ 
                            textColor: 'white',
                            tooltip: {
                                container: {
                                    background: '#333',
                                },
                            }
                        
                          }}
                        />  
                
                       
        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        //width: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        //alignItems: 'center',
        //backgroundColor: colors.primarydark,
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 5,
        paddingLeft: 10,
        paddingBottom: 5,
        margin: 10,
        height: 160
    },
    
    
}));

export default SubmissionAge;
