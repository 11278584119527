import React from "react";

/**
 * @component CustomerContext
 * @description customer context. See CustomerContextProvider for details
 */

export const CustomerContext = React.createContext({
    //customer:  {},
    //source_guid: "",
    //setCustomer: null,
});

export default CustomerContext;  