// TwoCards.js
// copyright @ artiss.co

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import * as colors from '../../Colours'
import podium from '../../images/podium.png'
import {toDateStr} from '../../services/Utils'

const RankCards = (props) => {
    const classes = astyle();
    //let {msg, card1, card2} = props
    let {data, type} = props

    //let dtstr = dt.getDate() + " " + months[dt.getMonth()] + " " + dt.getFullYear() 
    let dtstr = toDateStr(data.since_date)
    let totalcnt = 0
    if(type === 'cpt') {
        totalcnt = data.total_cpts
    } else if(type === 'physician') {
        totalcnt = data.total_physicians
    } else if(type === 'payor') {
        totalcnt = data.total_payors
    } else if(type === 'rendering providers') {
        totalcnt = data.total_renderers
    } else if(type === 'service class') {
        totalcnt = data.total_services
    }
    
    let paidrank = data.paid_rank
    let volrank = data.volume_rank
    let allowedrank = data.allowed_rank
    let auprank = data.aup_rank

    const nth = (n) => {return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}

    return(
        <Paper className={classes.cardpaper} > 
            <img src={podium} alt="rank" className={classes.podium} />
            <div className={classes.cardrow}>
            <Paper elevation={2} className={classes.scp}>
                <Typography variant="h4"> {paidrank}<sup>{nth(paidrank)} </sup></Typography> 
                <Typography variant="body2"> Paid </Typography> 
            </Paper>
            <Paper elevation={2} className={classes.scp}>
                <Typography variant="h4"> {volrank}<sup>{nth(volrank)} </sup></Typography> 
                <Typography variant="body2"> Volume </Typography> 
            </Paper>
            <Paper elevation={2} className={classes.scp}>
                <Typography variant="h4"> {allowedrank}<sup>{nth(allowedrank)} </sup></Typography> 
                <Typography variant="body2"> Allowed </Typography> 
            </Paper>
            <Paper elevation={2} className={classes.scp}>
                <Typography variant="h4"> {auprank}<sup>{nth(auprank)} </sup></Typography> 
                <Typography variant="body2"> AUP </Typography> 
            </Paper>
            </div>
            <Typography variant="caption" className={classes.outoftxt}> rank placement out of the total of {totalcnt} {type}</Typography> 
        </Paper>
    )        
            
}
     
    
const astyle = makeStyles((theme) => ({
    cardpaper: {
        // width: '80%',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-evenly',
        //alignItems: 'center',
        backgroundColor: theme.palette.primary.dark,
        paddingTop: 10,
        paddingBottom: 4,
        margin: 10
    },
    cardrow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: -10
    },
    outoftxt:{
        alignSelf: 'flex-end',
        paddingRight: 10,
        paddingTop: 5
    },
    scp: {
        backgroundColor: colors.secondarydark,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5
    },
    sctxt: {
        fontSize: 'large'
    },
    msg: {
        width: '50%'
    },
    podium: {
        width: 30,
        height: 30,
    }
}));

export default RankCards