// NodataMsg.js
// copyrights @artiss.co


import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const NoDataMsg = (props) => {
    const classes = astyle();
    
    return(
        <Paper className={classes.nod}>
            <Typography variant={'h6'} >
                No data available for this data range
            </Typography>
        </Paper>
    )

}

const astyle = makeStyles((theme) => ({
    nod: {
        //width: '80%',
        display: 'flex',
        justifyContent: 'center',
        margin: 20,
        padding: 10
        //flexDirection: 'row',
    },

}));

export default NoDataMsg;
    