// SubmissionText.js
// copyright @artiss.co

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import * as utils from '../../services/Utils'

const changeTrendIcon = (dir) => {
    if(dir === 'up') {
        return <TrendingUpIcon style={{color:'#76ff03'}} fontSize='default'/>
    } else if(dir === 'down') {
        return <TrendingDownIcon style={{color:'red'}} fontSize='default' />
    } else {
        return <TrendingFlatIcon />
    }
} 

const predictR = (ad) => {
    const classes = astyle();

    let pred = ad.projection_bucket; let msg = ''; let totalp = ad.projection_amount
    for(const [k, v] of Object.entries(pred)) { 
        if(v === 0) {continue}
        else {
            if(msg === '') {
                msg = 'Our prediction model forcast a receivable amount of ' + utils.showUSD_f1(totalp)
                        + ', with ' + utils.showPercent_f2(v/totalp)  + ' ' + k
            } else {
                msg += ', ' + utils.showPercent_f2(v/totalp) + k
            }
        }
    }
    let firstflag = true
    return(
        <>
        {Object.entries(pred).map((d,i) => {
            if(d[1] === 0 ) {return null}
            else {
                if(firstflag)  {
                    firstflag = false
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                            Our prediction model forcast a receivable amount of <span className={classes.td2}>{utils.showUSD_f1(totalp)} </span> with <span className={classes.td2} >{utils.showPercent_f2(d[1]/totalp)} {d[0]} </span>
                        </Typography>
                    )
                } else {
                    return(
                        <Typography key={i} display='inline' variant='subtitle1'>
                            , <span className={classes.td2} > {utils.showPercent_f2(d[1]/totalp)} {d[0]}</span> 
                        </Typography>
                    )

                }
            }

        })}
        </>
        )
    
}

const rAge = (ad, total) => {
    const classes = astyle();
        let abucket = ad.age_bucket; let ages = []
        for(const [k, v] of Object.entries(abucket)) {
            if(k === 'Total') {continue}
            else {
                if(v/total > 0.2) {
                    ages.push({age: k, amt: v/total})
                }
            }
        }
        //console.log("r ages---", ages)
        let msg = ''
        if(ages.length === 1) {
            msg += 'Majority of the receivable amount is in ' + ages[0].age + '(' + utils.showPercent_f1(ages[0].amt)  + ')' + '. '
            return(
                <Typography display='inline' variant='subtitle1' pl='5'>
                    Majority of the receivable amount is in <span className={classes.td2}> {ages[0].age} </span> ({utils.showPercent_f1(ages[0].amt)}).
                </Typography>
            )
        } else {
            for(let i=0; i<ages.length; i++) {
                if(i === 0) {msg += 'Majority of the receivable amount are in ' + ages[i].age +  '(' + utils.showPercent_f1(ages[i].amt)  + ') , '}
                else if( i < ages.length-1) {
                    msg += ages[i].age + '(' + utils.showPercent_f1(ages[i].amt) + ') , '
                } else {
                    msg += ages[i].age + '(' + utils.showPercent_f1(ages[i].amt) + ').'
                }
            }
            return(
                <>
                {ages.map((d,i, arr) => {
                    if(i === 0) { 
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                                Majority of the receivable amount are in <span className={classes.td2}> {d.age} </span>  ({utils.showPercent_f1(d.amt)})
                            </Typography>
                            )
                    } else if(i < arr.length-1) {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1'>
                                , <span className={classes.td2}> {d.age} </span> ({utils.showPercent_f1(d.amt)}) 
                            </Typography>
                        )
                    } else {
                        return(
                            <Typography key={i} display='inline' variant='subtitle1' >
                                , <span className={classes.td2}> {d.age} </span> ({utils.showPercent_f1(d.amt)}).
                            </Typography>
                        )
                    }
                    
                })}
                </>
            )
        }
}


const ReceivableSummaryText = (props) => {
    const classes = astyle();
    let rd = props.data.data
    //console.log( 'receivable summary ----', props.data)
    let smsg = ''
    let rdcp = rd.current_vs_previous
    let mnth = utils.monYearStr(rd.current_period.start)
    if(rd.measure === 'receivable') {
        smsg = 'Receivables for ' + mnth + ' is ' + utils.showUSD_f2(rd.measurement_amount)            
    }
    return(
        <Paper className={classes.pap}>
            <Typography variant="h6"> {smsg} </Typography>
            <Divider variant='fullWidth' light={true} />
            <div className={classes.dm} />
            {rAge(rd, rd.measurement_amount)}
            {predictR(rd)}
        </Paper>
    )
}


const astyle = makeStyles((theme) => ({
    pap: {
        marginTop: 5,
        marginLeft: 15,
        padding: 10,
        width: '50%'
    },
    dm: {
        paddingTop: 8
    },
    td1: {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.secondary.main
    }, 
    td2: {
        color: theme.palette.secondary.main
    }
}));

export {ReceivableSummaryText}

