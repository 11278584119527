// LoginPage.js
// copyrights @ artiss.co

import React from 'react';
import {withStyles} from '@material-ui/core/styles'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Typography } from '@material-ui/core';

import AllOptionsSwipeableDrawer from '../menu/AllOptionsSwipeableDrawer'
import * as colours from '../Colours'
import UserContext from '../../context/UserContext'
import SearchContextProvider from '../../context/SearchContextProvider'
import CustomerContextProvider from '../../context/CustomerContextProvider'
import {InitFirebase, fbanalytics, firebase} from './Fbdetails'

import * as EULAHandler from '../services/EULAHandler'
//import EULAView from '../eula/EULAView'
import EULAContainer from '../eula/EULAContainer';

InitFirebase();

const uiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
    }
}
//ui.start('#firebaseui-auth-container', uiConfig);


class LoginPage extends React.Component {
    constructor(props) {
        super(props)

        let logout = function(){
            //https://firebase.google.com/docs/auth/web/password-auth
            //https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuth#signOut()
            //https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signout
            //https://stackoverflow.com/questions/37376708/how-do-i-sign-out-users-in-firebase-3-0
            firebase.auth().signOut().then(function() {
                console.log('User signed out');
            }, function(error) {
                console.error('User signed out with error: ', error);
            });    
            window.location.reload();
        };
    
        this.state = {
            firebaseuser: null,
            isSignedIn: false, // Local signed-in state.
            name: null,
            email: null,
            photo: null,
            idtoken: null,
            logout: logout,
        };        
    }

    async componentDidMount() {        
        this.handleEULA();

        //ideally we should not have used idtoken/authtoken directly in the APIHandler, but used user instead, so we can refresh the JWT token within that handler.
        //however, since we've used idtoken across the codebase, thats not an easy option as it involves changes across the board
        //in lieu of that, we'll instead refresh the user idtoken every x minutes
        try {
            setInterval(async () => {
                console.log("getting refreshed token");
                let user = this.state.firebaseuser;
                if (user) {
                    user.getIdToken(true)
                    .then((idToken) => {
                        console.log("got refreshed token: ", idToken)
                        this.setState({firebaseuser: user, isSignedIn: !!user, name: user.displayName, 
                            email: user.email, photo: user.photoURL,
                            idtoken: idToken })
                    })
                    .catch((err) => {
                        console.log("get refreshed token error: ", err)
                    })        
                }
            }, 1000 * 60 * 30);//every 30 minutes
        } catch(e) {
            console.log("get refreshed token error 2: ", e);
        }        

        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => { 
            console.log("user Auth state changed: user: ", user)
            //catch condition when user logs out
            if (user) {
                user.getIdToken(true)
                .then((idToken) => {
                    console.log("got idToken", idToken)
                    this.setState({firebaseuser: user, isSignedIn: !!user, name: user.displayName, 
                        email: user.email, photo: user.photoURL,
                        idtoken: idToken })
                })
                .catch((err) => {
                    console.log("get token error", err)
                })    
            }
        })        
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.isSignedIn !== prevState.isSignedIn || 
            this.state.email !== prevState.email) {
            console.log("componentDidUpdate: this.state: ", this.state);
            console.log("componentDidUpdate: prevState: ", prevState);
            this.handleEULA();        
        }
    }

    //get EULA status
    async handleEULA() {
        let user = this.state;  
        if (user.isSignedIn === true) {
            let handleEULAResponse = await EULAHandler.handleEULA(user);
            console.log("handleEULAResponse: ", handleEULAResponse);
            //this.setState({ handleEULAResponse: handleEULAResponse });      
            if (handleEULAResponse.status==="success" && handleEULAResponse.showEULA === true) { //show EULA
                //return (<EULAView handleEULAResponse={handleEULAResponse} user={user} />);            
                EULAContainer.show(null, user, handleEULAResponse)
            }    
        }
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        const {classes} = this.props
        fbanalytics.logEvent('RI started');

        if (!this.state.isSignedIn) {
            return(
                <div className={classes.top} >
                    <img src={require('../images/artiss-logo-v1.png')} alt='artiss logo' style={{height: 150, width: 300}}/>
                    {/*<div className={classes.artisslogo}> ARTISS </div> */}
                    <div className={classes.welcome}>Revenue Insight</div>

                    {/* <div className={classes.fauth} id="firebaseui-auth-container" /> */}
                    <StyledFirebaseAuth className={classes.fauth} uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>

                </div>
            )        
        }

        console.log("before return", this.state.idtoken)

        let user = this.state;  
        return (            
            <UserContext.Provider value={user}>                    
                    <SearchContextProvider>
                        <CustomerContextProvider>
                            <EULAContainer /> 
                            <AllOptionsSwipeableDrawer />
                        </CustomerContextProvider>    
                    </SearchContextProvider>
            </UserContext.Provider>                
        );  
    }
}

const astyle = {
    top: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2%',
        //width: 800,
        //backgroundColor: colours.bluegrey100,
        borderRadius: 5
    },
    artisslogo: {
        fontSize: 'xx-large',
        fontWeight: 'bold',
        letterSpacing: 3,
        color: colours.purple600,
        justifyContent: 'flex-start'
    },
    welcome: {
        marginTop: 5,
        fontSize: 'medium',
        letterSpacing: 3,
        color: 'grey'
    },
    fauth: {
        marginTop: 50

    }
  }

export default withStyles(astyle)(LoginPage);